<template>
    <div class="main-input">
        <div class="input" :class="{invalid : errortext.length,valid : value}">
            <div style="position: relative">
                <label v-show="!value" :for="name">{{faname}}</label>
                <input @input="$emit('send',value)" v-model="value" :type="type1" :name="name" id="name">
                <img @click.prevent="toggle('text')" v-show="type==='password' && type1==='password' && value"
                     src="../../assets/icon/eye.svg" alt="">
                <img @click.prevent="toggle('password')" v-show="type==='password' && type1==='text' && value"
                     src="../../assets/icon/hide.svg" alt="">
            </div>
            <div class="bottom-border"></div>
        </div>
        <div class="invalid-alert">
            {{errortext}}
        </div>
    </div>
</template>

<script>
    export default {
        name: "AliInput",
        props: ['type', 'name', 'errortext', 'faname'],
        data() {
            return {
                value: '',
                type1: this.type
            }
        },
        methods: {
            toggle(e) {
                this.type1 = e
            }
        }

    }
</script>

<style scoped>
    .main-input {
        width: 100%;
        margin: 10px 0;
        height: 45px;
    }

    .input input {
        direction: ltr;
        text-align: left;
        width: 100%;
        height: 100%;
        padding: 10px 35px 10px 10px;
        font-size: 14px;
        color: black;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        background: transparent;
    }

    label {
        position: absolute;
        right: 10px;
        /*padding: 10px 0;*/
        top:50%;
        transform: translateY(-50%);
        font-size: 14px;
        color: black;
        pointer-events: none;
        transition: 0.23s;
        /*margin-bottom: 10px;*/
        opacity: 0.8;
    }


    .invalid {
        color: var(--main-red);
    }

    .invalid input,
    .invalid label {
        color: var(--main-red);
        border-color: var(--main-red);
    }

    .invalid .bottom-border {
        background-color: var(--main-red);
    }

    .invalid-alert {
        margin-top: 5px;
        font-size: 10px;
        transition: 0.3s;
        color: var(--main-red);
    }

    .input img {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translate(0, -50%);
    }
</style>