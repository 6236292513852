<template>
    <div class="main-box">
        <div v-for="(content,index) in headerData" :key="index" class="block" @click="routing(index)">
            <img :src="require('../../assets/icon/'+index+'.png')" alt="">
            <div class="price-main">
                <div class="price">
                    <div> بهترین خرید <a style="min-width:20px;display:inline-block">{{content.buy !== 'null'? content.buy.slice(0,6) : ' - '}}</a> تومان</div> 
                    <!-- <div v-else style="display:flex"> بهترین خرید <p style="margin:0;color:red;margin-right:4px">-</p></div> -->
                </div>
                <div class="price">
                    <div> بهترین فروش <a style="min-width:20px;display:inline-block">{{content.sell !== 'null' ? content.sell.slice(0,6) : ' - ' }}</a> تومان </div> 
                    <!-- <div v-else style="display:flex"> بهترین فروش <p style="margin:0;color:red;margin-right:4px">  تومان </p></div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DashboardHeader",
        props:['toid','headerData'],
        watch:{
            headerData:function(val){
                console.log(val);
                for (let i in val){
                    console.log(val[i].sell);
                }
            }
        },
        data(){
            return{
                links:{
                    BITCOIN:0,ETHEREUM:1,BITCOIN_CASH:2,LITE_COIN:3,RIPPLE:4,TETHER:5,DASH:6,
                }
            }
        },
        methods:{
            routing(e){
                // console.log();
                this.$router.push('/dashboard/' + this.toid + '/' + this.$coinArray2[e])
            }
        }
    }
</script>

<style scoped>
    .main-box{
        width: 100%;
        height: 235px;
        display: flex;
        overflow: auto;
        flex-direction: row;
        /* justify-content: space-between; */
        align-items: center;
        font-size: 10px;
        padding: 0 5px;
        margin-bottom: 10px;
        background-color: var(--dark-background) !important;
    }
    .block{
        width: 100%;
        /* max-width: 150px; */
        min-width: 150px;
        border-radius: 5px;
        /*border: 1px solid var(--light-border);*/
        padding: 10px 5px;
        margin: 0 3px ;
        cursor: pointer;
        transition: 0.2s;
        background-color: white;
    }
    .block:hover{
        background-color: var(--dark-background);
        color: white;
    }
    .block img{
        width: 105px;
        margin: 0 0 20px;
    }
    .price{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 10px 0;
    }
    .price:first-of-type a{
        color: var(--main-green);
    }
    .price:last-of-type a{
        color: var(--main-red);
    }

    .price>div{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

</style>