<template>
    <div class="main">
        <div class="row">
            <div class="col-lg-9 col-sm-12 row no-gutters">
                <!-- <button @click="getBestPrice">cliiiiick</button> -->
                <dashboard-header :toid="tradetoid" :headerData='forHeader' class="box"></dashboard-header>
                <account-level class="box"></account-level>
                <account-wage class="box"></account-wage>
                <dashboard-wallet class="box" :toid="tradetoid"></dashboard-wallet>
            </div>
            <div class="col-lg-3 col-sm-12 no-gutters">
                <div class="sidebar col-12 pad row no-gutters">
                    <real-price-table-toman
                            :table-contents="realpricetoman"
                            class="box"
                            :toid="tradetoid"
                    ></real-price-table-toman>
                    <!-- <button @click="getLastTrades">clickkk</button> -->
                    <last-trade
                            class="box"
                            :key="tradetoid + '-' + tradefromid"
                            :class="{ dark: theme === 'dark' }"
                    />
                </div>
            </div>
            <div class="col-12 row no-gutters">
                <div class="col-md-6 col-12 pad pl-1">
                    <active-order
                            :tableContents="activeordertable"
                            class="box"
                            :class="{ dark: theme === 'dark' }"
                            :to="tradeto[tradetoid]"
                            :from="$coinLabel[$coinArray[tradefromid]]"
                            @removeorder="removeorder($event)"
                            :isEmpty="fromTrade"
                    ></active-order>
                </div>

                <div class="col-md-6 col-12 pad pr-md-1 pr-0">
                    <last-order
                            :tableContents="lastordertable"
                            class="box"
                            :class="{ dark: theme === 'dark' }"
                            :to="tradeto[tradetoid]"
                            :from="$coinLabel[$coinArray[tradefromid]]"
                            :isEmpty="emptyLastOrder"
                    ></last-order>
                </div>
            </div>
        </div>
        <completed-modal
                v-show="completedmodal"
                :text="'درخواست شما با موفقیت لغو شد.'"
                :theme="theme"
        ></completed-modal>
        <ErrorNotification/>
    </div>
</template>

<script>
    import LastTrade from "../Trade/LastTrade";
    import LastOrder from "../Trade/LastOrder";
    import ActiveOrder from "../Trade/ActiveOrder";
    import CompletedModal from "@/components/Tools/CompletedModal";
    import RealPriceTableToman from "@/components/Trade/RealPrice/RealPriceTableToman";
    import DashboardHeader from "@/components/Dashboard/DashboardHeader";
    import AccountLevel from "@/components/Dashboard/AccountLevel";
    import AccountWage from "@/components/Dashboard/AccountWage";
    import DashboardWallet from "@/components/Dashboard/DashboardWallet";
    import ErrorNotification from "../Tools/ErrorNotification";

    export default {
        name: "Dashboard",
        props: ["tradetoid", "tradefromid"],
        components: {
            DashboardWallet,
            AccountWage,
            AccountLevel,
            DashboardHeader,
            RealPriceTableToman,
            CompletedModal,
            ActiveOrder,
            LastOrder,
            LastTrade,
            ErrorNotification
        },
        data() {
            return {
                realpricetoman: [],
                lasttradetable: [],
                lastordertable: [],
                activeordertable: [],
                depthTableSell: [],
                depthTableBuy: [],
                maintable: {},
                tradefrom: [
                    "بیتکوین",
                    "اتریوم",
                    "بیتکوین کش",
                    "لایت کوین",
                    "ریپل",
                    "تتر",
                    "دش",
                ],
                tradeto: ["تومان", "تتر"],
                coin: ["BTC", "ETH", "BCH", "TRX", "XRP", "USDT"],
                coinComplete: [
                    "BITCOIN",
                    "ETHEREUM",
                    "BITCOIN_CASH",
                    "LITE_COIN",
                    "RIPPLE",
                    "TETHER",
                    "DASH"
                ],
                tradetoEng: ["TOMAN", "TETHER"],
                completedmodal: "",
                theme: "light",
                toLastTrade: null,
                emptyLastOrder: null,
                fromTrade: null,
                coinsList: 'coins=BITCOIN&coins=ETHEREUM&coins=BITCOIN_CASH&coins=RIPPLE&coins=TETHER&coins=LITE_COIN&coins=DASH',
                forHeader: "",
                toMyWallet: [],
                walletCoins: {
                    "BTC": 'بیتکوین',
                    "ETH": "اتریوم",
                    "BCH": "بیتکوین کش",
                    "TRX": "ترون",
                    "XRP": "ریپل",
                    "USDT": "تتر"
                },

            };
        },
    
        methods: {

            getBestPrice() {
                console.log('getting Best Price');
                this.$axios(`/orders/dash-board?type=${this.tradetoEng[this.tradetoid]}`)
                    .then(res => {
                        console.log(res.data);
                        this.forHeader = res.data
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
            getLastTrades() {
                this.$axios(
                    `/trades/last-trades?marketType=${
                        this.$coinArray[this.tradefromid]
                    }_${this.tradetoEng[this.tradetoid]}&page=1&size=20`
                )
                    .then((response) => {
                        // console.log('fetched from last trades:',response.data.content);

                        if (response.data.content.length !== 0) {
                            this.lasttradetable = response.data.content;
                        } else {
                            this.toLastTrade = "داده ای جهت نمایش یافت نشد.";
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
            getLastOrders() {
                this.$axios(
                    `/orders?market=${
                        this.$coinArray[this.tradefromid]
                    }_${this.tradetoEng[this.tradetoid]}&page=1&size=20`
                )
                    .then((res) => {
                        console.log("from last orders");
                        if (res.data.content) {
                            // console.log(res.data.content);
                            // console.log("fetched");

                            const result = res.data.content.filter(i => i.orderStatus !== 'IS_OPEN');
                            console.log(result, 'heeeeeey');
                            if (result.length === 0) {
                                this.emptyLastOrder = "داده ای جهت نمایش یافت نشد.";
                            } else {
                                this.lastordertable = result
                            }

                        } else {
                            this.emptyLastOrder = "داده ای جهت نمایش یافت نشد.";
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.state.errorMessage = "خطایی رخ داده است. مجددا تلاش کنید";
                        this.state.showError = true;
                    });
            },
            getActiveOrders() {
                console.log("get Active Orders");
                // console.log(this.$coinLabel[this.tradefromid],this.tradeto[this.tradetoid])
                // console.log(this.tradetoEng[this.tradetoid],this.coinComplete[this.tradefromid])
                this.$axios(
                    `/orders?orderStatus=IS_OPEN&market=${
                        this.$coinArray[this.tradefromid]
                    }_${this.tradetoEng[this.tradetoid]}`
                )
                    .then((res) => {
                        // console.log(res)
                        if (res.data.content) {
                            this.activeordertable = res.data.content;
                        } else {
                            this.fromTrade = "داده ای جهت نمایش یافت نشد.";
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.state.errorMessage = "خطایی رخ داده است. مجددا تلاش کنید";
                        this.state.showError = true;
                    });
            },
            removeorder(e) {
                console.log("gonna be remove", e);
                this.$axios
                    .delete(`orders/${e}`)
                    .then((res) => {
                        console.log(res);
                        if (res.data.message === "deleting of order was successful") {
                            this.completed();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.state.errorMessage = "خطایی رخ داده است. مجددا تلاش کنید";
                        this.state.showError = true;
                        console.log(this.state.showError, this.state.errorMessage);
                    });
            },
            completed() {
                this.completedmodal = true;
                setTimeout(() => {
                    this.completedmodal = false;
                }, 1500);
            },
        },
        created() {
            this.state.resetpassword = false;
            if (localStorage.theme) {
                this.theme = localStorage.theme;
            }
        },
        mounted() {
            this.getLastTrades()
            this.getLastOrders()
            this.getActiveOrders()
            this.getBestPrice()
        },
    };
</script>

<style lang="scss" rel="stylesheet" src="../../assets/bootstrap-grid-rtl-2/bootstrap-grid-rtl.scss" scoped></style>
<style scoped>
    .main {
        width: 100%;
        max-width: 1440px;
        padding: 0 10px;
        margin-top: 57px;
        padding-top: 10px;
    }

    .pad {
        padding-bottom: 10px;
    }

    .box {
        background-color: white;
        border-radius: 5px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    }

    .dark {
        background-color: var(--dark-main) !important;
        color: white !important;
    }

    .chart-page {
        background-color: transparent !important;
    }

    body #widget-container {
        border: none !important;
    }

    .sidebar {
        position: sticky;
        position: -webkit-sticky;
        top: 56px;
    }

    .sidebar .box:first-of-type {
        margin-bottom: 10px;
    }
</style>