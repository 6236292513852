<template>
  <div class="main-box">
    <h1 style="text-align: right">راهنمای استفاده</h1>
    <div
      class="message-block"
      v-for="(message, index) in content"
      :key="showmessage +' - '+ index"
    >
      <div @click.prevent="toggle(message.id, index)" class="message-header">
        <div class="header-header">
          <img src="../../assets/icon/question.svg" alt="" />
          <h3>{{ message.title }}</h3>
        </div>
        <img
          v-show="!showmessage[index]"
          width="15px"
          height="15px"
          src="../../assets/icon/plus.svg"
          alt=""
        />
        <img
          v-show="showmessage[index]"
          width="15px"
          height="15px"
          src="../../assets/icon/minus.svg"
          alt=""
        />
      </div>
      <div class="message-description" style="overflow: hidden" :style="{
            height: showmessage[index]? '100%' : '0'
          }">
        <froalaView v-model="message.html"></froalaView>
      </div>
    </div>

    <!-- <button @click="getHtml">click</button> -->
  </div>
</template>

<script>
// const sleep = (e) => {
//   return new Promise((res) => setInterval(res, e));
// };

export default {
  name: "HowToUse",
  data() {
    return {
      showmessage: [],
      htmlFile: "",
      messages: null,
      content:[]
    };
  },
  methods: {
    getData(){
      this.$axios.get('/user-manual')
          .then(res=>{
              this.content = res.data
              for (let i=0;i<this.content.length;i++){
                this.showmessage[i] = false
              }
          })
    },
    toggle(e, i) {
        // console.log(this.showmessage[i],i)
      this.showmessage[i] = !this.showmessage[i];
      this.$forceUpdate()
      // this.showHtml(e, i);
    },
  },
  mounted(){
    this.getData()
  }
};
</script>

<style lang="scss" scoped>
.main-box {
  display: flex;
  flex-direction: column;
  /*align-self: flex-start;*/
  /*background-color: white;*/
  // position: absolute;
  // top:0;
  direction: rtl;
  text-align: right;
  box-sizing: border-box;
  width: 100%;
  // max-width: 1300px;
  padding: 0 25px;
  margin: 56px 0;
  font-size: 18px;
  font-family: VistaDev, sans-serif;
}
h1 {
  font-size: 22px;
  margin-right: 20px;
  margin-top: 50px;
}

.message-block {
  display: block;
  flex-direction: column;
  align-items: center;
  /*background-color: white;*/
  text-align: right;
  border-bottom: #00000015 solid 1px;
  box-sizing: border-box;
  width: 100%;
  font-size: 18px;
  transition: 0.3s;
}
.message-header {
  position: relative;
  display: flex;
  height: 61px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: 15px;

  img {
    margin-left: 10px;
  }
}

.message-header h3 {
  font-family: VistaDev, sans-serif;
  font-weight: 300;
  font-size: 18px;
  opacity: 0.8;
}

.message-description {
  font-size: 18px;
  transition: 0.4s ease-out;
  -webkit-transition: 0.4s ease-out;
  -moz-transition: 0.4s ease-out;
  // max-height: 300px;

  p {
    margin-top: 0;
    opacity: 0.8;
  }
  iframe {
    transition: .5s;
    // height: auto;
    // width: 100%;
    // overflow: visible;
    // display: flex;
  }
}

.header-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    margin: 0 10px;
  }
}
</style>
<style>
p[data-f-id]{
  display: none;
}
</style>