<template>
  <div class="main-box">
    <h2 style="text-align: right">حساب کاربری</h2>
    <h4
      v-if="!state.userinfo.authorized && state.userinfo.pending"
      style="color: red; text-align: center"
    >
      حساب کاربری شما در دست بررسی است لطفا شکیبا باشید
    </h4>
    <!-- <h4 v-if="!state.userinfo.authorized && state.userinfo.pending" style="color:red;text-align:center">جهت تکمیل فرایند احراز هویت میبایست کد ارسال شده</h4> -->
    <div class="box2">
      <personal-information :birth="toBirth" :idApproved="idApproved"/>
      <bank-information :bankInformation="toBankInformation" :bankError="Navid"/>
      <upload-documents :idApproved="idApproved"/>
      <button @click="submit" type="submit" class="normal-btn"
        :disabled="!state.userinfo.bankAccountsString[state.userinfo.bankAccountsString.length - 1].shebaCode ||
         !state.userinfo.bankAccountsString[state.userinfo.bankAccountsString.length - 1].cardNumber ||
          state.userinfo.birthDay === null ||
           state.userinfo.firstName === '' ||
            state.userinfo.lastName === '' ||
             state.userinfo.nationalCode === '' ||
              state.userinfo.telephone === '' ||
               state.userinfo.mobileNumber === '' ||
                state.userinfo.address === '' ||
                 !state.userinfo.image ||
                  waitForFino
        "
      >
        ثبت تغییرات
      </button>
      <!-- <button @click="check">click to fetch</button> -->
      <!-- <button @click="removeDash(state.userinfo.bankAccountsString)">click to bank</button> -->
    </div>
    <div v-if="showToastVar" class="main-toast">
      <div class="toast">
        <h4>اطلاعات شما با موفقیت ذخیره شد.</h4>
        <span id="toast-line"/>
      </div>
    </div>
    <ErrorNotification/>
    <Loading v-if="state.loading"/>
  </div>
</template>

<script>
import PersonalInformation from "@/components/UserAccount/PersonalInformation";
import BankInformation from "@/components/UserAccount/BankInformation";
import UploadDocuments from "@/components/UserAccount/UploadDocuments";
import Loading from "../Tools/Loading";
import ErrorNotification from "../Tools/ErrorNotification";


export default {
  name: "Profile",
  components: {
    UploadDocuments,
    BankInformation,
    PersonalInformation,
    Loading,
    ErrorNotification,
    
  },
  data() {
    return {
      toPersonalInformation: {},
      toBankInformation: {},
      res: "",
      toBirth: "",
      showToastVar: false,
      toastWidth: 0,
      idApproved:false,
      Navid: "",
      showErrorModalVar: false,
      showFino: false,
      waitForFino:false
    };
  },
  methods: {
    async check() {
      this.state.loading = true
      let bankinfo;
      // console.log(localStorage.token)
      await this.$axios
        .get("/users/account")
        .then((response) => {
          console.log(response.data.baseDTO);
          this.res = response.data.baseDTO;
          
          this.toBankInformation = response.data.baseDTO.bankAccounts;
          if (response.data.baseDTO.bankAccounts.length === 0) {
            bankinfo = [{ shebaCode: "", cardNumber: "", bankName: "" }];
          } else {
            bankinfo = response.data.baseDTO.bankAccounts;
          }
          if (this.res.authenticationType === 'AUTHORIZED' || this.res.authenticationType ==="INFORMATION_CHANGED"){
            this.idApproved = true
          }
          this.state.userinfo = {
            authorized: this.res.authenticationType === "AUTHORIZED",
            pending: this.res.authenticationType === "PENDING",
            authenticationType: this.res.authenticationType,
            firstName: this.res.firstName,
            lastName: this.res.lastName,
            email: this.res.email,
            nationalCode: this.res.nationalCode,
            birthDay: this.res.birthDay,
            mobileNumber: this.res.mobileNumber,
            telephone: this.res.telephone,
            address: this.res.address,
            bankAccountsString: bankinfo,
            image: this.res.image,
            imageUploaded: this.res.image === '',
            towStepAuthenticationType : this.res.towStepAuthenticationType

          };
          console.log(this.state.userinfo.imageUploaded);
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          
          // this.showLoading = false;
        });
      console.log("aaaaaaa", this.state.userinfo);
    },
    fromBank(item) {
      console.log("from bank:", item);
    },
    changeBirthDay(birth) {
      console.log("changing", birth);
      let changedTime = "";
      if (birth !== null) {
        changedTime =
          birth.slice(6, 10) +
          "/" +
          birth.slice(3, 5) +
          "/" +
          birth.slice(0, 2);
      }

      return changedTime;
    },
    updateBank(value) {
      this.bankaccounts = value;
    },
    sendBack(birth) {
      let toBack =
        birth.slice(8, 10) + "-" + birth.slice(5, 7) + "-" + birth.slice(0, 4);
      return toBack;
    },
    showToast() {
      
      let a = setInterval(() => {
       
        this.toastWidth = this.toastWidth + 1;
        document.getElementById(
          "toast-line"
        ).style.width = `${this.toastWidth}px`;
        if (this.toastWidth >= 322) {
          clearInterval(a);
          this.showToastVar = false;
          this.toastWidth = 0;
        }
      }, 15);
    },
    removeDash(item) {
      console.log(item);
      for (let i = 0; i < item.length; i++) {
        item[i].cardNumber = item[i].cardNumber.replace(/-/gi, "");
        console.log(item[i].cardNumber);
      }
    },
    bankCheck(item) {
      for (let i = 0; i < item.length; i++) {
        if (!item[i].cardNumber || !item[i].shebaCode) {
          alert("شماره شبا یا کارت بانکی را وارد نکرده اید");
          return false;
        } else {
          return true;
        }
      }
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    async submit() {
      this.state.loading = true;
      
      let bankinputcheck = this.bankCheck(
        this.state.userinfo.bankAccountsString
      );
      this.removeDash(this.state.userinfo.bankAccountsString);
      console.log("birthday", this.state.userinfo.birthDay);

      var form_data = new FormData();
      for (let key in this.state.userinfo) {
        form_data.append(key, this.state.userinfo[key]);
      }
      form_data.delete("bankAccountsString");
      let bankAccountsss = JSON.stringify(
        this.state.userinfo.bankAccountsString
      );
      form_data.append("bankAccountsString", bankAccountsss);
     
        // console.log(this.state.userinfo.imageUploaded,this.state.userinfo.image);
        if (this.state.userinfo.imageUploaded){
          // console.log('uploaaaad');
          form_data.append("file", this.state.userinfo.image);
        }else{
          // console.log('NOT uploaaaad');
          const file = this.dataURLtoFile(this.state.userinfo.image, "image");
          // fileReader.readAsDataURL(this.state.userinfo.image);
          form_data.append("file", file);
        }
      if (bankinputcheck) {
        await this.$axios
          .post("/users/account", form_data)
          .then((response) => {
            this.res = response.data;
            console.log(this.res);
            if (
              this.res.message ==="User Updated Successfully, need to be verified"
            ) {
              // console.log(this.state.userinfo.authenticationType);
                console.log('go to otp');
                this.state.loading = true;
                this.$axios(`/finotech/send-sms-to-customer?email=${this.state.userinfo.email}`)
                .then(res=>{
                  this.waitForFino = true
                  console.log(res);
                  if (res.data.message === 'Sms sent to customer.'){
                    this.$router.push('/verifying-page')
                  }
                })
            }
            else if(this.res.message === "User Updated Successfully"){
              this.$error('', 'اطلاعات با موفقیت ذخیره شد', 'success')
              console.log('not fino');
            }
                
              else{
                this.showToastVar = true
                this.showToast()
              }
          })
          .catch((error) => {
            // this.showLoading = false;
            if (error.response.data.message === "MOBILE"){
              this.state.errorMessage = "شماره همراه تکراری میباشد.";
              this.state.showError = true;
            }else if(error.response.data.message === 'NATIONAL_CODE_IS_INCORRECT'){
                    this.state.errorMessage = "کد ملی تکراری میباشد.";
                    this.state.showError = true;
            }
            if (
              error.response.data.message.includes(
                "ShebaCode or CardNumber Is For Another User"
              )
            ) {
              
              let start = error.response.data.message.indexOf("{");
              let DuplicateError = error.response.data.message.slice(start);
              this.state.userinfo.duplicateBank = DuplicateError;
              console.log(this.state.userinfo.duplicateBank);
              this.Navid = DuplicateError;
            }
          })
         
      }
    },
  },
  mounted() {
    console.log(this.state.userinfo.pending, this.state.userinfo.authorized);
    this.check();
    // this.showToast()
  },

  watch: {
    "this.state.userinfo.pending": (val) => {
      console.log(val);
    },
  },
};
</script>

<style scoped>
.normal-btn {
  color: white;
  font-size: 14px;
  width: 30%;
  min-width: 137px;
  margin: 34px;
  border-radius: 5px;
}

.main-box {
  /*display: grid;*/
  /*justify-self: flex-start;*/
  /*position: absolute;*/
  top: 0;
  background-color: transparent;
  text-align: right;
  margin-top: 56px;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1300px;
}

h2 {
  font-family: IRANSans-Bold, sans-serif;
  font-size: 22px;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-right: 10px;
}

.box2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*background-color: white;*/
  text-align: right;
  width: 100%;
}
.normal-btn {
  max-width: 300px;
}

.main-toast {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  /* backdrop-filter: blur(3px); */
  margin-right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 500;
}

.toast {
  width: 350px;
  height: 100px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 2px 2px 2px 2px #efebeb;
  overflow: hidden;
}

.toast > span {
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 14px;
  background: rgb(152, 194, 90);
  width: 0;
  height: 3px;
  border-radius: 50px;
}
  p[data-f-id="pbf"]{
    display: none;
  }
</style>