<template>
    <div class="loading">
        <lottie-player :options="options" :src="require('../../assets/loading.json')"/>
    </div>
</template>

<script>
export default {
    name:'Loading',
    data(){
        return{
            options:{
                autoplay:true,
                speed:1.2,
                loop:true,
                width:'150px',
                height:'150px'
            }
        }
    }
}
</script>

<style scoped>
    .loading{
        width: 100vw;
        height: calc(100vh);
        /* margin-top: 54px; */
        background: #e2e2e27a;
        position: fixed;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
    }
</style>