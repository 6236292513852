<template>
    <div class="Auth-box">
        <div class="Auth-header">
            <h3>فراموشی رمز عبور</h3>
        </div>
        <form class="Auth" @submit.prevent="gotoresetpass">
            <ali-input @send="receive($event)" :type="'email'" :name="'email'" :errortext="emailError"
                       :faname="'ایمیل'"></ali-input>
            <button type="submit" class="normal-btn"  :disabled="!email || emailError.length>0">ارسال ایمیل
            بازیابی
            </button>
        </form>
        
        <Loading v-if="showLoading"/>
    </div>
</template>

<script>
    import AliInput from "@/components/Tools/AliInput";
    import Loading from '../Tools/Loading';

    export default {
        name: "RecoveryPass",
        components: {AliInput,Loading},
        data() {
            return {
                email: '',
                emailError: '',
                res:'',
                showLoading:false,
            }
        },
        methods: {
            checkEmail() {
                console.log('aaaaa')
                if (!this.email) {
                    this.emailError = 'ایمیل اجباری';
                } else if (!this.validEmail(this.email)) {
                    this.emailError = 'ایمیل صحیح وارد نمایید';
                } else {
                    this.emailError = ''
                }
                if (!this.emailError.length) {
                    return true;
                }
            },
            validEmail(email) {
                var re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                return re.test(email);
            },
            receive(e) {
                this.email = e
                this.checkEmail(e)
            },
            gotoresetpass() {
                this.showLoading = true
                if (this.checkEmail()) {
                    console.log(this.email)
                    let resetEmail = {'email':this.email}
                    console.log(resetEmail)
                    this.$axios.post(`/users/forget-password?email=${this.email}`)
                    .then(response => {this.res = response.data;
                    if (response.data.message === "code sent successfully."){
                        this.showLoading = false
                        this.state.userinfo.towStepAuthenticationType = response.data.baseDTO.towStepAuthenticationType
                        this.state.userinfo.mobileNumber = response.data.baseDTO.starTwoStepAuthentication || '';
                        localStorage.email = this.email
                        this.state.email = this.email;
                        console.log(this.state.userinfo);
                        this.$router.push('reset-pass')
                    }

                    console.log(response.data)
                    })
                    .catch(error=>{
                        this.showLoading = false
                        console.log('error message',error)
                    })
            }
            }
        }
    }
</script>

<style scoped>

    .Auth-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 400px;
        height: 100%;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 50px 0 70px;
    }

    .normal-btn {
        margin-bottom: 80px;

    }
</style>
