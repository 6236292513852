<template>
    <div class="box1">
        <h3>خریداران</h3>
        <perfect-scrollbar :options="settings">
            <table >
                <tr class="table-header">
                    <th>مقدار <a style="font-size: 10px">({{base}})</a></th>
                    <th>قیمت واحد <a style="font-size: 10px">({{to}})</a></th>
                    <th>مجموع <a style="font-size: 10px">({{to}})</a></th>
                </tr>
                <LoadingModal v-if="showloading === true"/>
                <p v-else-if="tableContentsBuy.length===0" style="width:100%;position:absolute">{{notif}}</p>
                <tr v-for="(tableContent,index) in tableContentsBuy" :key="index"
                    class="table-row" v-else-if="showloading === false && tableContentsBuy.length!==0">
                    <td>{{tableContent.volume-tableContent.executedAmount}}</td>
                    <td>{{tableContent.unitPrice.toLocaleString()}}</td>
                    <td style="color:var(--main-green)">{{tableContent.wholePrice.toLocaleString()}}</td>
                    <span :style="{width: tableContent.executedPercent + '%'}" class="percent2"></span>
                </tr>
            </table>
        </perfect-scrollbar>
    </div>
</template>

<script>
import LoadingModal from '../LoadingModal'
    export default {
        name: "MarketDepthBuy",
        components:{LoadingModal},
        props: ['base', 'to', 'tableContentsBuy','showloading'],
       
        watch:{
            'state.amount'(value){
                console.log(value)
            },
            base:function(val){
                console.log('herre here from buy table',val)
            },
            tableContentsBuy:function(val){
                if (val.length === 0){
                    this.notif = 'در حال حاضر اطلاعاتی وجود ندارد'
                }
                // console.log('table data',val)
            }
        },
        data() {
            return {
                notif:'',
                settings:{
                    suppressScrollX :true
                }
            }
        },
    }
</script>

<style scoped>
    .ps {
        height: 260px;
        width: 100%;
    }
    .box1 {
        font-family: VistaDev,sans-serif;
        font-size: 14px;
        /*height: 900px;*/
        border-top: 5px solid var(--main-green);
    }
    h3{
        font-size: 16px;
        margin: 10px 0 ;
    }
    .dark a{
        color: white;
    }

    table {
        width: 100%;
        align-items: center;
        border-radius: 5px;
        border-collapse: collapse;
        /*direction: rtl;*/
    }

    table th {
        position: sticky;
        position: -webkit-sticky;
        top:0;
        align-items: center;
        height: 30px;
        text-align: center;
        /*opacity: 0.8;*/
        background-color: var(--main-grey);
        z-index: 2;
        color: #494949;
    }

    /*.table-header::after {*/
    /*    content: '';*/
    /*    position: absolute;*/
    /*    background-color: #000000;*/
    /*    opacity: 0.05;*/
    /*    left: 50%;*/
    /*    bottom: 0;*/
    /*    transform: translate(-50%, 0);*/
    /*    height: 2px;*/
    /*    width: calc(100%);*/
    /*}*/

    .table-row {
        /*position: relative;*/
        transform: scale(1);
        -ms-transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
    }
    /*.table-header th:first-of-type,*/
    /*.table-row td:first-of-type{*/
    /*    text-align: right;*/
    /*    padding-right: 10px;*/
    /*}*/
    /*.table-header th:last-of-type,*/
    /*.table-row td:last-of-type{*/
    /*    text-align: left;*/
    /*    padding-left: 10px;*/
    /*}*/

    .table-row td {
        color: #494949;
        /*opacity: 0.7;*/
        align-items: center;
        height: 35px;
        text-align: center;
        cursor: default;
    }
    .percent2{
        position: absolute;
        left: 0;
        top: 0;
        background-color: var(--main-green);
        height: 100%;
        opacity: 0.15;
    }
</style>