<template>
    <div class="account">
        <account-active v-if="state.token" :route="route" :account="state.token"
                        :class="[['userAccount','Messages'].indexOf(route)>-1 ? 'selected' : '']"></account-active>
        <account-deactive v-else :route="route" :account="false"></account-deactive>
    </div>
</template>

<script>
    import AccountActive from "@/components/Navbar/account-active";
    import AccountDeactive from "@/components/Navbar/AccountDeactive";

    export default {
        name: "account",
        props: ['account', 'route'],
        components: {AccountDeactive, AccountActive},
    }
</script>

<style scoped>

    .account {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
</style>