<template>
    <div class="Auth-box">
        <LoadingModal v-if="showModalLoading"/>
        <div class="Auth-header">
            <h3>شناسایی دو عاملی</h3>
        </div>
        <div class="Auth">
            <p>
                می توانید با استفاده از برنامه google authenticator شناسایی دو عاملی را بر روی حساب کاربری خود فعال کنید . ابتدا برنامه مورد نظر را از یکی از لینک های گوگل پلی یا اپل استور دانلود کنید و سپس با استفاده از اسکن بارکد زیر کد تولید شده توسط برنامه را در کادر زیر وارد کرده سپس پنجره را بسته و روی گزینه ارسال کلیک کنید.
                 دقت کنید که پس از تایید کد، ورود به حساب شما علاوه بر گذرواژه نیاز به وارد کردن کد شناسایی دوعاملی نیز خواهد داشت. بنابراین حتما از بارکد و آدرس تولید شده بکاپ تهیه کنید و آن را به صورت امن ذخیره نمایید. چراکه دیگر امکان مشاهده آن را نخواهید داشت.
            </p>
            <div style="margin:0 auto;display:flex;justify-content:center">
                <img src="" alt="" id="ItemPreview" style="width:150px;height:150px;">
            </div>
            <a @click="test" class="link">{{url}}</a>
            <!-- <CodeInput class="auth-row"
                       @change="onChange" @complete="submit"
                       :fields="num" ref="code-input" :fieldWidth="45" :fieldHeight="45"/> -->
            <input class="auth-row new-input" type="text" v-model="code" maxlength="6">
            <div class="footer">
               
            </div>
        </div>
        <button class="normal-btn" @click.prevent="submit" :disabled="code.toString().length < num">تایید
        </button>
        <a style="opacity: 0.8;color:red">{{error}}</a>
    </div>
</template>

<script>
import axios from 'axios'
import LoadingModal from '../LoadingModal'
// import CodeInput from "vue-verification-code-input";
    export default {
        name: "TwoStepGoogleAuthModal",
        props:['googleImage','url'],
        data() {
            return {
                
                smscode: [],
                error: '',
                num: 6,
                code: '',
                showModalLoading:false
            }
        },
        components: {
            LoadingModal,
            // CodeInput
        },
        mounted(){
            // this.setPlaceholder()
            // console.log('navid navid',this.googleImage);
            document.getElementById("ItemPreview").src = "data:image/png;base64," + this.googleImage;
        },
        methods: {
            test(){
                // console.log(this.url);
                window.location.href = this.url
            },
            
            submit() {
                this.showModalLoading = true
                
                axios.post(`users/change-otp?twoStepAuthorization=GOOGLE_AUTHENTICATOR&code=${this.code}`)
                    .then(() => {
                    // console.log('this is response from googel authenticator',response);
                    this.showModalLoading = false
                    this.$emit('submited','show toast')
                    
                    })
                .catch(error => {
                    this.showModalLoading = false
                    if(error.response){
                        console.log(error.response.data.message,'this is response error')
                        if (error.response.data.message === 'Code Expired'){
                            this.error = 'کد شما منقضی شده است.'
                        } else if(error.response.data.message === 'Incorrect OTP Code'){
                            this.error = 'کد شما اشتباه است.'
                        }
                    }
                });
            }
        },
        
    }
</script>

<style lang="scss" scoped>

    input {
        padding: 5px 0;
    }

    .Auth-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 400px;
        height: 100%;
        position: relative;
        h3{
            padding: 5px;
        }
    }

    .countdown {
        font-size: 14px;
        text-decoration: underline;
    }

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
    }
    .Auth{
        padding: 0 3% 0;
    }

    .auth-row {
        display: flex;
        direction: ltr;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 45px;
        margin-top: 10px;
        border: 1px solid var(--light-border);
        border-radius: 15px;
    }

    .Auth-input {
        font-size: 25px;
        border-radius: 5px;
        /*border: solid 1px #00000010;*/
        width: 40px;
        height: 100%;
        text-align: center;
        letter-spacing: -2px;
        /*padding: 0;*/

        &:focus {
            border-color: var(--main-orange);
        }
    }

    p {
        margin: 20px 0 20px;
        text-align: right;
    }

    .normal-btn {
        margin-bottom: 25px;
    }

    .url{
        width: 145px;
        // height: 35px;
        margin:0 auto;
        display:flex;
        align-items: center;
        justify-content: center;
        // background:var(--main);
        // padding: 10px;
        font-weight: normal;
        font-family: 'VistaDev';
        margin-bottom: 15px;
    }
    @media screen and (min-width:769px){
        .url{
            display: none;
        }
    }

    .link{
        display: inline-block;
        height: auto;
        width: 100%;
        word-break: break-all;
        cursor: pointer;
        text-align: center;
    }
</style>
<style lang="scss">
    .auth-row{
        width: 100% !important;
        .react-code-input{
            border: none;
            input{
                border: none !important;
                color: var(--font-color3);
                font-family: 'VistaDev',sans-serif;
                font-size: 25px;
                letter-spacing: -2px;
                text-align: center !important;
            }
        }
    }
</style>