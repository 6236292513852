<template>
    <div class="main-box">
        <h1 style="text-align: right">سوالات متداول</h1>
        <div class="message-block"
             v-for="(message,index) in messages"
             :key="index">
            <div @click.prevent="toggle(index)" class="message-header">
                <div class="header-header">
                    <img src="../../assets/icon/question.svg">
                    <h3>{{message.question}}</h3>
                </div>
                <img v-show="!showmessage[index]" width="15px" height="15px" src="../../assets/icon/plus.svg" alt="">
                <img v-show="showmessage[index]" width="15px" height="15px" src="../../assets/icon/minus.svg" alt="">
            </div>
            <div class="message-description" :style="{maxHeight: [showmessage[index]? '150px' : '']}">
                <p>{{message.answer}}</p>
            </div>
        </div>
        
    </div>
</template>

<script>

    export default {
        name: "FAQ",
        // components:
        data() {
            return {
                read: false,
                showmessage: [],
                messages: [],
            }
        },
        methods: {
            toggle(e) {
                this.showmessage[e] = !this.showmessage[e];
                this.$forceUpdate()
            },
            getFaq(){
                this.$axios.get('/faq')
                    .then(res=>{
                        this.messages = res.data
                    })
            }
        },
        mounted(){
            this.getFaq()
        }

    }
</script>

<style lang="scss" scoped>

    .main-box {
        display: flex;
        flex-direction: column;
        /*align-self: flex-start;*/
        /*background-color: white;*/
        position: absolute;
        top:0;
        direction: rtl;
        text-align: right;
        box-sizing: border-box;
        width: 100%;
        max-width: 1300px;
        padding: 0 25px;
        margin: 56px 0;
        font-size: 18px;
        font-family: VistaDev, sans-serif;
    }
    h1{
        font-size: 22px;
        margin-right: 20px;
        margin-top: 50px;
    }

    .message-block {
        display: block;
        flex-direction: column;
        align-items: center;
        /*background-color: white;*/
        text-align: right;
        border-bottom: #00000015 solid 1px;
        box-sizing: border-box;
        width: 100%;
        font-size: 18px;
        transition: 0.3s;
    }
    .message-header {
        position: relative;
        display: flex;
        height: 61px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin-top: 15px;

        img {
            margin-left: 10px;
        }
    }

    .message-header h3 {
        font-family: VistaDev,sans-serif;
        font-weight: 300;
        font-size: 18px;
        opacity: 0.8;
    }

    .message-description {
        font-size: 18px;
        overflow: hidden;
        transition: 0.4s ease-out;
        -webkit-transition: 0.4s ease-out;
        -moz-transition: 0.4s ease-out;
        max-height: 0;
        p{
            margin-top: 0;
            opacity: 0.8;
        }
    }

    .header-header {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
        margin: 0 10px
        }
    }

</style>