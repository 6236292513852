<template>
    <div class="main-box">
        <h1>سفارش های خرید و فروش</h1>
        
        <div class="box1">

            <h2>سفارش های خرید</h2>
            <buy-orders :id="id" :tableContents="buytable"></buy-orders>
            <!--            <withdraw-table v-show="tab === 'withdraw'" :id="id"></withdraw-table>-->
        </div>
        
        <div class="box1">
            <h2>سفارش های فروش</h2>
            <sell-orders :id="id" :tableContents="selltable"></sell-orders>
            <!--            <withdraw-table v-show="tab === 'withdraw'" :id="id"></withdraw-table>-->
        </div>
    </div>
</template>

<script>

    import BuyOrders from "@/components/Orders/BuyOrders";
    import SellOrders from "@/components/Orders/SellOrders";
    export default {
        name: "Orders",
        components: {SellOrders, BuyOrders},
        props: {
            tab:
                {
                    default: 'deposit'
                },
            id:{
                default : 1
            }
        },
        data() {
            return {
                deposit: false,
                withdraw: true,
                buytable:'',
                selltable:'',
                
                marketcoins:{
                    'RIPPLE_TOMAN':'ریپل-ریال',
                    'ETHEREUM_TOMAN':'اتریوم-ریال',
                    'BITCOIN_TOMAN':'بیت کوین-ریال',
                    'DASH_TOMAN':' دش-ریال',
                    'TRON_TOMAN':'ترون-ریال',
                    'RIPPLE_TETHER':'ریپل-تتر',
                    'ETHEREUM_TETHER':'اتریوم-تتر',
                    'BITCOIN_TETHER':'بیت کوین-تتر',
                    'DASH_TETHER':'دش-تتر',
                    'TRON_TETHER':'ترون-تتر',
                }
            }
        },
        methods:{
            routing(e){
                this.$router.push({ name: 'Wallet' , params: { tab: e , id : 1} })
            }
        }
    }
</script>

<style scoped>

    .main-box {
        background-color: transparent;
        text-align: right;
        margin-top: 30px;
        padding: 0 10px;
        box-sizing: border-box;
        width: 100%;
        max-width: 1400px;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        font-size: 14px;
    }

    h1 {
        font-size: 22px;
        margin-top: 30px;
        margin-bottom: 20px;
        margin-right: 10px;
    }

    .tab {
        display: flex;
        flex-direction: row;
        position: relative;
        height: 60px;
        border-bottom: #00000010 1px solid;
        font-size: 18px;
        align-items: center;
        background-color: transparent;
        text-align: right;
        width: 100%;

    }

    .tab-content {
        width: 50%;
        opacity: 0.5;
        text-align: center;
        cursor: pointer;
    }

    .tab-content-active {
        font-weight: 800;
        width: 50%;
        opacity: 0.8;
        text-align: center;
    }

    .selector {
        position: absolute;
        background-color: var(--main-orange);
        width: 50%;
        height: 6px;
        border-radius: 5px;
        bottom: -3px;
        right: 0;
        transition: 0.5s;
    }


    .box1 {
        flex-direction: column;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        padding: 10px 2.5%;
        margin: 20px 0;
        position: relative;
    }

    h2{
        width: 100%;
        font-size: 17px;
        padding: 10px 0;
        margin: 0 0 20px;
        border-bottom: 1px solid var(--light-border);
    }
</style>