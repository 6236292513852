<template>
    <div v-show="!logedin || home" class="footer">
        <div class="footer-content row ml-2 mr-2">
            <div style="display: flex;flex-direction: column;align-items: center" class="col-md-4 col-12 px-5">
                <img src="../../assets/vista-logo-copy.png" alt="logo">
            </div>
            <div class="footer-list col-md-4 col-12 px-5">
                <div>
                    <p>تماس با ما</p>
                    <ul>
                        <li><a>آدرس : تهران، خیابان پیروزی، خیابان پرستار، کوچه نادر اسد، پلاک 47، طبقه چهارم، گروه رمز نگار رامان</a></li>
                        <li><a>تلفن : 09120604394</a></li>
                        <li><a>ایمیل : Bitraman.official@gmail.com</a></li>
                    </ul>
                </div>
            </div>
            <div class="footer-list col-md-4 col-12 px-5">
                <div>
                    <p>عضویت در خبرنامه</p>
                    <ul>
                        <li><a>برای اطلاع از آخرین اخبار رمزارز ها و آخرین تغییرات, به خبرنامه بپیوندید.</a></li>
                    </ul>
                    <label>
                        <input type="text" placeholder="آدرس ایمیل">
                        <button type="submit" class="normal-btn">عضویت</button>
                    </label>
                </div>
            </div>
        </div>
        <div class="footer-social">
            <div class="row m-2 no-gutters">
                <div class="col-12 justify-content-center" style="cursor: default;display: flex;color: var(--main-green)">
                    کلیه حقوق این سایت متعلق به شرکت ویستا می‌باشد.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BlogFooter",
        data() {
            return {
                home: true,
                logedin: false,
            }
        },
        methods: {
            twitter() {
                // window.location.href = 'https://twitter.com';
                window.open(
                    'https://twitter.com',
                    '_blank' // <- This is what makes it open in a new window.
                );
            },
            instagram() {
                window.open(
                    'http://instagram.com',
                    '_blank' // <- This is what makes it open in a new window.
                );
            },
            linkedin() {
                window.open(
                    'https://linkedin.com',
                    '_blank' // <- This is what makes it open in a new window.
                );
            },
            telegram() {
                window.open(
                    'https://telegram.org',
                    '_blank' // <- This is what makes it open in a new window.
                );
            }
        }
    }
</script>

<style scoped>
    .footer {
        display: flex;
        flex-direction: column;
        background-color: #000;
        align-items: center;
    }

    .footer-content {
        position: relative;
        width: 100%;
        max-width: 1180px;
        margin-top: 50px;
        padding-bottom: 50px;
    }

    .footer-content::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 50%;
        transform: translate(50%, 0);
        background-color: var(--light-border);
        opacity: 0.8;
        width: 98%;
        height: 1px;
    }

    .footer-content img {
        width: 65%;
        /*height: 100%;*/
        margin-right: 5px;
        display: flex;
        justify-self: right;
    }

    .footer-list {
        color: white;
        text-align: right;
        display: flex;
        justify-content: center;
    }

    .footer-list p {
        font-size: 21px;
        color: var(--main-green);
        cursor: default;
    }
    .footer-list a {
        font-size: 21px;
        color: white;
        cursor: default;
    }

    ul {
        margin-top: 20px;
    }

    li {
        list-style: none;
        line-height: 1.79;
        margin-bottom: 15px;
    }

    li a {
        font-size: 14px !important;
        font-weight: 500;
        opacity: 0.8;
        cursor: pointer !important;

    }

    .footer-list li a:hover {
        color: #ffffff;
        cursor: text !important;
    }

    .footer-list label {
        display: flex;
        background-color: var(--dark-background);
        height: 55px;
        border-radius: 10px;
        border: var(--main-green) solid 2px;
        padding-right: 5px;
        margin-top: 30px;

    }

    .footer-list input {
        width: calc(100% - 100px);
        height: 100%;
        color: white;
        font-size: 16px;
        padding: 0 10px;
    }

    .normal-btn {
        /*color: rgba(0, 0, 0, 0.80);*/
        height: 100%;
        width: 100px;
        margin: 0;
        border-radius: 6px 0 0 6px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .footer-social {
        width: 100%;
        max-width: 1180px;
        opacity: 0.8;
        font-family: IRANSans,sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        justify-content: center;
    }

    .social {
        display: flex;
    }

    .social a {
        color: white;
        cursor: default;
        vertical-align: super;
    }

    .social img {
        cursor: pointer;
        transition: 0.5s;
    }

    .social img:hover {
        filter: invert(77%) sepia(79%) saturate(3719%) hue-rotate(80deg) brightness(250%) contrast(60%);
        transform: rotate(360deg);
    }

</style>