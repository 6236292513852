<template>
    <div class="main">
        <svg :fill="route==='Messages'? 'var(--main-green)' : '#fff'" @click="$router.push('/messages')"
             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 416 416" xml:space="preserve" width="27px" height="27px">
            <path d="M208,416c23.573,0,42.667-19.093,42.667-42.667h-85.333C165.333,396.907,184.427,416,208,416z"/>
            <path d="M336,288V181.333c0-65.6-34.88-120.32-96-134.827V32c0-17.707-14.293-32-32-32s-32,14.293-32,32v14.507
				c-61.12,14.507-96,69.227-96,134.827V288l-42.667,42.667V352h341.333v-21.333L336,288z"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             x="0px" y="0px" width="27px" height="27px" :fill="route==='Setting'? 'var(--main-green)' : '#fff'"
             @click="$router.push('/setting')" viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;" xml:space="preserve">
		<path d="M416.8,269.44l-45.013-35.307c0.853-6.827,1.493-13.76,1.493-20.8s-0.64-13.973-1.493-20.8l45.12-35.307
			c4.053-3.2,5.227-8.96,2.56-13.653L376.8,69.653c-2.667-4.587-8.213-6.507-13.013-4.587l-53.12,21.44
			c-10.987-8.427-23.04-15.573-36.053-21.013l-8-56.533C265.653,3.947,261.28,0,255.947,0h-85.333c-5.333,0-9.707,3.947-10.56,8.96
			l-8,56.533c-13.013,5.44-25.067,12.48-36.053,21.013l-53.12-21.44c-4.8-1.813-10.347,0-13.013,4.587L7.2,143.573
			c-2.667,4.587-1.493,10.347,2.56,13.653l45.013,35.307c-0.853,6.827-1.493,13.76-1.493,20.8s0.64,13.973,1.493,20.8L9.76,269.44
			c-4.053,3.2-5.227,8.96-2.56,13.653l42.667,73.92c2.667,4.587,8.213,6.507,13.013,4.587L116,340.16
			c10.987,8.427,23.04,15.573,36.053,21.013l8,56.533c0.853,5.013,5.227,8.96,10.56,8.96h85.333c5.333,0,9.707-3.947,10.56-8.96
			l8-56.533c13.013-5.44,25.067-12.48,36.053-21.013l53.12,21.44c4.8,1.813,10.347,0,13.013-4.587l42.667-73.92
			C422.027,278.507,420.853,272.747,416.8,269.44z M213.28,288c-41.28,0-74.667-33.387-74.667-74.667S172,138.667,213.28,138.667
			s74.667,33.387,74.667,74.667S254.56,288,213.28,288z"/>
        </svg>

        <div class="drop_btn">
            <svg :fill="['Profile'].indexOf(route)>-1? 'var(--main-green)' : '#fff'"
                 width="47px" height="27px" viewBox="0 0 47 27" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.298707 13.3788C-0.1289 13.8468 -0.0885676 14.5728 0.363239 15.0164L4.17871 18.8399C4.40457 19.0658 4.70303 19.1787 5.00149 19.1787C5.29996 19.1787 5.59834 19.0658 5.82428 18.8399L9.63967 15.0245C10.0833 14.5808 10.1317 13.8468 9.7042 13.387C9.25248 12.8949 8.48624 12.8788 8.0183 13.3467L5.57414 15.7908C5.25962 16.1054 4.75135 16.1054 4.43676 15.7908L1.9926 13.3467C1.51667 12.8707 0.750352 12.8868 0.298707 13.3788Z"/>
                <path d="M41.7668 18.7759L37.6618 17.4076C37.5636 17.3746 37.4608 17.3577 37.3571 17.3574H29.6429C29.5392 17.3576 29.4364 17.3746 29.3382 17.4076L25.2332 18.7759C22.1061 19.8149 19.997 22.741 20 26.036C20 26.5686 20.4317 27.0003 20.9643 27.0003H46.0357C46.5683 27.0003 47 26.5686 47 26.036C47.003 22.741 44.8939 19.8149 41.7668 18.7759Z"/>
                <path d="M33.5001 0C30.3047 0 27.7144 2.59035 27.7144 5.78571V9.64287C27.7175 12.8369 30.306 15.4254 33.5001 15.4286C36.6941 15.4254 39.2826 12.8369 39.2858 9.64287V5.78571C39.2858 2.59035 36.6954 0 33.5001 0Z"/>
            </svg>
            <div class="dropbox">
                <div class="dropbox1">
                    <div v-for="(content,index) in contents"
                         :key="index"
                         class="dropbox-contents"
                         @click="Route(content.link)">
                        <div :class="route === content.route? 'selected' : ''" class="contents">
                            <!--                            <img class="icon" :src="content.img" :alt="'image'+index">-->
                            {{content.name}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--        <svg v-show="theme === 'dark' && route==='Trade'" @click.prevent="changetheme('')" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"-->
        <!--             viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;"  width="30px" height="30px" xml:space="preserve">-->
        <!--            <path d="M26,8.1V16l2,1.2c2.5,1.4,4,4.1,4,6.9c0,4.4-3.6,8-8,8s-8-3.6-8-8c0-2.8,1.5-5.5,4-6.9l2-1.2V8.1H26 M30,4.1H18v9.6-->
        <!--            c-3.6,2.1-6,5.9-6,10.4c0,6.6,5.4,12,12,12s12-5.4,12-12c0-4.4-2.4-8.3-6-10.4C30,13.7,30,4.1,30,4.1z"/>-->
        <!--        </svg>-->
        <!--        <svg v-show="theme !== 'dark' && route==='Trade'" @click.prevent="changetheme('dark')" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="30px" height="30px">-->
        <!--            <path d="M3.55 19.09l1.41 1.41 1.79-1.8-1.41-1.41zM11 20h2v3h-2zM1 11h3v2H1zm12-6.95v3.96l1 .58c1.24.72 2 2.04 2 3.46 0 2.21-1.79 4-4 4s-4-1.79-4-4c0-1.42.77-2.74 2-3.46l1-.58V4.05h2m2-2H9v4.81C7.21 7.9 6 9.83 6 12.05c0 3.31 2.69 6 6 6s6-2.69 6-6c0-2.22-1.21-4.15-3-5.19V2.05zM20 11h3v2h-3zm-2.76 7.71l1.79 1.8 1.41-1.41-1.8-1.79z"/>-->
        <!--        </svg>-->
    </div>
</template>

<script>
    export default {
        name: "account-active",
        props: ['route'],
        data() {
            return {
                username: 'زهرا الهی',
                open: false,
                theme: '',
                contents: [
                    {
                        name: 'حساب کاربری',
                        link: '/profile',
                        route: 'Profile'
                    },
                    {
                        name: 'دعوت دوستان',
                        link: '/invite',
                        route: 'Invite'
                    },
                    {
                        name: 'خروج',
                        link: '/home',
                    },
                ]
            }
        },
        methods: {
           
            Route(e) {
                if (e === '/home'){
                    this.$logout()
                }
                this.$router.push(e)
            },
            changetheme(e) {
                localStorage.theme = e
                window.location.reload()
            }
        },
        created() {
            this.theme = localStorage.theme
        }
    }
</script>

<style scoped>
    .dark svg {
        fill: white;
    }

    .dark img {
        filter: invert(98%) sepia(100%) saturate(7%) hue-rotate(155deg) brightness(102%) contrast(106%);
    }

    .main {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        /*width: 200px;*/
    }

    .drop_btn {
        position: relative;
        cursor: pointer;
        /*width: 30px;*/
        height: 30px;
        /*margin: 0 40px;*/
    }

    .drop_btn:hover svg {
        fill: var(--main-green);
    }

    .dropbox {
        margin-top: -6px;
        padding-top: 15px;
        margin-right: -50px;
        font-size: 14px;
        position: absolute;
        display: none;
        transition: 0.5s;
        z-index: 6;
    }

    .dropbox1 {
        color: #fff;
        background-color: var(--dropdown-back);
        text-align: right;
        width: 130px;
        border-radius: 5px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
        transition: 0.5s;
        z-index: 500;
    }

    .dark .dropbox1 {
        background-color: var(--dark-main);
        color: white !important;
    }

    .dropbox-contents {
        padding: 8px 8px;
        padding-left: 10px;
        transition: 0.25s;
        cursor: pointer;
        position: relative;
        border-bottom: 2px solid #000;
       
    }

    .contents {
        width: max-content;
        display: flex;
        align-items: center;
        
    }

    .dropbox-contents:hover {
        background-color: var(--hover);
    }

    .dropbox-contents:nth-of-type(2) {
        border-bottom: solid 2px rgba(0, 0, 0, 0.1);
    }

    .dark .dropbox-contents {
        border-color: rgba(255, 255, 255, 0.1);
    }

    .drop_btn:hover .dropbox {
        display: block;
    }

    .icon {
        margin-left: 7px;
    }

    svg {
        /*margin: 0 25px;*/
        opacity: 0.8;
    }

    svg:first-of-type {
        /*margin-right: 10px;*/
    }

    svg:last-of-type {
        /*margin-left: 10px*/
    }

    svg:hover {
        fill: var(--main-green);
        cursor: pointer;
    }

    .selected {
        color: var(--main-green);
    }

    .selected img {
        filter: invert(67%) sepia(42%) saturate(3267%) hue-rotate(5deg) brightness(110%) contrast(101%);
    }


</style>