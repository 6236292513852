<template>
    <div class="loading-modal">
        <lottie-player :options="options" :src="require('../assets/loading.json')"/>
    </div>
</template>

<script>
export default {
    name:'LoadingModal',
    data(){
        return{
            options:{
                autoplay:true,
                speed:1.2,
                loop:true,
                width:'150px',
                height:'150px'
            }
        }
    }
}
</script>

<style scoped>
    .loading-modal{
        width: 100%;
        height: 100%;
        background: #e2e2e27a;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 500;
    }
</style>