<template>
    <div class="main-box">
        <blog-header></blog-header>
        <div class="main-routing">
            <div v-for="(rout,index) in routs" :key="index" class="routing">
                <a>{{rout}}</a>
                <svg height="11px" v-show="index<(routs.length-1)"
                     version="1.1" id="Capa_1"
                     xmlns="http://www.w3.org/2000/svg"
                     x="0px" y="0px" viewBox="0 0 443.52 443.52"
                     style="enable-background:new 0 0 443.52 443.52;" xml:space="preserve">
                <path d="M143.492,221.863L336.226,29.129c6.663-6.664,6.663-17.468,0-24.132c-6.665-6.662-17.468-6.662-24.132,0l-204.8,204.8
                c-6.662,6.664-6.662,17.468,0,24.132l204.8,204.8c6.78,6.548,17.584,6.36,24.132-0.42c6.387-6.614,6.387-17.099,0-23.712
                L143.492,221.863z"/>
            </svg>
            </div>
<!--            <a>{{header}}</a>-->
        </div>
        <div class="main">
            <h1>{{header}}</h1>
            <!--            <div class="main-header">-->
            <div class="header">
                <div>
                    <img :src="avatar_path" alt="">
                    <p>نویسنده : {{author}}</p>
                </div>
                <p>{{date}}</p>
            </div>
            <div class="content">
                <img src="../../assets/blog/btc-crown.jpg" alt="">
                <p>پادشاه ارزهای دیجیتال با در اختیار داشتن بیش از ۶۰ درصد از کل بازار، علاوه بر‌ این‌که رتبه اول بازار
                    را در اختیار دارد، رتبه اول این فهرست را هم به خودش اختصاص داده است و بقیه ارزهای دیجیتال برای تصاحب
                    این رتبه حداقل در این مقطع حرفی برای گفتن ندارند. بیت کوین علاوه‌بر این‌که اولین، بزرگترین و سرچشمه
                    نوآوری است، طی ده سال گذشته به خوبی خودش را اثبات کرده است. این شبکه تاکنون هیچ هک یا رخنه امنیتی
                    بزرگی را تجربه نکرده و طی این سال‌ها به یکی از امن‌ترین شبکه‌های کامپیوتری دنیا تبدیل شده است. با
                    مشاهده رفتار بازار، به خوبی مشخص است که قیمت بسیاری از آلت کوین‌ها (ارزهای دیجیتال پس از بیت کوین)،
                    همگام با بیت کوین بالا و پایین می‌شود. حتی بیشتر بیت کوینرها (عاشقان بیت کوین) معتقدند که نباید به
                    جز بیت کوین در ارز دیجیتال دیگری سرمایه‌گذاری کرد. همچنین بیت کوین امسال (۲۰۲۰) سومین هاوینگ خود را
                    تجربه می‌کند؛ رویدادی که در کد آن برای کنترل تورم تعبیه شده و هر چهار سال یکبار پاداش بلاک یا به‌طور
                    دقیق‌تر، میزان تولید بیت کوین‌های جدید به دست ماینرها را نصف می‌کند. این رویداد با کاهش شدید عرضه و
                    افزایش کمیابی می‌تواند ارزش بیت کوین را به شدت افزایش دهد. تاریخچه قیمت بیت کوین به وضوح نشان می‌دهد
                    که بیشتر اوقات سرمایه‌گذاران از نگهداری بلندمدت بیت کوین نه تنها ضرر نکرده‌اند، بلکه به سودهای چند
                    صد و حتی چند هزار درصدی رسیده‌اند. بنابراین، اضافه‌ کردن بیت کوین به سبد سرمایه‌گذاری به عنوان اولین
                    گزینه، منطقی‌ترین کار ممکن است.</p>
                <img src="../../assets/blog/ether.jpg" alt="">
                <p>پادشاه ارزهای دیجیتال با در اختیار داشتن بیش از ۶۰ درصد از کل بازار، علاوه بر‌ این‌که رتبه اول بازار
                    را در اختیار دارد، رتبه اول این فهرست را هم به خودش اختصاص داده است و بقیه ارزهای دیجیتال برای تصاحب
                    این رتبه حداقل در این مقطع حرفی برای گفتن ندارند. بیت کوین علاوه‌بر این‌که اولین، بزرگترین و سرچشمه
                    نوآوری است، طی ده سال گذشته به خوبی خودش را اثبات کرده است. این شبکه تاکنون هیچ هک یا رخنه امنیتی
                    بزرگی را تجربه نکرده و طی این سال‌ها به یکی از امن‌ترین شبکه‌های کامپیوتری دنیا تبدیل شده است. با
                    مشاهده رفتار بازار، به خوبی مشخص است که قیمت بسیاری از آلت کوین‌ها (ارزهای دیجیتال پس از بیت کوین)،
                    همگام با بیت کوین بالا و پایین می‌شود. حتی بیشتر بیت کوینرها (عاشقان بیت کوین) معتقدند که نباید به
                    جز بیت کوین در ارز دیجیتال دیگری سرمایه‌گذاری کرد. همچنین بیت کوین امسال (۲۰۲۰) سومین هاوینگ خود را
                    تجربه می‌کند؛ رویدادی که در کد آن برای کنترل تورم تعبیه شده و هر چهار سال یکبار پاداش بلاک یا به‌طور
                    دقیق‌تر، میزان تولید بیت کوین‌های جدید به دست ماینرها را نصف می‌کند. این رویداد با کاهش شدید عرضه و
                    افزایش کمیابی می‌تواند ارزش بیت کوین را به شدت افزایش دهد. تاریخچه قیمت بیت کوین به وضوح نشان می‌دهد
                    که بیشتر اوقات سرمایه‌گذاران از نگهداری بلندمدت بیت کوین نه تنها ضرر نکرده‌اند، بلکه به سودهای چند
                    صد و حتی چند هزار درصدی رسیده‌اند. بنابراین، اضافه‌ کردن بیت کوین به سبد سرمایه‌گذاری به عنوان اولین
                    گزینه، منطقی‌ترین کار ممکن است.</p>
            </div>
            <div class="main-footer">
<!--                <div class="col-6" style="opacity: 0.5;text-align: right">-->
<!--                    نویسنده : {{author}}-->
<!--                </div>-->
                <div class="footer1">
                    <svg height="24px" viewBox="-21 -47 682.66669 682" width="24px" xmlns="http://www.w3.org/2000/svg">
                        <path d="m552.011719-1.332031h-464.023438c-48.515625 0-87.988281 39.464843-87.988281 87.988281v283.972656c0 48.414063 39.300781 87.816406 87.675781 87.988282v128.863281l185.191407-128.863281h279.144531c48.515625 0 87.988281-39.472657 87.988281-87.988282v-283.972656c0-48.523438-39.472656-87.988281-87.988281-87.988281zm50.488281 371.960937c0 27.835938-22.648438 50.488282-50.488281 50.488282h-290.910157l-135.925781 94.585937v-94.585937h-37.1875c-27.839843 0-50.488281-22.652344-50.488281-50.488282v-283.972656c0-27.84375 22.648438-50.488281 50.488281-50.488281h464.023438c27.839843 0 50.488281 22.644531 50.488281 50.488281zm0 0"/>
                        <path d="m171.292969 131.171875h297.414062v37.5h-297.414062zm0 0"/>
                        <path d="m171.292969 211.171875h297.414062v37.5h-297.414062zm0 0"/>
                        <path d="m171.292969 291.171875h297.414062v37.5h-297.414062zm0 0"/>
                    </svg>
                    {{comment.length}}

                    <svg @click.prevent="like=true" v-show="!like" version="1.1" id="Capa_1"
                         xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         width="22px" viewBox="0 0 477.534 477.534" style="enable-background:new 0 0 477.534 477.534;"
                         xml:space="preserve">
                        <path d="M438.482,58.61c-24.7-26.549-59.311-41.655-95.573-41.711c-36.291,0.042-70.938,15.14-95.676,41.694l-8.431,8.909
                        l-8.431-8.909C181.284,5.762,98.662,2.728,45.832,51.815c-2.341,2.176-4.602,4.436-6.778,6.778
                        c-52.072,56.166-52.072,142.968,0,199.134l187.358,197.581c6.482,6.843,17.284,7.136,24.127,0.654
                        c0.224-0.212,0.442-0.43,0.654-0.654l187.29-197.581C490.551,201.567,490.551,114.77,438.482,58.61z M413.787,234.226h-0.017
                        L238.802,418.768L63.818,234.226c-39.78-42.916-39.78-109.233,0-152.149c36.125-39.154,97.152-41.609,136.306-5.484
                        c1.901,1.754,3.73,3.583,5.484,5.484l20.804,21.948c6.856,6.812,17.925,6.812,24.781,0l20.804-21.931
                        c36.125-39.154,97.152-41.609,136.306-5.484c1.901,1.754,3.73,3.583,5.484,5.484C453.913,125.078,454.207,191.516,413.787,234.226
                        z"/>
                    </svg>
                    <svg @click.prevent="like=false" v-show="like" version="1.1" id="Capa_1"
                         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         width="22px" viewBox="0 0 477.534 477.534" style="enable-background:new 0 0 477.534 477.534;"
                         xml:space="preserve">
                        <path d="M438.482,58.61c-24.7-26.549-59.311-41.655-95.573-41.711c-36.291,0.042-70.938,15.14-95.676,41.694l-8.431,8.909
                        l-8.431-8.909C181.284,5.762,98.663,2.728,45.832,51.815c-2.341,2.176-4.602,4.436-6.778,6.778
                        c-52.072,56.166-52.072,142.968,0,199.134l187.358,197.581c6.482,6.843,17.284,7.136,24.127,0.654
                        c0.224-0.212,0.442-0.43,0.654-0.654l187.29-197.581C490.551,201.567,490.551,114.77,438.482,58.61z"/>
                    </svg>
                    {{likes}}

                </div>
                <div class="footer2">
                    {{counter}}
                    <svg width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                         viewBox="0 0 469.333 469.333"
                         style="enable-background:new 0 0 469.333 469.333;" xml:space="preserve">
                        <path d="M234.667,170.667c-35.307,0-64,28.693-64,64s28.693,64,64,64s64-28.693,64-64S269.973,170.667,234.667,170.667z"/>
                        <path d="M234.667,74.667C128,74.667,36.907,141.013,0,234.667c36.907,93.653,128,160,234.667,160
                                 c106.773,0,197.76-66.347,234.667-160C432.427,141.013,341.44,74.667,234.667,74.667z M234.667,341.333
                                 c-58.88,0-106.667-47.787-106.667-106.667S175.787,128,234.667,128s106.667,47.787,106.667,106.667
                                 S293.547,341.333,234.667,341.333z"/>
                    </svg>
                </div>

            </div>
            <!--                <img width="100%" src="../../assets/postshow.png">-->
            <!--                <div class="row no-gutters main-header-footer">-->
            <!--                    <div class="col-6">-->
            <!--                        <svg width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"-->
            <!--                             viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;margin-left: 10px"-->
            <!--                             xml:space="preserve">-->
            <!--                            <path d="M347.216,301.211l-71.387-53.54V138.609c0-10.966-8.864-19.83-19.83-19.83c-10.966,0-19.83,8.864-19.83,19.83v118.978-->
            <!--                                     c0,6.246,2.935,12.136,7.932,15.864l79.318,59.489c3.569,2.677,7.734,3.966,11.878,3.966c6.048,0,11.997-2.717,15.884-7.952-->
            <!--                                     C357.766,320.208,355.981,307.775,347.216,301.211z"/>-->
            <!--                            <path d="M256,0C114.833,0,0,114.833,0,256s114.833,256,256,256s256-114.833,256-256S397.167,0,256,0z M256,472.341-->
            <!--                                     c-119.275,0-216.341-97.066-216.341-216.341S136.725,39.659,256,39.659c119.295,0,216.341,97.066,216.341,216.341-->
            <!--                                     S375.275,472.341,256,472.341z"/>-->
            <!--                        </svg>-->
            <!--                        {{time}} دقیقه-->
            <!--                    </div>-->
            <!--                    <div class="col-6 justify-content-end">-->
            <!--                        {{counter}}-->
            <!--                        <svg width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"-->
            <!--                             viewBox="0 0 469.333 469.333"-->
            <!--                             style="enable-background:new 0 0 469.333 469.333; margin-right: 10px" xml:space="preserve">-->
            <!--                            <path d="M234.667,170.667c-35.307,0-64,28.693-64,64s28.693,64,64,64s64-28.693,64-64S269.973,170.667,234.667,170.667z"/>-->
            <!--                            <path d="M234.667,74.667C128,74.667,36.907,141.013,0,234.667c36.907,93.653,128,160,234.667,160-->
            <!--                            c106.773,0,197.76-66.347,234.667-160C432.427,141.013,341.44,74.667,234.667,74.667z M234.667,341.333-->
            <!--                            c-58.88,0-106.667-47.787-106.667-106.667S175.787,128,234.667,128s106.667,47.787,106.667,106.667-->
            <!--                            S293.547,341.333,234.667,341.333z"/>-->
            <!--                        </svg>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
        <div class="addcomment">
            <h2>دیدگاه شما</h2>
            <add-coment class="addcomment-box"></add-coment>
        </div>
        <div class="comments">
            <div class="comments-header">
                <h2>دیدگاه کاربران</h2>
                <a style="margin-right: 10px">({{comment.length}})</a>
                <span></span>
            </div>
            <div>
                <comments v-for="(comment,index) in comment" :key="index" :comments="comment"></comments>
            </div>
        </div>
        <div class="related">
            <h2>شاید از این نوشته ها هم خوشتان بیاید:</h2>
            <div class="related-main-box">
                <related-posts :posts="relatedposts"></related-posts>
            </div>
        </div>
    </div>
</template>

<script>
    import Comments from "@/components/Blog/Comments";
    import AddComent from "@/components/Blog/addComent";
    import RelatedPosts from "@/components/Blog/RelatedPosts";
    import BlogHeader from "@/components/Blog/BlogHeader";

    export default {
        name: "PostsShow",
        components: {BlogHeader, RelatedPosts, AddComent, Comments},
        props: ['id'],
        data() {
            return {
                routs: ['بلاگ', 'مقالات', 'مقالات سرمایه گذاری',],
                header: 'آشنایی با ۱۱ اصطلاح ضروری در بازارهای مالی',
                date: '22 خرداد 1399',
                time: 10,
                counter: 2576,
                author: 'مهشاد غلامی',
                comments: 5,
                likes: 482,
                like: false,
                relatedposts: [
                    {
                        image: require("../../assets/blog/blog-gallery.jpg"),
                        header: 'سرمایه‌ گذاری بیت کوین در سال ۲۰۲۰؛ هرآنچه باید بدانید.',
                        description: 'یکی از شعب دادگاه فدرال آمریکا، روز جمعه اعلام کرد که طبق قانون نقل و انتقال پول، بیت کوین در ایالت واشینگتن به‌عنوان یک پول به‌رسمیت شناخته خواهد شد…',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                    {
                        image: require("../../assets/blog/blog-gallery.jpg"),
                        header: 'بیت کوین در واشینگتن آمریکا به‌عنوان پول به رسمیت شناخته شد',
                        description: 'یکی از شعب دادگاه فدرال آمریکا، روز جمعه اعلام کرد که طبق قانون نقل و انتقال پول، بیت کوین در ایالت واشینگتن به‌عنوان یک پول به‌رسمیت شناخته خواهد شد…',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                    {
                        image: require("../../assets/blog/blog-gallery.jpg"),
                        header: 'سرمایه‌ گذاری بیت کوین در سال ۲۰۲۰؛ هرآنچه باید بدانید.',
                        description: 'یکی از شعب دادگاه فدرال آمریکا، روز جمعه اعلام کرد که طبق قانون نقل و انتقال پول، بیت کوین در ایالت واشینگتن به‌عنوان یک پول به‌رسمیت شناخته خواهد شد…',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                ],
                comment: [
                    {
                        profileimg: require('../../assets/profilepic.jpg'),
                        name: 'نیما',
                        description: 'اینکه یکباردیگه ریزش میکنه خیلی محتمله ولی نه دیگه به اندازه اسفندماه کمتر از ۶ هزار نمیشه اونوقته که باید خرید زد البته قبلش کانال ۱۲ هزارو میبینه بعد نزولی میشه',
                        like: 5,
                        dislike: 2,
                        date: '22 خرداد 1399',
                        comments: [
                            {
                                profileimg: require('../../assets/profilepic.jpg'),
                                name: 'شاهین',
                                description: 'سلام\n' +
                                    'سپاس از توجه و همراهی شما',
                                like: 5,
                                dislike: 2,
                                date: '22 خرداد 1399'
                            }
                        ]
                    },
                    {
                        profileimg: require('../../assets/profilepic.jpg'),
                        name: 'نیما',
                        description: 'اینکه یکباردیگه ریزش میکنه خیلی محتمله ولی نه دیگه به اندازه اسفندماه کمتر از ۶ هزار نمیشه اونوقته که باید خرید زد البته قبلش کانال ۱۲ هزارو میبینه بعد نزولی میشه',
                        like: 5,
                        dislike: 2,
                        date: '22 خرداد 1399',
                        comments: ''
                    },
                    {
                        profileimg: require('../../assets/profilepic.jpg'),
                        name: 'نیما',
                        description: 'اینکه یکباردیگه ریزش میکنه خیلی محتمله ولی نه دیگه به اندازه اسفندماه کمتر از ۶ هزار نمیشه اونوقته که باید خرید زد البته قبلش کانال ۱۲ هزارو میبینه بعد نزولی میشه',
                        like: 5,
                        dislike: 2,
                        date: '22 خرداد 1399',
                        comments: [
                            {
                                profileimg: require('../../assets/profilepic.jpg'),
                                name: 'شاهین',
                                description: 'سلام\n' +
                                    'سپاس از توجه و همراهی شما',
                                like: 5,
                                dislike: 2,
                                date: '22 خرداد 1399',
                                comments: [
                                    {
                                        profileimg: require('../../assets/profilepic.jpg'),
                                        name: 'شاهین',
                                        description: 'سلام\n' +
                                            'سپاس از توجه و همراهی شما',
                                        like: 5,
                                        dislike: 2,
                                        date: '22 خرداد 1399'
                                    },
                                ]
                            },
                            {
                                profileimg: require('../../assets/profilepic.jpg'),
                                name: 'شاهین',
                                description: 'سلام\n' +
                                    'سپاس از توجه و همراهی شما',
                                like: 5,
                                dislike: 2,
                                date: '22 خرداد 1399'
                            }
                        ]
                    },
                ],
                avatar_path: require('../../assets/Avatar.svg')

            }
        }
    }
</script>

<style scoped>
    .main-box {
        padding: 0 20px;
        width: 100%;
        max-width: 1250px;
        margin-top: 56px;
    }

    .main {
        font-family: VistaDev;
    }

    .main-routing {
        font-size: 14px;
        margin-top: 30px;
        display: flex;
        opacity: 0.5;
        flex-wrap: wrap;

    }

    .main-routing a {
        margin: 0 10px;
        cursor: default;
        text-align: right;
    }

    .routing {
        text-align: right;
        min-width: max-content;
    }

    .main-header {
        /*margin: 10px 0 30px;*/
    }

    .main-header img {
        border-radius: 5px;
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
    }

    .header div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 50px;
    }

    .header p {
        text-align: left;
        font-size: 16px;
        margin: 0;

    }

    .header img {
        height: 100%;
        margin-left: 15px;
    }

    .main h1 {
        text-align: right;
        font-size: 22px;
        margin-top: 30px;
    }

    /*.main-header-footer div {*/
    /*    display: flex;*/
    /*    flex-direction: row;*/
    /*    align-items: center;*/
    /*    opacity: 0.5;*/
    /*}*/

    .content {
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .content img{
        width: 100%;
        max-width: 600px;
    }

    .content p {
        font-size: 14px;
        margin-bottom: 30px;
        line-height: 25px;
    }

    .content h2 {
        font-size: 21px;
        margin-bottom: 10px;
    }

    .main-footer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .main-footer .footer1,
    .main-footer .footer2{
        display: flex;
        align-items: center;
    }
    .main-footer svg{
        margin: 0 10px;
    }

    .addcomment {
        margin-top: 50px;
        text-align: right;
        padding: 15px;
        /*background-color: white;*/
        border-radius: 5px;
        /*border: 1px solid var(--light-border);*/
    }
    .addcomment h2{
        font-size: 20px;
    }
    .addcomment .addcomment-box{
        border: 1px solid var(--light-border);
        padding: 0 10%;
    }

    .comments {
        font-family: VistaDev;
        margin: 30px 0;
        text-align: right;
        padding: 15px;
        /*background-color: white;*/
        border-radius: 5px;
        /*border: solid 1px rgba(54, 52, 53, 0.1);*/
    }
    .comment-box:first-of-type{
        border-top: 0;
    }

    .comments-header {
        display: flex;
        align-items: center;
    }
    .comments-header h2{
        font-size: 20px;
    }
    .comments-header span{
        height: 2px;
        background-color: var(--main-grey);
        width: calc(100% - 170px);
        margin-right: 15px;
    }

    /*.comment:first-child{*/
    /*    margin-top: 40px;*/
    /*}*/

    .related {
        margin-top: 50px;
        margin-bottom: 25px;
        text-align: right;
    }

    .related h2 {
        font-size: 20px;
        margin-bottom: 30px;
    }

</style>