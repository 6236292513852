<template>
    <div class="navbar justify-content-between align-items-center
    pl-xl-3 pr-xl-3 pl-lg-2 pr-lg-2 pl-1 pr-1">
        <div class="menu">
            <account-deactive class="account"/>
            <div class="menuimg">
                <img @click="nav=true" class="icon col-auto" src="../../assets/menu.svg"
                     alt="menuicon">
            </div>
            <touch-menu :style="{transform: [nav? 'translateX(0)' : '']}" @close="nav=false" :account="account"
                        :currentroute="currentroute" @help="help=true" @account="accountmenu=true"></touch-menu>
            <div class="sub-menu2 row">
                <a :class="[(currentroute==='Home') ? 'selected' : '']" href="/home" @click.prevent="$router.push('/home')" class="col-auto">صفحه اصلی</a>
                <a :class="[(currentroute==='Trade') ? 'selected' : '']" href="/trade" @click.prevent="$router.push('/trade')" class="col-auto">بازار ها</a>
                <a :class="[(currentroute==='Wage') ? 'selected' : '']" href="/wage" class="col-auto" @click.prevent="$router.push('/wage')">کارمزد</a>
                <a :class="[(currentroute==='Blog') ? 'selected' : '']" href="/blog" class="col-auto" >بلاگ</a>
                <a :class="[(currentroute==='HowToUse') ? 'selected' : '']" href="/howtouse" class="col-auto" @click.prevent="$router.push('/howtouse')">راهنما</a>
            </div>
        </div>
        <a href="/" class="no-gutters" style="width:70px;height:51px;padding:3px 0"><img style="width:50px;height:100%" src="../../assets/logo1.svg" alt=""></a>

         
        
    </div>
</template>

<script>
    // import Account from "@/components/Navbar/account";
    // import DropHover from "@/components/Navbar/dropHover";
    // import AccountDeactive from "@/components/AccountDeactive";
    import TouchMenu from "@/components/Navbar/TouchMenu/touch-menu";
    // import TouchMenuContent from "@/components/Navbar/TouchMenu/touchMenuContent";
    // import TouchMenuAccount from "@/components/Navbar/TouchMenu/touchMenuAccount";
    import AccountDeactive from "@/components/Navbar/AccountDeactive";
    // import TouchMenuTrade from "@/components/Navbar/TouchMenu/touchMenuTrade";

    export default {
        name: "NavBar",
        components: {AccountDeactive, TouchMenu},
        data() {
            return {
                nav: false,
                help: false,
                accountmenu: false,
                logo: require("../../assets/logo.png"),
                trade: false,
                tshow: false,
                tsshow: false,
                account: true,
                mobile: false,
                screanWidth: screen.width,
                loginpage: false,
                currentroute: '',
                dropname: '',
                subheader: '',
                contents_sub: [],
                contents: [
                    {
                        name: 'راهنمای استفاده',
                        link: '/howtouse',
                        route: 'HowToUse'
                    },
                    {
                        name: 'سوالات متداول',
                        link: '/faq',
                        route: 'FAQ'
                    },
                    {
                        name: 'قوانین',
                        link: '/policy',
                        route: 'Policy'
                    },
                ],
                tradeRoutes: ['Trade', 'Wallet', 'History','Orders'],
                accountRoutes: ['Profile', 'Setting', 'Messages'],
                deg: [90, 90, 90, 90],
                show: [false, false, false, false],
                subcontent: [
                    {
                        name: 'تومان',
                        link: '',
                        route: '/trade/0'
                    },
                    {
                        name: 'تتر',
                        link: '',
                        route: '/trade/1'
                    },
                ],
                subcontentstoman: [
                    {
                        name: 'بیتکوین',
                        link: '/trade/0/0',
                        route: '/trade/0/0'
                    },
                    {
                        name: 'اتریوم',
                        link: '/trade/0/1',
                        route: '/trade/0/1'
                    },
                    {
                        name: 'بایننس کوین',
                        link: '/trade/0/2',
                        route: '/trade/0/2'
                    },
                    {
                        name: 'بیتکوین کش',
                        link: '/trade/0/3',
                        route: '/trade/0/3'
                    },
                    {
                        name: 'ایاس',
                        link: '/trade/0/4',
                        route: '/trade/0/4'
                    },
                    {
                        name: 'استلار',
                        link: '/trade/0/5',
                        route: '/trade/0/5'
                    },
                    {
                        name: 'اتریوم کلاسیک',
                        link: '/trade/0/6',
                        route: '/trade/0/6'
                    },
                    {
                        name: 'لایت کوین',
                        link: '/trade/0/7',
                        route: '/trade/0/7'
                    },
                    {
                        name: 'ترون',
                        link: '/trade/0/8',
                        route: '/trade/0/8'
                    },
                    {
                        name: 'ریپل',
                        link: '/trade/0/9',
                        route: '/trade/0/9'
                    },
                    {
                        name: 'تتر',
                        link: '/trade/0/10',
                        route: '/trade/0/10'
                    },
                ],
                subcontentsteter: [
                    {
                        name: 'بیتکوین',
                        link: '/trade/1/0',
                        route: '/trade/1/0'
                    },
                    {
                        name: 'اتریوم',
                        link: '/trade/1/1',
                        route: '/trade/1/1'
                    },
                    {
                        name: 'بایننس کوین',
                        link: '/trade/1/2',
                        route: '/trade/1/2'
                    },
                    {
                        name: 'بیتکوین کش',
                        link: '/trade/1/3',
                        route: '/trade/1/3'
                    },
                    {
                        name: 'ایاس',
                        link: '/trade/1/4',
                        route: '/trade/1/4'
                    },
                    {
                        name: 'استلار',
                        link: '/trade/1/5',
                        route: '/trade/1/5'
                    },
                    {
                        name: 'اتریوم کلاسیک',
                        link: '/trade/1/6',
                        route: '/trade/1/6'
                    },
                    {
                        name: 'لایت کوین',
                        link: '/trade/1/7',
                        route: '/trade/1/7'
                    },
                    {
                        name: 'ترون',
                        link: '/trade/1/8',
                        route: '/trade/1/8'
                    },
                    {
                        name: 'ریپل',
                        link: '/trade/1/9',
                        route: '/trade/1/9'
                    },
                ]

            }
        },
        watch: {
            $route(to) {
                this.nav = false
                this.help = false
                this.accountmenu = false
                this.tshow = false
                this.tsshow = false
                this.currentroute = to.name
                this.RouteName()
            }
        },
        mounted() {
            this.currentroute = this.$route.name;
            this.RouteName()
            window.addEventListener('resize', this.windowssize)
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.windowssize)
        },
        methods: {
            RouteName() {
                var e = this.currentroute
                if (this.tradeRoutes.indexOf(e) > -1) {
                    this.trade = true
                } else {
                    this.trade = false
                }
                if (this.trade || this.accountRoutes.indexOf(e) > -1) {
                    this.account = true
                } else {
                    this.account = false
                }
            },
            windowssize() {
                this.screanWidth = screen.width
                if (this.screanWidth > 724) {
                    this.nav = false
                    this.deg = [90, 90, 90, 90]
                    this.show = [false, false, false, false]
                }
            },
            Route(e) {
                this.$router.push(e)
            },
            touchdropshow(e) {
                if (!this.show[e]) {
                    this.show[e] = true
                    this.deg[e] = 0
                } else {
                    this.show[e] = false
                    this.deg[e] = 90
                }
                this.$forceUpdate()
            },
        }
    }
</script>

<style scoped>

    .navbar {
        width: 100%;
        box-sizing: inherit;
        background-color: var(--navbar-back);
        min-height: 54px;
        position: fixed;
        position: -ms-device-fixed;
        /*position: sticky;*/
        /*position: -webkit-sticky;*/
        /*display: block;*/
        top: 0;
        box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
        z-index: 4;
        color: white;
        display: flex;
        flex-direction: row;

    }

    .logo {
        height: 40px;
        width: 140px;
        cursor: pointer;
        background-image: url("../../assets/rocket.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .account{
        width: 250px;
    }

    .menu {
        position: revert;
        display: flex;
        /* justify-content: space-between; */
        width: calc(100% - 370px);
        /*color: white;*/
        font-size: 14px;
    }

    .menuimg {
        display: none;
        flex-direction: column;
        align-items: center;
    }

    .dark .menuimg img {
        filter: invert(100%)
    }

    .menu .icon {
        height: 30px;
        width: 50px;
    }


    .sub-menu2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        max-width: max-content;
    }


    .sub-menu2 a {
        font-size: 14px;
        height: 22px;
        /*width: 73px;*/
        padding: 0 20px;
        cursor: pointer;
        /*border-left: solid var(--light-border) 2px;*/
        color: white;
        text-decoration: none;
    }

    .sub-menu2 a:last-of-type {
        border-left: none
    }

    .sub-menu2 a:hover {
        color: var(--main-green);
    }

    .selected {
        color: var(--main-green) !important;
        /*height: 30px;*/
        /*border-bottom: #f8b200 solid 2px;*/
        cursor: default;
    }

    .selected a {
        color: var(--main-green);
    }

    @media (max-width: 785px) {
        .menuimg {
            display: flex;
        }

        .sub-menu2, .account {
            display: none;
        }
        .menu{
            width: calc(100% - 140px);
        }
    }

    .touch-menu .account {
        display: flex;
    }

    ul {
        list-style-type: none;
        font-size: 14px;
        width: max-content;
    }

    li {
        padding: 10px 12px;
        transition: 0.3s;
        position: relative;
        text-align: center;
    }

    .dropdown {
        position: relative;
    }

    .drop {
        display: none;
    }

    .dropdown:hover .drop {
        display: block !important;
    }

    .dark a {
        color: white;
        border-color: rgba(255, 255, 255, 0.2);
    }

    .dark svg {
        fill: white !important;
    }

</style>