<template>
    <div class="box2">
        <perfect-scrollbar :options="setting" @ps-y-reach-end="load" ref="scroll">
            <!-- <button @click="getBuyOrders">click</button> -->
            <table>
                <tr class="table-header">
                    <th>بازار</th>
                    <th>مقدار</th>
                    <th class="mobilehidden">قیمت واحد (تومان)</th>
                    <th>قیمت کل (تومان)</th>
                    <th>انجام شده</th>
                    <th class="mobilehidden">شناسه سفارش</th>
                    <th>تاریخ</th>
                    <th class="mobilehidden">وضعیت</th>
                </tr>
                <tr v-for="(tableContent,page) in tableContents" :key="page" class="table-row">
                    <td>{{$coinLabel[tableContent.sourceCoin]}} - {{$coinLabel[tableContent.destinationCoin]}}</td>
                    <td>{{tableContent.amount}}</td>
                    <td class="mobilehidden">{{tableContent.unitPrice.toLocaleString()}}</td>
                    <td>{{(tableContent.wholePrice).toLocaleString()}}</td>
                    <td class="mobileshow" :style="{color:[(tableContent.orderStatus == 'IS_OPEN')? 'var(--main-orange)' : [(tableContent.orderStatus === 'FINISHED')? 'var(--main-green)':'var(--main-red)']]}">{{(tableContent.executedPercent)}}%</td>
                    <td class="mobilehidden">{{tableContent.executedPercent}} %</td>
                    <td class="mobilehidden">{{tableContent.id}}</td>
                    <td class="mobilehidden" style="direction:ltr">{{$G2J(tableContent.updatedAtDateTime,'faDateTime')}}</td>
                    <td class="mobileshow">{{$G2J(tableContent.updatedAtDateTime,'faDate').slice(2)}}</td>
                    <td class="mobilehidden" v-if="tableContent.orderStatus === 'IS_OPEN'" :style="{color:'var(--main-orange)'}">در حال انجام</td>
                    <td class="mobilehidden" v-else-if="tableContent.orderStatus === 'FINISHED' " :style="{color:'var(--main-green)'}">موفق</td>
                    <td class="mobilehidden" v-else :style="{color:'var(--main-red)'}">ناموفق</td>
                </tr>
            </table>
            <p style="text-align:center;color:red;opacity:0.8">{{error}}</p>
            <div class="empty" v-show="!tableContents">
                <img src="../../assets/icon/empty.png" alt="">
                <p>تا کنون سفارش خرید نداشته اید</p>
            </div>
        </perfect-scrollbar>
        <!--        <pagination v-show="tableContents.length>16" style="margin-top: 30px" :n="5" :max="3" :page="id"-->
        <!--                    :link="'Wallet'"></pagination>-->
    </div>
</template>

<script>
    // import Pagination from "../Tools/Pagination";

    export default {
        name: "BuyOrders",
        components: {},
        props: ['id'],
        data() {
            return {
               
                loading: false,
                setting: {
                    suppressScrollX: true,
                    swipeEasing: false,
                    wheelPropagation: false,
                },
                tableContents:[],
                
                lastPage:3,
                page:0,
                error:''
            }
        },
        methods: {
           getBuyOrders(){
                if (this.page<this.lastPage){
                    this.page ++
                this.$axios(`/orders?orderType=BUY&size=10&page=${this.page}`)
                    .then(res=>{
                        this.lastPage = res.data.totalPages
                        // console.log(res);
                        if (res.data){
                            this.tableContents = [...this.tableContents,...res.data.content]
                        }else{
                            this.error = 'تاکنون سفارش خریدی انجام نداده اید.'
                            // console.log('emptyyyyyyy');
                        }
                        
                    })
                    .catch(err=>{
                        console.log(err);
                    })
                }else if(this.page>=this.lastPage){
                    this.error = 'پیغام بیشتری جهت نمایش وجود ندارد'
                }
            },
           
            load() {
                
                this.loading = true;
                if(this.page<this.lastPage){document.body.style.cursor = 'wait';}
                setTimeout(() => {
                    this.getBuyOrders()
                    document.body.style.cursor = 'default';
                    this.loading = false;
                }, 1000)
            
            },
            
        },
        mounted(){
            this.load()
        }

    }
</script>

<style scoped>
    .ps {
        height: 450px;
    }


    .box2 {
        font-size: 16px;
        border: none;
        background-color: white;
        width: 100%;
        margin-bottom: 30px;
        position: relative;
    }

    table {
        background-color: white;
        width: 100%;
        /*min-width: 768px;*/
        /*height: 710px;*/
        border-collapse: collapse;
        position: relative;
    }

    table div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 50%;
        top: 30%;
        transform: translate(50%, -50%);
    }

    table div img {
        margin: 55px;
    }

    table th {
        height: 50px;
        font-weight: 300;
        /*opacity: 0.8;*/
        position: -webkit-sticky;
        position: sticky;
        background-color: var(--main-grey);
        top: 0;
        z-index: 1;
    }

    .table-header {
        /*position: -webkit-sticky;*/
        /*position: sticky;*/
        /*top: 0;*/
        text-align: center;
        font-size: 15px;
    }

    /*.table-header th:first-of-type {*/
    /*    text-align: right;*/
    /*    padding-right: 4%;*/
    /*}*/

    /*.table-header th:last-of-type {*/
    /*    text-align: left;*/
    /*    padding-left: 3%;*/
    /*}*/

    .table-row {
        position: relative;
        text-align: center;
        /*cursor: default;*/
        height: 35px;
        font-size: 14px;
    }

    .table-row:hover {
        background-color: var(--hover);
    }

    /*.table-row:nth-of-type(odd){*/
    /*    height: 40px;*/
    /*    background-color: var(--hover);*/
    /*}*/
    /*.table-row:nth-of-type(even){*/
    /*    height: 45px;*/
    /*    background-color: white;*/
    /*}*/

    /*.table-row:nth-of-type(odd) td:first-of-type{*/
    /*    height: 40px;*/
    /*}*/
    /*.table-row:nth-of-type(even) td:first-of-type{*/
    /*    height: 45px;*/
    /*}*/


    /*.table-row td:first-of-type {*/
    /*    padding-right: 10%;*/
    /*    text-align: right;*/
    /*    direction: rtl;*/
    /*    display: flex;*/
    /*    flex-direction: row;*/
    /*    align-items: center;*/
    /*}*/
    /**/
    /**/
    /*.table-row td:nth-of-type(7),*/
    /*.table-row td:nth-of-type(8){*/
    /*    padding-left: 3%;*/
    /*    text-align: left;*/
    /*direction: ltr;*/
    /*display: flex;*/
    /*flex-direction: row;*/
    /*align-items: center;*/
    /*margin-left: 10%;*/
    /**/
    /*}*/

    /*.table-row td:first-of-type a {*/
    /*    margin-right: 5px;*/
    /*}*/

    .table-row td {
        /*direction: ltr;*/
        opacity: 0.8;
    }

    .empty {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 50px;
    }

    .mobilehidden{
        display: table-cell;
    }
    .mobileshow{
        display:none
    }
    @media (max-width: 580px){
        .mobilehidden{
            display: none;
        }
        .mobileshow{
            display:table-cell;
        }
    }

</style>