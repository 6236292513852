<template>
    <div class="touch-menu" style="padding-bottom: 10px">
        <div class="touch-menu-header">
            <div class="header">{{header}}</div>
            <svg height="20px" @click="$emit('close')" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                 x="0px" y="0px" viewBox="0 0 443.52 443.52" style="enable-background:new 0 0 443.52 443.52;transform: rotate(180deg)"
                 xml:space="preserve" class="back" fill="#ffffffaa">
                        <path d="M143.492,221.863L336.226,29.129c6.663-6.664,6.663-17.468,0-24.132c-6.665-6.662-17.468-6.662-24.132,0l-204.8,204.8
                        c-6.662,6.664-6.662,17.468,0,24.132l204.8,204.8c6.78,6.548,17.584,6.36,24.132-0.42c6.387-6.614,6.387-17.099,0-23.712
                        L143.492,221.863z"/>
                    </svg>
        </div>
        <a :style="{height: 'calc(100vh/' + (contents.length+1.5) +')'}" v-for="(content,index) in contents" :key="index"
           :class="[content.route.indexOf(currentroute)>-1 ? 'selected' : '']"
           @click="clicked(content.link,index)" class="col-auto">{{content.name}}</a>
    </div>
</template>

<script>
    export default {
        name: "touchMenuContent",
        props: ['currentroute','contents','header'],
        methods:{
            clicked(e,i){
                if(e){
                    this.$router.push(e)
                }else{
                    this.$emit('subcontent',this.contents[i].name)
                }
            }
        }
    }
</script>

<style scoped>
    .touch-menu .account {
        display: flex;
    }

    .touch-menu-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 54px;
        align-items: center;
        border-bottom: solid 1px var(--light-border);
        padding: 0 10px;
    }
    .header{
        color: var(--main-green);
    }

    .touch-menu {
        font-size: 18px !important;
        position: absolute;
        /*position: -webkit-sticky;*/
        right: 0;
        top: 0;
        transform: translateX(100%);
        background-color: var(--dark-background);
        display: flex;
        width: 60%;
        flex-direction: column;
        overflow: hidden;
        transition: 0.25s;
        z-index: 5;
        height: 100vh;
        padding: 0 10px;
        color: white;
        /*margin-left: -300px;*/
    }

    .touch-menu a {
        display: flex;
        align-items: center;
        /*height: calc(100vh/12.5);*/
        max-height: 70px;
        /*margin: 20px 0;*/
        text-align: right;
        opacity: 0.8;
        padding-right: 10px;
        color: white;
    }

    .selected {
        color: var(--main-green) !important;
        /*height: 30px;*/
        border-bottom: var(--main-green) solid 2px;
        cursor: default;
    }

    .selected a {
        color: var(--main-green);
    }

    .account-deactive {
        width: calc(100% - 30px);
    }

    .account-active {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #00000080;
    }
</style>