<template>
    <div class="from-google">
        <p style="color:green;opacity:0.8;font-size:18px">{{status}}</p>
        <Loading v-if="showLoading"/>
    </div>
</template>

<script>
import Loading from '../Tools/Loading'
export default {
    name:'fromGoogle',
    components:{Loading},
    data(){
        return{
            OSName:'',
            borwserType:'',
            deviceType:'',
            status:'',
            showLoading:false
        }
    },
    mounted(){
        this.detectOs()
        this.detectBrowser()
        this.detectDevice()
        this.check()
    },

    methods:{
    detectOs(){
            var Name = "Unknown OS"; 
            if (navigator.userAgent.indexOf("Win") != -1) Name =  
            "WINDOWS"; 
            if (navigator.userAgent.indexOf("Mac") != -1) Name =  
            "MACINTOSH"; 
            if (navigator.userAgent.indexOf("Linux") != -1) Name =  
            "LINUX"; 
            if (navigator.userAgent.indexOf("Android") != -1) Name =  
            "ANDROID"; 
            if (navigator.userAgent.indexOf("like Mac") != -1) Name = "IOS"; 
            this.OSName = Name
            console.log('Your OS: '+this.OSName);
        },
        detectBrowser(){
            // Opera 8.0+
            var isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
            if(isOpera){
                this.borwserType = 'OPERA'
            }

            // Firefox 1.0+
            var isFirefox = typeof InstallTrigger !== 'undefined';
            if(isFirefox){
                this.borwserType = 'FIREFOX'
            }

            // Safari 3.0+ "[object HTMLElementConstructor]" 
            var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
            if(isSafari){
                this.borwserType = 'SAFARI'
            }
            // Internet Explorer 6-11
            var isIE = /*@cc_on!@*/false || !!document.documentMode;
            if(isIE){
                this.borwserType = 'IE'
            }
            // Edge 20+
            var isEdge = !isIE && !!window.StyleMedia;
            if(isEdge){
                this.borwserType = 'EDGE'
            }
            // Chrome 1 - 79
            var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
            if(isChrome){
                this.borwserType = 'CHROME'
            }
            // Edge (based on chromium) detection
            var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
            if(isEdgeChromium){
                this.borwserType = 'EDGECHROMIUM'
            }
            if (this.borwserType === ''){
                this.borwserType = 'CHROME'}
            console.log(this.borwserType)
        },
        detectDevice(){
            const ua = navigator.userAgent;
            if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
                this.deviceType = 'TABLET'
            }
            if (
                /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                ua
                )
            ) {
                this.deviceType = 'MOBILE'
            }else{
                this.deviceType = 'DESKTOP'
            }
            console.log(this.deviceType)
        },
        check(){
            // console.log(this.$route.query.code,'lll');
            this.showLoading = true
                setTimeout(() => {
                    // console.log(this.$route.query.code)
                    this.$axios.post(`/users/get-google-token?browser=${this.borwserType}&osType=${this.OSName}&deviceType=${this.deviceType}&code=${this.$route.query.code}`)
                        .then(response=>{
                            this.showLoading = false
                            // console.log(response.data);
                            if (response.data.token){
                                this.status = 'با موفقیت وارد شدید.'
                                localStorage.token = response.data.token
                                this.state.token = localStorage.token
                                this.$axios.defaults.headers.common['Authorization'] = 'Bearer '+this.state.token
                                // console.log(this.state.token);
                                this.$router.push('profile')
                            }
                        })
                        .catch(async err=>{
                            this.showLoading = false
                            // console.log('hereksdjhgkr',err.response.data.message);
                            if (err.response.data.message === "GOOGLE_FAILURE"){
                                await this.$error('خطای ورود، به صفحه قبل منتقل میشوید', '')
                                this.$router.push('login')
                            }
                        })
                    }, 2000);
                }
    }
}
</script>

<style scoped>
    .from-google{
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>