<template>
    <div class="main-box">
        <!-- <button @click="getWalletCredit">click</button> -->
        <div class="tab">
            <div
                    :class="[!limit ? 'tab-content' : 'tab-content-active']"
                    @click.prevent="tabChange('limited')"
                    style="margin-left: 1rem"
            >
                <a>قیمت سفارشی</a>
            </div>
            <div
                    :class="[!market ? 'tab-content' : 'tab-content-active']"
                    @click.prevent="tabChange('market')"
                    style="margin-right: 1rem"
            >
                <a>قیمت بازار</a>
            </div>
            <div
                    class="selector"
                    :style="{ right: [limit ? '' : 'calc(50% + 1rem)'] }"
            ></div>
        </div>
        <div class="row box1 no-gutters">
            <div class="col-12 col-sm-6 pl-0 pl-sm-3 pb-1 pb-sm-0" v-if="limit">
                <buy-crypto
                        :tradeto="tradeto[tradetoid]"
                        :tradefrom="$coinLabel[$coinArray[tradefromid]]"
                        :tolabel="tradeto[tradetoid]"
                        :fromlabel="tradeto[tradetoid]"
                        :cash="buyCredit"
                        :minSell='queueMaxBuy'
                        :wage="state.userinfo.wage"
                        :decimal="8"
                        :limit="true"
                        :id="1"
                />
            </div>
            <div class="col-12 col-sm-6 pr-0 pr-sm-3" v-if="limit">

                <sell-crypto
                        :tradeto="tradeto[tradetoid]"
                        :tradefrom="$coinLabel[$coinArray[tradefromid]]"
                        :tolabel="coin[tradefromid]"
                        :fromlabel="tradeto[tradetoid]"
                        :cash="sellCredit"
                        :maxSell='queueMinSell'
                        :wage="state.userinfo.wage"
                        :decimal="8"
                        :limit="true"
                        :id="2"
                />
            </div>
            <!--            trade-->
            <div class="col-12 col-sm-6 pl-0 pl-sm-3 pb-1 pb-sm-0" v-if="!limit">
                <buy-crypto
                        :tradeto="tradeto[tradetoid]"
                        :tradefrom="$coinLabel[$coinArray[tradefromid]]"
                        :tolabel="tradeto[tradetoid]"
                        :fromlabel="tradeto[tradetoid]"
                        :cash="buyCredit"
                        :minSell='queueMaxBuy'
                        :wage="state.userinfo.wage"
                        :decimal="8"
                        :limit="false"
                        :id="3"
                        :realprice="16299900"
                />
            </div>
            <div class="col-12 col-sm-6 pr-0 pr-sm-3" v-if="!limit">
                <sell-crypto
                        :tradeto="tradeto[tradetoid]"
                        :tradefrom="$coinLabel[$coinArray[tradefromid]]"
                        :tolabel="coin[tradefromid]"
                        :fromlabel="tradeto[tradetoid]"
                        :cash="sellCredit"
                        :maxSell='queueMinSell'
                        :wage="state.userinfo.wage"
                        :decimal="8"
                        :limit="false"
                        :id="4"
                        :realprice="10"
                />
            </div>
        </div>
    </div>
</template>

<script>

    import BuyCrypto from "./BuyCrypto";
    import SellCrypto from "./SellCrypto";

    export default {
        name: "Shop",
        components: {SellCrypto, BuyCrypto},
        props: [
            "tomanTable",
            "teterTable",
            "tradefromid",
            "tradetoid",
            "tradefrom",
            "tradeto",
            "coin",
        ],
        data() {
            return {
                limit: true,
                market: false,
                queueMaxBuy: 0,
                queueMinSell: 0,
                sellCredit: 0,
                buyCredit: 0,
                tradetoEng: ["TOMAN", "TETHER"],
                sellWallet: ''
            };
        },
        methods: {
            async getWalletCredit() {
                if (this.state.userinfo.authorized) {
                    const res = await this.$axios.get('/wallets/customer-addresses', {
                        params: {
                            coins: `${this.$coinArray[this.tradefromid]},${this.tradetoEng[this.tradetoid]}`
                        }
                    })
                    this.sellCredit = res.data[this.$coinArray[this.tradefromid]].credit || 0
                    this.buyCredit = res.data[this.tradetoEng[this.tradetoid]].totalBalance || res.data[this.tradetoEng[this.tradetoid]].credit || 0
                }
            },
            getBestOffers() {
                this.$axios(`/orders/order-book?marketType=${this.$coinArray[this.tradefromid]}_${this.tradetoEng[this.tradetoid]}`)
                    .then((res) => {
                        this.depthTableBuy = res.data.buyOrderBook;
                        this.depthTableSell = res.data.sellOrderBook;
                        for (let i in this.depthTableSell) {
                            if (i === '0') {
                                this.queueMaxBuy = this.depthTableSell[i].unitPrice
                            }
                        }
                        for (let i in this.depthTableBuy) {
                            if (i === '0') {
                                this.queueMinSell = this.depthTableBuy[i].unitPrice
                            }
                        }
                    })
                    .catch(() => {
                        this.state.errorMessage = "خطایی رخ داده است. مجددا تلاش کنید";
                        this.state.showError = true;

                    });
            },
            tabChange(val) {
                if (val === "limited") {
                    this.limit = true;
                    this.market = false;
                    this.state.islimited = true;
                } else {
                    this.limit = false;
                    this.market = true;
                    this.state.islimited = false;
                }
            },
            closemodal() {
                this.showmodal = false;
            },
            showmodal() {
            },
        },
        mounted() {
            this.getWalletCredit()
            this.getBestOffers()
        }
    };
</script>

<style scoped>
    .main-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: right;
        /*padding: 0 0 10px;*/
        box-sizing: border-box;
        width: 100%;
        max-width: 1400px;
        font-size: 12px;
        background-color: transparent;
    }

    .dark a {
        color: white;
    }

    .tab {
        display: flex;
        flex-direction: row;
        position: relative;
        height: 30px;
        /*border-bottom: #00000010 1px solid;*/
        font-size: 12px;
        align-items: center;
        background-color: transparent;
        text-align: right;
        width: 100%;
        max-width: 500px;
    }

    .tab-content {
        width: 50%;
        opacity: 0.5;
        text-align: center;
        cursor: pointer;
    }

    .tab-content-active {
        font-weight: 800;
        width: 50%;
        opacity: 0.7;
        text-align: center;
    }

    .box1 {
        /*background-color: white;*/
        width: 100%;
        margin-top: 20px;
    }

    .selector {
        position: absolute;
        background-color: dodgerblue;
        width: calc(50% - 1rem);
        height: 4px;
        border-radius: 5px;
        bottom: -1px;
        right: 0;
        transition: 0.5s;
    }
</style>