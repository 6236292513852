<template>
    <div class="main">
        <blog-header></blog-header>
        <div class="body">
            <related-posts :posts="posts"></related-posts>
            <div class="body-post">
                <div class="img" :style="{backgroundImage : 'url(' + post_image +')'}">
                    <a href="/blog/show/3" class="footer">
                        {{post_header}}
                        <span>
                            <p>{{post_time}} دقیقه</p>
                            <p>{{post_date}}</p>
                        </span>
                    </a>
                </div>
            </div>
            <related-posts :posts="posts"></related-posts>
            <div class="body-post">
                <div class="img" :style="{backgroundImage : 'url(' + post_image +')'}">
                    <a href="/blog/show/3" class="footer">
                        {{post_header}}
                        <span>
                            <p>{{post_time}} دقیقه</p>
                            <p>{{post_date}}</p>
                        </span>
                    </a>
                </div>
            </div>
            <button @click="$router.push('/blog/index')" class="more">نمایش بیشتر</button>
        </div>
        <div class="sidebar">
            <div class="last-posts">
                <h3>آخرین مطالب</h3>
                <a :href="'/blog/show/'+index" v-for="(posts,index) in lastposts" :key="index">
                    {{posts.header}}
                    <span class="footer">
                        <p>{{posts.time}} دقیقه</p>
                        <p>{{posts.date}}</p>
                    </span>
                </a>
            </div>
            <div class="fav-posts">
                <h3>مقالات منتخب</h3>
                <a :href="'/blog/show/'+index" v-for="(posts,index) in lastposts" :key="index">
                    {{posts.header}}
                    <span class="footer">
                        <p>{{posts.time}} دقیقه</p>
                        <p>{{posts.date}}</p>
                    </span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import BlogHeader from "@/components/Blog/BlogHeader";
    import RelatedPosts from "@/components/Blog/RelatedPosts";

    export default {
        name: "BlogHome",
        components: {RelatedPosts, BlogHeader},
        data() {
            return {
                lastposts: [
                    {
                        header: 'دلایلی که قیمت بیت کوین طی روزهای آینده می‌تواند به ۱۰,۰۰۰ دلار برسد.',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                    {
                        header: 'تحلیل تکنیکال هفتگی ارزهای دیجیتال ۳۰ ژوئن',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                    {
                        header: 'دلایلی که قیمت بیت کوین طی روزهای آینده می‌تواند به ۱۰,۰۰۰ دلار برسد.',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                ],
                posts: [
                    {
                        image: require('../../assets/blog/blog-gallery.jpg'),
                        header: 'سرمایه‌ گذاری بیت کوین در سال ۲۰۲۰؛ هر آنچه باید بدانید',
                        time: 10,
                        date: '22 خرداد 1399'

                    },
                    {
                        image: require('../../assets/blog/blog-gallery.jpg'),
                        header: 'سرمایه‌ گذاری بیت کوین در سال ۲۰۲۰؛ هر آنچه باید بدانید',
                        time: 10,
                        date: '22 خرداد 1399'

                    },
                ],
                favposts: [
                    {
                        header: 'بیت کوین در واشینگتن آمریکا به‌عنوان پول به رسمیت شناخته شد',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                    {
                        header: 'بیت کوین در واشینگتن آمریکا به‌عنوان پول به رسمیت شناخته شد',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                    {
                        header: 'بیت کوین در واشینگتن آمریکا به‌عنوان پول به رسمیت شناخته شد',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                ],
                post_image: require('../../assets/blog/cryptobitcoin.jpg'),
                post_header: 'سرمایه‌ گذاری بیت کوین در سال ۲۰۲۰؛ هرآنچه باید بدانید',
                post_time: 10,
                post_date: '22 خرداد 1399'
            }
        }
    }
</script>

<style scoped>
    .main {
        width: 100%;
        max-width: 1250px;
        padding: 0 20px 20px;
        margin-top: 56px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .sidebar {
        width: 33.333%;
        position: sticky;
        position: -webkit-sticky;
        top: 60px;
        height: 100%;
        text-align: right;
    }


    .sidebar h3 {
        font-size: 22px;
    }

    .sidebar a {
        display: flex;
        padding-bottom: 25px;
        position: relative;
        font-size: 16px;
        margin-bottom: 25px;
        text-align: right;
    }

    .sidebar a .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        bottom: 0;
        font-size: 14px;
        position: absolute;
    }

    .sidebar a .footer p {
        margin: 0;
    }

    .last-posts {
        margin-bottom: 100px;
    }

    .body {
        width: 66.667%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media (max-width: 768px){
        .sidebar{
            position: relative;
            width: 100%;
            top:0;
        }
        .body{
            width: 100%;
        }
    }

    .body .body-post {
        /*height: 460px;*/
        width: 100%;
        padding: 56.25% 2% 0;
        position: relative;
    }

    .body-post .img {
        /*border-radius: 5px;*/
        height: 100%;
        /*width: 400px !important;*/
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: absolute;
        top:0;
        left:50%;
        transform: translateX(-50%);
        width: calc(100% - 4%);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .body-post .img .footer{
        position: relative;
        background-color: #00000080;
        padding: 5px 10px 35px;
        color: white;
        font-size: 16px;
        font-weight: 700;
    }

    .body-post .img .footer span{
        position: absolute;
        color: white;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 20px);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
    }
    .body-post .img .footer span p{
        margin: 0;
    }
    .more{
        background-color: var(--dark-background);
        color: white;
        padding: 10px 0;
        margin: 30px 0;
        display: block;
        width: 245px;
        border-radius: 25px;
        font-size: 20px;
        cursor: pointer;
    }

</style>