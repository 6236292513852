<template>
    <form>
        <input type="text" v-model="fname" name="fname" id="fname" placeholder="نام">
        <input type="email" v-model="email" name="email" id="email" placeholder="ایمیل">
        <textarea v-model="address" name="address" id="address" placeholder="دیدگاه"></textarea>
        <button type="button" class="normal-btn">ارسال دیدگاه</button>
    </form>
</template>

<script>
    export default {
        name: "addComent"
    }
</script>

<style scoped>

    form {
        font-family: VistaDev;
        text-align: right;
        /*border-radius: 5px;*/
        width: 100%;
        font-size: 14px;
        margin-top: 20px;
        position: relative;
    }


    form input {
        border: solid rgba(54, 52, 53, 0.15) 1px;
        height: 50px;
        border-radius: 5px;
        padding: 2px 10px;
        width: calc(50% - 25px);
        max-width: 380px;
        margin: 25px;
    }
    form input:first-of-type{
        margin-right: 0;
    }
    form input:last-of-type{
        margin-left: 0;
    }
    @media (max-width: 600px) {
        form input {
            width: 100%;
            margin: 15px 0;
            max-width: none;
        }
    }

    form input:hover {
        border: solid rgba(54, 52, 53, 0.3) 1px;
    }

    form input:focus {
        border: solid rgba(54, 52, 53, 0.5) 1px;
    }

    textarea {
        position: relative;
        border-bottom: solid rgba(54, 52, 53, 0.15) 1px;
        display: inline-block;
        width: 100% !important;
        height: 97px;
        /*border-radius: 5px;*/
        padding: 2px 5px;
        margin-bottom: 70px;
    }

    form textarea:hover {
        border-bottom: solid rgba(54, 52, 53, 0.3) 1px;
    }

    form textarea:focus {
        border-bottom: solid rgba(54, 52, 53, 0.5) 1px;
    }

    .normal-btn {
        position: absolute;
        bottom: 10px;
        left: 10%;
        width: 50%;
        max-width: 180px;
        margin: 0;
        height: 50px;
        border-radius: 20px;
        background-color: transparent;
        border: 3px solid var(--main-green);
        color: black;
        font-size: 16px;
    }
    .comments .normal-btn{
        left:0
    }


</style>