<template>
    <div class="box2">
        <perfect-scrollbar :options="setting" @ps-y-reach-end="load" ref="scroll">
            <table>
                <tr class="table-header">
                    <th>بازار</th>
                    <th class="mobilehidden">مقدار</th>
                    <th class="mobilehidden">قیمت واحد</th>
                    <th>قیمت کل</th>
                    <th class="mobilehidden">کارمزد</th>
                    <th>زمان</th>
                    <th>دریافتی شما</th>
                </tr>
                <tr v-for="(tableContent,index) in tableContents" :key="index" class="table-row">
                    <td>{{$coinLabel[tableContent.destinationCoin]}} - {{$coinLabel[tableContent.sourceCoin]}} </td>
                    <td style="direction:ltr" class="mobilehidden">{{tableContent.amount}} {{$coinUnit[tableContent.destinationCoin]}}</td>
                    <td class="mobilehidden">{{tableContent.unitPrice}} {{$coinLabel[tableContent.sourceCoin]}}</td>
                    <td>{{tableContent.unitPrice * tableContent.amount}} {{$coinLabel[tableContent.sourceCoin]}}</td>
                    <td class="mobilehidden">% {{tableContent.wagePercent}}</td>
                    <td class="mobilehidden" style="direction:ltr">{{$G2J(tableContent.createdAtDateTime,'faDateTime')}}</td>
                    <!-- <td class="mobileshow">{{tableContent.time.slice(0,8)}}</td> -->
                    <td style="direction:ltr">{{tableContent.receivedAmount}} {{$coinUnit[tableContent.destinationCoin]}}</td>
                </tr>
            </table>
            <p style="text-align:center;color:red;opacity:0.8">{{error}}</p>
            <div class="empty" v-show="tableContents.length === 0">
                <img src="../../assets/icon/empty.png" alt="">
                <p>تاکنون معامله خرید انجام نداده‌اید.</p>
            </div>
        </perfect-scrollbar>
        <!-- <button @click="check">clickkk</button> -->
        <!--        <pagination v-show="tableContents.length>16" style="margin-top: 30px" :n="6" :max="3" :page="id" :link="'History'"></pagination>-->
    </div>
</template>

<script>
    // import Pagination from "../Tools/Pagination";

    export default {
        name: "BuyTable",
        components: {},
        data() {
            return {
                buy: false,
                sell: false,
                login: true,
                page:0,
                lastPage:5,
                error:'',
                tableContents: [],
                loading: false,
                setting: {
                    suppressScrollX: true,
                    swipeEasing: false,
                    wheelPropagation: false,
                },
               
            }
        },
        methods: {
            
            check(){
                // console.log('fetch logins')
                if (this.page<this.lastPage){
                    this.page ++
                    // console.log(this.page)
                    this.$axios.get(`/trades?size=10&page=${this.page}&type=BUY`)
                    .then(response =>{
                        console.log(response.data)
                        this.lastPage = response.data.totalPages
                        console.log('response',response.data.content.length)
                        
                        this.tableContents = [...this.tableContents,...response.data.content]
                        this.keyValue = this.tableContents
                        console.log('length',this.keyValue)
                    })
                    .catch(error=>{
                        console.log(error)
                    })
                }else if(this.page>=this.lastPage && this.tableContents.length !== 0){
                    this.error = 'پیغام بیشتری جهت نمایش وجود ندارد'
                }
                // console.log(this.lastPage) Navid Bobmos And He Can Gerdalo gerdalo gerdalo . 
            },
            load() {
                // var vm = this;
                console.log('end of scroll')
                this.loading = true;
                if(this.page<=this.lastPage){document.body.style.cursor = 'wait';
                    setTimeout(() => {
                    this.check()
                    
                    document.body.style.cursor = 'default';
                    this.loading = false;
                }, 1000)
                }
            }
        },
        mounted(){
            this.load()
        }

    }
</script>

<style scoped>
    .ps {
        height: 450px;
    }

    .mobilehidden {
        display: table-cell;
    }

    .mobileshow {
        display: none !important;
    }

    @media (max-width: 800px) {
        .mobilehidden {
            display: none;
        }

        .mobileshow {
            display: table-cell !important;
        }
    }

    @media (max-width: 445px) {
        .table-row td {
            font-size: 12px;
        }

    }

    .box2 {
        font-size: 16px;
        border: none;
        background-color: white;
        width: 100%;
        margin-bottom: 30px;
        position: relative;
    }

    table {
        background-color: white;
        width: 100%;
        /*min-width: 768px;*/
        /*height: 710px;*/
        border-collapse: collapse;
        position: relative;
    }

    table div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 50%;
        top: 30%;
        transform: translate(50%, -50%);
    }

    table div img {
        margin: 55px;
    }

    table th {
        height: 50px;
        font-weight: 300;
        /*opacity: 0.8;*/
        position: -webkit-sticky;
        position: sticky;
        background-color: var(--main-grey);
        top: 0;
        z-index: 1;
    }

    .table-header {
        /*position: -webkit-sticky;*/
        /*position: sticky;*/
        /*top: 0;*/
        text-align: center;
        font-size: 15px;
    }

    /*.table-header th:first-of-type {*/
    /*    text-align: right;*/
    /*    padding-right: 4%;*/
    /*}*/

    /*.table-header th:last-of-type {*/
    /*    text-align: left;*/
    /*    padding-left: 3%;*/
    /*}*/

    .table-row {
        position: relative;
        text-align: center;
        /*cursor: default;*/
        height: 35px;
        font-size: 14px;
    }

    .table-row:hover {
        background-color: var(--hover);
    }

    /*.table-row:nth-of-type(odd){*/
    /*    height: 40px;*/
    /*    background-color: var(--hover);*/
    /*}*/
    /*.table-row:nth-of-type(even){*/
    /*    height: 45px;*/
    /*    background-color: white;*/
    /*}*/

    /*.table-row:nth-of-type(odd) td:first-of-type{*/
    /*    height: 40px;*/
    /*}*/
    /*.table-row:nth-of-type(even) td:first-of-type{*/
    /*    height: 45px;*/
    /*}*/


    /*.table-row td:first-of-type {*/
    /*    padding-right: 10%;*/
    /*    text-align: right;*/
    /*    direction: rtl;*/
    /*    display: flex;*/
    /*    flex-direction: row;*/
    /*    align-items: center;*/
    /*}*/
    /**/
    /**/
    /*.table-row td:nth-of-type(7),*/
    /*.table-row td:nth-of-type(8){*/
    /*    padding-left: 3%;*/
    /*    text-align: left;*/
    /*direction: ltr;*/
    /*display: flex;*/
    /*flex-direction: row;*/
    /*align-items: center;*/
    /*margin-left: 10%;*/
    /**/
    /*}*/

    /*.table-row td:first-of-type a {*/
    /*    margin-right: 5px;*/
    /*}*/

    .table-row td {
        /*direction: ltr;*/
        opacity: 0.8;
    }

    .empty {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 50px;
    }

</style>