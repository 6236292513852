import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/components/Auth/Login";
import Register from "@/components/Auth/Register";
import TwoStepEmail from "@/components/Auth/TwoStepEmail";
import TwoStepGoogleAuth from "@/components/Auth/TwoStepGoogleAuth";
import TwoStepSms from "@/components/Auth/TwoStepSms"
import RecoveryPass from "@/components/Auth/RecoveryPass";
import ResetPass from "@/components/Auth/ResetPass";
import Profile from "@/components/UserAccount/Profile";
import Messages from "@/components/UserAccount/Messages";
import HowToUse from "@/components/Help/HowToUse";
import FAQ from "@/components/Help/FAQ";
import History from "@/components/History/History";
import Wage from "@/components/Help/Wage";
import Policy from "@/components/Help/Policy";
import AboutUs from "@/components/Help/AboutUs";
import Wallet from "@/components/Wallet/Wallet";
import Home from "@/components/Home/Home";
import BlogHome from "@/components/Blog/BlogHome";
import PostIndex from "@/components/Blog/PostIndex";
import PostsShow from "@/components/Blog/PostsShow";
import Trade from "@/components/Trade/Trade";
import Setting from "@/components/UserAccount/Setting";
import Orders from "@/components/Orders/Orders";
import Dashboard from "@/components/Dashboard/Dashboard";
import FromGoogle from '@/components/Auth/FromGoogle'
import VerifyingPage from '@/components/Auth/VerifyingPage';
import RedirectFromBank from '@/components/Wallet/RedirectFromBank'
import Invite from "@/components/UserAccount/Invite";


const that = Vue.prototype
Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    // base: '/',
    linkActiveClass: 'active-tab',
    routes: [
        {
            path: '/',
            // redirect: '/home',
            component: {
                render(c) {
                    return c('router-view');
                }
            },
            children: [
                {
                    path: '/',
                    name: 'Home',
                    component: Home

                },
                {
                    path: 'login',
                    name: 'Login',
                    component: Login
                },
                {
                    path: 'register',
                    name: 'Register',
                    component: Register
                },
                {
                    path: 'reset-pass',
                    name: 'ResetPass',
                    component: ResetPass,

                },
                {
                    path: 'recovery-pass',
                    name: 'RecoveryPass',
                    component: RecoveryPass
                },
                {
                    path: 'two-step-email',
                    name: 'TwoStepEmail',
                    component: TwoStepEmail,
                    props: route => ({query: route.query.q})
                },
                {
                    path: 'two-step-google-auth',
                    name: 'TwoStepGoogleAuth',
                    component: TwoStepGoogleAuth
                },
                {
                    path: 'fromgoogle',
                    name: 'FromGoogle',
                    component: FromGoogle
                },
                {
                    path: 'two-step-sms',
                    name: 'TwoStepSms',
                    component: TwoStepSms,
                    props: route => ({query: route.query.q})
                },
                {
                    path: 'verifying-page',
                    name: 'VerifyingPage',
                    component: VerifyingPage
                },
                {
                    path: 'profile',
                    name: 'Profile',
                    component: Profile
                },
                {
                    path: 'setting',
                    name: 'Setting',
                    component: Setting
                },
                {
                    path: 'invite',
                    name: 'Invite',
                    component: Invite
                },
                {
                    path: 'messages/:id',
                    name: 'Messages',
                    component: Messages,
                    props: true
                },
                {
                    path: 'messages',
                    name: 'Messages',
                    component: Messages
                },
                {
                    path: 'howtouse',
                    name: 'HowToUse',
                    component: HowToUse
                },
                {
                    path: 'faq',
                    name: 'FAQ',
                    component: FAQ
                },
                {
                    path: 'history/:tab',
                    name: 'History',
                    component: History,
                    props: true
                },
                {
                    path: 'history',
                    redirect: 'history/buy',
                    name: 'History',
                    component: History,
                    props: true
                },
                {
                    path: 'wallet',
                    name: 'Wallet',
                    component: Wallet,
                },
                {
                    path: 'orders',
                    name: 'Orders',
                    component: Orders,
                },
                {
                    path: 'wage',
                    name: 'Wage',
                    component: Wage
                },
                {
                    path: 'policy',
                    name: 'Policy',
                    component: Policy
                },
                {
                    path: 'about-us',
                    name: 'AboutUs',
                    component: AboutUs
                },
                {
                    path: 'trade/1/5',
                    redirect: '/trade/0/5',
                },
                {
                    path: 'trade/:tradetoid/:tradefromid',
                    name: 'Trade',
                    component: Trade,
                    props: true
                },
                {
                    path: 'trade',
                    redirect: '/trade/0/0',
                },
                {
                    path: 'dashboard/1/5',
                    redirect: '/dashboard/0/5',
                },
                {
                    path: 'dashboard',
                    redirect: '/dashboard/0/0',
                },
                {
                    path: 'dashboard/:tradetoid/:tradefromid',
                    name: 'Dashboard',
                    component: Dashboard,
                    props: true
                },
                {
                    path: 'home',
                    name: 'Home',
                    component: Home
                },

            ]
        },
        {
            path: '/blog',
            redirect: '/blog/home',
            component: {
                render(c) {
                    return c('router-view');
                }
            },
            children: [
                {
                    path: 'home',
                    name: 'BlogHome',
                    component: BlogHome
                },
                {
                    path: 'index/:id',
                    name: 'PostIndex',
                    component: PostIndex,
                    props: true
                },
                {
                    path: 'index',
                    name: 'PostIndex',
                    component: PostIndex
                },
                {
                    path: 'show/:id',
                    name: 'PostShow',
                    component: PostsShow,
                    props: true
                },
            ]
        },
        {
            path: '/redirect-from-bank',
            name: 'RedirectFromBank',
            component: RedirectFromBank,
            // props:true
        },

        {
            path: '/*',
            redirect: '/'
        }
    ]
})
const forLogin = [
    'History',
    'Dashboard',
    'Invite',
    'Wallet',
    'Setting',
    'Messages',
    'Profile',
    'Orders',
    'Trade'
]
const unAuthorUser = [
    'History',
    'Dashboard',
    'Wallet',
    'Orders',
]
const cointo = ["TOMAN", "TETHER"]
const otp = ['TwoStepEmail', 'TwoStepGoogleAuth', 'TwoStepSms', 'ResetPass', 'RecoveryPass']
router.beforeEach(async (to, from, next) => {

    if (localStorage.token) {
        that.state.token = localStorage.token
        if (!that.state.gotten)
            await that.$getData()
            await that.$getWage()
    }
    if (that.state.showError) {
        that.state.showError = false
        that.state.errorMessage = ''
    }
    if (forLogin.includes(to.name) && !that.state.token)
        next({name: 'Login'})
    else if (otp.includes(to.name) && !Vue.$cookies.isKey('username'))
        next({name: 'Login'})
    else if (otp.includes(to.name) && Vue.$cookies.isKey('username'))
        next()

    if (to.name === 'Trade' && that.state.token) {
        if (otp.includes(from.name)) {
            if (that.state.userinfo.authorized) {
                next()
            } else {
                next('/profile')
            }
        } else {
            that.state.loading = true
            await that.$axios(`/markets/${that.$coinArray[to.params.tradefromid]}_${cointo[to.params.tradetoid]}`)
                .then(() => {
                    next()
                })
                .catch(err => {
                    if (err.response.data.message === "MARKET_IS_NOT_ACTIVE") {
                        if (otp.includes(from.name)) {
                            next('/')
                        } else {
                            next(from)
                        }
                    }
                })
        }
    }

    if (unAuthorUser.includes(to.name) && that.state.token && !that.state.userinfo.authorized) {
        next(from)
        that.$error('', 'برای دسترسی به این بخش باید حساب کاربری تایید شده باشد.')
    }
    if ((to.name === 'Login' || to.name === 'Register') && that.state.token && that.state.userinfo.authorized)
        next({path: '/trade/0/0'})
    else if ((to.name === 'Login' || to.name === 'Register') && that.state.token && !that.state.userinfo.authorized)
        next({name: 'Profile'})
    else
        next()
    // if ((to.name === 'Trade' && from.name === 'Trade')) next({name:'Trade'})
})


export default router

