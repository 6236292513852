<template>
    <div class="main">
        <div class="row">
            <div class="col-lg-9 col-sm-12 row no-gutters">
                <div class="col-12 pad">
                    <!-- <button @click="getTradeHeader">click</button> -->
                    <trade-header
                            :realprice="maintable"
                            :tradefrom="$coinLabel[$coinArray[tradefromid]]"
                            :tradeto="tradeto[tradetoid]"
                            :class="{ dark: theme === 'dark' }"
                    />
                </div>
                <div class="col-12 pad">
                    <vue-trading-view
                            :key="tradetoid + ' ' + tradefromid"
                            style="height: 438px"
                            :options="{
              autosize: true,
              symbol: 'BINANCE:' + coin[tradefromid] + 'USDT',
              timezone: 'Asia/Tehran',
              theme: theme,
              library_path: '../assets/',
              style: '1',
              locale: 'fa_IR',
              toolbar_bg: '#f1f3f6',
              enable_publishing: true,
              withdateranges: true,
              range: 'ytd',
              hide_side_toolbar: false,
              allow_symbol_change: true,
              studies_overrides: {
                'volume.volume.color.0': '#C91B1B',
                'volume.volume.color.1': '#10d078',
                'volume.volume.transparency': 75,
              },
              overrides: {
                'paneProperties.background': '#131722',
                'paneProperties.vertGridProperties.color': 'rgba(83,87,96,0)',
                'paneProperties.horzGridProperties.color': 'rgba(54,60,78,0)',
                'mainSeriesProperties.areaStyle.color1': '#10d078',
                'mainSeriesProperties.areaStyle.color2': '#10d078',
                'mainSeriesProperties.areaStyle.linecolor': '#10d078',
                'mainSeriesProperties.lineStyle.color': '#10d078',
                'mainSeriesProperties.candleStyle.upColor': '#10d078',
                'mainSeriesProperties.candleStyle.downColor': '#C91B1B',
                'mainSeriesProperties.candleStyle.borderColor': '#10d078',
                'mainSeriesProperties.candleStyle.borderUpColor': '#10d078',
                'mainSeriesProperties.candleStyle.borderDownColor': '#C91B1B',
                'mainSeriesProperties.candleStyle.wickUpColor': '#10d078',
                'mainSeriesProperties.candleStyle.wickDownColor': '#C91B1B',
                'mainSeriesProperties.hollowCandleStyle.upColor': '#10d078',
                'mainSeriesProperties.hollowCandleStyle.downColor': '#C91B1B',
                'mainSeriesProperties.hollowCandleStyle.borderColor': '#10d078',
                'mainSeriesProperties.hollowCandleStyle.borderUpColor':
                  '#10d078',
                'mainSeriesProperties.hollowCandleStyle.borderDownColor':
                  '#C91B1B',
                'mainSeriesProperties.hollowCandleStyle.wickUpColor': '#10d078',
                'mainSeriesProperties.hollowCandleStyle.wickDownColor':
                  '#C91B1B',
                'mainSeriesProperties.haStyle.upColor': '#10d078',
                'mainSeriesProperties.haStyle.downColor': '#C91B1B',
                'mainSeriesProperties.haStyle.borderColor': '#10d078',
                'mainSeriesProperties.haStyle.borderUpColor': '#10d078',
                'mainSeriesProperties.haStyle.borderDownColor': '#C91B1B',
                'mainSeriesProperties.haStyle.wickUpColor': '#10d078',
                'mainSeriesProperties.haStyle.wickDownColor': '#C91B1B',
                'mainSeriesProperties.barStyle.upColor': '#10d078',
                'mainSeriesProperties.barStyle.downColor': '#C91B1B',
              },
              custom_css_url: 'ali.css',
              disabled_features: [
                'border_around_the_chart',
                'remove_library_container_border',
                'remove_container_border',
                'border_around_the_chart',
              ],
            }"
                    />
                </div>
                <div class="col-12 pad">
                    <shop
                            class="box"
                            :class="{ dark: theme === 'dark' }"
                            :tradetoid="tradetoid"
                            :tradefromid="tradefromid"
                            :tradeto="tradeto"
                            :tradefrom="tradefrom"
                            :coin="coin"
                            :key="tradetoid + '-' + tradefromid"
                    />
                </div>

                <!-- <button @click="getMarketDepth">click</button> -->

                <div class="col-12 pad row no-gutters">
                    <div class="col-sm-6 col-12 pl-sm-1 pl-0 pb-sm-0 pb-2">
                        <market-depth-sell
                                :tableContentsSell="depthTableSell"
                                :realprice="realpricetoman[0]"
                                :to="tradeto[tradetoid]"
                                :base="coin[tradefromid]"
                                :showloading="showLoadinginDepthSell"
                                class="box"
                        />
                    </div>
                    <div class="col-sm-6 col-12 pr-sm-1 pr-0">
                        <market-depth-buy
                                :tableContentsBuy="depthTableBuy"
                                :realprice="realpricetoman[0]"
                                :to="tradeto[tradetoid]"
                                :base="coin[tradefromid]"
                                :showloading="showLoadinginDepthBuy"
                                class="box"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-12 no-gutters">
                <div class="sidebar col-12 pad row no-gutters">
                    <real-price-table-toman
                            :table-contents="realpricetoman"
                            class="box"
                            :toid="tradetoid"
                    />
                    <!-- <button @click="getLastTrades">click</button> -->
                    <last-trade
                        class="box"
                        :key="tradetoid + '-' + tradefromid"
                        :class="{ dark: theme === 'dark' }"
                    />
                    
                </div>
            </div>
            <div class="col-12 row no-gutters">
                <div class="col-md-6 col-12 pad pl-md-1 pl-0">
                    <active-order
                            v-if="state.userinfo.authorized"
                            :tableContents="activeordertable"
                            class="box"
                            :key="tradetoid + '-' + tradefromid"
                            :class="{ dark: theme === 'dark' }"
                            :to="tradeto[tradetoid]"
                            :from="$coinLabel[$coinArray[tradefromid]]"
                            @removeorder="removeorder($event)"
                            :isEmpty="fromTrade"
                    />
                </div>

                <div class="col-md-6 col-12 pad pr-md-1 pr-0">
                    <last-order
                            v-if="state.userinfo.authorized"
                            
                            class="box"
                            :class="{ dark: theme === 'dark' }"
                            :to="tradeto[tradetoid]"
                            :from="$coinLabel[$coinArray[tradefromid]]"
                            
                            :key="tradetoid + '-' + tradefromid"
                    />
                </div>
            </div>
        </div>
        <completed-modal
                v-show="completedmodal"
                :text="'درخواست شما با موفقیت لغو شد.'"
                :theme="theme"
        />
        <ErrorNotification/>
    </div>
</template>

<script>
    import LastTrade from "./LastTrade";
    import LastOrder from "./LastOrder";
    import ActiveOrder from "./ActiveOrder";
    import TradeHeader from "./TradeHeader";
    import Shop from "./Shop/Shop";
    import VueTradingView from "vue-trading-view";
    import CompletedModal from "@/components/Tools/CompletedModal";
    import MarketDepthBuy from "@/components/Trade/MarketDepthBuy";
    import MarketDepthSell from "@/components/Trade/MarketDepthSell";
    import RealPriceTableToman from "@/components/Trade/RealPrice/RealPriceTableToman";
    import ErrorNotification from "../Tools/ErrorNotification";

    export default {
        name: "Trade",
        props: ["tradetoid", "tradefromid"],
        components: {
            RealPriceTableToman,
            MarketDepthSell,
            MarketDepthBuy,
            CompletedModal,
            Shop,
            TradeHeader,
            ActiveOrder,
            LastOrder,
            LastTrade,
            VueTradingView,
            ErrorNotification,
        },
        data() {
            return {
                fromTrade: null,
                today: '',
                showLoadinginDepthSell: false,
                showLoadinginDepthBuy: false,
                realpricetoman: [],
                realpriceteter: [],
                lasttradetable: [],
                lastordertable: [],
                activeordertable: [],
                depthTableSell: [],
                depthTableBuy: [],
                maintable: {
                    tradefrom: "بیتکوین",
                    tradeto: "ریال",
                    top: 0,
                    down: 0,
                    last: 0,
                    vol: 0,
                    change: 0,
                },
                tradefrom: [
                    "بیتکوین",
                    "اتریوم",
                    "بیتکوین کش",
                    "لایت کوین",
                    "ریپل",
                    "تتر",
                    "دش",
                ],
                tradeto: ["تومان", "تتر"],
                tradetoEng: ["TOMAN", "TETHER"],
                coin: ["BTC", "ETH", "BCH", "LTC", "XRP", "USDT", 'DASH', 'DOGE', 'ETC'],
                coinComplete: [
                    "BITCOIN",
                    "ETHEREUM",
                    "BITCOIN_CASH",
                    "LITE_COIN",
                    "RIPPLE",
                    "TETHER",
                    "DASH"
                ],
                completedmodal: "",
                theme: "light",
                getMarketDepthInterval: "",
                emptyLastOrder: null,
                toLastTrade: null
            };
        },
        watch: {
            tradetoid: function () {

                this.$axios(
                    `/orders?orderStatus=IS_OPEN&market=${
                        this.$coinArray[this.tradefromid]
                    }_${this.tradetoEng[this.tradetoid]}`
                );
            },
        },

        methods: {

            getTradeHeader() {
                this.$axios.get(`/trades/market-info?weeklyDataIncluded=false&market_type=${this.$coinArray[this.tradefromid]}_${this.tradetoEng[this.tradetoid]}`)
                    .then(res => {
                        // console.log(res.data.content[0]);
                        let response = res.data.content[0]
                        this.maintable = {
                            tradefrom: this.tradefrom,
                            tradeto: this.tradeto,
                            top: response.maxPrice,
                            down: response.minPrice,
                            last: response.lastPrice,
                            vol: response.last24HourVolume,
                            change: response.last24HourChange,
                        }
                    })
            },

            getMarketDepth() {
                // console.log('get market depth')
                this.$axios(`/orders/order-book?marketType=${this.$coinArray[this.tradefromid]}_${this.tradetoEng[this.tradetoid]}`)
                    .then((res) => {

                        this.depthTableBuy = res.data.buyOrderBook;
                        this.depthTableSell = res.data.sellOrderBook;
                    })
                    .catch(() => {
                        this.state.errorMessage = "خطایی رخ داده است. مجددا تلاش کنید";
                        this.state.showError = true;

                    });
            },
            removeorder(e) {
                this.$axios
                    .delete(`orders/${e}`)
                    .then(() => {
                        this.completed();
                    })
                    .catch(() => {
                        this.state.errorMessage = "خطایی رخ داده است. مجددا تلاش کنید";
                        this.state.showError = true;
                    });

            },
            completed() {
                this.completedmodal = true;
                setTimeout(() => {
                    this.completedmodal = false;
                }, 1500);
            },
        },
        created() {
            if (this.state.userinfo.authorized) {

                this.getMarketDepth();
                this.getTradeHeader()
                this.getMarketDepthInterval = setInterval(() => {
                    this.getMarketDepth();
                    this.getTradeHeader();
                }, 5000);
            } else {
                this.getMarketDepth();
                this.getTradeHeader()
                this.getMarketDepthInterval = setInterval(() => {
                    this.getMarketDepth();
                    this.getTradeHeader()
                }, 5000);
            }

        },

        beforeDestroy() {
            clearInterval(this.getMarketDepthInterval);
        },
    };
</script>

<style lang="scss" rel="stylesheet" src="../../assets/bootstrap-grid-rtl-2/bootstrap-grid-rtl.scss" scoped></style>
<style scoped>
    .main {
        width: 100%;
        max-width: 1440px;
        padding: 0 10px;
        margin-top: 10px;
    }

    .pad {
        padding-bottom: 10px;
    }

    .box {
        background-color: white;
        border-radius: 5px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    }

    .dark {
        background-color: var(--dark-main) !important;
        color: white !important;
    }

    .chart-page {
        background-color: transparent !important;
    }

    body #widget-container {
        border: none !important;
    }

    .sidebar {
        position: sticky;
        position: -webkit-sticky;
        top: 56px;
    }

    .sidebar .box:first-of-type {
        margin-bottom: 10px;
    }
</style>