import Vue from 'vue'
import App from './App.vue'
import '@babel/polyfill'
import '@/assets/style.scss'
import '@/assets/bootstrap-grid-rtl.css'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import '@/assets/vue2-perfect-scrollbar.css'
import TrendChart from "vue-trend-chart";
import VueCarousel from 'vue-carousel';
import '@/lib/georgianToJalali'
import VueFroala from 'vue-froala-wysiwyg'
import 'froala-editor/css/froala_editor.pkgd.min.css';
import LottiePlayer from 'lottie-player-vue';
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';

import router from '@/lib/router'
import '@/lib/globalConstants'
import '@/lib/state'
import '@/lib/axios'
import '@/lib/globalFunctions'

Vue.use(PerfectScrollbar)
Vue.use(VueCarousel);
Vue.use(TrendChart);
Vue.use(LottiePlayer);
Vue.use(VueSweetalert2);
Vue.use(VueFroala)


Vue.use(require('vue-cookies'))
Vue.$cookies.config('2min')
//routs

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router
}).$mount('#app')
