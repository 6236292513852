<template>
    <div class="main-box">
        <h1 style="text-align: right">تنظیمات امنیتی</h1>
        <div class="box2">
            <set-two-step-auth></set-two-step-auth>
            <change-password></change-password>
        </div>
    </div>
</template>

<script>
    import ChangePassword from "@/components/UserAccount/ChangePassword";
    import SetTwoStepAuth from "@/components/UserAccount/SetTwoStepAuth";


    export default {
        name: "Setting",
        components: {SetTwoStepAuth, ChangePassword},
        data() {
            return {
                profile: true,
            }
        },

    }
</script>

<style scoped>

    .main-box {
        display: flex;
        flex-direction: column;
        /*align-self: flex-start;*/
        /* position: absolute; */
        top: 0;
        background-color: transparent;
        text-align: right;
        margin-top: 56px;
        padding: 0 10px;
        box-sizing: border-box;
        width: 100%;
        max-width: 1300px;
    }

    h1 {
        font-size: 22px;
        margin-top: 30px;
        margin-bottom: 20px;
        margin-right: 10px;
    }


    .box2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        /*background-color: white;*/
        text-align: right;
        width: 100%;
    }
</style>