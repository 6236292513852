<template>
    <transition name="modal-fade">
        <div id="modal" class="modal-backdrop">
            <div class="modal"
                 role="dialog"
                 aria-labelledby="WithdrawModal"
                 aria-describedby="WithdrawModal">
                <img
                        src="../../assets/icon/close.svg"
                        class="btn-close"
                        @click="close"
                        aria-label="Close modal">
                <img>
                <header class="modal-header">
                    واریز {{wallet.name}}
                </header>
                <section class="modal-body" style="direction: rtl">
                    <div class="row justify-content-center" style="font-size: 16px">
                        <p>آدرس کیف پول شما در کادر زیر قابل مشاهده است برای واریز کردن ارزهای دیجیتال به این کیف
                            میتوانید از این آدرس استفاده کنید.
                        </p>
                        <p>دقت داشته باشید باتوجه به وضعیت شبکه ، واریزی شما با تاخیر تایید و ثبت می شود .</p>
                    </div>
                    <img style="margin-top: 20px;width:180px" :src="'data:image/png;base64,'+walletqrcode">
                    <div class="address">{{walletaddress}}</div>
                    <p style="color: var(--main-red);margin:0;margin-top:10px">دقت داشته باشید واریز هر نوع ارز دیگر به
                        این آدرس به جز بیت کوین موجب از دست رفتن دارایی شما می شود.</p>
                </section>
                <footer class="modal-btn">
                    <button
                            type="button"
                            class="normal-btn"
                            @click="Copy(walletaddress)">
                        <img src="../../assets/icon/copy.svg">
                        کپی آدرس
                    </button>
                    <button
                            type="button"
                            class="normal-btn"
                            @click="checkWallet">

                        بررسی موجودی کیف پول
                    </button>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "DepositModal",
        props: {
            wallet: {
                default: ''
            },
            walletaddress: {
                default: ''
            },
            walletqrcode: {
                default: ''
            }

        },
        watch: {
            // walletaddress:(val)=>{
            //     console.log(val);
            // }
        },
        data() {
            return {
                amount: '',
                twostepcode: '',
                // walletaddress: '0x40beE7b4e50d756C57B4e59693905D36B9cCD1B6',
            }
        },
        methods: {
            async checkWallet() {
                this.state.loading = true
                const res = await this.$axios.get(`/wallets/check-confirmed-transaction?relatedCoin=${this.wallet.relatedCoin}`)
                let text
                if (res.data.baseDTO.amount) {
                    text = `مقدار ${this.$toLocal(res.data.baseDTO.amount) || 0} ${this.$coinLabel[this.wallet.relatedCoin]} واریز شده و تایید ${res.data.baseDTO.transactionIsDone ? 'شده' : 'نشده'}`
                } else {
                    text = 'مقدار واریزی شناسایی نشد'
                }
                await this.$error('', text, res.data.baseDTO.transactionIsDone ? 'success' : 'info')
                if(res.data.baseDTO.amount){
                    this.$emit('update-wallet')
                }
            },
            close() {
                this.$emit('close');
            },
            Copy(e) {
                navigator.clipboard.writeText(e)
                alert('آدرس کپی شد')
            }
        },
    }
</script>

<style scoped>

    .modal-backdrop {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
    }

    .modal {
        position: relative;
        background: var(--account-back);
        border-radius: 5px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 950px;
        margin-top: 170px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        /*padding: 10px 10px;*/
    }

    .modal-header {
        color: white;
        font-size: 20px;
        height: 70px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10%;
    }

    .btn-close {
        position: absolute;
        left: 10%;
        top: 25px;
        height: 22px;
        width: 22px;
        transition: 0.5s;
        cursor: pointer;
        z-index: 5;
    }

    .btn-close:hover {
        transform: rotate(180deg);
    }

    .modal-body {
        /*margin-top: 20px;*/
        position: relative;
        font-size: 16px;
        background-color: white;
        /*border-top-right-radius: 5px;*/
        /*border-top-left-radius: 5px;*/
        padding: 20px 10% 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .modal-body div:last-of-type {
        margin: 10px 0 10px;
    }

    .modal-fade-enter,
    .modal-fade-leave-active {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .25s ease;
    }


    .modal-btn {
        /* height: 50px; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: white;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        flex-wrap: wrap;
    }

    .modal-btn button {
        margin: 5px 7px;
    }

    .normal-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: white;
        max-width: 200px;
        margin: 0;
        border-radius: 10px;
        height: 40px;
        padding: 0;
    }

    .normal-btn img {
        margin-left: 10px;
    }

    .address {
        color: var(--main-green);
    }

    @media (max-width: 400px) {
        .address {
            font-size: 12px;
        }

    }

    p {
        margin: 0;
    }

</style>
