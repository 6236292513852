<template>
    <div class="main-toast" v-if="state.showToast" >
        <div class="toast">
            <h4>{{state.toastMessage}}</h4>
            <!-- <span id="toast-line"></span> -->
        </div>
    </div>
</template>

<script>
export default {
    name:'ToastNotificatoin',
    props:{
        'timer':{
            default:5000
        }
    },
    data(){
        return{
            toastWidth:0,
            
        }
    },
    methods:{
        async showToast(){
            
            await this.$sleep(this.timer)
            this.state.showToast = false
            this.state.toastMessage = ''
            console.log('finish');
        }
    },
    changeTimer(val){
        this.timer = parseInt(val)
    },
    watch:{
         timer:(val)=>{
            console.log(val,'timerrr');
            this.changeTimer(val)
        },
        'state.showToast':function(){
            console.log('check the toast notif',this.state.showToast)
            if (this.state.showToast){
                this.showToast()
            }
            
        }
    }
}
</script>

<style scoped>
    .main-toast{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        right: 0;
        /* backdrop-filter: blur(3px); */
        margin-right: -10px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
    }

    .toast{
        width: 350px;
        height: 100px;
        background: #fff;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        box-shadow: 1px 1px 2px 2px #e0e3eb;
        overflow: hidden;
        color: var(--main-green);
        font-size: 15px;
        text-align: center;
    }

    #toast-line{
        display: inline-block;
        position: absolute;
        bottom: 0;
        right: 14px;
        background: rgb(152, 194, 90);
        width: 100px;
        height: 3px;
        border-radius: 50px;
       
    }
    

</style>