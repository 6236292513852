import { render, staticRenderFns } from "./BuyTable.vue?vue&type=template&id=5f963c68&scoped=true&"
import script from "./BuyTable.vue?vue&type=script&lang=js&"
export * from "./BuyTable.vue?vue&type=script&lang=js&"
import style0 from "./BuyTable.vue?vue&type=style&index=0&id=5f963c68&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f963c68",
  null
  
)

export default component.exports