<template>
    <div class="box1">
        سفارشات فعال من
        <perfect-scrollbar :options="settings">
            <table>
                <thead>
                <tr class="table-header">
                    <th>بازار</th>
                    <th>مقدار</th>
                    <th class="mobilehidden">قیمت واحد ({{to}})</th>
                    <th>قیمت کل ({{to}})</th>
                    <th>انجام شده</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <!-- <button @click="check">navid-btn</button> -->
                <p v-if="tableContents.length == 0" style="position:absolute;right:0;top:25px;color:red;width:100%;text-align:center">
                    داده ای جهت نمایش یافت نشد.</p>
                <tr
                    :style="{color:[tableContent.orderType.includes('BUY')? 'var(--main-green)':'var(--main-red)']}"
                    v-for="(tableContent,index) in tableContents" :key="index" class="table-row">
                    <td>{{from}} - {{to}}</td>
                    <td>{{tableContent.amount}}</td>
                    <td class="mobilehidden">{{tableContent.unitPrice.toLocaleString()}}</td>
                    <!-- <td>{{tableContent.unitPrice.toLocaleString()}}</td> -->
                    <td>{{tableContent.wholePrice.toLocaleString()}}</td>
                    <td>{{tableContent.executedPercent}}%</td>
                    <td>
                        <img @click.prevent="showModal(tableContent.id)"
                             src="../../assets/icon/trash1.svg"
                             class="trash"
                             :id="'trash'+tableContent.id">
                    </td>

                </tr>
                </tbody>

            </table>

        </perfect-scrollbar>

        <accept-modal v-show="modalshow" @close="closemodal" @remove="removeorder(removeid)"></accept-modal>
    </div>
</template>


<script>
    import AcceptModal from "@/components/Trade/acceptModal";

    export default {
        name: "ActiveOrder",
        components: {AcceptModal},
        props: ['base', 'to', 'from',],
        data() {
            return {
                modalshow: false,
                removeid: '',
                settings: {
                    suppressScrollX: true
                },
                tableContents: '',
                isEmpty: '',
                getInterval:'',
            }
        },
        created() {
        },
        methods: {
            getActiveOrders() {
                this.$axios(`/orders?orderStatus=IS_OPEN&market=${this.$farsiToCoin[this.from]}_${this.$farsiToCoin[this.to]}`)
                    .then((res) => {
                        // console.log(res);
                        if (res.data.content) {
                            this.tableContents = res.data.content;
                        } else {
                            this.tableContents = []
                        }
                    })
                    .catch(() => {
                        this.state.errorMessage = "خطایی رخ داده است. مجددا تلاش کنید";
                        this.state.showError = true;
                    });
            },
            closemodal() {
                this.modalshow = false
            },
            showModal(e) {
                this.modalshow = true
                this.removeid = e
            },
            removeorder(e) {
                this.modalshow = false
                this.$emit('removeorder', e);
            }
        },
        mounted() {
            this.getActiveOrders()
            this.getInterval = setInterval(() => {
                this.getActiveOrders()
            }, 5000);
        },
        beforeDestroy() {
            clearInterval(this.getInterval);
        }

    }
</script>

<style scoped>
    .ps {
        height: 250px;
        width: 100%;
    }

    .box1 {
        font-family: VistaDev, sans-serif;
        font-size: 14px;
        width: 100%;
        padding-top: 10px;
    }

    h3 {
        font-size: 18px;
        margin: 10px 0;
    }

    .dark a {
        color: white;
    }

    table {
        width: 100%;
        align-items: center;
        border-radius: 5px;
        border-collapse: collapse;
    }

    table th {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        align-items: center;
        height: 30px;
        text-align: center;
        background-color: var(--main-grey);
        z-index: 2;
        color: #494949;
    }

    .table-row {
        /*position: relative;*/
        transform: scale(1);
        -ms-transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
    }

    .table-header th:first-of-type,
    .table-row td:first-of-type {
        text-align: right;
        padding-right: 10px;
    }

    .table-header th:last-of-type,
    .table-row td:last-of-type {
        text-align: center;
        padding-left: 10px;
    }

    .table-row td {
        align-items: center;
        height: 35px;
        text-align: center;
        cursor: default;
        transition: 0.2s;
    }

    .table-row:hover {
        background-color: var(--hover);
    }

    .trash {
        cursor: pointer;
        width: 20px;
    }

    .mobilehidden {
        display: table-cell;
    }

    .mobileshow {
        display: none
    }

    @media (max-width: 580px) {
        .mobilehidden {
            display: none;
        }

        .mobileshow {
            display: table-cell;
        }
    }

</style>