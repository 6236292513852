import Vue from 'vue';

const that = Vue.prototype

that.$coinLabel = {
    TOMAN: 'تومان',
    BITCOIN: 'بیتکوین',
    BITCOIN_CASH: 'بیتکوین کش',
    ETHEREUM: 'اتریوم',
    CLASSIC_ETHEREUM: 'اتریوم کلاسیک',
    LITE_COIN: 'لایت کوین',
    BINANCE_COIN: 'بایننس کوین',
    EOS: 'ایاس',
    STELLAR: 'استلار',
    RIPPLE: 'ریپل',
    TRON: 'ترون',
    DASH: 'دش',
    DOGE_COIN: 'دوج',
    TETHER: 'تتر',
    BTC: 'بیتکوین',
    BCH: 'بیتکوین کش',
    ETH: 'اتریوم',
    ETC: 'اتریوم کلاسیک',
    LTC: 'لایت کوین',
    BNB: 'بایننس کوین',
    // EOS: 'ایاس',
    XLM: 'استلار',
    XRP: 'ریپل',
    TRX: 'ترون',
    // DASH: 'دش',
    DOGE: 'دوج',
    USDT: 'تتر',
    bitcoin: 'بیتکوین',
    'bitcoin-cash': 'بیتکوین کش',
    'ethereum-classic':'اتریوم کلاسیک',
    ethereum: 'اتریوم',
    etc: 'اتریوم کلاسیک',
    litecoin: 'لایت کوین',
    binancecoin: 'بایننس کوین',
    eos: 'ایاس',
    stellar: 'استلار',
    ripple: 'ریپل',
    tron: 'ترون',
    dash: 'دش',
    dogecoin: 'دوج',
    tether: 'تتر',
}

that.$coinUnit = {
    TOMAN: 'تومان',
    BITCOIN: 'BTC',
    BITCOIN_CASH: 'BCH',
    ETHEREUM: 'ETH',
    CLASSIC_ETHEREUM: 'ETC',
    LITE_COIN: 'LTC',
    BINANCE_COIN: 'BNB',
    EOS: 'EOS',
    STELLAR: 'XLM',
    RIPPLE: 'XRP',
    TRON: 'TRX',
    DASH: 'DASH',
    DOGE_COIN: 'DOGE',
    TETHER: 'USDT',
}

that.$farsiToCoin = {
    'تومان': 'TOMAN',
    'بیتکوین': 'BITCOIN',
    'اتریوم': 'ETHEREUM',
    'ریپل': 'RIPPLE',
    'بیتکوین کش': 'BITCOIN_CASH',
    'ترون': 'TRON',
    'تتر': 'TETHER',
    'بایننس کوین': 'BINANCE_COIN',
    'لایت کوین': 'LITE_COIN',
    'دش': 'DASH',
    'دوج': 'DOGE_COIN',
    'اتریوم کلاسیک': 'CLASSIC_ETHEREUM'
}

that.$toLabel = ['TOMAN', 'TETHER']

that.$coinArray = {
    0: 'BITCOIN',
    1: "ETHEREUM",
    2: "BITCOIN_CASH",
    3: "LITE_COIN",
    4: "RIPPLE",
    5: "TETHER",
    6: "DASH",
    7: "DOGE_COIN",
    8: "CLASSIC_ETHEREUM"
}

that.$sortCoins = ['bitcoin','ethereum','bitcoin-cash','litecoin','ripple','tether','dash','dogecoin','calssic-ethereum']

that.$coinArray2 = {
    'BITCOIN': 0,
    "ETHEREUM": 1,
    "BITCOIN_CASH": 2,
    "LITE_COIN": 3,
    "RIPPLE": 4,
    "TETHER": 5,
    "DASH": 6,
    "DOGE_COIN": 7,
    'CLASSIC_ETHEREUM': 8
}

that.$accountLevel = {
    SUPERVISOR:[0,'بازارگردان'],
    BRONZE:[.35,'عقیق'],
    SILVER:[.3,'فیروزه'],
    GOLD:[.25,'یاقوت'],
    URANIUM:[.185,'الماس'],
}

that.$errorHandeling = {
    BAD_REQUEST: 'اطلاعات ارسال شده اشتباه است',
    NOT_FOUND: 'اطلاعات درخواستی یافت نشد',
    FINNOTECH_NOT_COMPLETED: 'کاربر فرآیند فینوتک را تکمیل نکرده است',
    CONFLICT: 'اجازه ندارید',
    FORBIDDEN: 'دسترسی لازم را ندارید'
}