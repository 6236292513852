<template>
    <div class="main-box">
        <div class="box">
            تاریخچه کیف‌ پول ها
            <div class="tab">
                <div :class="[!deposit ? 'tab-content' : 'tab-content-active']"
                     @click.prevent="[deposit=true,withdraw=false]">
                    <a>واریز ها</a>
                </div>
                <div :class="[!withdraw ? 'tab-content' : 'tab-content-active']"
                     @click.prevent="[deposit=false,withdraw=true]">
                    <a>برداشت ها</a>
                </div>
            </div>
            <div class="box1">
                <deposit-table v-show="deposit"></deposit-table>
                <withdraw-table v-show="withdraw"></withdraw-table>
            </div>
        </div>
    </div>
</template>

<script>

    import DepositTable from "@/components/Wallet/DepositTable";
    import WithdrawTable from "@/components/Wallet/WithdrawTable";

    export default {
        name: "WalletHistory",
        components: {WithdrawTable, DepositTable},
        data() {
            return {
                deposit: false,
                withdraw: true,
            }
        },
    }
</script>

<style scoped>

    .main-box {
        display: inline-block;
        background-color: transparent;
        text-align: right;
        margin-top: 30px;
        margin-bottom: 20px;
        box-sizing: border-box;
        width: 100%;
        max-width: 1380px;
        padding: 0 10px;
    }

    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        text-align: right;
        border: #00000015 solid 0.5px;
        padding: 10px 15px;
        box-sizing: border-box;
        border-radius: 0;
        width: 100%;
        font-size: 14px;
        font-family: IRANSans-Bold;
        box-shadow:1px 1px 4px rgba(0 0 0 .25)

    }

    .tab {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: transparent;
        text-align: right;
        width: 100%;
        max-width: 360px;
        cursor: pointer;
        margin-top: 10px;

    }

    .tab-content {
        height: 30px;
        font-family: IRANSans;
        width: 50%;
        border-bottom: #00000010 solid;
        text-align: center;
    }

    .tab-content-active {
        height: 30px;
        font-family: IRANSans-Bold;
        width: 50%;
        border-bottom: #F8B200 solid;
        text-align: center;
    }

    .box1 {
        flex-direction: column;
        margin-top: 10px;
        border: #00000015 solid 0.5px;
        border-radius: 5px;
        background-color: white;
        width: 100%;
    }
</style>