<template>
    <div class="main-box">
        <h1>سطوح کاربری و محاسبه میزان کارمزد </h1>
        <div class="block">
            <div class="level">
                <p>کارمزد معاملات</p>
                <h2>با تتر</h2>
                <p>1.3 درصد</p>
            </div>
        </div>
        <div class="block" :class="[state.userinfo.accountLevel === 'BRONZE' ? 'selected' : '']">
            <img src="../../assets/Dashboard/aghigh.svg" alt="">
            <div class="level">
                <h2>سطح عقیق</h2>
                <p>کمتر از <span>{{String($accountLevel[0].tradeAmount).slice(0,-6)}}</span> میلیون</p>
                <p>کارمزد <span>{{String($accountLevel[0].wagePercent)}}</span> درصد</p>
            </div>
        </div>
        <div class="block" :class="[state.userinfo.accountLevel === 'SILVER' ? 'selected' : '']">
            <img src="../../assets/Dashboard/firooze 1.svg" alt="">
            <div class="level">
                <h2>سطح فیروزه</h2>
                <p><span>{{String($accountLevel[0].tradeAmount).slice(0,-6)}}</span> تا <span>{{String($accountLevel[1].tradeAmount).slice(0,-6)}}</span> میلیون</p>
                <p>کارمزد <span>{{String($accountLevel[1].wagePercent)}}</span> درصد</p>
            </div>
        </div>
        <div class="block" :class="[state.userinfo.accountLevel === 'GOLD' ? 'selected' : '']">
            <img src="../../assets/Dashboard/ruby2.png" alt="">
            <div class="level">
                <h2>سطح یاقوت</h2>
                <p><span>{{String($accountLevel[1].tradeAmount).slice(0,-6)}}</span> تا <span>{{String($accountLevel[2].tradeAmount).slice(0,-6)}}</span> میلیون</p>
                <p>کارمزد <span>{{String($accountLevel[2].wagePercent)}}</span> درصد</p>
            </div>
        </div>
        <div class="block" :class="[state.userinfo.accountLevel === 'URANIUM' ? 'selected' : '']">
            <img src="../../assets/Dashboard/diamond.png" alt="">
            <div class="level">
                <h2>سطح الماس</h2>
                <p>بیشتر از <span>{{String($accountLevel[2].tradeAmount).slice(0,-6)}}</span> میلیون</p>
                <p>کارمزد <span>{{String($accountLevel[3].wagePercent)}}</span> درصد</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AccountLevel",
        data(){
            return{}
        },
    }
</script>

<style scoped>
    h2{
        font-size: 14px;
        margin: 0;
    }
    p{
        margin: 0;
    }
    h1{
        position: absolute;
        font-size: 18px;
        top:10px;
        margin: 0;
        color: var(--font-color);
    }
    .main-box{
        width: 100%;
        height: 235px;
        display: flex;
        overflow: auto;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        padding: 50px 10px 0;
        margin-bottom: 10px;
        position: relative;
        /*background-color: var(--dark-background) !important;*/
    }
    .block{
        width: 100%;
        max-width: 150px;
        min-width: 130px;
        border-radius: 5px;
        border: 1px solid var(--light-border);
        padding: 10px 5px;
        margin: 0 2px ;
        cursor: pointer;
        transition: 0.2s;
        background-color: white;
        height: 163px;
    }
    .block:first-of-type{
        background-color: var(--main-green);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .block:first-of-type p{
        margin: 10px;
    }
    .selected{
        border-color: var(--main-green);
        color: var(--main-green);
    }
    .block:hover{
        background-color: var(--dark-background);
        color: white;
    }
    .block img{
        height: 70px;
    }



</style>