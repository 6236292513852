import Vue from 'vue'
// axios
import axios from 'axios'


const that = Vue.prototype

that.state.token = localStorage.token 
if (that.state.token) {
    console.log('I have token : ', that.state.token)
    axios.defaults.headers.common['Authorization'] = 'Bearer '+that.state.token
}
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*"
// axios.defaults.headers.common['access-control-allow-origin'] = "*"
// axios.defaults.headers.common['Access-Control-Allow-Headers'] = "*"
axios.defaults.baseURL = 'https://vistadev.xyz/api'
// axios.defaults.baseURL = 'https://whalebitex.org/api'
// axios.defaults.baseURL = 'https://aviinex.xyz/api'
// axios.defaults.baseURL = 'http://mobinex.xyz/api'
// axios.defaults.baseURL = 'http://192.168.0.102:8098/api'

// axios.interceptors.request.use(
//     (res)=>{
//         console.log(res)
//         that.state.loading = true
//     }
// )

axios.interceptors.response.use(
    (res) => {
        that.state.loading = false

        // console.log('result : ', res)

        return res;
    },
    (err) => {
        that.state.loading = false

        const res = err.response || err.toJSON()
        // console.error('result : ', res);
/*        console.warn(
            //     'config : ', err.config,
            //     'code : ', err.code,
            'request : ', err.request,
            //     'response : ', err.response,
            //     'isAxios : ', err.isAxiosError,
            //     'json : ', err.toJSON()
        )*/
        console.log(err.response.data.message);
        if (err.response) if (res.data.message) if (res.data.message.includes('JWT') || res.data.message.includes('Invalid Token')) {
            that.$logout()
            return Promise.reject(err);
        } else if (err.response.data.message === 'username exist.') {
            that.$error('اکانت تکراری', 'شما قبلا ثبت نام کرده اید.')
            return Promise.reject(err);
        } else if (err.response.data.message.includes('Code Expired')) {
            that.$error('کد شما منقضی شده است.', '')
            return Promise.reject(err);
        }else if (err.response.data.message.includes('Not Enough HDWallets')) {
            that.$error('خطایی رخ داده است با پشتیبانی تماس بگیرید', '')
            return Promise.reject(err);
        }else if (err.response.data.message ==='SMS_OTP_UNAVAILABLE') {
            that.$error('قابلیت پیامک فعال نمیباشد.', '')
            return Promise.reject(err);
        }else if (err.response.data.message ==='MARKET_IS_NOT_ACTIVE') {
            that.$error('این بازار فعال نمیباشد.', '')
            return Promise.reject(err);
        } else if (err.response.data.message.includes('ShebaCode')) {
            that.$error('شماره شبا یا کارت تکراری است.', '')
            return Promise.reject(err);
        } else if (err.response.data.message.includes('Incorrect OTP')) {
            that.$error('کد وارد شده اشتباه است.', '')
            return Promise.reject(err);
        } else if (err.response.data.message.includes('GOOGLE_FAILURE')) {
            return Promise.reject(err);
        } else if (err.response.data.message.includes('NOT_ENOUGH_BALANCE_TOMAN')) {
            that.$error('موجودی شما کافی نمیباشد.', '')
            return Promise.reject(err);
        } else if (err.response.data.status === 'UNAUTHORIZED') {
            that.$error('نام کاربری یا رمز عبور اشتباه است','');
            return Promise.reject(err);
        }else if (err.response.data.status === 'FORBIDDEN') {
                that.$error('دسترسی ندارید','');
                return Promise.reject(err);
        } else if (err.response.data.status === 'NOT_FOUND') {
            if(res.data.message.includes('Invalid Phone Number')){
                that.$error('شماره همراه اشتباه است.','');
            }else if(res.data.message === 'Old Password Is Incorrect'){
                that.$error('رمز قبلی اشتباه است.','');
            }
            else if(res.data.message === 'user not found'){
                that.$error('نام کاربری یافت نشد.','');
            }else{
                that.$error('خطایی رخ داده است.','');
            }
            return Promise.reject(err);
        }else if(err.response.data.message === "USER_IDENTITY_DATA"){
            // console.log('in USER_IDENTITY_DATA');
            const a = {
                MOBILE_IS_INCORRECT : 'شماره همراه تکراری میباشد.',
                NATIONAL_CODE_IS_INCORRECT : 'کد ملی تکراری میباشد.',
                TELEPHONE_IS_INCORRECT : 'شماره تلفن تکراری میباشد.',
            }
            let uniqueErrors = '';
            for (let i=0;i<err.response.data.errors.length;i++){
                uniqueErrors = uniqueErrors + (a[err.response.data.errors[i]] || err.response.data.errors[i]) + '\n'
            }
            that.$error('اطلاعات تکراری میباشد',uniqueErrors);
            return Promise.reject(err);
        }else if(err.response.data.status === 'BAD_REQUEST'){
            let a = err.response.data.errors[0]
            // a = JSON.parse(a.match(/\[(.*?)\]/)[1])
            let finoError
            if (a === 'invalid otp or mobile'){
                finoError = 'کد وارد شده اشتباه است.'
            }else if (a === 'otp used before'){
                finoError = 'کد منقضی شده است.'
            }else if (a === 'nid is not valid'){
                finoError = 'کد ملی صحیح نیست'
            }else if (a === 'mobile is not valid'){
                finoError = ' شماره همراه صحیح نیست'
            }else if (a === "nid and mobile is not match"){
                finoError = 'شماره همراه شما با کد ملی مطابقت ندارد.'
            }
            if(finoError){
                that.$error('خطای احراز هویت',finoError);
                return Promise.reject(err);
            }
        }

        // if (err.response ? ['UNAUTHORIZED'].includes(res.data.status) : false) {
        //     return Promise.reject(err);
        // }

        const text = err.response ?
            that.$errorHandeling[err.response.data.status]
            || that.$errorHandeling[err.response.data.message]
            || res.data.errors
            || res.data.error
            : res.message

        that.$swal({
            icon: 'error',
            title: err.request.status,
            text: text,
            confirmButtonText: 'متوجه شدم',
            customClass: {
                confirmButton: 'btn-success',
            },
        })

        return Promise.reject(err);
    },);


that.$axios = axios

export default axios
