<template>
    <div class="Auth-box">
        <div class="Auth-header">
            <h3>ورود به حساب کاربری</h3>
        </div>
        <form class="Auth" @submit.prevent="gototrade">
            <ali-input @send="receive($event)" :type="'email'" :name="'email'" :errortext="emailError"
                       :faname="'ایمیل'"/>
            <ali-input @send="receive1($event)" :type="'password'" :name="'password'" :errortext="passError"
                       :faname="'رمز عبور'"/>
            <a style="margin-bottom: 50px;align-self: flex-end" class="link" @click="resetPass"> فراموشی رمز عبور</a>
            <button hidden type="submit"/>
        </form>
        <p style="color:red" v-if="errorMessage">{{errorMessage}}</p>
        <button @click="gototrade" class="normal-btn" :disabled="!email || !password || emailError.length>0">ورود
        </button>
        <a style="margin: 10px 0">یا</a>
        <a href="https://whalebitex.org/api/users/google-sso" class="google-btn">
            <img class="google-icon" src="@/assets/Google_G_Logo.svg" alt="">
            ورود با گوگل
        </a>
        <div style="text-align: center;margin-bottom: 30px ;font-size: 14px">
            کاربر جدید هستید؟ <a class="link" style="color: #354FD2" @click="()=>this.$router.push('/register')">ثبت نام
            کنید</a>
        </div>
        <Loading v-if="showLoading"/>
    </div>
</template>

<script>
    import AliInput from "@/components/Tools/AliInput";
    import Loading from '../Tools/Loading'

    export default {
        name: "Login",
        components: {AliInput, Loading},
        data: () =>
            ({
                show: false,
                email: '',
                emailError: '',
                passError: '',
                password: '',
                res: '',
                errorMessage: '',
                OSName: 'Unknown',
                borwserType: '',
                deviceType: '',
                showLoading: false
            }),
        methods: {
            resetPass() {
                this.$cookies.set('username', this.email)
                this.$router.push('/recovery-pass')
            },
            check() {
                //    this.$router.push("http://localhost:8098/api/users/google-sso");
                //    let a = setInterval(() => {
                //         this.$axios.get('/users/get-google-token')
                //             .then(response=>{
                //                 console.log(response)
                //                 if (response.data.message !== 'Token Empty'){
                //                     console.log('thats it')
                //                     clearInterval(a)
                //                     localStorage.token = response.data.baseDTO.token
                //                     this.state.token =  localStorage.token
                //                     this.$router.push('trade')
                //                 }
                //             })
                //    }, 3000);

            },
            checkEmail() {

                if (!this.email) {
                    this.emailError = 'ایمیل اجباری';
                } else if (!this.validEmail(this.email)) {
                    this.emailError = 'ایمیل صحیح وارد نمایید';
                } else {
                    this.emailError = ''
                }
                if (!this.emailError.length) {
                    return true;
                }
            },
            validEmail(email) {
                var re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                return re.test(email);
            },
            receive(e) {
                this.email = e
                this.checkEmail(e)
            },
            receive1(e) {
                this.password = e
            },
            gototrade() {

                if (this.checkEmail()) {

                    const article = {
                        email: this.email,
                        password: this.password,
                        osType: this.OSName,
                        deviceType: this.deviceType,
                        browser: this.borwserType
                    };

                    this.showLoading = true
                    this.$axios.post('/users/login', article)
                        .then(response => {
                            this.res = response.data;

                            if (this.res.message === 'login successful, otp is required') {

                                if (this.res.baseDTO.towStepAuthenticationType === 'EMAIL') {
                                    this.$cookies.set('username', article.email)
                                    this.state.email = this.email
                                    this.$router.push('two-step-email')
                                } else if (this.res.baseDTO.towStepAuthenticationType === 'SMS') {

                                    this.$cookies.set('username', article.email)
                                    this.state.email = this.email
                                    this.state.mobilenumber = this.res.baseDTO.starTwoStepAuthentication;
                                    this.$cookies.set('mobilenumber', this.state.mobilenumber)

                                    this.$router.push('two-step-sms')
                                } else if (this.res.baseDTO.towStepAuthenticationType === 'GOOGLE_AUTHENTICATOR') {
                                    this.state.email = this.email
                                    this.$cookies.set('username', article.email)
                                    this.$router.push('two-step-google-auth')
                                }

                            }
                        })
                        .catch(error => {

                            if (error.response) {

                                if (error.response.data.status === 'UNAUTHORIZED') {
                                    this.errorMessage = 'نام کاربری یا رمز عبور اشتباه است';
                                } else if (error.response.data.status === 'NOT_FOUND') {
                                    this.errorMessage = 'نام کاربری یافت نشد.'
                                }
                            }

                        })
                        .then(() => {
                            this.showLoading = false
                        })

                }
            },
            detectOs() {
                var Name = "Unknown OS";
                if (navigator.userAgent.indexOf("Win") != -1) Name =
                    "WINDOWS";
                if (navigator.userAgent.indexOf("Mac") != -1) Name =
                    "MACINTOSH";
                if (navigator.userAgent.indexOf("Linux") != -1) Name =
                    "LINUX";
                if (navigator.userAgent.indexOf("Android") != -1) Name =
                    "ANDROID";
                if (navigator.userAgent.indexOf("like Mac") != -1) Name = "IOS";
                this.OSName = Name
                // console.log('Your OS: '+this.OSName);
            },
            detectBrowser() {
                // Opera 8.0+
                var isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
                if (isOpera) {
                    this.borwserType = 'OPERA'
                }

                // Firefox 1.0+
                var isFirefox = typeof InstallTrigger !== 'undefined';
                if (isFirefox) {
                    this.borwserType = 'FIREFOX'
                }

                // Safari 3.0+ "[object HTMLElementConstructor]" 
                var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
                    return p.toString() === "[object SafariRemoteNotification]";
                })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
                if (isSafari) {
                    this.borwserType = 'SAFARI'
                }
                // Internet Explorer 6-11
                var isIE = /*@cc_on!@*/false || !!document.documentMode;
                if (isIE) {
                    this.borwserType = 'IE'
                }
                // Edge 20+
                var isEdge = !isIE && !!window.StyleMedia;
                if (isEdge) {
                    this.borwserType = 'EDGE'
                }
                // Chrome 1 - 79
                var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
                if (isChrome) {
                    this.borwserType = 'CHROME'
                }
                // Edge (based on chromium) detection
                var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
                if (isEdgeChromium) {
                    this.borwserType = 'EDGECHROMIUM'
                }
                if (this.borwserType === '') {
                    this.borwserType = 'CHROME'
                }
                // console.log(this.borwserType)
            },
            detectDevice() {
                const ua = navigator.userAgent;
                if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
                    this.deviceType = 'TABLET'
                }
                if (
                    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                        ua
                    )
                ) {
                    this.deviceType = 'MOBILE'
                } else {
                    this.deviceType = 'DESKTOP'
                }
                // console.log(this.deviceType)
            }
        },
        mounted() {
            this.detectOs();
            this.detectBrowser();
            this.detectDevice()
        }

    }
</script>

<style lang="scss" scoped>

    .Auth-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 400px;
        height: 100%;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }


    .link {
        margin-right: 10px;
        /*text-decoration: underline;*/
        font-size: 14px !important;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

</style>