<template>
    <div class="box-sub">
        <h2>اطلاعات شخصی</h2>
        <form class="row no-gutters">
            <div class="row no-gutters col-6 col-sm-4 p-1 p-sm-3">
                <!--                <label class="col-4 pl-1" for="fname">نام</label>-->
                <input @input="state.userinfo.firstName = state.userinfo.firstName.replace(/[\w!#~^><+=?*&@.$%\\\-,()\[\]:;`/}{']|  /g,'')"
                       :style="{background:idApproved?'rgb(171 171 171 / 71%)':'transparent'}"
                       class="col-12" type="text" v-model="state.userinfo.firstName" name="fname" id="fname"
                       placeholder="نام" :disabled='idApproved'>
            </div>
            <div class="row no-gutters col-6 col-sm-4 p-1 p-sm-3">
                <!--                <label class="col-4 pl-1" for="lname">نام خانوادگی</label>-->
                <input @input="state.userinfo.lastName = state.userinfo.lastName.replace(/[\w!#~^><+=?*&@.$%\\\-,()\[\]:;`/}{']|  /g,'')"
                       :style="{background:idApproved?'rgb(171 171 171 / 71%)':'transparent'}"
                       class="col-12" type="text" v-model="state.userinfo.lastName" name="lname" id="lname"
                       placeholder="نام خانوادگی" :disabled='idApproved'>
            </div>
            <div class="row no-gutters col-6 col-sm-4 p-1 p-sm-3">
                <!--                <label class="col-4 pl-1" for="email">کد ملی</label>-->
                <input @input="state.userinfo.nationalCode = state.userinfo.nationalCode.replace(/\D/g,'')"
                       maxlength="12"
                       :style="{textAlign: [idnumber? 'left':'right'],background:idApproved?'rgb(171 171 171 / 71%)':'transparent'}"
                       class="col-12" type="text" v-model="state.userinfo.nationalCode" name="idnumber" id="idnumber"
                       placeholder="کد ملی" :disabled='idApproved'>
            </div>
            <!-- <div class="row no-gutters col-6 col-sm-4 p-1 p-sm-3"> -->
            <!--                <label class="col-4 pl-1" for="email">ایمیل</label>-->
            <!-- <input :style="{textAlign: [email? 'left':'right'],background:state.userinfo.email!== ''?'#e3e3e3':'transparent'}" class="col-12" type="email" v-model="state.userinfo.email" name="email" id="email" placeholder="ایمیل"
            :disabled="state.userinfo.email!== '' ">
        </div> -->
            <div class="row no-gutters col-6 col-sm-4 p-1 p-sm-3">
                <!--                <label class="col-4 pl-1" for="birthday">تاریخ تولد</label>-->
                <date-picker v-model="state.userinfo.birthDay" id="birthday" format="YYYY-MM-DDT00:00"
                             display-format="jYYYY/jMM/jDD" :disabled='idApproved' color="var(--navbar-back)"
                             :max="$jmoment().format($dateFormat['en'])"
                             :style="{borderRadius:'5px',background:idApproved?'rgb(171 171 171 / 71%)':'transparent'}"
                             class="col-12 date-pic p-0" :placeholder="'تاریخ تولد'"/>
            </div>
            <div class="row no-gutters col-6 col-sm-4 p-1 p-sm-3">
                <!--                <label class="col-4 pl-1" for="mobile">شماره موبایل</label>-->
                <input @input="state.userinfo.mobileNumber = state.userinfo.mobileNumber.replace(/\D/g,'')"
                       maxlength="13"
                       :style="{textAlign: [mobile? 'left':'right']}" class="col-12" type="text"
                       v-model="state.userinfo.mobileNumber" name="mobile" id="mobile" minlength="11"
                       placeholder="شماره موبایل">
            </div>
            <div class="row no-gutters col-6 col-sm-4 p-1 p-sm-3">
                <!--                <label class="col-4 pl-1" for="phone">شماره ثابت</label>-->
                <input @input="state.userinfo.telephone = state.userinfo.telephone.replace(/\D/g,'')" maxlength="13"
                       :style="{textAlign: [phone? 'left':'right']}" class="col-12" type="text"
                       v-model="state.userinfo.telephone" name="phone" id="phone" minlength="11"
                       placeholder="شماره ثابت">
            </div>

            <div class="row no-gutters col-12 p-1 p-sm-3">
                <!--                <label for="address">آدرس</label>-->
                <textarea v-model="state.userinfo.address" name="address" id="address" placeholder="آدرس"
                          :style="{resize:'none'}"></textarea>
            </div>
        </form>
        <!-- <button @click="check">click</button> -->
    </div>
</template>

<script>
    import 'vue-datepicker-persian/src/index.scss'
    import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
    import '@/assets/birthdayPicker.scss'
    // const persian ='\u0621-\u0628\u062A-\u063A\u0641-\u0642\u0644-\u0648\u064E-\u0651\u0655\u067E\u0686\u0698\u06A9\u06AF\u06BE\u06CC'; 
    export default {
        name: "PersonalInformation",
        components: {datePicker: VuePersianDatetimePicker},
        props: ['birth', 'idApproved'],
        watch: {
            birth: function (val) {
                this.birthday = val
            }
        },
        methods: {},
        data() {
            return {

                path: 'aaaa',
                inputDisabled: false,
                fname: '',
                lname: '',
                email: '',
                birthday: '',
                mobile: '',
                phone: '',
                address: '',
                idnumber: ''
            }
        },
        mounted() {
            this.birthday = this.birth

            // setTimeout(() => {
            //     this.birthday = this.birth
            //     console.log('mounting',this.birth,this.birthday)
            // }, 1000);

        }
    }
</script>

<style scoped>

    .box-sub {
        font-family: IRANSans-Bold, sans-serif;
        display: block;
        /*background-color: white;*/
        text-align: right;
        /*border: #00000015 solid 0.5px;*/
        padding: 10px 15px;
        margin: 20px 0;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        font-size: 17px;
        background: #FFFFFF;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    }

    h2 {
        font-size: 17px;
        padding: 10px 0;
        margin: 0 1rem;
        border-bottom: 1px solid var(--light-border);
    }

    form {
        font-family: VistaDev, sans-serif;
        text-align: right;
        margin: 20px 0;
        border-radius: 5px;
        width: 100%;
        font-size: 14px;
    }

    /*form div:last-of-type label{*/
    /*    width: 10.5%*/
    /*}*/

    /*.box-sub label {*/
    /*    display: flex;*/
    /*    align-items: center;*/
    /*    min-width: 84px;*/
    /*}*/

    .box-sub input {
        border: solid rgba(54, 52, 53, 0.15) 0.5px;
        height: 50px;
        border-radius: 5px;
        padding: 2px 5px;
        width: 100%;
        /*max-width: 66.666667%;*/
        text-align: left;
    }

    #fname, #lname {
        text-align: right;
    }

    /*    .vpd-input-group{
            height: 100%;
        }
        .vpd-input-group>input{
            height: 100%;
            width: 100%;
            border-radius: 5px 0 0 5px;
        }

        .vpd-input-group label{
            border-radius: 0 5px 5px 0;
            background-color: rgb(108 113 117) !important;
        }

        .vpd-header{
            background-color: rgb(108 113 117) !important;
        }

        .vpd-day-effect{
            background-color: #ccc !important;
        }

        .vpd-actions button{
            color: #494949 !important;
        }*/

    .box-sub input:hover {
        border: solid rgba(54, 52, 53, 0.3) 0.5px;
    }

    .box-sub input:focus {
        border: solid rgba(54, 52, 53, 0.5) 0.5px;
    }

    textarea {
        position: relative;
        border: solid rgba(54, 52, 53, 0.15) 0.5px;
        display: inline-block;
        /*width: calc(100% - 10.5%);*/
        width: 100% !important;
        height: 100px;
        border-radius: 5px;
        padding: 2px 5px;
    }

    /*@media (max-width: 911px) {*/
    /*    textarea {*/
    /*        width: 100%;*/
    /*    }*/
    /*}*/

    .box-sub textarea:hover {
        border: solid rgba(54, 52, 53, 0.3) 0.5px;
    }

    .box-sub textarea:focus {
        border: solid rgba(54, 52, 53, 0.5) 0.5px;
    }

</style>