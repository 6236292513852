<template>
    <div class="Auth-box">
        <div class="Auth-header">
            <h3>شناسایی دو عاملی</h3>
        </div>
        <div class="Auth">
            <p>کد ۶ رقمی که به شماره <span
                    style="display:inline-block;direction:ltr">{{$cookies.get('mobilenumber')}}</span> ارسال شده است را
                وارد نمایید.</p>
            <!--        <CodeInput type="text" style="direction: ltr" :loading="false" class="input" @change="onChange"-->
            <!--                   @complete="onComplete"/>-->
           
                <!-- <input type="text" v-for="n in num" :key="n" class="Auth-input" v-model="smscode[n-1]" maxlength="1"
                       :id="'auth' + (n-1)"
                       @input.prevent="next(n);" :autofocus="n===1" @keydown.enter="submit" placeholder="-----"> -->
            <input class="auth-row new-input" type="text" v-model="code" maxlength="6">
            <div class="footer">
                <a v-if="countDown===0" @click.prevent="send" style="cursor: pointer" class="countdown">ارسال مجدد</a>
                <a v-else style="opacity:0.5;cursor:default" class="countdown">ارسال مجدد</a>
                <a style="text-decoration: none" class="countdown">{{countDown}} ثانیه </a>
            </div>
        </div>
        <button class="normal-btn" @click.prevent="submit" :disabled="code.toString().length < num || countDown === 0">
            تایید
        </button>
        <a style="opacity: 0.8;color:red">{{error}}</a>
        <Loading v-if="showLoading"/>
    </div>
</template>

<script>
    import Loading from '../Tools/Loading'

    export default {
        name: "TwoStepSms",
        data() {
            return {
                countDown: 120,
                smscode: [],
                error: '',
                num: 6,
                showLoading: false,
                code: '',
                isSent: false

            }
        },
        components: {
            // CodeInput
            Loading
        },
        methods: {
            check() {

            },
            countDownTimer() {
                if (this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown--
                        this.countDownTimer()
                    }, 1000)
                }
            },
            async send() {
                this.showLoading = true
                this.smscode = []
                this.state.email = this.$cookies.get('username')
                await this.$axios.post(`/users/resend-otp-code?email=${this.$cookies.get('username')}`)
                    .then(() => {
                        this.$error('کد جدید ارسال شد.', '', 'success')
                        this.showLoading = false
                        this.$cookies.set('username', this.state.email)
                        this.isSent = true
                    })
                    .catch(() => {
                        this.showLoading = false
                    })
                if (this.countDown === 0 && this.isSent) {
                    this.countDown = 120
                    this.error = ''
                    this.isSent = false
                    this.countDownTimer()
                }
            },
            // next(e) {
            //     this.sortNumber()
            //     if (this.smscode[e - 1]) {
            //         if (e < this.num) {
            //             document.getElementById('auth' + e).focus();
            //         }
            //     } else {
            //         if (e > 1) {
            //             document.getElementById('auth' + (e - 2)).focus();
            //         }
            //     }
            // },
            // sortNumber() {
            //     // if (this.smscode.length === this.num) {
            //     var b = ''
            //     for (let i = 0; i < this.smscode.length; i++) {
            //         b = b + this.smscode[i]
            //     }
            //     this.code = Number(b)
            //     // }
            // },
            async submit() {
                this.showLoading = true
                var SmsOtp = {
                    'code': this.code,
                    'email': this.$cookies.get('username')
                }
                await this.$axios.post(`users/check-otp-code`, SmsOtp)
                    .then(response => {
                        this.state.token = response.data.token;
                        this.showLoading = false
                        localStorage.token = response.data.token;
                        this.state.token = localStorage.token
                        if (this.state.token !== '') {
                            this.$router.push('trade')
                        }
                    })
                    .catch(error => {
                        this.showLoading = false
                        if (error.response) {
                            if (error.response.data.message === 'Incorrect OTP') {
                                this.error = 'کد وارد شده اشتباه است.'
                            }
                        }
                    })
            }

        },
        created() {
            this.countDownTimer();

        },
        mounted() {
            // this.mobileNum = this.state.mobilenumber
        }

    }
</script>

<style lang="scss" scoped>

    input {
        padding: 5px 0;
    }

    .Auth-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 400px;
        height: 100%;
    }

    .Auth {
        margin-bottom: 70px;
    }

    .countdown {
        font-size: 14px;
        text-decoration: underline;
    }

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
    }

    .auth-row {
        display: flex;
        direction: ltr;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 45px;
        margin-top: 10px;
        border: 1px solid var(--light-border);
        border-radius: 15px;
    }

    .Auth-input {
        font-size: 25px;
        border-radius: 5px;
        /*border: solid 1px #00000010;*/
        width: 40px;
        height: 100%;
        text-align: center;
        letter-spacing: -2px;
        /*padding: 0;*/

        &:focus {
            border-color: var(--main-orange);
        }
    }

    p {
        margin: 20px 0 20px;
        text-align: right;
    }

    .normal-btn {
        margin-bottom: 80px;
    }
</style>