<template>
    <div class="navbar justify-content-between align-items-center
    pl-xl-3 pr-xl-3 pl-lg-2 pr-lg-2 pl-1 pr-1">
        <div v-if="currentroute==='Trade'" class="trad-base">
            <p>بازار پایه : </p>
            <a :class="{selectedbase: tradetoid == 0}" :title="tomanIsAvailable? 'بازار فعال':'بازار غیر فعال'"
               :href="[tradetoid == 0 || !tomanIsAvailable ? '#' : ('/trade/0/'+tradefromid) ]">ریال</a>
            <a :class="{selectedbase: tradetoid == 1}" :title="tetherIsAvailable? 'بازار فعال':'بازار غیر فعال'"
               :href="[tradetoid == 1 || !tetherIsAvailable ? '#' : ('/trade/1/'+tradefromid) ]">USDT</a>
        </div>
        <div v-else-if="currentroute==='Dashboard'" class="trad-base">
            <p>بازار پایه : </p>
            <a :class="{selectedbase: tradetoid == 0}" :title="tomanIsAvailable? 'بازار فعال':'بازار غیر فعال'"
               :href="[tradetoid == 0 || !tomanIsAvailable? '#' : ('/dashboard/0/'+tradefromid) ]">ریال</a>
            <a :class="{selectedbase: tradetoid == 1}" :title="tetherIsAvailable? 'بازار فعال':'بازار غیر فعال'"
               :href="[tradetoid == 1 || !tomanIsAvailable? '#' : ('/dashboard/1/'+tradefromid) ]">USDT</a>
        </div>
         <a v-else href="/" class="no-gutters trad-base" style="width:110px;height:51px;padding:5px 0">
             <img style="width:50px;height:100%" src="../../assets/logo1.svg" alt="">
         </a>

        <div class="menu justify-content-between">
            <div class="menuimg">
                <img @click="nav=true" class="icon col-auto" src="../../assets/menu.svg" alt="">
            </div>
            <touch-menu-trade :style="{transform: [nav? 'translateX(0)' : '']}" @close="nav=false" :account="account"
                              :currentroute="currentroute" @open="tshow=true" @trade="tsshow=true"
                              @account="accountmenu=true"/>
            <touch-menu-content :style="{transform: [tshow? 'translateX(0)' : '']}" :contents="historycontents"
                                :currentroute="params.tab"
                                @close="tshow=false" :header="'تاریخچه'"/>
            <touch-menu-content :style="{transform: [tsshow? 'translateX(0)' : '']}" :contents="tradetoid==1 ? subcontentsteter : subcontentstoman"
                                :currentroute="params.tab"
                                @close="tsshow=false" :header="'بازار ها'"/>
            <div class="sub-menu2 row">
                <a :class="[(currentroute==='Trade') ? 'selected' : '']" class="dropdown">بازار ها
                    <img v-if="currentroute==='Trade'" src="../../assets/blog/Vector2.svg">
                    <img v-else src="../../assets/blog/Vector1.svg">
                    <drop-content-hover class="drop" :contents="tradetoid==1 ? subcontentsteter : subcontentstoman"
                                        :route="params.tab"/>
                </a>
                <a @click.prevent="$router.push('/dashboard')" :class="[(currentroute==='Dashboard') ? 'selected' : '']"
                   :href="currentroute==='Home'? '#' : '/dashboard'">
                    داشبورد</a>
                <a :class="[(currentroute==='Wallet') ? 'selected' : '']" @click.prevent="$router.push('/wallet')">کیف
                    پول</a>
                <a :class="[(currentroute==='Orders') ? 'selected' : '']"
                   :href="currentroute==='Orders'? '#' : '/orders'" @click.prevent="$router.push('/orders')">سفارش
                    ها</a>
                <a :class="[(currentroute==='History') ? 'selected' : '']" class="dropdown">تاریخچه
                    <img v-if="currentroute==='History'" src="../../assets/blog/Vector2.svg">
                    <img v-else src="../../assets/blog/Vector1.svg">
                    <drop-content-hover class="drop" :contents="historycontents"
                                        :route="params.tab"/>
                </a>
            </div>
        </div>
        <Account class="account" :account="account" :route="currentroute"></Account>
        <a href="/" class="logo1 no-gutters" style="width:110px;height:51px">
            <img style="width:50px;height:51px" src="../../assets/logo1.svg" alt="">
        </a>

    </div>
</template>

<script>
    import Account from "@/components/Navbar/account";
    import TouchMenuContent from "@/components/Navbar/TouchMenu/touchMenuContent";
    import TouchMenuTrade from "@/components/Navbar/TouchMenu/touchMenuTrade";
    import DropContentHover from "@/components/Tools/DropContentHover";

    export default {
        name: "TradeNavBar",
        props: ['maintable', 'tradefrom', 'tradeto'],
        components: {
            DropContentHover,
            TouchMenuTrade, TouchMenuContent, Account
        },
        data() {
            return {
                nav: false,
                help: false,
                accountmenu: false,
                logo: require("../../assets/logo.png"),
                trade: false,
                tshow: false,
                tsshow: false,
                account: true,
                mobile: false,
                screanWidth: screen.width,
                loginpage: false,
                currentroute: '',
                currentposition: '',
                dropname: '',
                params: '',
                subheader: '',
                contents_sub: [],
                contents: [
                    {
                        name: 'راهنمای استفاده',
                        link: '/howtouse',
                        route: 'HowToUse'
                    },
                    {
                        name: 'سوالات متداول',
                        link: '/faq',
                        route: 'FAQ'
                    },
                    {
                        name: 'درباره ما',
                        link: '/about-us',
                        route: 'AboutUs'
                    },
                    {
                        name: 'قوانین و مقررات',
                        link: '/policy',
                        route: 'Policy'
                    },
                ],
                tradeRoutes: ['Trade', 'Wallet', 'History', 'Dashboard', 'Orders'],
                accountRoutes: ['Profile', 'Setting', 'Messages'],
                deg: [90, 90, 90, 90],
                show: [false, false, false, false],
                tradetoid: '',
                tradefromid: '',
                subcontent: [
                    {
                        name: 'تومان',
                        link: '',
                        route: '0'
                    },
                    {
                        name: 'تتر',
                        link: '',
                        route: '1'
                    },
                ],
                subcontentstoman: [
                    {
                        name: 'بیت کوین',
                        link: '/trade/0/0',
                        route: '/trade/0/0'
                    },
                ],
                subcontentsteter: [
                    {
                        name: 'بیت کوین',
                        link: '/trade/1/0',
                        route: '/trade/1/0'
                    },
                ],
                historycontents: [
                    {
                        name: 'اطلاعات خرید',
                        link: '/history/buy',
                        route: 'buy'
                    },
                    {
                        name: 'اطلاعات فروش',
                        link: '/history/sell',
                        route: 'sell'
                    },
                    {
                        name: 'اطلاعات ورود',
                        link: '/history/login',
                        route: 'login'
                    }
                ]

            }
        },
        watch: {
            $route(to) {
                this.nav = false
                this.help = false
                this.accountmenu = false
                this.tshow = false
                this.tsshow = false
                this.currentroute = to.name
                this.params = to.params
                this.tradetoid = to.params.tradetoid
                this.tradefromid = to.params.tradefromid
                this.currentposition = window.pageYOffset
                this.RouteName()
                window.pageYOffset = 0
            }
        },
        created() {
            window.addEventListener('scroll', this.scrollposition)
            this.getMarkets()
        },
        computed:{
            tetherIsAvailable(){
                return this.subcontentsteter.findIndex(e => e.destinationCoin === this.$coinArray[this.tradefromid]) > -1
            },
            tomanIsAvailable(){
                return this.subcontentstoman.findIndex(e => e.destinationCoin === this.$coinArray[this.tradefromid]) > -1
            }
        },
        mounted() {
            this.currentroute = this.$route.name;
            this.params = this.$route.params
            this.tradetoid = this.$route.params.tradetoid
            this.tradefromid = this.$route.params.tradefromid

            this.RouteName()
            window.addEventListener('resize', this.windowssize)

        },
        beforeDestroy() {
            window.removeEventListener('resize', this.windowssize)
            window.removeEventListener('scroll', this.scrollposition)
        },
        methods: {
            RouteName() {
                var e = this.currentroute
                if (this.tradeRoutes.indexOf(e) > -1) {
                    this.trade = true
                } else {
                    this.trade = false
                }
                if (this.trade || this.accountRoutes.indexOf(e) > -1) {
                    this.account = true
                } else {
                    this.account = false
                }
            },
            windowssize() {
                this.screanWidth = screen.width
                if (this.screanWidth > 724) {
                    this.nav = false
                    this.deg = [90, 90, 90, 90]
                    this.show = [false, false, false, false]
                }
            },
            Route(e) {
                this.$router.push(e)
            },
            touchdropshow(e) {
                if (!this.show[e]) {
                    this.show[e] = true
                    this.deg[e] = 0
                } else {
                    this.show[e] = false
                    this.deg[e] = 90
                }
                this.$forceUpdate()
            },
            opensub(e) {
                if (e === 'تومان') {
                    this.contents_sub = this.subcontentstoman
                } else {
                    this.contents_sub = this.subcontentsteter
                }
                this.subheader = e
                this.tsshow = true

            },
            async getMarkets() {
                const res = await this.$axios.get('/markets?isActive=true')
                this.subcontentstoman = res.data.content.filter(i => i.sourceCoin.includes('TOMAN'))
                for (let item of this.subcontentstoman) {
                    item.name = this.$coinLabel[item.destinationCoin]
                    item.link = '/trade/0/'+this.$coinArray2[item.destinationCoin]
                    item.route = '/trade/0/'+this.$coinArray2[item.destinationCoin]
                }
                this.subcontentsteter = res.data.content.filter(i => i.sourceCoin.includes('TETHER'))
                for (let item of this.subcontentsteter) {
                    item.name = this.$coinLabel[item.destinationCoin]
                    item.link = '/trade/1/'+this.$coinArray2[item.destinationCoin]
                    item.route = '/trade/1/'+this.$coinArray2[item.destinationCoin]
                }
            },
        }
    }
</script>

<style scoped>

    .navbar {
        width: 100%;
        box-sizing: inherit;
        background-color: var(--navbar-back);
        min-height: 54px;
        position: fixed;
        position: -ms-device-fixed;
        /*position: sticky;*/
        /*position: -webkit-sticky;*/
        /*display: block;*/
        top: 0;
        box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
        z-index: 4;
        color: white;
        display: flex;
        flex-direction: row;

    }

    .transparency {
        -webkit-backdrop-filter: blur(1px);
        backdrop-filter: blur(1px);
        background-color: rgba(24, 43, 78, 0);
    }

    .logo {
        height: 40px;
        width: 140px;
        cursor: pointer;
        background-image: url("../../assets/rocket.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .logo1 {
        display: none;
        height: 40px;
        width: 140px;
        cursor: pointer;

        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .account {
        width: 250px;
    }

    .menu {
        position: revert;
        display: flex;
        justify-content: space-between;
        width: calc(100% - 355px);
        /*color: white;*/
        font-size: 14px;
    }

    .menuimg {
        display: none;
        flex-direction: column;
        align-items: center;
    }

    /*.dark .menuimg img {*/
    /*    filter: invert(100%)*/
    /*}*/

    .menu .icon {
        height: 30px;
        width: 50px;
    }


    .sub-menu2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        max-width: max-content;
    }


    .sub-menu2 a {
        height: 22px;
        /*width: 73px;*/
        padding: 0 20px;
        cursor: pointer;
        /*border-left: solid var(--light-border) 2px;*/
        color: white
    }

    /*.sub-menu2 a:last-of-type {*/
    /*    border-left: none*/
    /*}*/

    .sub-menu2 a:hover {
        color: var(--main-green);
    }

    .selected {
        color: var(--main-green) !important;
        /*height: 30px;*/
        /*border-bottom: #f8b200 solid 2px;*/
        cursor: default !important;
    }


    @media (max-width: 991px) {
        .menuimg {
            display: flex;
        }

        .sub-menu2, .account {
            display: none;
        }

        .menu {
            width: calc(100% - 140px);
        }

        .trad-base {
            display: none !important;
        }

        .logo {
            display: none !important;
        }

        .logo1 {
            display: flex;
            justify-content: center;
            width: 70px !important;
        }
    }

    .touch-menu .account {
        display: flex;
    }

    ul {
        list-style-type: none;
        font-size: 14px;
        width: max-content;
    }

    li {
        padding: 10px 12px;
        transition: 0.3s;
        position: relative;
        text-align: center;
    }

    .dropdown {
        position: relative;
    }

    .dropdown img {
        margin-right: 5px;
        /*margin-top: 10px;*/
    }

    .drop {
        display: none;
    }

    .dropdown:hover .drop {
        display: block !important;
    }

    .dropdown:hover svg {
        fill: var(--main-green);
        transform: rotate(270deg);
    }

    .trad-base {
        width: 200px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .trad-base a {
        height: 35px;
        width: calc(50% - 5px);
        max-width: 55px;
        background-color: transparent;
        border: 3px solid var(--main-green);
        border-radius: 5px;
        color: white;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .trad-base a:hover {
        background-color: var(--main-green);
    }

    .trad-base .selectedbase {
        background-color: var(--main-green);
        cursor: default;
    }

</style>