import Vue from "vue";
import router from '@/lib/router'

let that = Vue.prototype

that.$getData=async()=>{
    await that.$axios.get('/users/account')
        .then(res => {
            that.state.gotten = true
            that.state.userinfo.authenticationType = res.data.baseDTO.authenticationType
            that.state.userinfo.email = res.data.baseDTO.email
            that.state.userinfo.towStepAuthenticationType = res.data.baseDTO.towStepAuthenticationType
            that.state.userinfo.supervisor = res.data.baseDTO.supervisor
            that.state.userinfo.accountLevel = res.data.baseDTO.userAccountLevel
            that.state.userinfo.totalTradesAmount = res.data.baseDTO.totalTradesAmount
            if (res.data.baseDTO.authenticationType === "AUTHORIZED") {
                that.state.userinfo.authorized = true
            } else if (res.data.baseDTO.authenticationType === "PENDING") {
                that.state.userinfo.pending = true
            }
        })       
}

that.$getWage = async ()=>{
    let a;
    const res = await that.$axios('/users/account-levels')
    that.$accountLevel = res.data
    a = res.data.filter(i=>i.name == that.state.userinfo.accountLevel)
    // console.log(res.data)
    that.state.userinfo.wage = a.length ? a[0].wagePercent : 0
    // console.log(that.state.userinfo.wage,that.state.userinfo.accountLevel);
}

that.$toLocal = (number) => {
    if(number==='') return ''
    let a
    number = number+''
    number = number.replace(/[^\d.]|\.\./ig, '')
    if (parseFloat(number.replace(/,/g, ''))) {
        a = parseFloat(number.split('.')[0].replace(/,/g, '')).toLocaleString()
        if (number.includes('.')) {
            return a + '.' + (number.split('.')[1] || '')
        }
        else{
            return  a
        }
    }else{
        return  number
    }
}

that.$marketType2relatedCoin = marketType => marketType.replace(/_TOMAN|_TETHER/,'')

that.$logout = () => {
    localStorage.clear('token')
    // Vue.$cookies.remove('--abcd14589')
    that.state.token = ''
    that.state.gotten = false
    that.$axios.defaults.headers.common['Authorization'] = that.state.token
    // console.log(Vue)
    router.push('/login')
}


that.$error = async (title, text, icon = 'error', button = 'متوجه شدم') => {
    return await that.$swal({
        icon: icon,
        title: title,
        text: text,
        confirmButtonText: button,
        customClass: {
            confirmButton: 'btn-success',
        },
    })
}

that.$sleep = (ms) => { return new Promise(res => setTimeout(res, ms)) }

var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date+' '+time;
    that.state.today = dateTime
setInterval(() => {
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date+' '+time;
    that.state.today = dateTime
}, 1000);
