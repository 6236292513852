<template>

    <div class="main-box">
        <!-- {{$accountLevel}} -->
        <h1>کارمزد فعلی: {{state.userinfo.wage}} درصد</h1>
        <h1>میزان معاملات شما تاکنون {{state.totalTradesAmount ? state.totalTradesAmount.toLocaleString() : 0}} تومان بوده است، شما در سطح {{levels[state.userinfo.accountLevel]}} قرار دارید</h1>
    </div>
</template>

<script>
    export default {
        name: "AccountWage",
        data() {
            return {
              levels:{
                    SUPERVISOR:'بازارگردان',
                    BRONZE:'عقیق',
                    SILVER:'فیروزه',
                    GOLD:'یاقوت',
                    PLATINUM:'الماس'
              }
            }
        },
    }
</script>

<style scoped>
    h2 {
        font-size: 14px;
        margin: 0;
    }

    p {
        margin: 0;
    }

    h1 {
        font-size: 17px;
        color: var(--font-color);
        position: relative;
        margin: 20px 0;
        padding-right: 20px;
    }
    h1::before{
        position: absolute;
        top: 14px;
        transform: translateY(-50%);
        right: 2px;
        content: '';
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background-color: var(--main-green);
    }

    .main-box {
        width: 100%;
        /*height: 235px;*/
        display: flex;
        overflow: auto;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        font-size: 13px;
        padding: 10px;
        margin-bottom: 10px;
        position: relative;
        /*background-color: var(--dark-background) !important;*/
    }


</style>