<template>
    <div class="main-box">
        <!-- <button @click="getWalletData">navid-btn</button> -->
        <div v-for="(content,index) in contents" :key="index" class="block" @click="routing(index)">
            <div class="name">
                <img :src="require('../../assets/icon/'+index+'.png')" alt="">
                <p>
                    {{$coinLabel[index]}}
                </p>
            </div>
            <p class="cash">
                {{$coinUnit[content.relatedCoin]}}
            </p>
            <p style="margin-right:5px"> {{content.credit || content.totalBalance || 0}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DashboardWallet",
        props: ['toid'],
        watch:{
            dashboardWallet:(val)=>{
                console.log('dash wall',val);
            }
        },
        data() {
            return {
                contents: [],
                coinsList:"ETHEREUM,BITCOIN_CASH,DASH,LITE_COIN,RIPPLE,TETHER,TOMAN,BITCOIN",
                coins:{'BITCOIN':0,
                RIPPLE:4
                }
            }
        },
        methods: {

            getWalletData(){
                // console.log('fetching from wallet');
                this.$axios(`/wallets/customer-addresses`,{
                    params:{
                        coins : this.coinsList
                    }
                })
                    .then(res=>{
                        // console.log(res.data);
                        this.contents = res.data
                        
                    })
                    .catch(err=>{
                        console.log(err);
                    })
                },
            routing(e) {
                // console.log(this.toid);
                this.$router.push('/dashboard/' + this.toid + '/' + this.coins[e])
            }
        },
        mounted(){
            this.getWalletData()
        }
    }
</script>

<style scoped>
    .main-box {
        width: 100%;
        /*height: 235px;*/
        display: flex;
        /*overflow: auto;*/
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        font-size: 15px;
        padding: 0 10px;
        margin-bottom: 10px;
        /*background-color: var(--dark-background) !important;*/
    }

    .block {
        width: 100%;
        max-width: 170px;
        min-width: max-content;
        border-radius: 5px;
        /*border: 1px solid var(--light-border);*/
        /*padding: 10px 5px;*/
        margin: 0 3%;
        /*cursor: pointer;*/
        transition: 0.2s;
        background-color: white;
        display: flex;
        flex-direction: row;
        /* justify-content: space-between; */
        align-items: center;
    }

    .block img {
        width: 30px;
        margin-left: 10px;
    }

    .cash{
        direction: rtl;
        margin-right: 10px;
    }
    @media (max-width: 426px) {
        .block{
            max-width: none;
        }
    }

    .name{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-left: 5px;
    }

    /* .block:nth-of-type(n+2) p{
        font-family: IRANSans,sans-serif;
        direction: ltr;
    } */


</style>