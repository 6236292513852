<template>
    <div class="Auth-box">
        <div class="Auth-header">
            <h3>ساخت حساب کاربری</h3>
        </div>

        <form class="Auth">
            <ali-input @send="receive($event)" :type="'email'" :name="'email'" :errortext="emailError"
                       :faname="'ایمیل'"></ali-input>
            <ali-input @send="receive1($event)" :type="'password'" :name="'password'" :errortext="passError"
                       :faname="'رمز عبور'"></ali-input>
            <input type="text" class="referral-code" placeholder="کد دعوت" v-model="referral" :readonly='readOnly' :style="{textAlign: referral.length ? 'left' : 'right'}">
            <div class="checkbox1">
                <input @change="check" type="checkbox" name="checkbox1" id="checkbox1">
                <label for="checkbox1">
                    <img src="../../assets/icon/tick.svg" alt="">
                </label>
                <a class="link" @click="$router.push('/policy')">قوانین و شرایط
                    صرافی را مطالعه کرده و میپذیرم.</a>
            </div>
           
        </form>
        <p v-if="error" style="color:red">{{error}}</p>
        <button @click="gotologin" class="normal-btn"
                :disabled="!email || !password || emailError.length>0 || passError.length>0 || !agree">ثبت نام
        </button>
        <a style="margin: 10px 0">یا</a>
        <a href="https://whalebitex.org/api/users/google-sso" class="google-btn" style="font-size:16px">
            <img class="google-icon" src="@/assets/Google_G_Logo.svg">
           ثبت نام با گوگل
          </a>

        <div style="text-align: center;margin-bottom: 30px ;font-size: 14px">
            قبلا در ویستا ثبت‌نام کرده‌اید؟ <a class="link" style="color: #354FD2" @click="()=>this.$router.push('login')"> وارد شوید</a>
        </div>
        <Loading v-if="showLoading"/>
    </div>
</template>

<script>
    import AliInput from "@/components/Tools/AliInput";
    import Loading from '../Tools/Loading'
    export default {
        name: "Register",
        components: {AliInput,Loading},
        data() {
            return {
                email: '',
                emailError: '',
                passError: '',
                password: '',
                OSName:'Unknown',
                borwserType:'',
                deviceType:'',
                error:'',
                showLoading:false,
                agree:false,
                referral:'',
            }
        },
        computed:{
            readOnly(){
                return localStorage.referralCode || this.$route.query.ref
            }
        },
        methods: {
            check(e){
                // console.log(e.target.checked);
                e.target.checked? this.agree = true : this.agree = false
                // console.log(this.agree);
            },
            checkPass() {
                if (!this.password) {
                    this.passError = 'رمز اجباری';
                } else {
                    if (this.password.length < 8) {
                        this.passError = 'حداقل 8 کاراکتر';
                    } else if (!this.validPass(this.password)) {
                        this.passError = 'ترکیبی از حروف کوچک و بزرگ و عدد';
                    } else {
                        this.passError = ''
                    }
                }
                if (!this.passError.length) {
                    return true;
                }
            },
            validPass(password) {
                var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
                return re.test(password);
            },
            checkEmail() {
                if (!this.email) {
                    this.emailError = 'ایمیل اجباری';
                } else if (!this.validEmail(this.email)) {
                    this.emailError = 'ایمیل صحیح وارد نمایید';
                } else {
                    this.emailError = ''
                }
                if (!this.emailError.length) {
                    return true;
                }
            },
            validEmail(email) {
                var re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                return re.test(email);
            },
            receive(e) {
                this.email = e
                this.checkEmail(e)
            },
            receive1(e) {
                this.password = e
                this.checkPass(e)
            },
            gotologin() {
                // console.log(this.email,this.password)
                this.showLoading = true
                    const article = {
                        "email": this.email,
                        "password": this.password,
                        "osType":this.OSName,
                        "deviceType":this.deviceType,
                        "browser":this.borwserType || 'CHROME',
                        "referralCode" : localStorage.referralCode || null
                        };
                    // console.log(article)
                    this.$axios.post(`/users/register`,article)
                    .then(response => {this.res = response.data;
                    // console.log('loglog',this.res)
                    if (this.res.message ==='user created successfully , verification email sent'){
                        if(this.res.baseDTO.towStepAuthenticationType === 'EMAIL'){
                            this.$cookies.set('username', this.email)
                            this.state.email = this.email
                            this.$router.push('two-step-email')
                        }
                    }
                    })
                    .catch(error => {
                    if(error.response){
                        // console.log(error.response.data)
                        console.log('my error',error.response.data.message)
                        if (error.response.data.message === 'username exist.'){
                            this.error = 'شما قبلا ثبت نام کرده اید.'
                        }
                    }
                    
                    })
                    .then(()=>this.showLoading = false)
            },
            detectOs(){
                var Name = "Unknown OS"; 
                if (navigator.userAgent.indexOf("Win") != -1) Name =  
                "WINDOWS"; 
                if (navigator.userAgent.indexOf("Mac") != -1) Name =  
                "MACINTOSH"; 
                if (navigator.userAgent.indexOf("Linux") != -1) Name =  
                "LINUX"; 
                if (navigator.userAgent.indexOf("Android") != -1) Name =  
                "ANDROID"; 
                if (navigator.userAgent.indexOf("like Mac") != -1) Name = "IOS"; 
                this.OSName = Name
                // console.log('Your OS: '+this.OSName);
            },
            detectBrowser(){
                // Opera 8.0+
                var isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
                if(isOpera){
                    this.borwserType = 'OPERA'
                }

                // Firefox 1.0+
                var isFirefox = typeof InstallTrigger !== 'undefined';
                if(isFirefox){
                    this.borwserType = 'FIREFOX'
                }

                // Safari 3.0+ "[object HTMLElementConstructor]" 
                var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
                if(isSafari){
                    this.borwserType = 'SAFARI'
                }
                // Internet Explorer 6-11
                var isIE = /*@cc_on!@*/false || !!document.documentMode;
                if(isIE){
                    this.borwserType = 'IE'
                }
                // Edge 20+
                var isEdge = !isIE && !!window.StyleMedia;
                if(isEdge){
                    this.borwserType = 'EDGE'
                }
                // Chrome 1 - 79
                var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
                if(isChrome){
                    this.borwserType = 'CHROME'
                }
                // Edge (based on chromium) detection
                var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
                if(isEdgeChromium){
                    this.borwserType = 'EDGECHROMIUM'
                }
                
                // console.log(this.borwserType)
            },
            detectDevice(){
                const ua = navigator.userAgent;
                if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
                    this.deviceType = 'TABLET'
                }
                if (
                    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                    ua
                    )
                ) {
                    this.deviceType = 'MOBILE'
                }else{
                    this.deviceType = 'DESKTOP'
                }
                // console.log(this.deviceType)
            }

        },
        mounted(){
            this.detectOs();
            this.detectBrowser();
            this.detectDevice()
            if(this.$route.query.ref || localStorage.referralCode){
                localStorage.referralCode = this.$route.query.ref || localStorage.referralCode
                this.referral = this.$route.query.ref || localStorage.referralCode
            }
            // if (localStorage.referralCode){
            //     this.readOnly = true
            // }
        }
    }
</script>

<style lang="scss" scoped>

    .Auth-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 400px;
        height: 100%;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .link {
        margin-right: 10px;
        /*text-decoration: underline;*/
        font-size: 14px !important;
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }

    .checkbox1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 15px 3px 50px 0;
        width: 100%;
        height: 18px;
        border-radius: 2px;
        position: relative;
        font-size: 14px;

        label {
            width: 18px;
            height: 18px;
            cursor: pointer;
            position: relative;
            top: 0;
            left: 0;
            background-color: var(--main-green);
            border-radius: 5px;
            opacity: 1;

            img {
                position: absolute;
                bottom: 50%;
                right: 50%;
                transform: translate(50%, 50%);
                opacity: 0;
            }

            &:hover {
                img{
                    opacity : 0.5
                }
            }
        }

        input[type=checkbox] {
            visibility: hidden;
            position: absolute;

            &:checked + label {
                img{
                    opacity: 1;
                }
            }
        }
    }
    .referral-code{
       direction: ltr;
        text-align:right;
        width: 100%;
        height: 100%;
        padding: 10px;
        font-size: 14px;
        color: black;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        background: transparent;
        margin-top: 10px;
    }
    input:read-only{
        background: rgba(0, 0, 0, 0.164);
        // opacity: .7;
    }
</style>
