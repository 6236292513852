<template>
    <div class="main dark">
        <form @input="calculate">
            <div class="footer">
                <a>موجودی</a>
                <a class="link" @click="putcash(decimal, 1)"
                ><span>{{ $toLocal(cash) }}</span> {{ tolabel }}</a
                >
            </div>
            <div class="row no-gutters">
                <label class="col-5 col-sm-12 col-md-5" for="unitprice"
                >قیمت واحد ({{ tradeto }})</label
                >
                <input
                        @input="un = $toLocal(un)"
                        :style="{ borderColor: [danger ? 'var(--main-red)' : ''] }"
                        class="col-7 col-sm-12 col-md-7"
                        type="text"
                        v-model="un"
                        name="unitprice"
                        id="unitprice"
                        :readonly="!limit"
                        :placeholder="[!limit ? tradefrom + ' - ' + tradeto : '']"
                />
            </div>
            <div class="row no-gutters">
                <label class="col-5 col-sm-12 col-md-5" for="amount">مقدار {{ tradefrom }}</label>
                <input
                        @input="state.sellAmount = $toLocal(state.sellAmount)"
                        :style="{ borderColor: [danger1 ? 'var(--main-red)' : ''] }"
                        class="col-7 col-sm-12 col-md-7"
                        type="text"
                        v-model="state.sellAmount"
                        name="amount"
                        id="amount"
                />
            </div>
            <div class="row no-gutters">
                <div
                        class="col-7 offset-5 col-sm-12 offset-sm-0 col-md-7 offset-md-5"
                        id="slider"
                >
                    <span class="slider-back"/>
                    <template v-for="n in 5">
                        <a
                                @click.prevent="value = (n-1)*0.25;putcash(decimal, value);"
                                class="percent"
                                :key="n">
                            {{(n-1)*25}}٪
                        </a>
                        <span
                                @click.prevent="value = (n-1)*0.25;putcash(decimal, value);"
                                class="dot"
                                :class="'dot'+n"
                                :key="-n"
                        />
                    </template>
                    <input
                            @input="putcash(decimal, value)"
                            style="direction: ltr"
                            type="range"
                            class="slider"
                            :id="'one' + id"
                            min="0"
                            max="1"
                            step="0.01"
                            v-model="value"
                    />
                </div>
            </div>
            <div class="row no-gutters">
                <label class="col-5 col-sm-12 col-md-5" for="totalprice"
                >قیمت کل ({{ tradeto }})</label
                >
                <input
                        :style="{ borderColor: [danger2 ? 'var(--main-red)' : ''] }"
                        class="col-7 col-sm-12 col-md-7"
                        type="text"
                        v-model="tp"
                        name="totalprice"
                        id="totalprice"
                        readonly
                />
            </div>
            <div v-if="limit" class="row no-gutters">
                <label class="col-5 col-sm-12 col-md-5" for="exp">تاریخ انقضا</label>
                <date-picker
                        format="YYYY-MM-DDTHH:mm"
                        display-format="jYYYY/jMM/jDD HH:mm"
                        v-model="datetime"
                        :min="state.today"
                        type="datetime"
                        id="exp"
                        class="col-7 col-sm-12 col-md-7 m-0"
                        color="rgb(200 203 204)"
                        placeholder="انتخاب تاریخ"
                />
            </div>
            <div v-if="limit" class="footer">
                <a style="text-align: right">بالاترین پیشنهاد خرید</a>
                <a style="text-align: left" class="link" @click="setMin"> <span>{{ maxSell.toLocaleString() }}</span> {{
                    fromlabel }}</a>
            </div>
            <div class="footer">
                <a>میزان کارمزد فعلی</a>
                <a>٪ {{ wage }}</a>
            </div>
            <p style="text-align: center; color: red; margin: 0; font-size: 14px">
                {{ error }}
            </p>
            <button
                    v-if="state.userinfo.authorized || state.userinfo.supervisor"
                    @click.prevent="showmodal"
                    class="btn"
            >
                فروش {{ tradefrom }}
            </button>
            <button
                    @click.prevent="goToPanel"
                    class="btn"
                    v-else-if="
          !state.userinfo.authorized && !state.userinfo.pending
        "
            >
                جهت انجام معامله باید حساب کاربری خود را کامل کنید.
            </button>
            <button
                    @click.prevent=""
                    class="btn"
                    v-else-if="state.userinfo.pending"
            >
                حساب شما در دست تایید است لطفا شکیبا باشید
            </button>
        </form>
        <shop-modal
                v-if="show"
                @close="closemodal"
                @accept="showAcceptModal"
                :unitprice="un"
                :totalprice="tp"
                :tradeto="tradeto"
                :tradefrom="tradefrom"
                :amount="state.sellAmount"
                :datetime="datetime"
                :type="'فروش'"
        />

        <ToastNotification/>
        <!-- <button @click="showAcceptModal">click</button> -->
    </div>
</template>

<script>
    // import DatePicker from 'vue-datepicker-persian'
    import "vue-datepicker-persian/src/index.scss";
    import ShopModal from "@/components/Trade/ShopModal";
    import VuePersianDatetimePicker from "vue-persian-datetime-picker";
    import ToastNotification from "../../Tools/ToastNotification";

    export default {
        name: "SellCrypto",
        props: [
            "tradefrom",
            "tradeto",
            "cash",
            "maxSell",
            "wage",
            "tolabel",
            "fromlabel",
            "decimal",
            "limit",
            "id",
            "realprice",
        ],
        components: {
            ShopModal,
            DatePicker: VuePersianDatetimePicker,
            ToastNotification,
        },
        data() {
            return {
                text: "",
                percent: "",
                unitprice: "",
                amount: "",
                totalprice: "",
                danger: "",
                danger1: "",
                danger2: false,
                error: "",
                tradetoEng: {تومان: "TOMAN", تتر: "TETHER"},
                coinComplete: {
                    بیتکوین: "BITCOIN",
                    اتریوم: "ETHEREUM",
                    "بیتکوین کش": "BITCOIN_CASH",
                    ترون: "TRON",
                    ریپل: "RIPPLE",
                    تتر: "TETHER",
                    دش: "DASH",
                    دوج: "DOGE_COIN",
                    "لایت کوین": "LITE_COIN"
                },
                un: "",
                tp: "",
                show: 0,
                value: "",
                datetime: "",
            };
        },
        mounted() {
            this.state.sellAmount = ''
        },
        methods: {
            goToPanel() {
                this.$router.push("/profile");
            },

            showAcceptModal() {
                this.show = false;
                this.state.toastMessage = "سفارش فروش شما با موفقیت ثبت شد";
                this.state.showToast = true;
            },
            putcash(a, p) {
                var up = 0;
                if (this.realprice) {
                    up = this.realprice;
                } else {
                    up = this.unitprice;
                }
                if (this.unitprice || this.realprice) {
                    this.percent = p;
                    var e = (this.percent * this.cash) / up;
                    this.state.sellAmount = e.toFixed(a);
                    this.calculate();
                    this.danger = 0;
                } else {
                    this.danger = 1;
                }
            },
            async getPrice(amount) {
                console.log(amount, "first get");
                if (amount) {
                    amount = parseFloat(amount);
                    var price = 0;
                    console.log("req is sent");
                    await this.$axios(
                        `/orders/market-buy-sell-whole?amount=${amount}&marketType=${
                            this.$coinArray[this.$route.params.tradefromid]
                        }_${this.tradetoEng[this.tradeto]}&orderType=MARKET_SELL`
                    )
                        .then((res) => {
                            console.log(res.data.baseDTO.wholePrice);
                            price = res.data.baseDTO.wholePrice;
                            this.tp = (price + 0.04 * price).toLocaleString();
                            this.un = this.tp.replace(/,/gi, "") / this.state.sellAmount.replace(/,/gi, "");
                            this.un = this.un.toLocaleString();
                            console.log(this.un, this.state.sellAmount, this.tp);
                            this.state.islimited = false
                        })
                        .catch((err) => {
                            console.log(err);
                        });

                    console.log(
                        "amount:",
                        typeof amount,
                        "mart:",
                        this.$coinArray[this.tradefrom],
                        "order:",
                        this.tradetoEng[this.tradeto]
                    );
                    if (price !== 0) {
                        return price;
                    }
                }
            },
            setMin() {
                if (this.limit) {
                    this.un = this.min.toLocaleString();
                    this.danger = false;
                    this.calculate();
                }
            },
            calculate() {
                if (typeof this.un === "string") {
                    var a = Number(this.un.replace(/,/gi, ""));
                }
                if (a) {
                    this.unitprice = a;
                }
                if (typeof this.state.sellAmount === "string") {
                    var amount = Number(this.state.sellAmount.replace(/,/gi, ""));
                }
                var up = 0;
                if (this.realprice) {
                    this.un = "";
                    this.getPrice(amount);
                    this.limitToModal = this.limit;
                } else {
                    up = this.unitprice;
                }
                this.totalprice = amount * up;
                this.tp = this.$toLocal(this.totalprice);
                this.value = amount / this.cash;
            },
            closemodal(ev) {
                if (ev === "big mount") {
                    this.error = "حجم درخواستی بیش از مقدار بازار است.";
                }
                this.show = false;
            },
            showmodal() {
                if (!(!this.limit || this.un) || this.un == 0) {
                    this.danger = true;
                    this.danger1 = false;
                    this.danger2 = false
                } else if (!this.state.sellAmount) {
                    this.danger1 = true;
                    this.danger = false;
                    this.danger2 = false
                } else if (this.cash < Number(this.un.replace(/,/gi, "")) && !this.state.userinfo.supervisor) {
                    this.danger2 = true
                    this.danger1 = false
                    this.danger = false
                } else {
                    this.show = true;
                    this.danger = false;
                    this.danger1 = false;
                    this.danger2 = false
                }
                console.log(this.cash, (this.un).replace(/,/gi, ""));
            },
        },
    };
</script>

<style lang="scss" scoped>
    .main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 10px 10px;
        background-color: var(--dark-background);
        color: white;
        border-radius: 5px;
        border-top: 5px solid var(--main-red);
    }

    form {
        width: 100%;
        min-height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    form div:not(.btn) {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 5px 0;
    }

    label {
        text-align: right;
    }

    input {
        border: solid rgba(54, 52, 53, 0.15) 0.5px;
        height: 40px;
        padding: 0 10px !important;
        text-align: left;
        border-radius: 5px;
        background-color: white;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }

    input:hover {
        border: solid rgba(54, 52, 53, 0.3) 0.5px;
    }

    input:focus {
        border: solid rgba(54, 52, 53, 0.5) 0.5px;
    }

    input:read-only {
        background-color: rgba(0, 0, 0, 0.1);
        border: none;
    }

    #slider {
        margin-top: 6px;
        margin-bottom: 20px;
    }

    .slider-back {
        height: 2px;
        width: 99%;
        background-color: #d8d8d8;
        border-radius: 5px;
        display: inline-block;
        position: absolute;
        cursor: pointer;
        left: 50%;
        transform: translateX(-50%);
    }

    .dot {
        height: 8px;
        width: 8px;
        background-color: var(--main-red);
        border-radius: 50%;
        /*border: solid 1px white;*/
        display: inline-block;
        position: absolute;
        cursor: pointer;
        background-image: none;
        margin: 0;
    }

    .dot:hover {
        background-color: var(--main-red);
    }

    .dot1 {
        left: 0;
    }

    .dot2 {
        left: 25%;
        transform: translateX(-24%);
    }

    .dot3 {
        left: 50%;
        transform: translateX(-50%);
    }

    .dot4 {
        left: 75%;
        transform: translateX(-74%);
    }

    .dot5 {
        left: 100%;
        transform: translateX(-100%);
    }

    .percent {
        font-size: 10px;
        position: absolute;
        top: 9px;
        cursor: pointer;
    }

    /*.percent:hover + .dot{*/
    /*    background-color: var(--main-red);*/
    /*}*/

    .percent:first-of-type {
        left: 0;
    }

    .percent:nth-of-type(2) {
        left: 25%;
        transform: translateX(-30%);
    }

    .percent:nth-of-type(3) {
        left: 50%;
        transform: translateX(-50%);
    }

    .percent:nth-of-type(4) {
        left: 75%;
        transform: translateX(-65%);
    }

    .percent:nth-of-type(5) {
        left: 100%;
        transform: translateX(-90%);
    }

    .slider {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 0;
        background: none !important;
        outline: none;
        border: none !important;
        -webkit-transition: 0.2s;
        transition: 0.2s;
        padding: 0 !important;
        margin: 0 !important;
        z-index: 1;
        cursor: pointer;
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 10px;
        height: 10px;
        background: #fff;
        border: solid 1px var(--main-red);
        border-radius: 50%;
        cursor: pointer;
    }

    .slider::-moz-range-thumb {
        width: 10px;
        height: 10px;
        background: #fff;
        border: solid 1px var(--main-red);
        border-radius: 50%;
        cursor: pointer;
    }

    .link {
        text-decoration: underline;
        cursor: pointer;
    }

    .footer {
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        /*cursor: pointer;*/
    }

    .btn {
        font-size: 12px;
        background-color: var(--main-red);
        height: 40px;
        width: 100%;
        border: none;
        border-radius: 5px;
        color: white;
        cursor: pointer;
        margin-top: 5px;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            transition: 0.25s;
            width: 100%;
            transform: scalex(0);
            background-color: white;
            opacity: 0.2;
            height: 100%;
        }

        &:hover {
            &:after {
                transform: scaleX(1);
            }
        }

        &:disabled {
            opacity: 0.5;
            cursor: default;

            &:hover {
                &:after {
                    transform: scaleX(0);
                }
            }
        }
    }

    .dark input {
        border: solid rgba(255, 255, 255, 0.4) 0.5px;
        color: black;
        text-align: center;
    }

    .dark input:hover {
        border: solid rgba(255, 255, 255, 0.6) 0.5px;
    }

    .dark input:focus {
        border: solid rgba(255, 255, 255, 0.8) 0.5px;
    }

    .dark input:read-only {
        background-color: rgba(255, 255, 255, 0.7);
        // border: none;
    }

    .dark a {
        color: white;
    }

    /*.dark .dot{*/
    /*    background-color: white;*/
    /*}*/

    .dark .slider::-moz-range-thumb {
        background: var(--main-red);
    }

    .dark .slider::-webkit-slider-thumb {
        background: var(--main-red);
    }

    .slider::-webkit-slider-thumb {
        width: 12px;
        height: 12px;
    }
</style>

<style scoped>
    /deep/ .vpd-input-group label {
        border-radius: 0 5px 5px 0;
        background-color: rgb(200 203 204);
    }

    /deep/ .vpd-input-group input {
        width: 100%;
        background: #fff;
        border-radius: 5px 0 0 5px;
    }

    /deep/ .vpd-days {
        color: #4a4a4ae0;
    }

    /deep/ .vpd-controls {
        color: #4a4a4ae0;
    }
</style>