<template>
  <div class="guest">
    <template v-if="state.token">
      <a
        :class="[['Profile'].indexOf(route) > -1 ? 'selected' : '']"
        @click="$router.push('/Profile')"
        >پنل کاربری</a
      >

      <a @click.prevent="deleteToken" >خروج</a>
    </template>
    <template v-else>
      <a
        :class="[['Register'].indexOf(route) > -1 ? 'selected' : '']"
        @click="$router.push('/register')"
        >ثبت نام</a
      >
      <a
        :class="[['Login'].indexOf(route) > -1 ? 'selected' : '']"
        @click="$router.push('/login')"
        >ورود</a
      >
    </template>
  </div>
</template>

<script>
export default {
  name: "AccountDeactive",
  props: ["route", "account"],
  methods:{
      deleteToken(){
          this.$logout()
      }
  }
};
</script>

<style scoped>
.dark a {
  color: white;
  border-color: white !important;
}

.guest {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
a {
  cursor: pointer;
  opacity: 0.8;
  width: calc(50% - 5px);
  max-width: 100px;
  height: 45px;
  background-color: var(--account-back);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}
a:hover {
  background-color: var(--main-green);
}

.dark a {
  border-color: rgba(255, 255, 255, 0.3);
}

.selected {
  background-color: var(--main-green) !important;
  cursor: default;
}
</style>