<template>
    <transition name="modal-fade">
        <div id="modal" class="modal-backdrop">
            <div class="modal"
                 role="dialog"
                 aria-labelledby="WithdrawModal"
                 aria-describedby="WithdrawModal">
                <img
                        src="../../assets/icon/close.svg"
                        class="btn-close"
                        @click="close"
                        aria-label="Close modal">
                <img>
                <header class="modal-header">
                    برداشت {{wallet.name}}
                </header>
                <section class="modal-body" style="direction: rtl">
                    <form>
                        <div class="row no-gutters input">
                            <label class="col-md-3" for="amount">مقدار {{wallet.name}}</label>
                            <input class="col-md-5" type="text" v-model="amount" name="amount" id="amount" >
                            <div class="row col-md-5 col-xs-12 offset-md-3 no-gutters">
                                <p v-if="amount > wallet.credit" style="color:red;font-size:12px">مقدار برداشتی نمیتواند بیش از موجودی کیف پول باشد.</p>
                                <a class="col-7">موجودی قابل برداشت:</a>
                                <div class="col-5 lefttext"><a
                                        @click.prevent="amount=wallet.credit">{{wallet.credit || 0}}</a> ({{$coinUnit[wallet.relatedCoin]}})
                                </div>
                            </div>

                        </div>
                        <div class="row no-gutters input">
                            <label class="col-md-3" for="walletaddress">آدرس کیف‌پول مقصد</label>
                            <input class="col-md-9" type="text" v-model="walletaddress" name="walletaddress"
                                   id="walletaddress">
                            <div class="w-100"></div>
                            <a class="col-md-9 offset-md-3">توجه: وارد کردن آدرس نادرست منجر به از دست رفتن منابع مالی
                                شما میشود.</a>
                        </div>
                        <!-- <div class="row no-gutters input">
                            <label class="col-md-3" for="twostepcode">کد شناسایی دوعاملی</label>
                            <input class="col-md-5" type="text" v-model="twostepcode" name="twostepcode"
                                   id="twostepcode">
                        </div> -->
                        <div class="row justify-content-center" style="font-size: 16px">
                            کارمزد انتقال<a style="text-decoration: underline;font-weight: 600; margin: 0 5px">3.5</a>{{wallet.name}} بوده و مربوط به ثبت تراکنش در شبکه‌ی اتریوم
                            میباشد.
                        </div>
                    </form>
                </section>
                <footer class="modal-btn">
                    <button
                            :disabled ="isValid"
                            type="button"
                            class="normal-btn"
                            @click="withdraw"
                            aria-label="Close modal">
                        برداشت
                    </button>
                </footer>
            </div>
            <LoadingModal v-if="showModalLoading"/>
        </div>
    </transition>
</template>

<script>
    import LoadingModal from '../LoadingModal'
    export default {
        name: "WithdrawModal",
        props: {
            wallet: {
                default: ''
            }
        },
        components:{LoadingModal},
        computed:{
            isValid(){
                return  !this.amount || this.amount==0 || !this.walletaddress || this.amount > this.wallet.credit
            }
        },
        data() {
            return {
                amount: '',
                walletaddress: '',
                twostepcode: '',
                showModalLoading:false
            }
        },
        methods: {
            check(){
                console.log(this.wallet);
            },
            close() {
                this.amount = ''
                this.walletaddress = ''
                this.$emit('close','close');
            },
            withdraw(){
                this.showModalLoading = true
                console.log('req for sending code');
                this.state.transaction = {
                    amount:this.amount,
                    destinationWalletAddress:this.walletaddress,
                    relatedCoin:this.wallet.engName
                }
                console.log(this.state.transaction);
                this.$axios.post('/users/send-code')
                    .then(res=>{
                        console.log(res);
                        if (res.data.message=== 'code sent successfully.'){
                            this.$emit('close','open',`${res.data.baseDTO.towStepAuthenticationType}`)
                        }
                    })
                    .catch(err=>{
                        this.showModalLoading = false
                        console.log(err);
                    })
            }
        },
    }
</script>

<style scoped>

    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
        /* position: relative; */
    }

    .modal {
        position: relative;
        background: var(--account-back);
        border-radius: 5px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 950px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        /*padding: 10px 10px;*/
    }

    .modal-header {
        color: white;
        font-size: 20px;
        height: 70px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10%;
    }

    .btn-close {
        position: absolute;
        left: 10%;
        top: 25px;
        height: 22px;
        width: 22px;
        transition: 0.5s;
        cursor: pointer;
        z-index: 5;
    }

    .btn-close:hover {
        transform: rotate(180deg);
    }

    .modal-body {
        /*margin-top: 20px;*/
        position: relative;
        background-color: white;
        /*border-top-right-radius: 5px;*/
        /*border-top-left-radius: 5px;*/
        padding: 0 10% 20px;
    }

    .modal-fade-enter,
    .modal-fade-leave-active {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .25s ease;
    }

    form {
        font-family: VistaDev;
        margin: 20px 0;
        border-radius: 5px;
        width: 100%;
        font-size: 14px;
    }

    .input {
        margin-bottom: 20px;
    }

    label {
        font-size: 16px;
        margin-top: auto;
        margin-bottom: auto;
    }

    input {
        border: solid rgba(54, 52, 53, 0.15) 0.5px;
        height: 39px;
        border-radius: 5px;
        padding: 2px 5px !important;
    }

    input:hover {
        border: solid rgba(54, 52, 53, 0.3) 0.5px;
    }

    input:focus {
        border: solid rgba(54, 52, 53, 0.5) 0.5px;
    }


    .modal-btn {
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: white;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .normal-btn {
        font-size: 16px;
        color: white;
        max-width: 200px;
        margin: 0;
        border-radius: 10px;
        height: 40px;
        padding: 0;
    }

    .lefttext {
        text-align: left;
        direction: ltr;
    }

    .lefttext a {
        text-decoration: underline;
        cursor: pointer;
    }
</style>
