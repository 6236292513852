<template>
    <div class="box1 row no-gutters">
        <div class="col-6 col-sm-4 col-md-3 pr-2 order-first">
            <h1 style="font-size: 14px">بازار {{tradefrom}} - {{tradeto}}</h1>
        </div>
        <div class="col-6 col-sm-4 col-md-2 pr-2 pr-md-0">
            <a>بیشترین</a>
            <a style="color: var(--main-green)">{{realprice.top.toLocaleString('en-US')}}<a style="color: var(--main-green)">{{tradeto}}</a></a>
        </div>
        <div class="col-6 col-sm-4 col-md-2 pr-2 pr-md-0">
            <a>کمترین</a>
            <a style="color: var(--main-red)">{{realprice.down.toLocaleString('en-US')}} <a style="color: var(--main-red)">{{tradeto}}</a></a>
        </div>
        <div class="col-6 col-sm-4 col-md-2 pr-2 pr-md-0">
            <a>آخرین</a>
            <a>{{realprice.last.toLocaleString('en-US')}}<a>{{tradeto}}</a></a>
        </div>
        <div class="col-6 col-sm-4 col-md-2 pr-2 pr-md-0">
            <a>حجم</a>
            <a>{{realprice.vol.toLocaleString('en-US')}} <a>{{tradeto}}</a></a>
        </div>
        <div class="col-6 col-sm-4 col-md-1 pr-2 pr-md-0 order-first order-sm-last">
            <a>تغییرات</a>
            <a :style="{color: [realprice.change>0 ?'var(--main-green)':'var(--main-red)']}">٪ {{realprice.change.toFixed(1)}}</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TradeHeader",
        props: {
            realprice :{default : []},
            tradeto : {default :[]},
            tradefrom: {default :[]}
        }
    }
</script>

<style scoped>

    .box1 {
        font-family: VistaDev,sans-serif;
        background-color: var(--dark-background);
        direction: rtl;
        text-align: right;
        min-height: 95px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        color: white;
    }
    a{
        color: white;
    }
    .dark a{
        color: white;
    }

    .box1 div {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        /*align-items: center;*/
        /*align-content: center;*/
        justify-content: space-around;
        /*vertical-align: center;*/
        opacity: 0.8;
    }

    a{
        display: flex;
        align-items: center;
    }

    .box1 div a a {
        font-size: 12px;
        margin-right: 4%;
    }

</style>