<template>
    <div class="box2">
        <perfect-scrollbar id="container" :options="setting" @ps-y-reach-end="load" ref="scroll">
            <table>
                <tr class="table-header">
                    <th class="mobilehidden">نوع دستگاه</th>
                    <th class="mobilehidden">پلتفرم</th>
                    <th>مرورگر</th>
                    <th>آی‌پی</th>
                    <th>زمان</th>
                    <th>وضعیت</th>
                </tr>
                <tr v-for="(tableContent,page) in tableContents" :key="page"  class="table-row">
                    <td class="mobilehidden">{{tableContent.deviceType}}</td>
                    <td class="mobilehidden">{{tableContent.osType}}</td>
                    <td class="mobilehidden">{{tableContent.browser}}</td>
                    <td class="mobileshow">{{tableContent.browser}}</td>
                    <td>{{tableContent.ipAddress}}</td>
                    <td class="mobilehidden">{{$G2J(tableContent.loginRequestedDateTime,'faDateTime')}}</td>
                    <td class="mobileshow">{{$G2J(tableContent.loginRequestedDateTime,'faDate').slice(2)}}</td>
                    <td v-show="tableContent.isSuccessful" :style="{color:'var(--main-green)'}">موفق</td>
                    <td v-show="!tableContent.isSuccessful" :style="{color:'var(--main-red)'}">ناموفق</td>
                </tr>
            </table>
            <p style="text-align:center;color:red;opacity:0.8">{{error}}</p>
            </perfect-scrollbar>
            <!-- <button @click="check">clickkkk</button> -->
<!--        <pagination v-show="tableContents.length>16" style="margin-top: 30px" :n="4" :max="3" :page="id" :link="'History'"></pagination>-->
    </div>
</template>

<script>
    // import Pagination from "../Tools/Pagination";
    
    // import PerfectScrollbar from 'vue2-perfect-scrollbar'
    // const container = document.querySelector('#container');
    // const ps = new PerfectScrollbar(container);
    // console.log(ps)
    
    
    export default {
        name: "BuyTable",
        components: {},
       
        data() {
            return {
                buy: false,
                sell: false,
                login: true,
                error:'',
                page:0,
                lastPage:5,
                tableContents: [],
                loading: false,
                setting: {
                    suppressScrollX: true,
                    swipeEasing: false,
                    wheelPropagation: false,
                }
            }
        },
        methods: {
           
             check(){
                // console.log('fetch logins')
                if (this.page<this.lastPage){
                    this.page ++
                    // console.log(this.page)
                    this.$axios.get(`/users/login-history?size=10&page=${this.page}`)
                    .then(response =>{
                        this.lastPage = response.data.totalPages
                        // console.log('response',response.data)
                        this.tableContents = [...this.tableContents,...response.data.content]  
                    })
                    .catch(error=>{
                        console.log(error)
                    })
                }else if(this.page>=this.lastPage){
                    this.error = 'پیغام بیشتری جهت نمایش وجود ندارد'
                }
                // console.log(this.lastPage)
                
            },
            load() {
                // var vm = this;
                
                this.loading = true;
                if(this.page<this.lastPage){document.body.style.cursor = 'wait';}
                
                setTimeout(() => {
                    this.check()
                    document.body.style.cursor = 'default';
                    this.loading = false;
                }, 1000)
            }
        },
        mounted(){
            this.load()
        }

    }
</script>

<style scoped>
    .ps {
        height: 450px;
    }

    .mobilehidden {
        display: table-cell;
    }

    .mobileshow {
        display: none !important;
    }

    @media (max-width: 800px) {
        .mobilehidden {
            display: none !important;
        }

        .mobileshow {
            display: table-cell !important;
        }
    }
    @media (max-width: 360px) {
        .table-row td{
            font-size: 12px;
        }

    }

    .box2 {
        font-size: 16px;
        border: none;
        background-color: white;
        width: 100%;
        margin-bottom: 30px;
        position: relative;
    }

    table {
        background-color: white;
        width: 100%;
        /*min-width: 768px;*/
        /*height: 710px;*/
        border-collapse: collapse;
        position: relative;
    }

    table div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 50%;
        top: 30%;
        transform: translate(50%, -50%);
    }

    table div img {
        margin: 55px;
    }

    table th {
        height: 50px;
        font-weight: 300;
        /*opacity: 0.8;*/
        position: -webkit-sticky;
        position: sticky;
        background-color: var(--main-grey);
        top: 0;
        z-index: 1;
    }

    .table-header {
        /*position: -webkit-sticky;*/
        /*position: sticky;*/
        /*top: 0;*/
        text-align: center;
        font-size: 15px;
    }

    /*.table-header th:first-of-type {*/
    /*    text-align: right;*/
    /*    padding-right: 4%;*/
    /*}*/

    /*.table-header th:last-of-type {*/
    /*    text-align: left;*/
    /*    padding-left: 3%;*/
    /*}*/

    .table-row {
        position: relative;
        text-align: center;
        /*cursor: default;*/
        height: 35px;
        font-size: 14px;
    }

    .table-row:hover {
        background-color: var(--hover);
    }

    /*.table-row:nth-of-type(odd){*/
    /*    height: 40px;*/
    /*    background-color: var(--hover);*/
    /*}*/
    /*.table-row:nth-of-type(even){*/
    /*    height: 45px;*/
    /*    background-color: white;*/
    /*}*/

    /*.table-row:nth-of-type(odd) td:first-of-type{*/
    /*    height: 40px;*/
    /*}*/
    /*.table-row:nth-of-type(even) td:first-of-type{*/
    /*    height: 45px;*/
    /*}*/


    /*.table-row td:first-of-type {*/
    /*    padding-right: 10%;*/
    /*    text-align: right;*/
    /*    direction: rtl;*/
    /*    display: flex;*/
    /*    flex-direction: row;*/
    /*    align-items: center;*/
    /*}*/
    /**/
    /**/
    /*.table-row td:nth-of-type(7),*/
    /*.table-row td:nth-of-type(8){*/
    /*    padding-left: 3%;*/
    /*    text-align: left;*/
    /*direction: ltr;*/
    /*display: flex;*/
    /*flex-direction: row;*/
    /*align-items: center;*/
    /*margin-left: 10%;*/
    /**/
    /*}*/

    /*.table-row td:first-of-type a {*/
    /*    margin-right: 5px;*/
    /*}*/

    .table-row td {
        direction: ltr;
        opacity: 0.8;
    }

    .empty {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 50px;
    }

</style>