<template>
    <div>
        
        <Loading v-if="!state.gotten && state.token "/>
        <Loading v-else-if=" state.gotten && state.token && state.loading"/>
        <trade-nav-bar v-if="dashboard.indexOf(currentroute)>-1 "/>
        <nav-bar v-else-if="!blog && haventnavbar.indexOf(currentroute)=== -1"/>
        <blog-nav-bar v-if="blog"/>

        <div :style="{marginTop: [currentroute === 'Home'? 0 : [currentroute ==='Trade'? '50px':'']],padding:[currentroute === 'Home'? 0 : '']}"
             class="container1" >
            <img style="margin-top:50px;max-width:100px" @click.prevent="$router.push('/home')" v-if="haventnavbar.indexOf(currentroute)>-1" :src="img_path" alt="">
            <router-view :key="$route.params.to + '-' + $route.params.from"/>
        </div>
       
        <blog-footer v-if="blog"/>
        <Footer v-if="!blog"/>
       
    </div>
</template>

<script>
    import Footer from "@/components/Footer/Footer";
    import BlogNavBar from "@/components/Navbar/BlogNavbar";
    import NavBar from "@/components/Navbar/NavBar";
    import BlogFooter from "@/components/Footer/BlogFooter";
    import TradeNavBar from "@/components/Navbar/TradeNavBar";
    import Loading from './components/Tools/Loading';

    export default {
        name: 'App',
        data() {
            return {
                currentroute: '',
                blog: '',
                sh: '',
                blogs: ['BlogHome', 'PostIndex', 'PostShow'],
                trans: ['Auth', 'TwoStepGoogle', 'TwoStepGoogleAuth', 'TwoStepSms'],
                dashboard: ['Trade','History','Dashboard','Wallet','Orders','Profile','Messages','Setting','Invite'],
                havefooter: ['Home', 'AboutUs', 'FAQ', 'HowToUse', 'Policy', 'Wage'],
                haventnavbar : ['Login','Register','RecoveryPass','ResetPass','TwoStepSms','TwoStepGoogle',
                'TwoStepGoogleAuth','TwoStepEmail','VerifyingPage','RedirectFromBank'],
                img_path: require('./assets/logo1.svg')
            }
        },
        components: {TradeNavBar, BlogFooter, NavBar, BlogNavBar, Footer,Loading},
        watch: {
            $route(to) {
                this.currentroute = to.name
                this.RouteName()
            },
            
        },
        created() {
            if (localStorage.theme) {
                this.theme = localStorage.theme
            }
            this.currentroute = this.$route.name;
            this.RouteName()
            this.setRychatTheme()
        },
        methods: {
            check(){
                // console.log(this.state.userinfo.authorized);
            },
            RouteName() {
                if (this.blogs.indexOf(this.currentroute) > -1) {
                    this.blog = true
                } else {
                    this.blog = false
                }
            },
            setRychatTheme(){
                var cssLink = document.createElement("link");
                cssLink.href = "/rychatStyle.css";
                cssLink.rel = "stylesheet";
                cssLink.type = "text/css";
                cssLink.lang = "css";
                // window.addEventListener('load',function () {
                //     var iframe = document.getElementById('raychatFrame')
                //     iframe.contentDocument.head.appendChild(cssLink);
                // })
                window.addEventListener('raychat_ready', function (ets) {
                    ets.target.s.contentDocument.head.appendChild(cssLink)
                });
            }
        },
    }
</script>
<style scoped>
    /*.dark{*/
    /*    background-color: var(--dark-main) !important;*/
    /*    color: white !important;*/
    /*}*/
</style>
