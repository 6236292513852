<template>
    <div class="box-sub">
        <h2>تغییر رمز عبور</h2>
        <form class="row no-gutters" @input="checkPass">
            <div class="row no-gutters col-12 col-sm-4 p-sm-3 p-1" :class="{danger:passError.length}">
<!--                <label class="col-4 p-" for="oldpassword">رمز عبور فعلی</label>-->
                <input class="col" :style="{textAlign: [oldpassword? 'left':'right']}"
                       type="password" placeholder="رمز عبور"
                       v-model="oldpassword"
                       name="oldpassword"
                       id="oldpassword">
                <a>{{passError}}</a>
            </div>

            <div class="row no-gutters col-12 col-sm-4 p-sm-3 p-1" :class="{danger:pass1Error.length}">
<!--                <label class="col-4" for="newpassword1">رمز عبور جدید</label>-->
                <input class="col" :style="{textAlign: [newpassword1? 'left':'right']}"
                       type="password" placeholder="رمز عبور جدید"
                       v-model="newpassword1"
                       name="newpassword1"
                       id="newpassword1">
                <a>{{pass1Error}}</a>

            </div>
            <div class="row no-gutters col-12 col-sm-4 p-sm-3 p-1" :class="{danger:pass2Error.length}">
<!--                <label class="col-4" for="newpassword2">تکرار رمز عبور</label>-->
                <input class="col" :style="{textAlign: [newpassword2? 'left':'right']}"
                       type="password" placeholder="تکرار رمز عبور"
                       v-model="newpassword2"
                       name="newpassword2"
                       id="newpassword2">
                <a>{{pass2Error}}</a>
            </div>
        </form>
        <button @click="submit" type="submit" class="normal-btn" :disabled="pass1Error !== '' || pass2Error !== ''">ثبت تغییرات</button>
        <Loading v-if="showLoading"/>
    </div>
</template>

<script>
import Loading from '../Tools/Loading';
    export default {
        name: "ChangePassword",
        components:{Loading},
        data() {
            return {
                oldpassword: '',
                newpassword1: '',
                newpassword2: '',
                passError: '',
                pass1Error: '',
                pass2Error: '',
                showLoading:false
            }
        },

        methods: {
            submit(){
                this.showLoading = true
                let passSent = {
                    oldPassword:`${this.oldpassword}`,
                    password:`${this.newpassword1}`
                }
                this.$axios.post(`/users/change-password`,passSent)
                    .then(response=>{
                        this.showLoading = false
                        // console.log(response.data.message)
                        if (response.data.message === "password changed successfully"){
                            this.oldpassword= '',
                            this.newpassword1= '',
                            this.newpassword2= '',
                            this.passError= '',
                            this.pass1Error= '',
                            this.pass2Error= '',
                            this.$error('رمز شما تغییر یافت.', '', 'success')
                        }
                    })
                    .catch(error=>{
                        this.showLoading = false
                        // console.log('come on',error.response.data.message)
                        if (error.response.data.message === 'Old Password Is Incorrect'){
                            this.passError = 'رمز قبلی اشتباه است.'
                        }
                        
                    })
            },
            
            checkPass(e) {
                
                if (this.newpassword1) {
                   
                    if (this.newpassword1 === this.oldpassword){
                        
                        this.pass1Error = 'رمز جدید باید با رمز قبل متفاوت باشد';
                        this.pass2Error = '';
                    }else{
                        if (this.newpassword1.length < 8) {
                        this.pass1Error = 'حداقل 8 کاراکتر';
                    } else if (!this.validPass(this.newpassword1) ) {
                        this.pass1Error = 'ترکیبی از حروف کوچک و بزرگ و عدد';
                    } else if (!this.newpassword2) {
                        this.pass1Error = ''
                        this.pass2Error = 'تکرار رمز اجباری';
                    } else if (this.newpassword2 !== this.newpassword1) {
                        this.pass1Error = ''
                        this.pass2Error = 'تکرار رمز مطابقت ندارد';
                    } else {
                        this.pass2Error = ''
                    }
                    }
                    
                }
                if (!this.passError.length || !this.pass1Error.length || !this.pass2Error.length) {
                    return true;
                }

                e.preventDefault();
            },
            validPass(password) {
                var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
                return re.test(password);
            }
        }
    }
</script>

<style scoped>
    .box-sub {
        font-family: IRANSans-Bold,sans-serif;
        display: block;
        background-color: white;
        text-align: right;
        border: #00000015 solid 0.5px;
        padding: 10px 15px;
        margin: 20px 0;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        font-size: 17px;
        position: relative;
    }
    h2{
        font-size: 17px;
        padding: 10px 0;
        margin: 0 1rem;
        border-bottom: 1px solid var(--light-border);
    }

    form {
        font-family: IRANSans,sans-serif;
        text-align: left;
        margin: 20px 0 50px;
        border-radius: 5px;
        width: 100%;
        font-size: 14px;
    }

    .error {
        color: var(--main-red);
        transition: 0.5s;
    }

    form label {
        text-align: right;
        width: 100%;
        min-width: 80px;
    }

    form div {
        position: relative;
        box-sizing: inherit;
        /*width: 29.5%;*/
        margin: 10px 0;
    }

    .box-sub input {
        position: relative;
        border: solid rgba(54, 52, 53, 0.15) 0.5px;
        /*display: inline-block;*/
        /*width: 70%;*/
        min-width: 165px;
        height: 50px;
        border-radius: 5px;
        padding: 2px 5px;
        text-align: left;
        direction: ltr;
    }

    .box-sub input:hover {
        border: solid rgba(54, 52, 53, 0.3) 0.5px;
    }

    .box-sub input:focus {
        border: solid rgba(54, 52, 53, 0.5) 0.5px;
    }

    .danger input {
        border: solid var(--main-red) 1px;
    }

    .danger input:hover {
        border: solid var(--main-red) 1px;
    }

    .danger input:focus {
        border: solid var(--main-red) 1px;
    }

    .danger a {
        color: var(--main-red);
        position: absolute;
        bottom: -10px;
        right: 10px;
        font-size: 12px;
    }

    .danger label {
        color: var(--main-red);
    }

    .normal-btn {
        position: absolute;
        left: 31px;
        bottom: 10px;
        color: white;
        font-size: 14px;
        max-width: calc(33.33% - 32px - 10px);
        /*min-width: 137px;*/
        margin: 0;
        border-radius: 5px;
        /*max-width: 33%;*/
        /*padding: 20px;*/
    }

    .main-toast{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        /* backdrop-filter: blur(3px); */
        margin-right: -40px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
    }

    .toast{
        width: 350px;
        height: 100px;
        background: #fff;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        box-shadow: 2px 2px 2px 2px #efebeb;
        overflow: hidden;
    }

    .toast>span{
        display: inline-block;
        position: absolute;
        bottom: 0;
        right: 14px;
        background: rgb(152, 194, 90);
        width: 0;
        height: 3px;
        border-radius: 50px;
    }
</style>