<template>
    <transition name="modal-fade">
        <div id="modal" class="modal-backdrop" @click.self="close">
            <div
                    class="modal"
                    role="dialog"
                    aria-labelledby="WithdrawModal"
                    aria-describedby="WithdrawModal"
            >
                <img
                        src="../../assets/icon/close.svg"
                        class="btn-close"
                        @click="close"
                        aria-label="Close modal"
                        alt=""
                />
                <img/>
                <LoadingModal v-if="showloading"/>
                <header class="modal-header">درخواست {{type}}</header>
                <section class="modal-body" style="direction: rtl">
                    <a class="row justify-content-center" style="font-size: 16px">
                        آیا از ثبت درخواست
                        <a style="font-weight: bold; margin: 0 3px">{{type}}</a>
                        به شرح زیر مطمئن هستید؟
                    </a>
                    <div>
                        <a>قیمت واحد <a style="font-size: 10px">(تومان)</a></a>
                        <a v-if="!datetime" style="font-weight: bold; font-size: 14px">{{$toLocal(unitprice)}}</a>
                        <a v-else style="font-weight: bold; font-size: 14px">{{$toLocal(unitprice)}}</a>
                    </div>
                    <div>
                        <a>مقدار
                            {{$coinLabel[$coinArray[$route.params.tradefromid]]}}
                        </a>
                        <a style="font-weight: bold; font-size: 14px">
                            {{$toLocal(amount) }}
                        </a>
                    </div>
                    <div>
                        <a>قیمت کل <a style="font-size: 10px">(تومان)</a></a>
                        <a style="font-weight: bold; font-size: 14px">
                            {{$toLocal(totalprice)}}
                        </a>
                    </div>
                </section>
                <footer class="modal-btn">
                    <button type="button" class="normal-btn" @click="accept">بله</button>
                    <button type="button" class="normal-btn" @click="close">خیر</button>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
    import LoadingModal from "../LoadingModal";

    export default {
        name: "ShopModal",
        components: {LoadingModal},
        props: [
            "totalprice",
            "unitprice",
            "amount",
            "type",
            "tradefrom",
            "tradeto",
            "limit",
            "datetime",
        ],
        data() {
            return {
                twostepcode: "",
                walletaddress: "",
                showloading: false,
                tradetoEng: {'تومان': "TOMAN", 'تتر': "TETHER"},
            };
        },
        methods: {
            close() {
                this.$emit("close");
            },
            accept() {
                this.showloading = true;
                let ordertype;
                let orderDetail;

                if (this.type === "خرید") {
                    ordertype = "BUY";
                } else {
                    ordertype = "SELL";
                }

                let dateTimeFinal;
                if (this.datetime) {
                    dateTimeFinal = this.datetime;
                } else {
                    dateTimeFinal = null;
                }

                if (this.state.islimited) {
                    orderDetail = {
                        marketType: this.$coinArray[this.$route.params.tradefromid] + '_' + this.$toLabel[this.$route.params.tradetoid],
                        amount: parseFloat(this.amount.replace(/,/gi, "")),
                        orderType: `LIMITED_${ordertype}`,
                        expiresAt: dateTimeFinal,
                        unitPrice: parseFloat(this.unitprice.replace(/,/gi, "")),
                        wholePrice: parseFloat(this.amount.replace(/,/gi, "")) * parseFloat(this.unitprice.replace(/,/gi, "")),
                    }
                } else {
                    orderDetail = {
                        marketType: this.$coinArray[this.$route.params.tradefromid] + '_' + this.$toLabel[this.$route.params.tradetoid],
                        amount: parseFloat(this.amount.replace(/,/gi, "")),
                        orderType: `MARKET_${ordertype}`,
                    };
                }
                this.$axios
                    .post(`/orders/${ordertype.toLowerCase()}`, orderDetail)
                    .then(() => {
                        this.showloading = false;
                        this.$emit("accept", "come and get");
                    })
                    .catch((error) => {
                        this.showloading = false;
                        if (
                            error.response.data.message ===
                            "the amount of order is bigger than market liquidity"
                        ) {
                            this.$emit("close", "big mount");
                        } else {
                            this.$emit("close", "");
                        }
                    })
            },
        },
    };
</script>

<style scoped>
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
    }

    .modal {
        position: relative;
        background: var(--account-back);
        border-radius: 5px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 950px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        /*padding: 10px 10px;*/
        max-width: 450px;
    }

    .modal-header {
        color: white;
        font-size: 20px;
        height: 70px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10%;
    }

    .btn-close {
        position: absolute;
        left: 15px;
        top: 15px;
        height: 22px;
        width: 22px;
        transition: 0.5s;
        cursor: pointer;
        z-index: 5;
    }

    .btn-close:hover {
        transform: rotate(180deg);
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        /*margin-top: 20px;*/
        position: relative;
        background-color: white;
        /*border-top-right-radius: 5px;*/
        /*border-top-left-radius: 5px;*/
        padding: 20px 30px 20px;
    }

    .modal-body div {
        width: 100%;
        max-width: 327px;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .modal-body div:first-of-type {
        margin-top: 15px;
    }

    .modal-fade-enter,
    .modal-fade-leave-active {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity 0.25s ease;
    }

    .modal-btn {
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: white;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .normal-btn {
        font-size: 16px;
        color: white;
        max-width: 150px;
        margin: 0 15px;
        border-radius: 5px;
        height: 40px;
        padding: 0;
    }
</style>
