<template>
    <div class="Auth-box">
        <div class="Auth-header">
            <h3>شناسایی دو عاملی</h3>
        </div>
        <div class="Auth">
            <p>کد تایید دومرحله ای که برنامه Google Authenticator تولید کرده است را وارد نمایید.</p>
                 <!-- <CodeInput class="auth-row"
                       @change="onChange" @complete="submit"
                       :fields="num" ref="code-input" :fieldWidth="45" :fieldHeight="45"/> -->
            <input class="auth-row new-input" type="text" v-model="code" maxlength="6">
        
            <div class="footer">
            </div>
        </div>
        <button class="normal-btn" @click.prevent="submit" :disabled="code.toString().length < num">تایید
        </button>
        <a style="opacity: 0.8">{{error}}</a>
    </div>
</template>

<script>
// import CodeInput from "vue-verification-code-input";
    export default {
        name: "TwoStepGoogleModal",
        data() {
            return {
                
                smscode: [],
                error: '',
                num: 6,
                code: ''
            }
        },
        components: {
            // CodeInput
        },
        methods: {
            
            // setPlaceholder() {
            //     for(let i=0;i<this.num;i++){
            //         this.$refs['code-input'].$refs['input_'+i][0].placeholder = '----'
            //     }
            // },
            // onChange(e) {
            //     this.code = e
            // },
            // next(e) {
            //     this.sortNumber()
            //     if (this.smscode[e - 1]) {
            //         if (e < this.num) {
            //             document.getElementById('auth' + e).focus();
            //         }
            //     } else {
            //         if (e > 1) {
            //             document.getElementById('auth' + (e - 2)).focus();
            //         }
            //     }
            // },
            // sortNumber() {
            //     // if (this.smscode.length === this.num) {
            //     var b = ''
            //     for (let i = 0; i < this.smscode.length; i++) {
            //         b = b + this.smscode[i]
            //     }
            //     this.code = Number(b)
            //     // }
            // },
            submit() {
                this.showModalLoading = true
                this.state.transaction.code = this.code
                console.log(this.state.transaction)
                this.$axios.post(`/wallets/withdrawal-request`,this.state.transaction)
                    .then(response => {
                    console.log('res data from email modal',response)
                    if (response.data.message === "withdrawal request created successfully"){
                        this.$emit('submited','success')
                    }
                    })
                .catch(error => {
                    if(error.response){
                        this.showModalLoading = false
                        console.log('my error')
                        console.log(error)
                        console.log(error.response.data.message,'this is response error')
                        if (error.response.data.message === 'Code Expired'){
                            this.error = 'کد شما منقضی شده است.'
                        } else if(error.response.data.message === 'Otp code is not valid.'){
                            this.error = 'کد شما اشتباه است.'
                        }
                    }
                    });
            }
        },
        mounted() {
            // this.setPlaceholder()
        }
    }
</script>

<style lang="scss" scoped>

    input {
        padding: 5px 0;
    }

    .Auth-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 400px;
        height: 100%;
    }
    .Auth{
        margin-bottom:  70px;
    }

    .countdown {
        font-size: 14px;
        text-decoration: underline;
    }

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
    }

    .auth-row {
        display: flex;
        direction: ltr;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 45px;
        margin-top: 10px;
        border: 1px solid var(--light-border);
        border-radius: 15px;
    }

    .Auth-input {
        font-size: 25px;
        border-radius: 5px;
        /*border: solid 1px #00000010;*/
        width: 40px;
        height: 100%;
        text-align: center;
        letter-spacing: -2px;
        /*padding: 0;*/

        &:focus {
            border-color: var(--main-orange);
        }
    }

    p {
        margin: 20px 0 20px;
        text-align: right;
    }

    .normal-btn {
        margin-bottom: 80px;
    }
</style>
<style lang="scss">
    .auth-row{
        width: 100% !important;
        .react-code-input{
            border: none;
            input{
                border: none !important;
                color: var(--font-color3);
                font-family: 'VistaDev',sans-serif;
                font-size: 25px;
                letter-spacing: -2px;
                text-align: center !important;
            }
        }
    }
</style>