<template>
    <div class="home">
        <div class="site-header">
            <div class="box">
                <div class="header-detail">
                    <h1>درباره ما</h1>
                    <a>لورم ایپسوم یا طرح‌نما به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته
                        می‌شود.</a>
                </div>
                <div class="site-details">
                    <div class="details">
                        <h3>تعداد کاربران</h3>
                        <p>{{users.toLocaleString()}}</p>
                    </div>
                    <div class="details">
                        <h3>ارزش معاملات(تومان)</h3>
                        <p>{{value.toLocaleString()}}</p>
                    </div>
                    <div class="details">
                        <h3>تعداد کل معاملات</h3>
                        <p>{{trades.toLocaleString()}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="box">
            <p>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و
                متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و
                کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده
                شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی
                الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و
                دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای
                اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
            </p>
            <p>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و
                متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و
                کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده
                شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی
                الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و
                دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای
                اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
            </p>
        </div>

    </div>
</template>

<script>

    export default {
        name: "AboutUs",
        data() {
            return {
                users: 18599,
                trades: 560245,
                value: 73560248,
            }
        }
    }
</script>

<style scoped>

    .home {
        margin-top: -20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .box {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1215px;
        padding: 0 10px;
        color: white;

    }
    .box:nth-of-type(n+1){
        margin: 50px
    }

    .header-detail {
        margin-top: 100px;
    }

    .box h1, h2 {
        margin: 0;
    }

    h1 {
        font-size: 30px;
        margin-top: 100px !important;
        margin-bottom: 10px;
    }

    .box a {
        color: white;
        font-size: 18px;
        font-weight: 500;
    }
    .box p{
        color: black;
    }

    .site-header {
        font-family: VistaDev;
        /*background-color: #202020;*/
        background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url("../../assets/header.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-blend-mode: normal;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        /*height: 695px;*/
    }

    .site-details{
        width: 100%;
        max-width: 1210px;
        min-height: 230px;
        background-color: var(--btn-back);
        border-radius: 10px;
        border: 3px solid var(--main-green);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 50px 0;

    }
    .site-details .details{
        width: 33.33333%;
    }
    .site-details .details:nth-of-type(2){
        border-left: 3px solid var(--main-green);
        border-right: 3px solid var(--main-green);
    }
    .site-details .details h3{
        color: var(--main-green);
        font-size: 22px;
        margin-top: 0;
    }
    .site-details .details p{
        color: var(--main-orange);
        font-size: 22px;
        margin-bottom: 0;
    }
    @media (max-width: 768px) {
        .site-details{
            flex-direction: column;
        }
        .site-details .details{
            width: max-content;
            padding: 20px 0;
        }
        .site-details .details:nth-of-type(2){
            border:none;
            border-top: 3px solid var(--main-green);
            border-bottom: 3px solid var(--main-green);
        }

    }

</style>