<template>
        <div class="related-main-box">
            <div @click="$router.push('/blog/show/1')" v-for="(post,index) in posts" :key="index" class="related-box">
                <div class="img" :style="{backgroundImage : 'url(' + post.image +')'}"></div>
                <div class="body">
                    <div class="main">
                        <h2>{{post.header}}</h2>
<!--                        <p> {{post.description}}</p>-->
                    </div>
                    <div class="footer">
                        <div>
                            <!--                            <svg width="20px" fill="#000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"-->
                            <!--                                 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;margin-left: 5px" xml:space="preserve">-->
                            <!--                                    <path d="M347.216,301.211l-71.387-53.54V138.609c0-10.966-8.864-19.83-19.83-19.83c-10.966,0-19.83,8.864-19.83,19.83v118.978-->
                            <!--			c0,6.246,2.935,12.136,7.932,15.864l79.318,59.489c3.569,2.677,7.734,3.966,11.878,3.966c6.048,0,11.997-2.717,15.884-7.952-->
                            <!--			C357.766,320.208,355.981,307.775,347.216,301.211z"/>-->
                            <!--                                <path d="M256,0C114.833,0,0,114.833,0,256s114.833,256,256,256s256-114.833,256-256S397.167,0,256,0z M256,472.341-->
                            <!--			c-119.275,0-216.341-97.066-216.341-216.341S136.725,39.659,256,39.659c119.295,0,216.341,97.066,216.341,216.341-->
                            <!--			S375.275,472.341,256,472.341z"/>-->
                            <!--                                </svg>-->
                            {{post.time}} دقیقه
                        </div>
                        <div>{{post.date}}</div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        name: "RelatedPosts",
        props:['posts']
    }
</script>

<style scoped>
    h2{
        font-size: 30px;
    }
    .related-main-box{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .related-box {
        width: calc(33.3333% - 4%);
        text-align: right;
        /*background-color: white;*/
        /*border-radius: 5px;*/
        /*border: 1px solid var(--light-border);*/
        cursor: pointer;
        display: flex;
        flex-direction: column;
        min-height: 150px;
        margin: 25px 2%;
        transition: 0.3s;
    }
    .body .related-box{
        width: calc(50% - 4%);
    }
    @media (max-width: 768px){
        .related-main-box{
            display: flex;
            flex-direction: column;
        }
        .related-box {
            width: 96%;
        }
        .body .related-box{
            width: 96%;
        }
    }

    .related-box .img {
        /*border-radius: 5px;*/
        min-height: 234px;
        /*width: 400px !important;*/
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .related-box:hover {
        /*border-color: var(--main-orange);*/
        /*border-width: 3px;*/
        box-shadow: 0 0 40px -5px #00000050;
    }

    .related-box .body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        opacity: 0.8;
        padding: 10px 10px 25px 10px;
        position: relative;
        height: 100%;
    }

    .related-box .body h2 {
        font-size: 16px;
        margin: 5px 0;
    }

    .related-box .body p {
        font-size: 14px;
        margin: 5px 0;
    }

    .related-box .body .footer {
        font-size: 14px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 5px;
        width: calc(100% - 20px);
    }

    .related-box .body .footer div {
        /*height: 25px;*/
        display: flex;
        flex-direction: row;
        align-items: center;
    }

</style>