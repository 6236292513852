<template>
    <div class="main">
        <div class="site-header">
            <div class="site-header1">
                <h2>خرید و فروش ارزهای دیجیتال</h2>
                <p>امن‌ترین مکان برای خرید، فروش و مدیریت رمز ارزهای شما</p>
                <a href="/register" target="_self">همین الان شروع کنید</a>
            </div>
            <div class="site-header2">
                <div class="tab-main">
                    <div class="tab" :class="{selectedtab : selected===1}" @click.prevent="switchtab(1)">تومان</div>
                    <div class="tab" :class="{selectedtab : selected===2}" @click.prevent="switchtab(2)">تتر</div>
                </div>
                <trade-abstract :tag="id"/>
            </div>
        </div>
        <div class="site-benefit row no-gutters">
            <div class="col-12 col-sm-6 col-lg-3 p-2">
                <div class="benefit-block">
                    <img src="../../assets/shield.svg" alt="">
                    <h3>امنیت معاملات</h3>
                    <p>با خرید بیت کوین یا واریز بیت کوین و سایر ارزهای دیجیتال دارایی شما به صورت سخت افزاری
                        Cold-Storage
                        ذخیره می‌شود. به همین جهت امکان سرقت و یا از بین رفتن آن وجود ندارد.</p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 p-2">
                <div class="benefit-block">
                    <img src="../../assets/data.svg" alt="">
                    <h3>ابزار تحلیل</h3>
                    <p>این امکان را برای افراد حرفه ای ایجاد شده است که برای خرید و فروش بیت کوین و سایر ارزهای دیجیتال
                        بتوانند از نمودارهای شمعی برای تحلیل و آنالیز بازار استفاده کنند.</p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 p-2">
                <div class="benefit-block">
                    <img src="../../assets/cryptocurrency.svg" alt="">
                    <h3>تنوع کوین ها</h3>
                    <p>ما در ابتدای راه به پوشش ارزهای های شاخص و پرتراکنش مانند بیت کوین، اتریوم و ... پرداخته ایم.این
                        توانایی وجود دارد که در آینده، هر ارز دیگری را پشتیبانی شود.</p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 p-2">
                <div class="benefit-block">
                    <img src="../../assets/wallet.svg" alt="">
                    <h3>کیف پول</h3>
                    <p>کیف پول اختصاصی و امن خواهید داشت که می‌توانید بدون نگرانی، دارایی‌های رمزارزیتان را در آن
                        نگه‌داری نمایید. کلید خصوصی شما به صورت آفلاین نگه‌داری می‌شود و در برابر هک ایمن خواهد بود.</p>
                </div>
            </div>
        </div>
        <div class="top-comments">
            <carousel :autoplay="true"
                      :loop="true"
                      :paginationActiveColor="'var(--navbar-back)'"
                      :paginationColor="'var(--main-grey)'"
                      :paginationPadding="5"
                      :paginationSize="15"
                      :per-page-custom=" [[0, 1], [576, 2],[992, 3]]"
                      :scroll-per-page="false">
                <slide v-for="n in 7" :key="n">
                    <div class="pad">
                        <div class="top-comments-block">
                            <img src="../../assets/Avatar.svg" alt="">
                            <p>با خرید بیت کوین یا واریز بیت کوین و سایر ارزهای دیجیتال دارایی شما به صورت سخت افزاری
                                Cold-Storage
                                ذخیره می‌شود. به همین جهت امکان سرقت و یا از بین رفتن آن وجود ندارد.</p>
                        </div>
                    </div>
                </slide>
            </carousel>
        </div>
        <div class="site-details">
            <!-- <button @click="check">click</button> -->
            <div class="details">
                <h3>تعداد کاربران</h3>
                <p>{{users.toLocaleString()}}</p>
            </div>
            <div class="details">
                <h3>ارزش معاملات(تومان)</h3>
                <p>{{value.toLocaleString()}}</p>
            </div>
            <div class="details">
                <h3>تعداد کل معاملات</h3>
                <p>{{trades.toLocaleString()}}</p>
            </div>
        </div>
        <div class="site-help">
            <div class="img">
                <img src="../../assets/Group 34.svg" alt="">
                <p>ثبت نام</p>
                <p>احراز هویت</p>
                <p>افزایش موجودی</p>
                <p>مبادله</p>
                <!--                <img width="18%" src="../../assets/help/left-arrow.svg" class="help-arrow">-->
                <!--                <img width="18%" src="../../assets/help/left-arrow.svg" class="help-arrow">-->
                <!--                <img width="18%" src="../../assets/help/left-arrow.svg" class="help-arrow">-->
            </div>
        </div>
        <div class="site-blog">
            <carousel :autoplay="true"
                      :loop="true"
                      :paginationActiveColor="'#292929'"
                      :paginationColor="'var(--main-grey)'"
                      :paginationPadding="5"
                      :paginationSize="15"
                      :per-page-custom=" [[0, 1], [576, 2],[992, 3]]"
                      :scroll-per-page="false"
                      style="width:100%;max-width: 1210px">
                <slide v-for="(post,index) in posts" :key="index">
                    <div class="pad">
                        <div @click.prevent="goToLink(post.link)" :title="post.title.rendered" class="blog-block" :style="{backgroundImage : 'url('+post.better_featured_image.source_url+')'}">
                            <!--                            <img src="../../assets/blog/blog-gallery.jpg" alt="">-->
                            <a>
                                <p style="min-width: 100px;font-size:14px">{{$G2J(post.date,'faBlog')}}</p>
                                <p :title="post.title.rendered" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{post.title.rendered}}</p>
                            </a>
                        </div>
                    </div>
                </slide>
            </carousel>
        </div>
    </div>
</template>

<script>
    import TradeAbstract from "@/components/Home/TradeAbstract";

    export default {
        name: "Home",
        components: {TradeAbstract},
        data() {
            return {
                selected: 1,
                id: 1,
                users: 0,
                trades: 0,
                value: 0,
                blog_img: require('../../assets/blog/blog-gallery.jpg'),
                posts:[
                    {
                        link:'',
                        title:{rendered:''},
                        date:'',
                        better_featured_image:{source_url:''}
                    }
                ]
            }
        },
        mounted(){
            this.info()
            this.getBlogInfo()
        },
        methods: {
            goToLink(link){
                // console.log(link);
                window.location.href = link
            },
            getBlogInfo(){
                this.$axios('https://whalebitex.com/blog/wp-json/wp/v2/posts?per_page=8&context=embed&page=1')
                    .then(res=>{
                        // console.log(res.data);
                        this.posts = res.data
                    })
                    // .catch(err=>{
                    //     console.log(err);
                    // })
            },
            info(){
                console.log('clicked user:')
                this.$axios.get('trades/market-info-home')
                    .then(res=>{
                        console.log(res.data.baseDTO)
                        // console.log(JSON.parse(res.data))
                        this.users = res.data.baseDTO.userCount
                        this.value = res.data.baseDTO.tradesValue
                        this.trades = res.data.baseDTO.tradesAmount
                    })

                    .catch(error=>{
                        console.log(error)
                    })
            },
            switchtab(e) {
                this.selected = e
                this.id = e
            }
        }
    }
</script>

<style scoped>
    .main {
        /*margin-top: 56px;*/
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        /*justify-content: space-between;*/
        padding: 100px 20px 0px;
        background-image: url("../../assets/Ellipse 36.svg");
        background-color: #343a40;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
    }

    .site-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 1250px;
        margin: 25px 0;
    }

    .site-header1 {
        text-align: right;
    }

    .site-header1 h2 {
        font-size: 22px;
        color: var(--main-green);
    }

    .site-header1 p {
        font-size: 20px;
        color: white;
    }

    .site-header1 a {
        display: block;
        text-decoration: none;
        font-size: 22px;
        font-weight: 700;
        text-align: center;
        color: white;
        border-radius: 10px;
        background-color: var(--btn-back);
        border: 3px solid var(--main-green);
        padding: 10px 0;
        width: 100%;
        max-width: 340px;
    }

    .site-header2 {
        width: 100%;
        max-width: 504px;
        border-radius: 5px;
    }

    @media (max-width: 992px) {
        .site-header{
            flex-direction: column;
        }
        .site-header1{
            width: 100%;
        }
        .site-header2{
            max-width: none;
            margin: 30px 0;
        }

    }

    .site-header2 .tab-main {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 30px;
    }

    .site-header2 .tab {
        background-color: white;
        height: 100%;
        width: 100px;
        transition: 0.2s;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        cursor: pointer;
    }

    .site-header2 .tab:first-of-type {
        border-top-right-radius: 5px;
    }

    .site-header2 .tab:last-of-type {
        border-top-left-radius: 5px;
    }

    .site-header2 .selectedtab {
        background-color: var(--main-grey);
        width: 100px;
        cursor: default;
    }

    .site-benefit {
        font-size: 14px;
        width: 100%;
        max-width: 1250px;
        /*padding: 0 20px;*/
        color: white;
        margin: 25px 0;
    }

    .site-benefit .benefit-block {
        height: 100%;
        min-height: 280px;
        width: 100%;
        /*max-width: 280px;*/
        background-color: var(--navbar-back);
        border-radius: 5px;
        padding: 15px;
    }

    .site-benefit .benefit-block h3 {
        margin: 5px;
    }

    .site-benefit .benefit-block p {
        margin: 5px;
        text-align: justify;
    }

    .top-comments {
        width: 100%;
        max-width: 1250px;
        /*padding: 0 20px;*/
        direction: ltr;
        margin: 25px 0;
    }

    .top-comments-block {
        background-color: var(--navbar-back);
        border-radius: 5px;
        position: relative;
        color: white;
        height: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 24px;
        padding: 0 10%;
    }
    .top-comments .pad{
        padding: 0 20px;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    }
    .top-comments-block img{
        position: absolute;
        top:0;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .site-details{
        width: 100%;
        max-width: 1210px;
        min-height: 230px;
        background-color: var(--btn-back);
        border-radius: 10px;
        border: 3px solid var(--main-green);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 25px 0;

    }
    .site-details .details{
        width: 33.33333%;
    }
    .site-details .details:nth-of-type(2){
        border-left: 3px solid var(--main-green);
        border-right: 3px solid var(--main-green);
    }
    .site-details .details h3{
        color: var(--main-green);
        font-size: 22px;
        margin-top: 0;
    }
    .site-details .details p{
        color: var(--main-orange);
        font-size: 22px;
        margin-bottom: 0;
    }
    @media (max-width: 768px) {
        .site-details{
            flex-direction: column;
        }
        .site-details .details{
            width: max-content;
            padding: 20px 0;
        }
        .site-details .details:nth-of-type(2){
            border:none;
            border-top: 3px solid var(--main-green);
            border-bottom: 3px solid var(--main-green);
        }

    }

    .site-help{
        width: 100%;
        max-width: 400px;
        position: relative;
        /*height: 400px;*/
        margin: 25px 0;
    }

    .site-help .img{
        position: relative;
        width: 100%;
        height: 100%;
        max-height: 368px;
    }
    .site-help .img img{
        /*position: absolute;*/
        width: 100%;
        left:0;
        top:0;
    }

    @media (min-width: 769px) {
        .site-help .img p{
            position: absolute;
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            color: white;
            padding: 0 10px;
            font-size: 18px;
        }

        .site-help .img p:first-of-type{
            top:0;
            right:0;
            transform: translateX(100%);
        }
        .site-help .img p:nth-of-type(2){
            top:0;
            left:0;
            transform: translateX(-100%);
        }
        .site-help .img p:nth-of-type(3){
            bottom:0;
            left:0;
            transform: translateX(-100%);
        }
        .site-help .img p:nth-of-type(4){
            bottom:0;
            right:0;
            transform: translateX(100%);
        }
    }

    @media (max-width: 768px) {
        .site-help .img{
            margin: 30px 0;
        }
        .site-help .img p{
            position: absolute;
            width: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: 10px 0;
            color: white;
            font-size: 12px;
        }
        .site-help .img p:first-of-type{
            top:0;
            right:0;
            transform: translateY(-100%);
            /*margin-right: 7px;*/
        }
        .site-help .img p:nth-of-type(2){
            top:0;
            left:0;
            transform: translateY(-100%);
        }
        .site-help .img p:nth-of-type(3){
            bottom:0;
            left:0;
            transform: translateY(100%);
            /*margin-left: 6px;*/
        }
        .site-help .img p:nth-of-type(4){
            bottom:0;
            right:0;
            transform: translateY(100%);
        }
    }

    .site-blog{
        direction: ltr;
        background-color: var(--btn-back);
        width: calc(100% + 40px);
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
    }
    .site-blog .pad{
        height: 180px;
        padding: 0 10%;
        margin: 50px 0;
    }
    .site-blog .blog-block{
        height: 100%;
        border-radius: 15px;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .site-blog .blog-block img{
        width: 100%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
    }
    .site-blog .blog-block a{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 0 0 15px 15px;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .site-blog .blog-block a p{
        direction: rtl;
        margin: 5px 5px;
    }


</style>