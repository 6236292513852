<template>
    <div class="main-box"> 
            <table>
                <tr class="table-header">
                    <th>نوع ارز</th>
                    <th>موجودی</th>
                    <th class="mobilehidden">ارزش</th>
                    <th>عملیات</th>
                </tr>
                <tr v-for="(wallet,index) in myWalletData" :key="index" class="table-row">
                    <td>
                        <img height="30px" :src="require('../../assets/icon/'+wallet.relatedCoin+'.png')" alt="WalletIcon">
                        <a>{{wallet.name}}</a>
                    </td>
                    <td class="mobilehidden" v-if="wallet.name!== 'ریال'">{{wallet.credit ? wallet.credit.toLocaleString() : 0}} {{$coinUnit[wallet.relatedCoin]}}</td>
                    <td class="mobilehidden" v-else>RIAL {{wallet.totalBalance ? (wallet.totalBalance*10).toLocaleString() : 0}}</td>
                    <td v-if="wallet.name!== 'ریال'">{{wallet.wholePriceOfCredit ? (wallet.wholePriceOfCredit).toLocaleString() : 0}} تومان</td>
                    <td v-else>{{wallet.totalBalance ? (wallet.totalBalance).toLocaleString() : 0}} تومان</td>
                    <td>
                        <button @click="$emit('deposit', index )">واریز</button>
                        <button @click="$emit('withdraw', index )">برداشت</button>
                    </td>
                </tr>
            </table>
    </div>
</template>

<script>

    export default {
        name: "MyWallet",
        props:['myWalletData'],
        data() {
            return {
                wallets: [],
                
            }
        },
        methods: {
            
            withdraw(e) {
                this.$emit('withdraw', e)
            },
            trade(e) {
                this.$emit('trade', e)
            }
        }
    }
</script>

<style scoped>

    .main-box {
        font-size: 16px;
        background-color: white;
        width: 100%;
        margin-bottom: 30px;
        overflow: auto;
    }

    h1 {
        font-size: 30px;
        margin-bottom: 40px;
        margin-top: 10px;
    }

    table {
        background-color: white;
        width: 100%;
        /*min-width: 550px;*/
        /*height: 100%;*/
        border-collapse: collapse;
    }

    table th {
        height: 50px;
        font-weight: 300;
        /*opacity: 0.8;*/
        background-color: var(--main-grey);
        text-align: center;
    }

    .table-row {
        /*height: ;*/
        font-size: 14px;
    }

    .table-header th:first-of-type,
    .table-row td:first-of-type {
        text-align: right;
        direction: rtl;
        margin-top: 10px;
        padding-right: 1.5%;
        min-width: 120px;
    }

    .table-row td:first-of-type img,
    .table-row td:first-of-type a {
        vertical-align: middle;
    }

    .table-header th:last-of-type {
        padding-left: 100px;
    }
    @media (max-width: 580px) {
        .table-header th:last-of-type {
            padding-left: 20px;
        }
        button{
            padding-right:0 !important;
        }
    }


    .table-header th:last-of-type,
    .table-row td:last-of-type {
        text-align: end;
    }

    .table-row td:first-of-type a {
        margin-right: 10px;
    }

    .table-row td:last-of-type {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        opacity: 0.8;
    }

    .table-row {
        text-align: center;
        cursor: default;
    }


    button {
        width: calc(100% - 10px);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25px;
        border-radius: 5px;
        max-width: 100px;
        background-color: var(--main-green);
        /*padding: 0 20px;*/
        margin: 10px;
        cursor: pointer;
        color: white;
        /*color: var(--font-color);*/
    }
    button:last-of-type{
        background-color: var(--main-red);
    }

    button:disabled{
        opacity: 0.5;
    }

@media (max-width: 768px) {
        .mobilehidden {
            display: none;
        }

        .mobileshow {
            display: table-cell !important;
        }
    }

</style>