<template>
    <div class="box1">
        آخرین سفارش های من
        <perfect-scrollbar :options="settings">
            <table>
                <tr class="table-header">
                    <th>بازار</th>
                    <th>مقدار</th>
                    <th class="mobilehidden">قیمت واحد ({{to}})</th>
                    <th>قیمت کل ({{to}})</th>
                    <th>تاریخ</th>
                    <th>وضعیت</th>
                </tr>
                <!-- <p>gdflkm{{isEmpty}}</p> -->
                <!-- <button @click="check">navid-btn</button> -->
                <p v-if="tableContents.length == 0" style="position:absolute;right:0;top:25px;color:red;width:100%;text-align:center">
                    داده ای جهت نمایش یافت نشد.</p>
                <tbody>
                <tr :style="{color:[tableContent.orderType.includes('BUY') ? 'var(--main-green)':'var(--main-red)']}"
                    v-for="(tableContent,index) in tableContents" :key="index"
                    class="table-row">
                    <td>{{from}} - {{to}}</td>
                    <td>{{tableContent.amount}}</td>
                    <td class="mobilehidden">{{tableContent.unitPrice.toLocaleString()}}</td>
                    <td>{{tableContent.wholePrice.toLocaleString()}}</td>
                    <td style="direction:ltr" class="mobilehidden">{{$jmoment(tableContent.createdAtDateTime,
                        'YYYY/M/DTHH:mm').format('jYYYY-jM-jD HH:mm:ss')}}
                    </td>
                    <td class="mobileshow">{{$jmoment(tableContent.createdAtDateTime,
                        'YYYY/M/DTHH:mm').format('jYYYY-jM-jD HH:mm:ss').slice(2,8)}}
                    </td>
                    <td v-if="tableContent.orderStatus === 'FINISHED'" style="color:var(--main-green)">انجام شده</td>
                    <td v-else style="color:var(--main-red)">انجام نشده</td>
                </tr>
                </tbody>

            </table>
        </perfect-scrollbar>
    </div>
</template>


<script>
    export default {
        name: "LastOrder",
        props: ['base', 'to', 'from'],
        data() {
            return {
                modalshow: false,
                removeid: '',
                settings: {
                    suppressScrollX: true
                },
                tableContents: [],
                isEmpty: '',
                getInterval:''
            }
        },
        methods: {
    
            async getLastOrders() {
                
                // console.log('test : ===>>>>',this.$farsiToCoin[this.from],this.from)
                await this.$axios(
                    `/orders?market=${this.$farsiToCoin[this.from]}_${this.$farsiToCoin[this.to]}&page=1&size=20`
                )
                    .then((res) => {
                        if (res.data.content) {
                            const result = res.data.content.filter(i => i.orderStatus !== 'IS_OPEN');
                            if (result.length === 0) {
                                this.isEmpty = "داده ای جهت نمایش یافت نشد.";
                            } else {
                                this.tableContents = result
                            }

                        } else {
                            this.isEmpty = "داده ای جهت نمایش یافت نشد.";
                        }
                    })
                    .catch(() => {
                        this.state.errorMessage = "خطایی رخ داده است. مجددا تلاش کنید";
                        this.state.showError = true;
                    });
            },
        },
        mounted() {
            this.getLastOrders()
            this.getInterval = setInterval(() => {
                this.getLastOrders()
            }, 5000);
        },
        beforeDestroy() {
            clearInterval(this.getInterval);
        }

    }
</script>

<style scoped>
    .ps {
        height: 250px;
        /*width: 100%;*/
    }

    .box1 {
        font-family: VistaDev, sans-serif;
        font-size: 14px;
        /*height: 900px;*/
        /*border-top: 5px solid var(--main-green);*/
        width: 100%;
        padding-top: 10px;
        position: relative;
    }

    h3 {
        font-size: 18px;
        margin: 10px 0;
    }

    .dark a {
        color: white;
    }

    table {
        width: 100%;
        align-items: center;
        border-radius: 5px;
        border-collapse: collapse;
        /*direction: rtl;*/
        position: relative;
    }

    table th {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        align-items: center;
        height: 30px;
        text-align: center;
        /*opacity: 0.8;*/
        background-color: var(--main-grey);
        z-index: 2;
        color: #494949;
    }

    /*.table-header::after {*/
    /*    content: '';*/
    /*    position: absolute;*/
    /*    background-color: #000000;*/
    /*    opacity: 0.05;*/
    /*    left: 50%;*/
    /*    bottom: 0;*/
    /*    transform: translate(-50%, 0);*/
    /*    height: 2px;*/
    /*    width: calc(100%);*/
    /*}*/

    .table-row {
        /*position: relative;*/
        transform: scale(1);
        -ms-transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
    }

    .table-header th:first-of-type,
    .table-row td:first-of-type {
        text-align: right;
        padding-right: 10px;
    }

    .table-header th:last-of-type,
    .table-row td:last-of-type {
        text-align: center;
        padding-left: 10px;
    }

    .table-row td {
        /*color: #494949;*/
        /*opacity: 0.7;*/
        align-items: center;
        height: 35px;
        text-align: center;
        cursor: default;
        transition: 0.2s;
    }

    .table-row:hover {
        background-color: var(--hover);
    }

    .mobilehidden {
        display: table-cell;
    }

    .mobileshow {
        display: none
    }

    @media (max-width: 580px) {
        .mobilehidden {
            display: none;
        }

        .mobileshow {
            display: table-cell;
        }
    }


</style>