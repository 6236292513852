<template>
    <div class="main">
        <blog-header></blog-header>
        <h1>آخرین مطالب</h1>
        <related-posts :posts="lastposts"></related-posts>
        <button @click.prevent="addpost" class="more">نمایش بیشتر</button>
    </div>
</template>

<script>

    import RelatedPosts from "@/components/Blog/RelatedPosts";
    import BlogHeader from "@/components/Blog/BlogHeader";
    export default {
        name: "PostIndex",
        components: {BlogHeader, RelatedPosts},
        props: ['id'],
        data() {
            return {
                lastposts: [
                    {
                        image: require('../../assets/blog/blog-gallery.jpg'),
                        header: 'دلایلی که قیمت بیت کوین طی روزهای آینده می‌تواند به ۱۰,۰۰۰ دلار برسد.',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                    {
                        image: require('../../assets/blog/blog-gallery.jpg'),
                        header: 'تحلیل تکنیکال هفتگی ارزهای دیجیتال ۳۰ ژوئن',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                    {
                        image: require('../../assets/blog/blog-gallery.jpg'),
                        header: 'دلایلی که قیمت بیت کوین طی روزهای آینده می‌تواند به ۱۰,۰۰۰ دلار برسد.',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                    {
                        image: require('../../assets/blog/blog-gallery.jpg'),
                        header: 'تحلیل تکنیکال هفتگی ارزهای دیجیتال ۳۰ ژوئن',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                    {
                        image: require('../../assets/blog/blog-gallery.jpg'),
                        header: 'دلایلی که قیمت بیت کوین طی روزهای آینده می‌تواند به ۱۰,۰۰۰ دلار برسد.',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                    {
                        image: require('../../assets/blog/blog-gallery.jpg'),
                        header: 'تحلیل تکنیکال هفتگی ارزهای دیجیتال ۳۰ ژوئن',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                    {
                        image: require('../../assets/blog/blog-gallery.jpg'),
                        header: 'دلایلی که قیمت بیت کوین طی روزهای آینده می‌تواند به ۱۰,۰۰۰ دلار برسد.',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                    {
                        image: require('../../assets/blog/blog-gallery.jpg'),
                        header: 'تحلیل تکنیکال هفتگی ارزهای دیجیتال ۳۰ ژوئن',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                    {
                        image: require('../../assets/blog/blog-gallery.jpg'),
                        header: 'تحلیل تکنیکال هفتگی ارزهای دیجیتال ۳۰ ژوئن',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                    {
                        image: require('../../assets/blog/blog-gallery.jpg'),
                        header: 'تحلیل تکنیکال هفتگی ارزهای دیجیتال ۳۰ ژوئن',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                    {
                        image: require('../../assets/blog/blog-gallery.jpg'),
                        header: 'تحلیل تکنیکال هفتگی ارزهای دیجیتال ۳۰ ژوئن',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                    {
                        image: require('../../assets/blog/blog-gallery.jpg'),
                        header: 'تحلیل تکنیکال هفتگی ارزهای دیجیتال ۳۰ ژوئن',
                        time: 10,
                        date: '22 خرداد 1399'
                    },
                ]
            }
        },
        methods:{
            addpost(){
                for(let i=0;i<6;i++){
                    this.lastposts.push({
                        image: require('../../assets/blog/blog-gallery.jpg'),
                        header: 'دلایلی که قیمت بیت کوین طی روزهای آینده می‌تواند به ۱۰,۰۰۰ دلار برسد.',
                        time: 10,
                        date: '22 خرداد 1399'
                    })

                }
            }
        }
    }
</script>

<style scoped>
    .main{
        margin-top: 56px;
        width: 100%;
        max-width: 1250px;
        padding: 0 20px 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    h1{
        font-size: 22px;
        text-align: right;
        margin-top: 50px;
    }
    .more{
        background-color: var(--dark-background);
        color: white;
        padding: 10px 0;
        display: block;
        width: 245px;
        border-radius: 25px;
        font-size: 20px;
        cursor: pointer;
    }

</style>