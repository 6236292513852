<template>
    <div class="main">
        <div style="display:flex;justify-content:space-between">
            <p>درگاه پرداخت : </p>
            <p>{{gateway}}</p>
        </div>
        <div style="display:flex;justify-content:space-between">
            <p>مقدار : </p>
            <p>{{amount.toLocaleString()}} تومان</p>
        </div>
        <div style="display:flex;justify-content:space-between">
            <p>کد رهگیری : </p>
            <p>{{code}}</p>
        </div>
        <div style="display:flex;justify-content:space-between">
        <p >وضعیت : </p>
        <p><span :style="{color:isDone === 'موفق'? 'var(--main-green)':'var(--main-red)' }">{{isDone}}</span></p>
        </div>
        <button @click="$router.push('wallet')" class="normal-btn" style="position:absolute;bottom:-70px;width:150px;right:calc(50% - 75px);font-size:14px">بازگشت به کیف پول</button>
    </div>
</template>

<script>
export default {
    data(){
        return{
            status:'',
            code:'',
            amount:'',
            gateway:'',
            isDone:''
        }
    },
    created(){
        console.log(this.$route);
        this.$axios(`/wallets/deposit-status?id=${this.$route.query.id}`)
            .then(res=>{
                console.log(res.data.baseDTO)
                let response = res.data.baseDTO
                this.amount = response.amount
                this.gateway = response.paymentGateway
                this.code = response.hashCode
                this.isDone = response.isDone?'موفق':'ناموفق'
                console.log(this.isDone);
            })
            
            .catch(err=>{
                console.log(err);
            })
    }
}
</script>

<style scoped>
    .main{
        width: 100%;
        max-width: 400px;
        text-align: right;
        padding: 0 30px;
        margin-top: 20px;
        border: 1px solid var(--main-green);
        border-radius: 10px;
        background: #e1e1e1;
        position: relative;
    }
    .main>p{
        margin: 25px 0;
    }
</style>