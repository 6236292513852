<template>
    <div class="box1">
        <h3>قیمت لحظه ای - دلار</h3>
        <LoadingModal v-if="loadingModal"/>
        <!-- <button @click="iterate">click</button> -->
        <table>
            <tr class="table-header">
                <th>بازار</th>
                <th>قیمت</th>
                <th>تغییرات</th>
                
            </tr>
            <tr v-for="(tableContent,index) in realpriceusd" :key="index" class="table-row" @click.prevent="$router.push('/trade/'+toid+'/'+coinArray[tableContent.sourceCoinSymbol])">
                <!-- {{coinIndex[tableContent.sourceCoinSymbol]}} -->
                
                <td v-if="tableContent.price24hrChangePercent !== ''">{{cointofarsi[tableContent.sourceCoinSymbol]}}</td>
                <td v-if="tableContent.price24hrChangePercent !== ''" :style="{color:[(tableContent.price24hrChangePercent>0)?'var(--main-green)':'var(--main-red)']}">{{(Math.floor(tableContent.price * 100) / 100).toLocaleString()}}</td>
                <td v-if="tableContent.price24hrChangePercent !== ''" :style="{color:[(tableContent.price24hrChangePercent>0)?'var(--main-green)':'var(--main-red)']}">{{Math.floor(tableContent.price24hrChangePercent * 100) / 100}}٪</td>
            </tr>
        </table>
    </div>
</template>

<script>

import LoadingModal from '../../LoadingModal'
    export default {
        name: "RealPriceTableToman",
        props:['tableContents','toid'],
        components:{LoadingModal},
        data() {
            return {
                loadingModal:false,
                tableIteration:'',
                realpriceusd:[
                    {sourceCoinSymbol:'',price:'',price24hrChangePercent:''},
                    {sourceCoinSymbol:'',price:'',price24hrChangePercent:''},
                    {sourceCoinSymbol:'',price:'',price24hrChangePercent:''},
                    {sourceCoinSymbol:'',price:'',price24hrChangePercent:''},
                    {sourceCoinSymbol:'',price:'',price24hrChangePercent:''},
                    {sourceCoinSymbol:'',price:'',price24hrChangePercent:''}

                ],
                cointofarsi:{'bitcoin':'بیت کوین','litecoin':'لایت کوین','ethereum':'اتریوم','ripple':'ریپل','tether':'تتر','bitcoin-cash':'بیت کوین کش','dash':'دش','dogecoin':'دوج','ethereum-classic':'اتریوم کلاسیک'},
                coinArray:{
                    'bitcoin':0,
                    'ethereum':1,
                    'bitcoin-cash':2,
                    'litecoin':3,
                    'ripple':4,
                    'tether':5,
                    'dash':6,
                    'dogecoin':7,
                    'ethereum-classic':8
                }
            }
        },
        methods:{
            iterate(){
                this.getPrice()
                this.tableIteration = setInterval(() => {
                    this.getPrice()
                }, 15000);
            },
            sort(){
                this.tableContents.sort((a,b)=>this.$sortCoins.indexOf(a.sourceCoinSymbol) - this.$sortCoins.indexOf(b.sourceCoinSymbol))
            },
            getPrice(){
                this.loadingModal = true
                this.$axios.get('/coins/price/usd?include24hrChange=true&includeWeeklyChange=false')
                    .then(response=>{
                        this.realpriceusd = response.data
                        this.sort()
                        this.loadingModal = false
                    })
                    .catch(()=>{
                        this.loadingModal = false
                    })
            }
        },
        created(){
            this.iterate()
        },
        beforeDestroy(){
            clearInterval(this.tableIteration)
        }

    }
</script>

<style scoped>

    .box1 {
        font-family: VistaDev,sans-serif;
        font-size: 14px;
        height: calc(50vh - 60px);
        max-height: 290px;
        /*height: 900px;*/
        /*border-top: 5px solid var(--main-green);*/
        width: 100%;
        position: relative;
        overflow-y: scroll;
    }
    h3{
        font-size: 16px;
        margin: 10px 0;
    }
    .dark a{
        color: white;
    }

    table {
        width: 100%;
        align-items: center;
        border-radius: 5px;
        border-collapse: collapse;
        direction: rtl;
        height: calc(100% - 45px);
    }

    .table-header {
        position: sticky;
        position: -webkit-sticky;
        top:0;
        align-items: center;
        /*max-height: 30px;*/
        text-align: center;
        /*opacity: 0.8;*/
        background-color: var(--main-grey);
        z-index: 2;
        color: #494949;
    }

    /*.table-header::after {*/
    /*    content: '';*/
    /*    position: absolute;*/
    /*    background-color: #000000;*/
    /*    opacity: 0.05;*/
    /*    left: 50%;*/
    /*    bottom: 0;*/
    /*    transform: translate(-50%, 0);*/
    /*    height: 2px;*/
    /*    width: calc(100%);*/
    /*}*/

    .table-row {
        /*position: relative;*/
        transform: scale(1);
        -ms-transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
    }
    .table-header th:first-of-type,
    .table-row td:first-of-type{
        text-align: right;
        padding-right: 10px;
    }
    .table-header th:last-of-type,
    .table-row td:last-of-type{
        text-align: left;
        padding-left: 10px;
        direction: ltr;
    }

    .table-row td {
        color: #494949;
        /*opacity: 0.7;*/
        align-items: center;
        /*max-height: 35px;*/
        text-align: center;
        cursor: pointer;
    }
    .table-row:hover{
        background-color: var(--hover);
    }
</style>