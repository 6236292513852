<template>
    <div class="box1">
        <h3>آخرین معاملات ({{this.$coinLabel[this.$coinArray[this.$route.params.tradefromid]]}}-{{this.$coinLabel[this.$toLabel[this.$route.params.tradetoid]]}})</h3>
        <perfect-scrollbar :options="settings">
            <table>
                <tr class="table-header">
                    <th>مقدار</th>
                    <th>قیمت</th>
                    <th>مجموع</th>
                    <th>زمان</th>
                </tr>
                <LoadingModal v-if="loadingModal"/>
                <tbody>
                    <p v-if="tableContents.length == 0" style="position:absolute;right:0;top:25px;color:red;width:100%;text-align:centre">داده ای جهت نمایش یافت نشد</p>
                    <tr :style="{color:tableContent.orderType.includes('BUY') ? 'var(--main-green)':'var(--main-red)'}"
                    v-for="(tableContent,index) in tableContents" :key="index"
                    class="table-row">
                    <td>{{tableContent.amount}}</td>
                    <td>{{tableContent.unitPrice.toLocaleString()}}</td>
                    <td>{{(tableContent.unitPrice*tableContent.amount).toLocaleString()}}</td>
                    <td>{{tableContent.createdAtDateTime.slice(11,16)}}</td>
                </tr>
                </tbody>
                
            </table>
        </perfect-scrollbar>
    </div>
</template>


<script>
import LoadingModal from '../LoadingModal'
    export default {
        name: "LastTrade",
        components:{LoadingModal},
        data() {
            return {
                loadingModal:false,
                tableContents:[],
                isEmpty:'',
                getInterval:'',
                s : 1,
                settings:{
                    suppressScrollX :true
                }
            }
        },
        methods: {
            async getLastTrades() {
                const res = await this.$axios.get('/trades/last-trades', {
                    params: {
                        marketType: this.$coinArray[this.$route.params.tradefromid] + '_' + this.$toLabel[this.$route.params.tradetoid],
                        size: 20
                    }
                })
                this.tableContents = res.data.content
                // console.log(res);
            },
        },
        async mounted() {
            // console.log('l');
            this.loadingModal = true
            await this.getLastTrades()
            this.loadingModal = false
            this.getInterval = setInterval(() => {
                this.getLastTrades()
            }, 5000);
        },
        beforeDestroy() {
            clearInterval(this.getInterval);
        }
    }
</script>

<style scoped>
    .ps {
        height: calc(50vh - 70px);
        width: 100%;
    }
    .box1 {
        font-family: VistaDev,sans-serif;
        font-size: 14px;
        /*height: 900px;*/
        /*border-top: 5px solid var(--main-green);*/
        width: 100%;
    }
    h3{
        font-size: 16px;
        margin: 10px 0;
    }
    .dark a{
        color: white;
    }

    table {
        width: 100%;
        align-items: center;
        border-radius: 5px;
        border-collapse: collapse;
    }

    table th {
        position: sticky;
        position: -webkit-sticky;
        top:0;
        align-items: center;
        height: 30px;
        text-align: center;
        /*opacity: 0.8;*/
        background-color: var(--main-grey);
        z-index: 2;
        color: #494949;
    }

    /*.table-header::after {*/
    /*    content: '';*/
    /*    position: absolute;*/
    /*    background-color: #000000;*/
    /*    opacity: 0.05;*/
    /*    left: 50%;*/
    /*    bottom: 0;*/
    /*    transform: translate(-50%, 0);*/
    /*    height: 2px;*/
    /*    width: calc(100%);*/
    /*}*/

    .table-row {
        /*position: relative;*/
        transform: scale(1);
        -ms-transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
    }
    .table-header th:first-of-type,
    .table-row td:first-of-type{
        text-align: right;
        padding-right: 10px;
    }
    .table-header th:last-of-type,
    .table-row td:last-of-type{
        text-align: left;
        padding-left: 10px;
    }

    .table-row td {
        /*color: #494949;*/
        /*opacity: 0.7;*/
        align-items: center;
        height: 35px;
        text-align: center;
        cursor: default;
    }
</style>