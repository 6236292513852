<template>
    <div class="Auth-box">
        <div class="Auth-header">
        <h3>تعیین رمز عبور</h3>
        </div>
        <form class="Auth" @submit.prevent="submit">
            <p v-if="state.userinfo.towStepAuthenticationType === 'EMAIL'">کد ۶ رقمی که به ایمیل
                        {{$cookies.get('username')}} ارسال شده است را وارد نمایید.</p>
            <p v-else-if="state.userinfo.towStepAuthenticationType === 'SMS'"> کد ۶ رقمی که به شماره
                {{state.userinfo.mobileNumber}} ارسال شده است را وارد
                نمایید.</p>
            <p v-else-if="state.userinfo.towStepAuthenticationType === 'GOOGLE_AUTHENTICATOR'">کد ۶ رقمی سرویس
                Google Authenticator را وارد نمایید.</p>
            <p v-else>کد ۶ رقمی ارسال شده را وارد نمایید.</p>
            <CodeInput class="auth-row"
                        @change="onChange"
                        :fields="num" ref="code-input" :fieldWidth="45" :fieldHeight="45"/>
           <div class="footer">
                <a v-if="countDown===0" @click.prevent="send" style="cursor: pointer" class="countdown">ارسال مجدد</a>
                <a v-else style="opacity:0.5;cursor:default" class="countdown">ارسال مجدد</a>
                <a style="text-decoration: none" class="countdown">{{countDown}} ثانیه </a>
            </div>
            <ali-input @send="receive1($event)" :type="'password'" :name="'password'" :errortext="passError"
                       :faname="'رمز عبور'"></ali-input>
            <button class="normal-btn" @click="submit" :disabled="disable">ذخیره
            </button>
        </form>
        <Loading v-if="showLoading"/>
    </div>
</template>

<script>
    import AliInput from "@/components/Tools/AliInput";
    import CodeInput from "vue-verification-code-input";
    import Loading from '../Tools/Loading';
    export default {
        name: "ResetPass",
        components: {AliInput,CodeInput,Loading},
        data: () => ({
            passError: '',
            password: '',
            res:'',
            countDown: 120,
            num: 6,
            code: '',
            showLoading:false,
        }),
        computed: {
            disable() {
                return this.code.toString().length < this.num
                    || this.countDown === 0
                    || !this.password
            }
        },
        methods: {
            countDownTimer() {
                if (this.countDown > 0) {
                    this.timeOut = setTimeout(() => {
                        this.countDown--
                        this.countDownTimer()
                    }, 1000)
                } else {
                    this.$error('زمان شما به اتمام رسید', '')
                }
            },
            async send() {
                this.code = ''
                await this.$axios.post('/users/forget-password?email=' + localStorage.email || this.state.userinfo.email)
                this.$error('کد جدید ارسال شد.', '', 'success')
                this.$cookies.set('username', this.state.userinfo.email)
                if (this.countDown === 0) {
                    if (this.state.userinfo.towStepAuthenticationType === 'GOOGLE_AUTHENTICATOR')
                        this.countDown = 180
                    else
                        this.countDown = 120
                    this.countDownTimer()
                }

            },
            checkPass() {
                if (!this.password) {
                    this.passError = 'رمز اجباری';
                } else {
                    if (this.password.length < 8) {
                        this.passError = 'حداقل 8 کاراکتر';
                    } else if (!this.validPass(this.password)) {
                        this.passError = 'ترکیبی از حروف کوچک و بزرگ و عدد';
                    } else {
                        this.passError = ''
                    }
                }
                if (!this.passError.length) {
                    return true;
                }
            },
            validPass(password) {
                var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
                return re.test(password);
            },
            receive1(e) {
                this.password = e
                this.checkPass(e)
            },
            setPlaceholder() {
                for (let i = 0; i < this.num; i++) {
                    this.$refs['code-input'].$refs['input_' + i][0].placeholder = '----'
                }
            },
            onChange(e) {
                this.code = e
            },
            async submit(){
                this.showLoading=true
                if(this.checkPass()){
                    console.log(this.password)
                    let newpassword = {
                        'password':this.password,
                        'email':localStorage.email,
                        'code':this.code
                    }
                    localStorage.token = ''
                    console.log(localStorage.token)
                    this.$axios.post(`/users/reset-password`,newpassword)
                    .then(response => {this.res = response.data;
                        this.showLoading=false
                        console.log(this.res.message)
                        if (this.res.message === 'password changed successfully'){
                            this.$error('رمز عبور با موفقیت تغییر یافت.', '', 'success')
                            this.$router.push('/login')
                        }
                    })
                    .catch(error => {
                    this.showLoading=false
                    if(error.response){
                        console.log(error.response.data)}
                        if(error.response.data.message === 'invalid token'){
                            window.alert('خطایی رخ داده است. لطفا از ابتدا تلاش کنید')
                            setTimeout(()=>{
                                this.$router.push('login')
                            },2000)
                        }
                    })
                }
            }
        },
        created() {
            
            this.countDownTimer()
            if (this.state.userinfo.towStepAuthenticationType === 'GOOGLE_AUTHENTICATOR')
                this.countDown = 180
        },
        beforeDestroy() {
            clearTimeout(this.timeOut)
        },
        mounted() {
            this.setPlaceholder()
        }
    }
</script>

<style scoped>
.auth-row {
        display: flex;
        direction: ltr;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 45px;
        margin-top: 10px;
        border: 1px solid var(--light-border);
        border-radius: 15px;
    }
    input {
        padding: 5px 0;
    }
    .Auth{
        margin-bottom:  70px;
    }

    .Auth-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 400px;
        height: 100%;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 50px 0 70px;
    }

    .normal-btn {
        margin-bottom: 80px;

    }
    .countdown {
        font-size: 14px;
        text-decoration: underline;
    }
    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
    }
</style>
<style lang="scss">
    .auth-row{
        width: 100% !important;
        .react-code-input{
            border: none;
            input{
                border: none !important;
                color: var(--font-color3);
                font-family: 'VistaDev',sans-serif !important;
                font-size: 25px;
                letter-spacing: -2px;
                text-align: center !important;
            }
        }
    }
</style>
