import Vue from 'vue';
const that = Vue.prototype;

const state = Vue.observable({
    gotten: false,
    email: '',
    loop:true,
    mobilenumber: '',
    token: '',
    sellAmount: '',
    buyAmount: '',
    totalPrice: 0,
    resetpassword: false,
    islimited: true,
    showError: false,
    loading:false,
    errorMessage: '',
    showToast: false,
    errorStatus:'',
    toastMessage: '',
    today:'',
    transaction: {
        amount: null,
        destinationWalletAddress: null,
        relatedCoin: null,
        code: null
        },
    userinfo: {
        accountLevel:'',
        wage:4,
        totalTradesAmount:0,
        authorized: false,
        imageUploaded:false,
        pending: false,
        authenticationType:'',
        firstName: '',
        lastName: '',
        mobileNumber: '',
        idnumber: '',
        address: '',
        bankAccountsString: [{ shebaCode: '', cardNumber: '', bankName: '' }],
        birthDay: '',
        telephone: '',
        image:'',
        nationalCode: null,
        email: '',
        bankError: '',
        duplicateBank: 'nothing',
        towStepAuthenticationType:'',
        supervisor: false,
    }
})

that.state = state
export default state