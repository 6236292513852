<template>
    <div class="Auth-box">
        <div class="Auth-header">
            <h3>شناسایی دو عاملی</h3>
        </div>
        <div class="Auth">
            <p>کد ۵ رقمی ارسال شده که به شماره خود را وارد نمایید.</p>
            <CodeInput class="auth-row"
                       @change="onChange" @complete="submit"
                       :fields="num" ref="code-input" :fieldWidth="45" :fieldHeight="45"/>
            <div class="footer">
                
                <a v-if="countDown===0" @click.prevent="send" style="cursor: pointer" class="countdown">ارسال مجدد</a>
                <a v-else style="opacity:0.5;cursor:default" class="countdown">ارسال مجدد</a>
                <a style="text-decoration: none" class="countdown">{{countDown}} ثانیه </a>
            </div>
        </div>
        <button class="normal-btn" @click.prevent="submit" :disabled="code.toString().length < num || countDown === 0">تایید
        </button>
        <p style="opacity: 0.8;color:red;margin:0">{{error}}</p>
       <ToastNotification timer='10000'/>
       <ErrorNotification timer="13000"/>
    </div>
</template>

<script>
    import ToastNotification from '../Tools/ToastNotification';
    import ErrorNotification from "../Tools/ErrorNotification";
    import CodeInput from "vue-verification-code-input";

    export default {
        name: "VerifyingPage",
        props:['query'],
        components:{ToastNotification,ErrorNotification,CodeInput},
        data() {
            return {
                countDown: 180,
                smscode: [],
                error: '',
                num: 5,
                code: '',
                forEmailOtp:'',
                res:'',
                isSent:false
            }
        },
        
    
        methods: {
           
            countDownTimer() {
                if (this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown--
                        this.countDownTimer()
                    }, 1000)
                }else{
                    this.error = 'زمان شما به اتمام رسید'
                }
            },
            async send() {
                this.smscode = ''
                // console.log(this.isSent);
                await this.$axios(`/finotech/send-sms-to-customer?email=${this.state.userinfo.email}`)
                .then(res=>{
                    
                  if (res.data.message === 'Sms sent to customer.'){
                    this.isSent = true
                  }
                })
                .catch(err=>{
                  console.log(err);
                  this.isSent = false
                })
               
                console.log(this.isSent);
                if (this.countDown === 0 && this.isSent){
                    this.countDown = 180
                    this.error = ''
                    this.isSent = false
                    this.countDownTimer()
                }
                
            },
            setPlaceholder() {
                for(let i=0;i<this.num;i++){
                    this.$refs['code-input'].$refs['input_'+i][0].placeholder = '----'
                }
            },
            onChange(e) {
                this.code = e
            },
            submit() {
                this.state.loading = true
                this.error = ''
                this.$axios(`/finotech/otp?otp=${this.code}&email=${this.state.userinfo.email}`)
                    .then(res=>{
                        console.log(res);
                        if (res.data.message === "FinnoTech user validation finished."){
                            this.state.toastMessage = 'فرایند احراز هویت انجام شد. منتظر تایید اطلاعات توسط پشتیبانی باشید.'
                            this.state.showToast = true
                            setTimeout(() => {
                                this.$router.push('/profile')
                            }, 5000);
                        }
                    })
                    .catch((err)=>{
                        console.log(err);
                        if (err.response && err.response.status !== 409){
                            console.log(err.response.data.message.slice(18,[err.response.data.message.length-1]));
                            let a = JSON.parse(err.response.data.message.slice(18,[err.response.data.message.length-1]))
                            console.log(a.error.message);
                            
                            if(a.error.message === "nid and mobile is not match"){
                                setTimeout(() => {
                                this.$router.push('/profile')
                                },6000);
                            }
                        }else if(err.response.status === 409){
                            this.error = 'اطلاعات وارد شده مطابقت ندارد. دوباره چک کنید'
                        }  
                    })
            }
        },
        created() {
            this.countDownTimer();
        },
        mounted() {
            this.setPlaceholder()
        }
    }
</script>

<style lang="scss" scoped>

    input {
        padding: 5px 0;
    }

    .Auth-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 400px;
        height: 100%;
    }
    .Auth{
        margin-bottom:  40px;
    }

    .countdown {
        font-size: 14px;
        text-decoration: underline;
    }

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
    }

    .auth-row {
        display: flex;
        direction: ltr;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 45px;
        margin-top: 10px;
        border: 1px solid var(--light-border);
        border-radius: 15px;
    }

    .Auth-input {
        font-size: 25px;
        border-radius: 5px;
        /*border: solid 1px #00000010;*/
        width: 40px;
        height: 100%;
        text-align: center;
        letter-spacing: -2px;
        /*padding: 0;*/

        &:focus {
            border-color: var(--main-orange);
        }
    }

    p {
        margin: 20px 0 20px;
        text-align: right;
    }

    .normal-btn {
        margin-bottom: 50px;
    }
</style>
<style lang="scss">
    .auth-row{
        width: 100% !important;
        .react-code-input{
            border: none;
            input{
                border: none !important;
                color: var(--font-color3);
                font-family: 'VistaDev',sans-serif;
                font-size: 25px;
                letter-spacing: -2px;
                text-align: center !important;
            }
        }
    }
</style>