<template>
    <div class="main-navbar">
        <h1>بلاگ ارز دیجیتال</h1>
        <div class="navbar">
            <!--        menu bar -->
            <div class="sub-menu2 row">
                <a :class="[(currentroute=='BlogHome') ? 'selected' : '']" @click="()=>this.$router.push('/blog')"
                   class="col-auto" id="home">خانه</a>
                <a :class="[(['News'].indexOf(currentroute)>-1) ? 'selected' : '']"
                   class="dropdown col-auto" id="news">اخبار
                    <img src="../../assets/blog/Vector.svg" alt="">
                    <drop-content-hover class="drop" :contents="newscontents"></drop-content-hover>
                </a>
                <a :class="[(['Base'].indexOf(currentroute)>-1) ? 'selected' : '']"
                   class="dropdown col-auto" id="baseknowledge">مفاهیم پایه
                    <img src="../../assets/blog/Vector.svg" alt="">
                    <drop-content-hover class="drop" :contents="baseknowledgecontents"></drop-content-hover>
                </a>
                <a :class="[(['PostShow','PostIndex'].indexOf(currentroute)>-1) ? 'selected' : '']"
                   class="dropdown col-auto" id="article">مقالات
                    <img src="../../assets/blog/Vector.svg" alt="">
                    <drop-content-hover class="drop" :contents="articlecontents"></drop-content-hover>
                </a>
            </div>
            <div class="main-searchbox">
                <div class="searchbox">
                    <input @input="search1()" name="searchbox" id="searchbox"
                           v-model="search" type="text" placeholder="جستجو در مطالب بلاگ…">
                </div>
                <div v-show="searcheshow" class="searchdropbox">
                    <div v-for="(post,index) in results" :key="index" class="searcheresult"
                         @click.prevent="()=>$router.push('/blog/show/'+ 1)">
                        <a>{{post.first}}</a>
                        <a style="color: var(--main-green)">{{searchtarget}}</a>
                        <a>{{post.last}}</a>
                    </div>
                    <div class="searcheresult other" @click.prevent="()=>$router.push('/blog/index')">
                        <a>مشاهده تمام مطالب ...</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    // import HelpDropDownHover from "@/components/HelpDropDownHover";

    import DropContentHover from "@/components/Tools/DropContentHover";

    export default {
        name: "BlogHeader",
        components: {DropContentHover},
        data() {
            return {
                mobile: false,
                currentroute: '',
                dropname: '',
                search: '',
                screanWidth: screen.width,
                searcheshow: false,
                contentheader: '',
                newscontents: [
                    {
                        name: 'اخبار بیت‌کوین',
                        link: '/blog/index',
                        route: 'News'
                    },
                    {
                        name: 'اخبار اتریوم',
                        link: '/blog/index',
                        route: 'News'
                    },
                    {
                        name: 'اخبار بیت‌کوین کش',
                        link: '/blog/index',
                        route: 'News'
                    },
                    {
                        name: 'اخبار لایت کوین',
                        link: '/blog/index',
                        route: 'News'
                    },

                    {
                        name: 'اخبار دش کوین',
                        link: '/blog/index',
                        route: 'News'
                    },
                    {
                        name: 'اخبار تتر',
                        link: '/blog/index',
                        route: 'News'
                    },
                ],
                baseknowledgecontents: [
                    {
                        name: 'آشنایی با بیت‌کوین',
                        link: '/blog/index',
                        route: 'Base'
                    },
                    {
                        name: 'آشنایی با اتریوم',
                        link: '/blog/index',
                        route: 'Base'
                    },
                    {
                        name: 'آشنایی با بیت‌کوین کش',
                        link: '/blog/index',
                        route: 'Base'
                    },
                    {
                        name: 'آشنایی با لایت‌کوین',
                        link: '/blog/index',
                        route: 'Base'
                    },
                    {
                        name: 'آشنایی با دش کوین',
                        link: '/blog/index',
                        route: 'Base'
                    },
                    {
                        name: 'آشنایی با تتر',
                        link: '/blog/index',
                        route: 'Base'
                    },
                ],
                articlecontents: [
                    {
                        name: 'آخرین مطالب',
                        link: '/blog/index',
                        route: 'PostIndex'
                    },
                    {
                        name: 'مقالات منتخب',
                        link: '/blog/index',
                        route: ''
                    },
                    {
                        name: 'مقالات استخراج',
                        link: '/blog/index',
                        route: ''
                    },
                    {
                        name: 'مقالات سرمایه گذاری',
                        link: '/blog/index',
                        route: ''
                    }
                ],
                posts: [
                    {
                        description: 'حجم معاملات اتریوم رکورد تاریخی زد؛ ارزش…'
                    },
                    {
                        description: 'حجم معاملات اتریوم رکورد تاریخی زد؛ ارزش…'
                    },
                    {
                        description: 'حجم معاملات اتریوم رکورد تاریخی زد؛ ارزش…'
                    },
                    {
                        description: 'حجم معاملات اتریوم رکورد تاریخی زد؛ ارزش…'
                    },
                    {
                        description: 'حجم معاملات اتریوم رکورد تاریخی زد؛ ارزش…'
                    },
                    {
                        description: 'حجم معاملات اتریوم رکورد تاریخی زد؛ ارزش…'
                    },
                ],
                deg: [90, 90, 90, 90],
                show: [false, false, false, false],
                nav: false,
                op: false,
                searchmenu: false,
                contents: [],
                searchtarget: 'اتریوم',
                results: []
            }
        },
        watch: {
            $route(to) {
                this.nav = false
                this.op = false
                this.searchmenu = false
                this.currentroute = to.name
                this.RouteName()
            }
        },
        mounted() {
            this.currentroute = this.$route.name;
            window.addEventListener('resize', this.windowssize)
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.windowssize)
        },
        methods: {
            RouteName() {
                this.currentroute = this.$route.name;
                if (this.currentroute === 'Trade') {
                    this.trade = true
                } else {
                    this.trade = false
                }
            },
            search1() {
                if (this.search.length >= 3) {
                    this.results = []
                    this.searcheshow = true
                    this.posts.forEach(value => {
                        var l = this.searchtarget.length
                        var a = value.description.search(this.searchtarget)
                        var first = value.description.slice(0, a)
                        var last = value.description.slice(a + l)
                        this.results.push({
                            first: first,
                            last: last
                        })
                    })
                } else {
                    (this.searcheshow = false)
                }

            },
            windowssize() {
                this.screanWidth = screen.width
                if (this.screanWidth > 800) {
                    this.nav = false
                    this.deg = [90, 90, 90, 90]
                    this.show = [false, false, false, false]
                }
            },
            Route(e) {
                this.nav = false
                this.deg = [90, 90, 90, 90]
                this.show = [false, false, false, false]
                this.$router.push('/' + e)
            },
            touchdropshow(e) {
                if (!this.show[e]) {
                    this.show[e] = true
                    this.deg[e] = 0
                } else {
                    this.show[e] = false
                    this.deg[e] = 90
                }
                this.$forceUpdate()
            },
            open(e) {
                switch (e) {
                    case 'article':
                        this.contents = this.articlecontents
                        this.contentheader = 'مقالات'
                        this.op = true
                        break;
                    case 'news':
                        this.contents = this.newscontents
                        this.contentheader = 'اخبار'
                        this.op = true
                        break;
                    case 'base':
                        this.contents = this.baseknowledgecontents
                        this.contentheader = 'مفاهیم پایه'
                        this.op = true
                }
            }
        }
    }
</script>

<style scoped>
    .main-navbar{
        width: 100%;
    }

    h1{
        font-size: 22px;
        margin-top: 80px;
    }
    .navbar {
        width: 100%;
        box-sizing: inherit;
        /*height: 100%;*/
        min-height: 54px;
        top: 0;
        z-index: 4;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-top: 1px solid var(--main-grey);
        border-bottom: 5px solid #000;
        padding: 8px 0;

    }

    .sub-menu2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        max-width: max-content;
    }

    .sub-menu2 a {
        height: 22px;
        /*width: 73px;*/
        padding: 0 20px;
        cursor: pointer;
        /*border-left: solid var(--light-border) 2px;*/
        color: #7E7E7E;
        font-weight: 700;
    }

    .sub-menu2 a:last-of-type {
        border-left: none
    }

    .sub-menu2 a:hover {
        color: var(--main-green);
    }
    .sub-menu2 a img{
        margin-right: 10px;
    }

    .selected {
        color: var(--main-green) !important;
        /*height: 30px;*/
        /*border-bottom: #f8b200 solid 2px;*/
        cursor: default;
    }

    .selected a {
        color: var(--main-green);
    }

    .main-searchbox {
        position: relative;
        display: flex;
        width: 100%;
        max-width: 300px;
    }

    @media (max-width: 768px) {
        .navbar{
            display: none !important;
        }
    }

    .searchbox {
        /*height: 26px;*/
        color: black;
        width: 100%;
        min-width: 100px;
        max-width: 575px;
        position: relative;
    }

    .searchbox input {
        color: black;
        background-color: var(--main-grey);
        height: 50px;
        border-radius: 10px;
        /*border-bottom: var(--main-green) solid 2px;*/
        transition: 0.5s;
        width: 100%;
    }

    .main-searchbox input {
        background-image: url("../../assets/icon/search1.svg");
        background-position: 10px center;
        background-repeat: no-repeat;
        padding-left: 40px;
        padding-right: 10px;;
    }

    .searchdropbox {
        margin-top: 41px;
        font-size: 14px;
        position: absolute;
        background-color: var(--dropdown-back);
        text-align: right;
        box-shadow: 0 -2px 20px 1px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        transition: 0.5s;
        z-index: 5;
        display: block;
        right: 50%;
        transform: translate(50%, 0);
        width: 100%;

    }

    .searcheresult {
        padding: 10px 10px 10px 25px;
        position: relative;
        cursor: pointer;
        border-bottom: solid 1px rgba(0, 0, 0, 0.25);
        opacity: 0.8;
    }
    .searcheresult a{
        color: white;
    }

    .searcheresult:last-of-type {
        border-bottom: none;
    }

    .searcheresult:hover {
        background-color: var(--hover);
    }

    .other {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .drop_btn {
        cursor: pointer;
    }

    .dropdown {
        position: relative;
    }

    .drop {
        display: none;
    }

    .dropdown:hover .drop {
        display: block !important;
    }

</style>