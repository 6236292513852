<template>
    <div class="box1">
        <!-- {{realpriceusd[0]}} -->
        <!-- <button @click="check">clickckckckck</button> -->
        <LoadingModal v-if="realpriceusd[0] === 'notArrive'"/>

        <table v-else>
            <tr class="table-header">
                <th>بازار</th>
                <th>قیمت</th>
                <th>تغییرات</th>
                <th>نمودار هفتگی</th>
            </tr>

            <tr v-for="(tableContent,index) in realpriceusd" :key="index" class="table-row"
                @click.prevent="$router.push('/trade/'+(tag-1)+'/'+$coinArray2[$marketType2relatedCoin(tableContent.marketType)])">
                <td>{{$coinLabel[$marketType2relatedCoin(tableContent.marketType)]}}</td>
                <td :style="{color:[(tableContent.last24HourChange>=0)?'var(--main-green)':'var(--main-red)']}">
                    {{Math.floor(tableContent.lastPrice * 100) / 100}}
                </td>
                <td style="direction:ltr"
                    :style="{color:[(tableContent.last24HourChange>=0)?'var(--main-green)':'var(--main-red)']}">
                    {{Math.floor(tableContent.last24HourChange * 100) / 100}}٪
                </td>
                <td>
                    <span>
                        <trend-chart
                                :datasets="[{data:tableContent.lastWeekData || [0,0],smooth: true,fill: true,className: 'curve-btc',stroke:false}]"
                                :min="-20"/>
                    </span>
                </td>
            </tr>
        </table>
        <p v-if="realpriceusd.length === 0">در حال حاضر بازار فعالی وجود ندارد</p>
    </div>
</template>

<script>
    import LoadingModal from '../LoadingModal'

    const TomanCoin = 'market_type=BITCOIN_TOMAN' +
        '&market_type=BITCOIN_CASH_TOMAN' +
        '&market_type=ETHEREUM_TOMAN' +
        '&market_type=CLASSIC_ETHEREUM_TOMAN' +
        '&market_type=LITE_COIN_TOMAN' +
        '&market_type=BINANCE_COIN_TOMAN' +
        '&market_type=EOS_TOMAN' +
        '&market_type=STELLAR_TOMAN' +
        '&market_type=TRON_TOMAN' +
        '&market_type=DASH_TOMAN' +
        '&market_type=DOGE_COIN_TOMAN' +
        '&market_type=TETHER_TOMAN'
    const TetherCoin = 'market_type=BITCOIN_TETHER' +
        '&market_type=BITCOIN_CASH_TETHER' +
        '&market_type=ETHEREUM_TETHER' +
        '&market_type=CLASSIC_ETHEREUM_TETHER' +
        '&market_type=LITE_COIN_TETHER' +
        '&market_type=BINANCE_COIN_TETHER' +
        '&market_type=EOS_TETHER' +
        '&market_type=STELLAR_TETHER' +
        '&market_type=TRON_TETHER' +
        '&market_type=DOGE_COIN_TETHER' +
        '&market_type=DASH_TETHER'

    export default {
        name: "RealPriceTableToman",
        props: ['toid', 'tag'],
        components: {LoadingModal},
        mounted() {
            this.check()
        },
        methods: {
            check() {
                this.$axios.get(`/trades/market-info?weeklyDataIncluded=true&${this.tag === 1 ? TomanCoin : TetherCoin}`)
                    .then(response => {
                        this.realpriceusd = response.data.content
                        console.log(this.realpriceusd[0].marketType)
                        for (const i in this.realpriceusd) {
                            // this.realpriceusd[i].marketType = this.$coinLabel[this.realpriceusd[i].relatedCoin]
                            if (this.realpriceusd[i].lastWeekData) {
                                this.realpriceusd[i].lastWeekData = this.realpriceusd[i].lastWeekData.reverse()
                            }
                        }
                    })
                    .catch(() => {
                        this.realpriceusd = ['error']
                    })
            }
        },
        watch: {
            tag: function () {
                if (this.tag === 1) {
                    this.realpriceusd = ['notArrive']
                    this.markettype = TomanCoin
                } else {
                    this.realpriceusd = ['notArrive']
                    this.markettype = TetherCoin
                }
                this.check()
            }
        },

        data() {
            return {
                weeklydata: [],
                realpriceusd: ['notArrive'],
            }
        },

    }
</script>

<style lang="scss" scoped>

    .vtc {
        height: 45px;
        width: 100%;
        max-width: 120px;
    }

    .box1 {
        font-family: VistaDev, sans-serif;
        font-size: 14px;
        background-color: white;
        border-radius: 5px;
        border-top-right-radius: 0;
        overflow: hidden;
        width: 100%;
        /*padding-bottom: 10px;*/
        position: relative;
        // height: 200px;
        min-height: 305px;

    }

    h3 {
        font-size: 16px;
        margin: 10px 0;
    }

    .dark a {
        color: white;
    }

    table {
        width: 100%;
        align-items: center;
        /*border-radius: 5px;*/
        border-collapse: collapse;
        direction: rtl;
    }

    .table-header {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        align-items: center;
        text-align: center;
        height: 40px;
        background-color: var(--main-grey);
        z-index: 2;
        color: #494949;
    }

    .table-row {
        /*position: relative;*/
        transform: scale(1);
        -ms-transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
    }

    .table-header th:first-of-type,
    .table-row td:first-of-type {
        text-align: right;
        padding-right: 15px;
    }

    .table-header th:last-of-type,
    .table-row td:last-of-type {
        text-align: left;
        padding-left: 10px;
        max-width: 90px;

        span {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 5px 0;
        }

    }

    .table-row td {
        color: #494949;
        align-items: center;
        text-align: center;
        cursor: pointer;
    }

    .table-row:hover {
        background-color: var(--hover);
    }
</style>