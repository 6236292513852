<template>
    <div class="navbar justify-content-between align-items-center
    pl-xl-3 pr-xl-3 pl-lg-2 pr-lg-2 pl-md-1 pr-md-1">
        <div class="menu justify-content-start">
            <div class="menuimg">
                <img @click="nav=true" class="icon col-auto" src="../../assets/menu.svg" alt="menuicon">
            </div>
            <blog-touch-menu :style="{transform: [nav? 'translateX(0)' : '']}" :currentroute="currentroute"
                             @close="nav=false"
                             @open="open($event)" @search="searchmenu=true"></blog-touch-menu>
            <touch-menu-content :style="{transform: [op? 'translateX(0)' : '']}" @close="op=false" :contents="contents"
                                :currentroute="currentroute" :header="contentheader"></touch-menu-content>
            <touch-menu-search
                    :style="{width: [searchmenu? '100%' : ''],transform: [searchmenu? 'translateX(0)' : ''],}"
                    @close="searchmenu=false"></touch-menu-search>
            <div class="sub-menu2">
                <img @click="twitter" src="../../assets/icon/Twitter.svg" alt="Twitter">
                <img @click="telegram" src="../../assets/icon/Telegram.svg" alt="Telegram">
                <img @click="instagram" src="../../assets/icon/Instagram.svg" alt="Instagram">
            </div>
        </div>
              <a href="/" class="no-gutters" style="width:110px;height:51px"><img style="width:50px;height:51px" src="../../assets/logo1.svg" alt=""></a>

    </div>
</template>

<script>

    // import HelpDropDownHover from "@/components/HelpDropDownHover";

    // import DropContentHover from "@/components/Tools/DropContentHover";
    import BlogTouchMenu from "@/components/Navbar/TouchMenu/BlogTouchMenu";
    import TouchMenuContent from "@/components/Navbar/TouchMenu/touchMenuContent";
    import TouchMenuSearch from "@/components/Navbar/TouchMenu/touchMenuSerach";

    export default {
        name: "BlogNavBar",
        components: {TouchMenuSearch, TouchMenuContent, BlogTouchMenu},
        data() {
            return {
                mobile: false,
                currentroute: '',
                dropname: '',
                search: '',
                screanWidth: screen.width,
                searcheshow: false,
                contentheader: '',
                newscontents: [
                    {
                        name: 'اخبار بیت‌کوین',
                        link: '/blog/index',
                        route: 'News'
                    },
                    {
                        name: 'اخبار اتریوم',
                        link: '/blog/index',
                        route: 'News'
                    },
                    {
                        name: 'اخبار بیت‌کوین کش',
                        link: '/blog/index',
                        route: 'News'
                    },
                    {
                        name: 'اخبار لایت کوین',
                        link: '/blog/index',
                        route: 'News'
                    },

                    {
                        name: 'اخبار دش کوین',
                        link: '/blog/index',
                        route: 'News'
                    },
                    {
                        name: 'اخبار تتر',
                        link: '/blog/index',
                        route: 'News'
                    },
                ],
                baseknowledgecontents: [
                    {
                        name: 'آشنایی با بیت‌کوین',
                        link: '/blog/index',
                        route: 'Base'
                    },
                    {
                        name: 'آشنایی با اتریوم',
                        link: '/blog/index',
                        route: 'Base'
                    },
                    {
                        name: 'آشنایی با بیت‌کوین کش',
                        link: '/blog/index',
                        route: 'Base'
                    },
                    {
                        name: 'آشنایی با لایت‌کوین',
                        link: '/blog/index',
                        route: 'Base'
                    },
                    {
                        name: 'آشنایی با دش کوین',
                        link: '/blog/index',
                        route: 'Base'
                    },
                    {
                        name: 'آشنایی با تتر',
                        link: '/blog/index',
                        route: 'Base'
                    },
                ],
                articlecontents: [
                    {
                        name: 'آخرین مطالب',
                        link: '/blog/index',
                        route: 'PostIndex'
                    },
                    {
                        name: 'مقالات منتخب',
                        link: '/blog/index',
                        route: ''
                    },
                    {
                        name: 'مقالات استخراج',
                        link: '/blog/index',
                        route: ''
                    },
                    {
                        name: 'مقالات سرمایه گذاری',
                        link: '/blog/index',
                        route: ''
                    }
                ],
                posts: [
                    {
                        description: 'حجم معاملات اتریوم رکورد تاریخی زد؛ ارزش آن …'
                    },
                    {
                        description: 'حجم معاملات اتریوم رکورد تاریخی زد؛ ارزش آن …'
                    },
                    {
                        description: 'حجم معاملات اتریوم رکورد تاریخی زد؛ ارزش آن …'
                    },
                    {
                        description: 'حجم معاملات اتریوم رکورد تاریخی زد؛ ارزش آن …'
                    },
                    {
                        description: 'حجم معاملات اتریوم رکورد تاریخی زد؛ ارزش آن …'
                    },
                    {
                        description: 'حجم معاملات اتریوم رکورد تاریخی زد؛ ارزش آن …'
                    },
                ],
                deg: [90, 90, 90, 90],
                show: [false, false, false, false],
                nav: false,
                op: false,
                searchmenu: false,
                contents: [],
                searchtarget: 'اتریوم',
                results: []
            }
        },
        watch: {
            $route(to) {
                this.nav = false
                this.op = false
                this.searchmenu = false
                this.currentroute = to.name
                this.RouteName()
            }
        },
        mounted() {
            this.currentroute = this.$route.name;
            window.addEventListener('resize', this.windowssize)
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.windowssize)
        },
        methods: {
            RouteName() {
                this.currentroute = this.$route.name;
                if (this.currentroute === 'Trade') {
                    this.trade = true
                } else {
                    this.trade = false
                }
            },
            search1() {
                if (this.search.length >= 3) {
                    this.results = []
                    this.searcheshow = true
                    this.posts.forEach(value => {
                        var l = this.searchtarget.length
                        var a = value.description.search(this.searchtarget)
                        var first = value.description.slice(0, a)
                        var last = value.description.slice(a + l)
                        this.results.push({
                            first: first,
                            last: last
                        })
                    })
                } else {
                    (this.searcheshow = false)
                }

            },
            windowssize() {
                this.screanWidth = screen.width
                if (this.screanWidth > 800) {
                    this.nav = false
                    this.deg = [90, 90, 90, 90]
                    this.show = [false, false, false, false]
                }
            },
            Route(e) {
                this.nav = false
                this.deg = [90, 90, 90, 90]
                this.show = [false, false, false, false]
                this.$router.push('/' + e)
            },
            touchdropshow(e) {
                if (!this.show[e]) {
                    this.show[e] = true
                    this.deg[e] = 0
                } else {
                    this.show[e] = false
                    this.deg[e] = 90
                }
                this.$forceUpdate()
            },
            open(e) {
                switch (e) {
                    case 'article':
                        this.contents = this.articlecontents
                        this.contentheader = 'مقالات'
                        this.op = true
                        break;
                    case 'news':
                        this.contents = this.newscontents
                        this.contentheader = 'اخبار'
                        this.op = true
                        break;
                    case 'base':
                        this.contents = this.baseknowledgecontents
                        this.contentheader = 'مفاهیم پایه'
                        this.op = true
                }
            },
            twitter() {
                // window.location.href = 'https://twitter.com';
                window.open(
                    'https://twitter.com',
                    '_blank' // <- This is what makes it open in a new window.
                );
            },
            instagram() {
                window.open(
                    'http://instagram.com',
                    '_blank' // <- This is what makes it open in a new window.
                );
            },
            telegram() {
                window.open(
                    'https://telegram.org',
                    '_blank' // <- This is what makes it open in a new window.
                );
            }
        }
    }
</script>

<style scoped>
    .navbar {
        width: 100%;
        box-sizing: inherit;
        background-color: var(--navbar-back);
        min-height: 54px;
        position: fixed;
        position: -ms-device-fixed;
        /*position: sticky;*/
        /*position: -webkit-sticky;*/
        /*display: block;*/
        top: 0;
        box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
        z-index: 4;
        color: white;
        display: flex;
        flex-direction: row;

    }

    .logo {
        height: 40px;
        max-width: 135px;
        cursor: pointer;
        background-image: url("../../assets/Bitraman.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .menu {
        position: revert;
        display: flex;
        /*justify-content: space-between;*/
        width: 100%;
        /*color: white;*/
        font-size: 14px;
    }

    .logo {
        height: 40px;
        width: 140px;
        cursor: pointer;
        background-image: url("../../assets/rocket.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .menuimg {
        display: none;
        flex-direction: column;
        align-items: center;
    }


    .menu .icon {
        height: 50px;
        width: 50px;
    }

    div a {
        /*color: white;*/
    }

    .sub-menu2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        max-width: 320px;
    }

    .sub-menu2 img {
        height: 22px;
        /*width: 73px;*/
        padding: 0 20px;
        cursor: pointer;
        /*border-left: solid var(--light-border) 2px;*/
        color: rgba(0, 0, 0, 0.8);
    }

    @media (max-width: 768px) {
        .menuimg {
            display: flex;
        }

        .sub-menu2,
        .main-searchbox {
            display: none !important;
        }
    }

    .drop_btn {
        cursor: pointer;
    }

    .dropdown {
        position: relative;
    }

    .drop {
        display: none;
    }

    .dropdown:hover .drop {
        display: block !important;
    }

</style>