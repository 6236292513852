<template>
    <div class="main-box">
        <h1>کارمزد ها</h1>
        <div class="content">
            <svg width="13px" height="13px">
                <circle r="6px" cx="6" cy="6"></circle>
            </svg>
            <h2>کارمزد معاملات</h2>
            <p>کارمزد معاملات در سامانه ما بصورت درصدی از مبلغ کل معامله است و محاسبه‌ی آن بر اساس ملاحظات زیر صورت
                می‌گیرد. لازم به توضیح است که کسر کارمزد از معاملات باعث جلوگیری از ثبت معاملات صوری و مکرر خواهد شد و
                شرایط مطلوب‌تری را در بازار برای تمامی کاربران ایجاد می‌کند.</p>
            <ul>
                <li>
                    <a>
                        کارمزد خریدار ارزدیجیتال به صورت ارزدیجیتال کسر و کارمزد فروشنده ارزدیجیتال از مبلغ دریافتی به
                        تومان
                        کسر می شود.
                    </a>
                </li>
                <li>
                    <a>
                        کارمزد از هر دو سمت معامله کسر می شود.
                    </a>
                </li>
                <li>
                    <a>
                        در هنگام ثبت معاملات، میزان کارمزد به شما نمایش داده می شود.
                    </a>
                </li>
            </ul>
            <div class="table" style="max-width: 580px">
                <table>
                    <tr>
                        <th>بازه معامله</th>
                        <th>میزان کارمزد</th>
                        <th>سطح کاربر</th>
                    </tr>

                    <tr>
                        <td>کمتر از ۲۰ میلیون تومان</td>
                        <td>۰.۴%</td>
                        <td>سطح برنزی</td>
                    </tr>
                    <tr>
                        <td>۵۰-۲۰ میلیون تومان</td>
                        <td>۰.۳۵%</td>
                        <td>سطح برنزی</td>
                    </tr>
                    <tr>
                        <td>۱۰۰-۵۰ میلیون تومان</td>
                        <td>۰.۳%</td>
                        <td>سطح برنزی</td>
                    </tr>
                    <tr>
                        <td>۲۰۰-۱۰۰ میلیون تومان</td>
                        <td>۰.۲۵%</td>
                        <td>سطح برنزی</td>
                    </tr>
                    <tr>
                        <td>بیشتر از ۲۰۰ میلیون تومان</td>
                        <td>۰.۲%</td>
                        <td>سطح برنزی</td>
                    </tr>
                </table>
            </div>

            <p>لازم به ذکر است کارمزد معاملات جفت ارز (به طور مثال بیت کوین - تتر) به صورت ثابت ۰.۲% در نظر گرفته می
                شود.</p>
        </div>
        <div class="content">
            <svg width="13px" height="13px">
                <circle r="6px" cx="6" cy="6"></circle>
            </svg>
            <h2>کارمزد واریز و برداشت ارز دیجیتال</h2>
            <ul>
                <li>
                    <a>
                        کارمزد واریز تمامی دارایی‌های دیجیتال (از جمله بیت‌کوین، اتریوم، لایت‌کوین، ریپل، بیت‌کوین کش،
                        بایننس کوین، تتر و ...) صفر است.
                    </a>
                </li>
                <li>
                    <a>
                        کارمزد برداشت دارایی‌های دیجیتال به نوع ارز بستگی دارد. این هزینه در واقع به دلیل کارمزدهای
                        تراکنش
                        شبکه کسر می‌شود و ما در آن نقشی نداریم.
                    </a>
                </li>
            </ul>
            <div class="table" style="max-width: 390px !important;">
                <table>
                    <tr>
                        <th>نوع ارز</th>
                        <th>میزان کارمزد</th>
                    </tr>

                    <tr>
                        <td>بیت‌کوین</td>
                        <td>BTC 0/00025</td>
                    </tr>
                    <tr>
                        <td>اتریوم</td>
                        <td>ETH 0/0025</td>
                    </tr>
                    <tr>
                        <td>بیت‌کوین کش</td>
                        <td>BCH 0/0001</td>
                    </tr>
                    <tr>
                        <td>لایت کوین</td>
                        <td>LTC 0/001</td>
                    </tr>
                    <tr>
                        <td>دش</td>
                        <td>DASH 0/002</td>
                    </tr>
                    <tr>
                        <td>تتر</td>
                        <td>USDT 1/5</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="content">
            <svg width="13px" height="13px">
                <circle r="6px" cx="6" cy="6"></circle>
            </svg>
            <h2>کارمزد برداشت ریالی</h2>

            <p>برداشت‌های ریالی برای تمامی حساب‌ها، در قالب انتقال پایا و یک بار در روز خواهد بود. بدیهی است مدت زمان
                انجام تسویه ریالی، بسته به زمان درخواست برداشت، در روزهای غیر تعطیل می‌تواند حداکثر تا ۲۴ ساعت به طول
                انجامد.</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Wage",
    }
</script>

<style scoped>

    .main-box {
        display: flex;
        flex-direction: column;
        /*align-self: flex-start;*/
        /*background-color: white;*/
        direction: rtl;
        text-align: right;
        box-sizing: border-box;
        width: 100%;
        max-width: 1300px;
        padding: 0 25px;
        margin: 56px 0;
        font-size: 18px;
        font-family: VistaDev, sans-serif;
        /*position: absolute;*/
        top: 0;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-content: center;
        width: 100%;
        margin-bottom: 50px;
        position: relative;
    }

    .content svg {
        position: absolute;
        right: -20px;
        top: 8px;
        fill: var(--main-green);
    }

    h1 {
        font-size: 22px;
        margin: 50px 0 50px 0;
    }

    h2 {
        font-family: IRANSans-Medium, sans-serif;
        font-weight: 500;
        font-size: 18px;
        margin-top: 0;
        margin-bottom: 10px;
    }

    p {
        margin: 10px 0;
        text-align: justify;
    }

    ul {
        margin: 10px 0 0 0;
        list-style-position: inside;
    }

    ul li {
        margin-bottom: 10px;
        color: var(--main-green);
        text-align: justify;
    }

    ul li:last-of-type {
        margin-bottom: 0;
        color: var(--main-green);
    }

    .table{
        display: table;
        border: solid 1px #00000010;
        border-radius: 15px;
        width: 100%;
        align-self: center;
        margin: 30px 0;
    }

    table {
        font-size: 18px;
        /*border: solid 1px #00000010;*/
        /*border-radius: 15px;*/
        /*background-color: #ffffff;*/
        text-align: center;
        align-self: center;
        /*margin: 30px 0;*/
        width: 100%;
        /*max-width: 350px;*/
        border-collapse: collapse;
    }

    th, td {
        height: 52px;
        border-left:  #00000010 2px solid;;
    }
    th:last-of-type, td:last-of-type {
        border-left: none;
    }

    th {
        border-bottom: var(--main-green) 2px solid;
    }
    th:first-of-type {
        min-width: 100px;
    }

    tr {
        border-bottom: #00000010 2px solid;
    }
    tr:last-of-type{
        border-bottom: none;
    }

</style>