<template>
    <div class="dropbox">
        <div class="dropbox1">
            <div v-for="(content,index) in contents"
                 :key="index"
                 class="dropbox-contents"
                 @click="Route(content.link)"
                 :class="{selected : route === content.route}">
                <div class="contents">{{content.name}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DropContentHover",
        props: ['contents', 'name','route'],
        methods: {
            Route(e) {
                this.$router.push(e)
            }
        },
    }
</script>

<style scoped>

    .dropbox {
        padding-top: 17px;
        font-size: 14px;
        position: absolute;
        z-index: 5;
        display: block;
        right: 50%;
        transform: translate(50%, 0);
    }

    .dropbox1 {
        color: white;
        background-color: var(--dropdown-back);
        text-align: right;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        transition: 0.5s;
        z-index: 500;
    }

    .dropbox-contents {
        padding: 10px 10px 10px 15px;
        transition: 0.25s;
        cursor: pointer;
        position: relative;
        border-bottom: solid 2px rgba(0, 0, 0, 0.25);
    }

    .dropbox-contents:first-of-type {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    }

    .dropbox-contents:last-of-type {
        border-bottom: none;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .contents {
        width: max-content;
    }

    .dropbox-contents:hover {
        background-color: var(--hover);
    }

    /*.dropbox-contents::after {*/
    /*    content: '';*/
    /*    position: absolute;*/
    /*    margin-top: 8px;*/
    /*    right: 0;*/
    /*    background-color: #000000;*/
    /*    height: 2px;*/
    /*    width: 100%;*/
    /*    opacity: 0.1;*/
    /*}*/

    .arrow {
        fill: white;
        margin-right: 5px;
        transition: 0.25s;
    }


    .selected {
        color: var(--main-green);
        border-bottom: var(--main-green) solid 2px;
        cursor: default;
    }

</style>