<template>
    <div class="main-box">
        <h1 style="text-align: right">اعلانات</h1>
        <perfect-scrollbar :options="setting" @ps-y-reach-end="load" style="padding-right:10px" v-if="notif">
            <div v-if="notif">
                    <div class="message-block" style="direction: rtl"
                        v-for="(message,index) in messages"
                        :key="index">
                        <div @click.prevent="toggle(index)" class="message-header" :style="{cursor: [loading? 'wait':'']}">
                            <div class="header-header">
                                <svg height="12" width="12" style="margin-right: 5px">
                                    <circle cx="6" cy="6" r="5px" :fill="[message.read ? '#00000030' : '#10d078']"/>
                                </svg>
                                <img src="../../assets/icon/mail.svg" alt="">
                                <h3>{{message.title}}</h3>
                            </div>
                            <h3 class="date" style="direction:ltr" v-if="notif">{{message.createdAtDateTime.slice(0,10)}} {{message.createdAtDateTime.slice(11,16)}}</h3>
                            <img v-show="!showmessage[index]" width="15px" height="15px" src="../../assets/icon/plus.svg"
                                alt="">
                            <img v-show="showmessage[index]" width="15px" height="15px" src="../../assets/icon/minus.svg"
                                alt="">
                        </div>
                        <div class="message-description" :style="{maxHeight: [showmessage[index]? '150px' : '']}">
                            <p>{{message.content}}</p>
                        </div>
                </div>
            </div>
            
            
        </perfect-scrollbar>
        <h4 style="text-align:center;color:#111" v-else>پیامی جهت نمایش وجود ندارد.</h4>
<!--        <pagination style="margin-top: 30px" :n="6" :max="3" :page="id" :link="'Messages'"></pagination>-->
        <!-- <button @click="check">click</button> -->
    </div>
</template>

<script>

    // import Pagination from "../Tools/Pagination";

    export default {
        name: "Messages",
        props: ['id'],
        // components:
        data() {
            return {
                time:'',
                setTime:'',
                notif:true,
                showmessage: [],
                res:'',
                messages: [],
                loading:false,
                setting: {
                    suppressScrollX: true
                }
            }
        },
        mounted(){
            this.getMessages()
        },
        methods: {
            getMessages(){
                this.$axios.get('/users/notifications?read=&page=1&size=6')
                .then(response => {this.res = response.data;
                    console.log(this.res)
                    if (this.res.message === 'No Notifications'){
                        this.messages = null
                        console.log('no',this.messages)
                        this.notif = false
                    }else{
                        console.log('yes')
                        this.messages = this.res.content
                    }
                    
                    
                    
                })
                .catch(error=>{
                    console.log(error,'/////////////',error.message,error.status)
                })
            },
            check(){
                console.log(this.messages)
            },
            toggle(e) {
                console.log('god',e)
                this.showmessage[e] = !this.showmessage[e];
                this.$forceUpdate()
                this.readMessage(e)
            },
            readMessage(e) {
                        if (this.messages[e].read){
                            console.log('already read')
                        }else{
                            this.$axios.put(`/users/notifications/read/${this.messages[e].id}`)
                                .then(response=>{console.log(response) === 'Notification Read Successfully'
                                    console.log('it is')
                                    this.messages[e].read = true
                                })
                            }
                // this.$set(this.messages[e], 'read', 1)
                
                // console.log(this.messages[e].read)
                this.$forceUpdate()
                
            },
            load() {
                // this.loading = true;
                // document.body.style.cursor = 'wait';
            }
        },

    }
</script>

<style lang="scss" scoped>
    .ps {
        height: 550px;
    }

    .date {
        margin-right: -135px;
    }

    @media (max-width: 576px) {
        .date {
            margin-right: -70px;
        }
    }

    .main-box {
        display: flex;
        flex-direction: column;
        /*align-self: flex-start;*/
        /*background-color: white;*/
        position: relative;
        top: 0;
        /*direction: ltr;*/
        text-align: right;
        box-sizing: border-box;
        width: 100%;
        max-width: 1300px;
        padding: 0 25px;
        margin: 56px 0;
        font-size: 18px;
        font-family: VistaDev, sans-serif;
    }

    h1 {
        font-size: 22px;
        margin-right: 20px;
        margin-top: 50px;
    }

    .message-block {
        display: block;
        flex-direction: column;
        align-items: center;
        /*background-color: white;*/
        text-align: right;
        border-bottom: #00000015 solid 1px;
        box-sizing: border-box;
        width: 100%;
        font-size: 18px;
        transition: 0.3s;
    }

    .message-header {
        position: relative;
        display: flex;
        height: 61px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin-top: 15px;

        img {
            margin-left: 10px;
        }
    }

    .message-header h3 {
        font-family: VistaDev, sans-serif;
        font-weight: 300;
        font-size: 18px;
        opacity: 0.8;
    }

    .message-description {
        font-size: 18px;
        overflow: hidden;
        transition: 0.4s ease-out;
        -webkit-transition: 0.4s ease-out;
        -moz-transition: 0.4s ease-out;
        max-height: 0;

        p {
            margin-top: 0;
            opacity: 0.8;
        }
    }

    .header-header {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
            margin: 0 10px
        }
    }

</style>