<template>
    <div v-show="!logedin || home" class="footer">
        <div class="footer-content row ml-2 mr-2">
            <div class="footer-list col-md-3 col-6 px-3">
                <div>
                    <p>درباره ما</p>
                    <ul>
                        <li><a @click="()=>this.$router.push('/about-us')">درباره ما</a></li>
                        <li><a @click="()=>this.$router.push('/wage')">کارمزدها</a></li>
                        <li><a @click="()=>this.$router.push('/policy')">قوانین</a></li>
                    </ul>
                </div>
            </div>
            <div class="footer-list col-md-3 col-6 px-3">
                <div>
                    <p>نحوه استفاده</p>
                    <ul>
                        <li><a @click="()=>this.$router.push('/howtouse')">راهنمای استفاده</a></li>
                        <li><a @click="()=>this.$router.push('/faq')">سوالات متداول</a></li>
                        <li><a @click="()=>this.$router.push('/blog')">بلاگ</a></li>
                    </ul>
                </div>
            </div>
            <div class="footer-list col-md-3 col-12 px-3">
                <div>
                    <p>تماس با ما</p>
                    <ul>
                        <li><a>آدرس : {{info.address}}</a></li>
                        <li><a>تلفن : {{info.number}}</a></li>
                        <li><a>ایمیل : {{info.email}}</a></li>
                    </ul>
                </div>
            </div>
            <div style="display: flex;flex-direction: column;align-items: center" class="col-md-3 col-12 px-3 order-first order-md-last">
                <img @click="$router.push('/')" style="max-width: 250px" src="../../assets/logo1.svg" alt="">
            </div>
        </div>
        <div class="footer-social">
            <div class="row m-2 no-gutters">
                <div class="col-md-6 col-12 justify-content-center justify-content-md-start"
                     style="cursor: default;display: flex;color: var(--main-green)">
                    کلیه حقوق این سایت متعلق به شرکت والبیتکس می‌باشد.
                    طراحی شده توسط شرکت <a href="https://vistaapp.ir" target="_blank" style="color:var(--main-green);text-decoration:underline;margin-right:5px">ویستا</a>
                </div>
                <div class="col-md-6 col-12 social justify-content-md-end justify-content-center row no-gutters m-0">
                    <a class="col-12 col-md-4">ما در شبکه های اجتماعی:</a>
                    <div class="social col-6 col-md-4 justify-content-around justify-content-sm-between">
                        <a :href="info.twitter.includes('http')? info.twitter: `https://twitter.com/${info.twitter}`" target="_blank"><img src="../../assets/icon/Twitter.svg" alt="Twitter"></a>
                        <a :href="info.telegram.includes('http')? info.telegram: `https://telegram.org/${info.telegram}`" target="_blank"><img src="../../assets/icon/Telegram.svg" alt="Telegram"></a>
                        <a :href="info.linkedin.includes('http')? info.linkedin: `https://linkedin.com/${info.linkedin}`" target="_blank"><img src="../../assets/icon/LinkedIn.svg" alt="LinkedIn"></a>
                        <a :href="info.instagram.includes('http')? info.instagram: `https://instagram.com/${info.instagram}`" target="_blank"><img src="../../assets/icon/Instagram.svg" alt="Instagram"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer",
        data() {
            return {
                home: true,
                logedin: false,
                info:
                   {
                        "address": "",
                        "appAndroid": "",
                        "appIOS": "",
                        "email": "",
                        "instagram": "",
                        "linkedin": "",
                        "number": "",
                        "telegram": "",
                        "twitter": ""
                    }
            }
        },
        mounted(){
            this.getInfo()
        },
        methods: {
            getInfo(){
                this.$axios.get('/exchange-info')
                    .then(res=>{
                        this.info = res.data.baseDTO
                        this.state.appAndroid = this.info.appAndroid
                        this.state.appIOS = this.info.appIOS
                    })
            },
        }
    }
</script>

<style scoped>
    .footer {
        display: flex;
        flex-direction: column;
        background-color: var(--dark-main);
        align-items: center;
    }

    .footer-content {
        position: relative;
        width: 100%;
        max-width: 1180px;
        margin-top: 50px;
        padding-bottom: 50px;
        opacity: 0.8;
    }

    .footer-content::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 50%;
        transform: translate(50%, 0);
        background-color: var(--light-border);
        opacity: 0.8;
        width: 98%;
        height: 1px;
    }

    .footer-content img {
        width: 75%;
        /*margin-right: 5px;*/
    }

    .footer-list {
        color: white;
        text-align: right;
        display: flex;
        flex-direction: column;
    }

    .footer-list p {
        font-size: 21px;
        color: var(--main-green);
        cursor: default;
    }
    .footer-list a{
        color: white;
    }
    @media (max-width: 768px){
        .footer-list{
            text-align: center;
        }
    }

    ul {
        margin-top: 20px;
    }

    li {
        list-style: none;
        line-height: 1.79;
        margin-bottom: 15px;
    }

    li a {
        font-size: 14px !important;
        font-weight: 500;
        opacity: 0.8;
        cursor: pointer !important;

    }

    li a:hover {
        color: var(--main-green);
    }

    .footer-list:last-of-type li a:hover {
        color: #ffffff;
        cursor: text !important;
    }

    .footer-social {
        width: 100%;
        max-width: 1180px;
        opacity: 0.8;
        font-family: IRANSans;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        justify-content: center;
    }

    .social {
        display: flex;
    }

    .social a {
        color: white;
        cursor: default;
        vertical-align: super;
    }

    .social img {
        cursor: pointer;
        transition: 0.5s;
    }

    .social img:hover {
        filter: invert(77%) sepia(79%) saturate(3719%) hue-rotate(40deg) brightness(250%) contrast(60%);
        transform: rotate(360deg);
    }
</style>