<template>
    <div class="Auth-box">
        <LoadingModal v-if="showModalLoading"/>
        <div class="Auth-header">
            <h3>شناسایی دو عاملی</h3>
        </div>
        <div class="Auth">
            <p>کد تایید دومرحله ای که برنامه Google Authenticator تولید کرده است را وارد نمایید.</p>
                <!-- <CodeInput class="auth-row"
                       @change="onChange" @complete="submit"
                       :fields="num" ref="code-input" :fieldWidth="45" :fieldHeight="45"/> -->
            <input class="auth-row new-input" type="text" v-model="code" maxlength="6">
        </div>
        <button class="normal-btn" @click.prevent="submit" :disabled="code.toString().length < num">تایید
        </button>
        <a style="opacity: 0.8">{{error}}</a>
    </div>
</template>

<script>
import LoadingModal from '../LoadingModal'
// import CodeInput from "vue-verification-code-input";
    export default {
        name: "TwoStepGoogleAuth",
        data() {
            return {
                
                smscode: [],
                error: '',
                num: 6,
                code: '',
                showModalLoading:false
            }
        },
        components: {
            // CodeInput,
            LoadingModal
        },
        methods: {
            // test(){
            //     console.log(this.code);
            // },
    
            // setPlaceholder() {
            //     for(let i=0;i<this.num;i++){
            //         this.$refs['code-input'].$refs['input_'+i][0].placeholder = '----'
            //     }
            // },
            // onChange(e) {
            //     this.code = e
            // },
            // next(e) {
            //     this.sortNumber()
            //     if (this.smscode[e - 1]) {
            //         if (e < this.num) {
            //             document.getElementById('auth' + e).focus();
            //         }
            //     } else {
            //         if (e > 1) {
            //             document.getElementById('auth' + (e - 2)).focus();
            //         }
            //     }
            // },
            // sortNumber() {
            //     // if (this.smscode.length === this.num) {
            //     var b = ''
            //     for (let i = 0; i < this.smscode.length; i++) {
            //         b = b + this.smscode[i]
            //     }
            //     this.code = Number(b)
            //     // }
            // },
            submit() {
                this.showModalLoading = true
                let req = {
                    'code':`${this.code}`,
                    'email':this.$cookies.get('username')
                }
                this.$axios.post(`users/check-otp-code`,req)
                    .then(response => {
                    this.showModalLoading = false
                    this.state.token = response.data.token;
                    localStorage.token = response.data.token;
                    this.$axios.defaults.headers.common['Authorization'] = 'Bearer '+ this.state.token
                    if (response.data.message === 'token returned successfully.'){
                        this.$router.push('trade')
                    }
                    })
                .catch(() => {
                    this.showModalLoading = false
                })
            }
        },
       
        mounted() {
            // this.setPlaceholder()
        }
    }
</script>

<style lang="scss" scoped>

    input {
        padding: 5px 0;
    }

    .Auth-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 400px;
        height: 100%;
    }
    .Auth{
        margin-bottom:  70px;
    }

    .countdown {
        font-size: 14px;
        text-decoration: underline;
    }

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
    }

    .auth-row {
        display: flex;
        direction: ltr;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 45px;
        margin-top: 10px;
        border: 1px solid var(--light-border);
        border-radius: 15px;
    }

    .Auth-input {
        font-size: 25px;
        border-radius: 5px;
        /*border: solid 1px #00000010;*/
        width: 40px;
        height: 100%;
        text-align: center;
        letter-spacing: -2px;
        /*padding: 0;*/

        &:focus {
            border-color: var(--main-orange);
        }
    }

    p {
        margin: 20px 0 20px;
        text-align: right;
    }

    .normal-btn {
        margin-bottom: 80px;
    }
</style>