<template>
    <div class="touch-menu" style="padding-bottom: 10px">
        <div class="touch-menu-header justify-content-end">
            <svg height="20px" @click="$emit('close')" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 viewBox="0 0 443.52 443.52" style="enable-background:new 0 0 443.52 443.52;transform: rotate(180deg)"
                 xml:space="preserve" fill="#ffffffaa">
                        <path d="M143.492,221.863L336.226,29.129c6.663-6.664,6.663-17.468,0-24.132c-6.665-6.662-17.468-6.662-24.132,0l-204.8,204.8
                        c-6.662,6.664-6.662,17.468,0,24.132l204.8,204.8c6.78,6.548,17.584,6.36,24.132-0.42c6.387-6.614,6.387-17.099,0-23.712
                        L143.492,221.863z"/>
                    </svg>
        </div>
        <div class="main-searchbox">
            <div class="searchbox">
                <input @input="search1()"
                       name="searchbox"
                       id="searchbox" v-model="search" type="text" placeholder="جستجو در مطالب بلاگ…">
            </div>
            <div v-show="searcheshow" class="searchdropbox">
                <div v-for="(post,index) in results" :key="index" class="searcheresult"
                     @click.prevent="()=>$router.push('/blog/show/'+ 1)">
                    <a>{{post.first}}</a>
                    <a style="color: var(--main-green)">{{searchtarget}}</a>
                    <a>{{post.last}}</a>
                </div>
                <div class="searcheresult other" @click.prevent="()=>$router.push('/blog/index')">
                    <a>مشاهده تمام مطالب ...</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "touchMenuSearch",
        data(){
            return{
                searcheshow: false,
                search:'',
                searchmenu: false,
                searchtarget: 'اتریوم',
                results: [],
                posts: [
                    {
                        description: 'حجم معاملات اتریوم رکورد تاریخی زد؛ ارزش آن …'
                    },
                    {
                        description: 'حجم معاملات اتریوم رکورد تاریخی زد؛ ارزش آن …'
                    },
                    {
                        description: 'حجم معاملات اتریوم رکورد تاریخی زد؛ ارزش آن …'
                    },
                    {
                        description: 'حجم معاملات اتریوم رکورد تاریخی زد؛ ارزش آن …'
                    },
                    {
                        description: 'حجم معاملات اتریوم رکورد تاریخی زد؛ ارزش آن …'
                    },
                    {
                        description: 'حجم معاملات اتریوم رکورد تاریخی زد؛ ارزش آن …'
                    },
                ],
            }
        },
        methods:{
            search1() {
                if (this.search.length >= 3) {
                    this.results = []
                    this.searcheshow = true
                    this.posts.forEach(value => {
                        var l = this.searchtarget.length
                        var a = value.description.search(this.searchtarget)
                        var first = value.description.slice(0, a)
                        var last = value.description.slice(a + l)
                        this.results.push({
                            first: first,
                            last: last
                        })
                    })
                } else {
                    (this.searcheshow = false)
                }

            },
        }
    }
</script>

<style scoped>
    .touch-menu-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 54px;
        align-items: center;
        border-bottom: solid 1px var(--light-border);
        padding: 0 10px;
    }

    .touch-menu {
        font-size: 18px !important;
        position: absolute;
        /*position: -webkit-sticky;*/
        right: 0;
        top: 0;
        transform: translateX(100%);
        background-color: var(--dark-background);
        display: flex;
        width: 60%;
        flex-direction: column;
        overflow: hidden;
        transition: 0.25s;
        z-index: 5;
        height: 100vh;
        padding: 0 10px;
        color: white;
        /*margin-left: -300px;*/
    }

    .touch-menu a {
        margin: 20px 0;
        text-align: right;
        opacity: 0.8;
        padding-right: 10px;
    }

    .selected a {
        color: var(--main-green);
    }
    .searchbox {
        height: 50px;
        color: black;
        width: 100%;
        min-width: 100px;
        max-width: 575px;
        position: relative;
    }

    .searchbox input {
        height: 100%;
        color: white;
        border-bottom: var(--main-green) solid 2px;
        transition: 0.5s;
        width: 100%;
    }

    .icon {
        width: 50px;
    }

    .main-searchbox {
        position: relative;
        display: flex;
    }

    .main-searchbox input {
        background-image: url("../../../assets/icon/search.svg");
        background-position: 10px center;
        background-repeat: no-repeat;
        padding-left: 40px;
    }

    .searchdropbox {
        margin-top: 41px;
        font-size: 14px;
        position: absolute;
        background-color: var(--dark-background);
        color: white;
        text-align: right;
        /*box-shadow: 0 -2px 20px 1px rgba(0, 0, 0, 0.1);*/
        border-radius: 5px;
        transition: 0.5s;
        z-index: 5;
        display: block;
        right: 50%;
        transform: translate(50%, 0);
        width: 100%;

    }

    .searcheresult {
        padding: 5px 5px 5px 10px;
        position: relative;
        cursor: pointer;
        border-bottom: solid 1px rgba(255, 255, 255, 0.1);
        opacity: 0.8;
    }
    .searcheresult a{
        color: white;
    }

    .searcheresult:last-of-type {
        border-bottom: none;
    }

    .searcheresult:hover {
        background-color: var(--hover);
    }

    .other {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
</style>