<template>
    <transition name="modal-fade">
        <div id="modal" class="modal-backdrop">
            <div class="modal"
                 role="dialog"
                 aria-labelledby="WithdrawModal"
                 aria-describedby="WithdrawModal">
                <img
                        src="../../assets/icon/close.svg"
                        class="btn-close"
                        @click="close"
                        aria-label="Close modal">
                <img>
                <header class="modal-header">
                    واریز ریالی
                </header>
                <section class="modal-body" style="direction: rtl">
                    <p>جهت افزایش اعتبار کیف پول ریالی خود با استفاده از کارت‌های بانکی عضو شبکه شتاب و از طریق
                        درگاه پرداخت، حتما باید از کارت‌های بانکی به نام خودتان که در پروفایل‌تان ثبت و تایید شده
                        است، استفاده نمایید.</p>
                    <form>
                        <div class="row no-gutters input">
                            <label class="col-md-3" for="amount">مبلغ واریزی (تومان)</label>
                            <input @input="seprate" class="col-md-5" type="text" v-model="amount" name="amount" id="amount">
                        </div>
                        <div class="row no-gutters input">
                            <label class="col-md-3" for="walletaddress">آدرس کیف‌پول مقصد</label>
                            <div class="select col-md-9">
                                
                                <select @click.prevent="" class="col-12" v-model="walletaddress" name="walletaddress"
                                        id="walletaddress">
                                    <option v-for="(Bank,index) in banksName" :key="index" :value="index">{{Bank}}</option>
                                </select>
                                <a>&nbsp;</a>
                            </div>
                            <div class="col-md-9 offset-md-3 link" @click="()=>this.$router.push('/profile')">افزودن
                                حساب جدید
                            </div>
                        </div>
                    </form>
                </section>
                <footer class="modal-btn">
                    <button :disabled="!amount || typeof walletaddress !== 'number'"
                            type="button"
                            class="normal-btn"
                            @click="deposit"
                            aria-label="Close modal">
                        انتقال به درگاه پرداخت
                    </button>
                </footer>
                <LoadingModal style="position:absolute" v-if="showLoad"/>
            </div>
            <ErrorNotification />
        </div>
        
    </transition>
</template>

<script>
import LoadingModal from '../LoadingModal';
import ErrorNotification from "../Tools/ErrorNotification";

    export default {
        name: "DepositModalRial",
        components:{LoadingModal,ErrorNotification},
        props: {
            walletamount:{},
            banks:{}
        },
        watch:{
            banks(val){
                console.log(val);
                this.banksName = val
            }
        },
        data() {
            return {
                amount: '',
                walletaddress: '',
                twostepcode: '',
                banksName:[],
                showLoad:false
            }
        },
        methods: {
            showBank(){
                console.log(this.banks);
                this.$forceUpdate()
               
            },
            close() {
                this.$emit('close');
            },
            deposit(){
                this.showLoad = true
                let walletInfo = {
                    amount:this.amount.replace(/,/ig, ''),
                    paymentGateway:'Vandar',
                    cardNumber:this.banksName[this.walletaddress],
                    callBackURL:"http://localhost:8080/redirect-from-bank"
                }
                console.log(walletInfo);
                this.$axios.post('/wallets/deposit-toman',walletInfo)
                    .then(res=>{
                        console.log(res.data.baseDTO.redirectSite)
                        window.open(res.data.baseDTO.redirectSite,"_self")
                        if (res.data.message === "Deposit Request Created Successfully"){
                            this.$emit('close');
                        }
                    })
                    .catch(err=>{
                        console.log(err.response);
                        if (err.response.status === 400){
                            this.state.errorMessage = "خطایی رخ داده است. مجددا تلاش کنید";
                            this.state.showError = true;
                            setTimeout(() => {
                                this.$emit('close');
                            }, 3000);
                            
                        }
                    })
                    .then(()=>{
                        this.showLoad = false
                    })
            },
            seprate(){
                var a = Number(this.amount.replace(/,/ig, ''))
                if(!isNaN(a)){
                    this.amount = a.toLocaleString()
                }
            }
        },
    }
</script>

<style scoped>

    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
    }

    .modal {
        position: relative;
        background: var(--account-back);
        border-radius: 5px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 950px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        /*padding: 10px 10px;*/
    }

    .modal-header {
        color: white;
        font-size: 20px;
        height: 70px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10%;
    }

    .btn-close {
        position: absolute;
        left: 10%;
        top: 25px;
        height: 22px;
        width: 22px;
        transition: 0.5s;
        cursor: pointer;
        z-index: 5;
    }

    .btn-close:hover {
        transform: rotate(180deg);
    }

    .modal-body {
        /*margin-top: 20px;*/
        position: relative;
        background-color: white;
        /*border-top-right-radius: 5px;*/
        /*border-top-left-radius: 5px;*/
        padding: 0 10% 20px;
    }

    .modal-fade-enter,
    .modal-fade-leave-active {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .25s ease;
    }

    form {
        font-family: VistaDev;
        margin: 20px 0;
        border-radius: 5px;
        width: 100%;
        font-size: 14px;
    }

    .input {
        margin-bottom: 20px;
        position: relative;
    }

    label {
        font-size: 16px;
        margin-top: auto;
        margin-bottom: auto;
    }

    input,
    select{
        border: solid rgba(54, 52, 53, 0.2) 0.5px;
        height: 39px;
        border-radius: 5px;
        padding: 2px 5px !important;
    }

    input:hover,
    select:hover{
        border: solid rgba(54, 52, 53, 0.3) 0.5px;
    }

    input:focus,
    select:focus{
        border: solid rgba(54, 52, 53, 0.5) 0.5px;
    }


    .modal-btn {
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: white;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .normal-btn {
        font-size: 16px;
        color: white;
        max-width: 200px;
        margin: 0;
        border-radius: 10px;
        height: 40px;
        padding: 0;
    }

    .link {
        text-decoration: underline;
        cursor: pointer;
    }

    select {
        border: solid rgba(54, 52, 53, 0.15) 0.5px;
        height: 39px;
        border-radius: 5px;
        padding: 2px 5px !important;
        font-size: 16px;
    }


    .select{
        overflow: hidden;
        position: relative;
    }

    .select a {
        position: absolute;
        top: 50%;
        left: 15px;
        width: 11px;
        height: 11px;
        border-left: black solid 4px;
        border-radius: 1px;
        border-bottom: black solid 4px;
        transform: translate(0,-65%) rotate(-45deg);
        transition: 0.25s;
    }
</style>
