<template>
    <div class="main-box">
        <div class="content">
            <svg width="13px" height="13px">
                <circle r="6px" cx="6" cy="6"></circle>
            </svg>
            <h2>قوانین و مقررات والبیتکس</h2>
            <froalaView v-model="content"></froalaView>
        </div>
    </div>
</template>

<script>

    export default {
        name: "Policy",
        data(){
            return{
                content: ''
            }
        },
        mounted(){
            this.$axios.get('/exchange-info')
            .then(res=>{
                this.content = res.data.baseDTO.privacyPolicy
            })
        }
    }
</script>

<style scoped>

    .main-box {
        display: flex;
        flex-direction: column;
        /*align-self: flex-start;*/
        /*background-color: white;*/
        position: absolute;
        top:0;
        direction: rtl;
        text-align: right;
        box-sizing: border-box;
        width: 100%;
        max-width: 1300px;
        padding: 0 25px;
        margin: 56px 0;
        font-size: 18px;
        font-family: VistaDev, sans-serif;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-content: center;
        width: 100%;
        margin-bottom: 50px;
        position: relative;
    }
    .content:first-of-type{
        margin-top: 50px;
    }

    .content svg {
        position: absolute;
        right: -20px;
        top: 8px;
        fill: var(--main-green);
    }

    h1 {
        font-size: 22px;
        margin: 50px 0 50px 0;
    }

    h2 {
        font-family: IRANSans-Medium, sans-serif;
        font-weight: 500;
        font-size: 18px;
        margin-top: 0;
        margin-bottom: 10px;
    }

    p {
        margin: 15px 0;
        text-align: justify;
        line-height: 30px;
    }

    ul {
        margin: 10px 0 0 0;
        list-style-position: inside;
    }

    ul li {
        margin-bottom: 10px;
        color: var(--main-green);
        text-align: justify;
    }

    ul li:last-of-type {
        margin-bottom: 0;
        color: var(--main-green);
    }
</style>