<template>
    <div class="box-sub">
        <h2>تایید هویت</h2>
        <div class="form">
            <p>
                جهت احراز هویت در سامانه ی ما لازم است تصویر سلفی خود را با کارت ملی تان و یک برگه با متن «جهت عضویت در
                سایت » با تاریخ کامل و امضا ارسال نمایید .
                <br>
                دقت کنید که حتماً تمامی عبارات موجود بر روی کارت‌ و دست‌نوشته خوانا و واضح باشد؛ به تصاویر ناخوانا و بی
                کیفیت ترتیب اثر داده نخواهدشد .
            </p>
        </div>
        <form>
            <img v-if="state.userinfo.imageUploaded" class="img" :src="path" alt="">
            <img v-else-if="!state.userinfo.imageUploaded && !state.userinfo.image " class="img" :src="path" alt="">
            <img :src="state.userinfo.image" alt="">
            <input type="file"
                   name="picture"
                   id="picture"
                   style="display: none"
                   @change="upload"
                   required>
            <label v-if="!idApproved" for="picture" class="normal-btn">بارگذاری تصویر</label>
        </form>
    </div>
</template>

<script>
    export default {
        name: "UploadDocuments",
        props:['idApproved'],
        data() {
            return {
                path: require('@/assets/icon/inputpicture.png')
            }
        },
     
        methods: {
           
            upload(evt) {
                this.state.userinfo.imageUploaded = true
                var file = evt.target.files.item(0);
                console.log(file)
                this.state.userinfo.image = file;
                var fileReader = new FileReader();
                fileReader.addEventListener('load', (event) => {
                    this.path = event.target.result;
                });
                fileReader.readAsDataURL(file);
            }
        }
    }
</script>

<style scoped>

    .box-sub {
        font-family: IRANSans-Bold,sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        text-align: right;
        /*border: #00000015 solid 0.5px;*/
        padding: 10px 31px;
        margin: 20px 0;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        font-size: 14px;
    }

    h2{
        width: 100%;
        font-size: 17px;
        padding: 10px 0;
        margin: 0 1rem;
        border-bottom: 1px solid var(--light-border);
    }


    .form {
        font-family: IRANSans,sans-serif;
        text-align: right;
        margin: 20px 0;
        border-radius: 5px;
        width: 100%;
        font-size: 14px;
    }

    .form p {
        margin: 0 1.916%;
        text-align: center;
        font-size: 16px;
    }

    .form div {
        display: inline-block;
        position: relative;
        box-sizing: inherit;
        width: 29.5%;
        margin: 20px 1.91%;
    }

    form {
        font-family: IRANSans,sans-serif;
        margin: 20px 0;
        width: 40%;
        font-size: 17px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    form img {
        width: 100%;
    }

    .normal-btn {
        font-size: 14px;
        text-align: center;
        width: 100%;
        min-width: 102px;
        max-width: 250px;
        background-color: white;
        color: var(--main-green);
        border: 3px solid var(--main-green);
        box-sizing: border-box;
        border-radius: 10px;
        margin-bottom: 0;
    }
    .normal-btn:hover{
        background-color: var(--main-green);
        color: white;
    }

</style>