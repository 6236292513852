<template>
    <div class="box-sub">
        <h2>اطلاعات بانکی</h2>
        <button @click.prevent="addaccount" class="normal-btn">افزودن حساب جدید</button>
        <form @input="update">
            <div class="accountlist row no-gutters" v-for="(bankaccount,index) in state.userinfo.bankAccountsString" :key='`${index},${bankError}`'>
                <div class="row no-gutters col-6 col-sm-5 p-1 p-sm-3">
                    <!--                    <label class="col-4 pl-1" :for="'shaba'+index">شماره شبا</label>-->
                    <input type="text" style="padding-left: 40px;text-align: left" @input.prevent="numberValidation(index,'sheba')"
                           v-model="state.userinfo.bankAccountsString[index].shebaCode" placeholder="شماره شبا"
                           :name="'shaba'+index" :style="{textAlign: [state.userinfo.bankAccountsString[index].shebaCode? 'left':'right']}"
                           :id="'shaba'+index" maxlength="24">
                    <p class="shaba">-IR</p>
                    <span id="sheba-error" v-if="state.userinfo.duplicateBank && 
                    state.userinfo.duplicateBank.includes(state.userinfo.bankAccountsString[index].shebaCode) === true &&
                    state.userinfo.bankAccountsString[index].shebaCode !== ''">این شماره شبا قبلا ثبت شده است</span>
                </div>
                <div class="row no-gutters col-6 col-sm-5 p-1 p-sm-3">
                    <!--                    <label class="col-4 pl-1" :for="'cardnumber'+index">شماره کارت</label>-->
                    <input @input="(state.userinfo.bankAccountsString[index].cardNumber.length>=6)?
                    Bank(state.userinfo.bankAccountsString[index].cardNumber.replace(/-/ig, ''),index):
                    state.userinfo.bankAccountsString[index].bankName='';dash(index);numberValidation(index,'number')"
                           class="col-12" type="text" placeholder="شماره کارت"
                           v-model="state.userinfo.bankAccountsString[index].cardNumber"
                           :name="'cardnumber'+index" :style="{textAlign: [state.userinfo.bankAccountsString[index].cardNumber? 'left':'right']}"
                           :id="'cardnumber'+index" maxlength="19">
                    <span id="sheba-error" v-if="state.userinfo.duplicateBank && 
                    state.userinfo.duplicateBank.includes(state.userinfo.bankAccountsString[index].cardNumber.replace(/-/ig, '')) === true &&
                    state.userinfo.bankAccountsString[index].shebaCode !== ''">این شماره کارت قبلا ثبت شده است</span>

                </div>
                <div class="row no-gutters col-6 col-sm-2 p-1 p-sm-3 justify-content-end">
                    <!--                    <label class="col-4 pl-1" :for="'bankname'+index">بانک</label>-->
                    <!--                    <input class="col-12" type="text"-->
                    <!--                           v-model="bankaccount.bankname"-->
                    <!--                           :name="'bankname'+index"-->
                    <!--                           :id="'bankname'+index"-->
                    <!--                           readonly>-->
                    <div class="trash" @click.prevent="removeaccount(index)">
                        <img src="../../assets/icon/trash1.png"
                             class="trash-icon" alt=""
                             :id="'trash'+index">
                    </div>
                </div>
            </div>
            <!-- <p style="text-align:center;color:red">{{bankError}}</p> -->
            <!-- <button @click.prevent="check">ckick</button> -->
        </form>
    </div>
</template>

<script>
    export default {
        name: "BankInformation",
        props:['bankInformation','bankError'],
        data() {
            return {
                shaba: '',
                error:'',
                cardnumber: '',
                bankname: '',
                bankaccounts: [
                    {shaba: '', cardnumber: '', bankname: ''}
                ],
                banks: [
                    {id: '603799', name: 'بانک ملی ایران'},
                    {id: '589210', name: 'بانک سپه'},
                    {id: '627648', name: 'بانک توسعه صادرات'},
                    {id: '207177', name: 'بانک توسعه صادرات'},
                    {id: '627353', name: 'بانک تجارت'},
                    {id: '585983', name: 'بانک تجارت'},
                    {id: '627961', name: 'بانک صنعت و معدن'},
                    {id: '603770', name: 'بانک کشاورزی'},
                    {id: '639217', name: 'بانک کشاورزی'},
                    {id: '628023', name: 'بانک مسکن'},
                    {id: '627760', name: 'پست بانک ایران'},
                    {id: '502908', name: 'بانک توسعه تعاون'},
                    {id: '627412', name: 'بانک اقتصاد نوین'},
                    {id: '627412', name: 'بانک اقتصاد نوین'},
                    {id: '622106', name: 'بانک پارسیان'},
                    {id: '639194', name: 'بانک پارسیان'},
                    {id: '627884', name: 'بانک پارسیان'},
                    {id: '502229', name: 'بانک پاسارگاد'},
                    {id: '639347', name: 'بانک پاسارگاد'},
                    {id: '639599', name: 'بانک قوامین'},
                    {id: '504706', name: 'بانک شهر'},
                    {id: '502806', name: 'بانک شهر'},
                    {id: '603769', name: 'بانک صادرات'},
                    {id: '610433', name: 'بانک ملت'},
                    {id: '991975', name: 'بانک ملت'},
                    {id: '589463', name: 'بانک رفاه'},
                    {id: '502938', name: 'بانک دی'},
                    {id: '639607', name: 'بانک سرمایه'},
                    {id: '627381', name: 'بانک انصار'},
                    {id: '505785', name:'بانک ایران زمین'},
                    {id: '636214', name:'بانک آینده'},
                    {id: '636949', name:'بانک حکمت ایرانیان'},
                    {id: '621986', name: 'بانک سامان'},
                    {id: '639346', name: 'بانک سینا'},
                    {id: '606373', name: 'بانک قرض الحسنه مهر'},
                    {id: '627488', name: 'بانک کارآفرین'},
                    {id: '502910', name: 'بانک کارآفرین'},
                    {id: '505416', name: 'بانک گردشگری'},
                    {id: '639370', name: 'بانک مهر اقتصاد'},
                    {id: '628157', name: 'موسسه اعتباری توسعه'},
                    {id: '505801', name: 'موسسه اعتباری کوثر'},
                ]
            }
        },
        methods: {
            numberValidation(index,name){
                // console.log(index,name);
                if (name === 'sheba'){
                    this.state.userinfo.bankAccountsString[index].shebaCode =
                    this.state.userinfo.bankAccountsString[index].shebaCode.replace(/[^0-9]/ig,'')
                }else{
                    this.state.userinfo.bankAccountsString[index].cardNumber =
                    this.state.userinfo.bankAccountsString[index].cardNumber.replace(/[^0-9 -]/ig,'')
                }
            },
            check(){
                console.log(this.bankaccounts.cardnumber)

            },
            Bank(cardnumber, index) {
                console.log('navid')
                var a = 0;
                this.banks.forEach(element => {
                    if (cardnumber.startsWith(element.id)) {
                        this.state.userinfo.bankAccountsString[index].bankName = element.name
                        a = 1;
                    }
                })
                if (!a) {
                    this.state.userinfo.bankAccountsString[index].bankName = 'کارت نا معتبر'
                }
                // switch (cardnumber[0-5]) {
                //     case 0:
                //         day = "Sunday";
                //         break;
                // }
            },
            addaccount() {
                 this.state.userinfo.bankAccountsString.push({
                    shebaCode: '',
                    cardNumber: '',
                    bankName: ''
                })
            },
            removeaccount(e) {
                this.state.userinfo.bankAccountsString.splice(e, 1)
            },
            update() {
               
            },
            dash(e) {
                console.log('here bank number');
                var a = this.state.userinfo.bankAccountsString[e].cardNumber.replace(/-/ig, '')
                const d = a.length / 4
                for (let i = 1; i < d; i++) {
                    // console.log(i)
                    a = a.slice(0, (i * 4) + (i - 1)) + "-" + a.slice((i * 4) + (i - 1));
                }
                this.state.userinfo.bankAccountsString[e].cardNumber = a
                
            },
            dashFromBack(item){
                for (let i=0;i<=item.length-1;i++){
                    console.log(item[i].cardNumber)
                    // this.state.userinfo.bankAccounts[i].cardNumber = 
                }
            }
        },
        watch:{
            bankInformation:function(val){
                console.log(val)
                this.Bank(this.state.userinfo.bankAccountsString[0].cardNumber.replace(/-/ig, ''),0)
                this.$forceUpdate()
            },
            bankError:function(val){
                console.log(val)
            }
        }
    }
</script>

<style scoped>
    .trash {
        border: 1px solid rgba(54, 52, 53, 0.15);
        width: 50px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        cursor: pointer;
    }
    .trash:hover{
        border-color: rgba(54, 52, 53, 0.3);
    }

    /*@media (max-width: 575px) {*/
    /*    .trash {*/
    /*        bottom: 19px;*/
    /*        left: 10px;*/
    /*    }*/
    /*}*/

    .normal-btn {
        font-size: 12px;
        position: absolute;
        left: calc(15px + 1rem);
        bottom: 10px;
        border-radius: 5px;
        width: calc(50% - 15px - 0.5rem);
        max-width: 250px;
        margin: 0;
        /*margin-left: 1.3%;*/
    }

    .box-sub {
        font-family: IRANSans-Bold, sans-serif;
        position: relative;
        display: block;
        background: #FFFFFF;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        text-align: right;
        /*border: #00000015 solid 0.5px;*/
        padding: 10px 15px;
        margin: 20px 0;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        font-size: 17px;
        /*color: var(--font-color);*/
    }

    h2 {
        font-size: 17px;
        padding: 10px 0;
        margin: 0 1rem;
        border-bottom: 1px solid var(--light-border);
    }

    form {
        font-family: VistaDev, sans-serif;
        position: relative;
        display: block;
        background-color: transparent;
        text-align: right;
        margin: 20px 0 50px;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        font-size: 14px;
    }

    form div {
        width: 100%;
    }

    form div div {
        margin: 0 !important;
    }

    /*.box-sub label {*/
    /*    display: flex;*/
    /*    align-items: center;*/
    /*    min-width: 84px;*/
    /*}*/

    .box-sub input {
        position: relative;
        border: solid rgba(54, 52, 53, 0.15) 0.5px;
        display: inline-block;
        height: 50px;
        border-radius: 5px;
        padding: 2px 5px;
        width: 100%;
        direction: ltr;
        /*max-width: 66.666667%;*/
    }

    /*@media (max-width: 911px) {*/
    /*    .box-sub input {*/
    /*        max-width: 100%;*/
    /*    }*/
    /*}*/

    .box-sub input:hover {
        border: solid rgba(54, 52, 53, 0.3) 0.5px;
    }

    .box-sub input:focus {
        border: solid rgba(54, 52, 53, 0.5) 0.5px;
    }


    .accountlist {
        width: 100%;
        margin: 0;
        padding: 0;
        justify-content: flex-start;
    }

    .accountlist div:last-of-type {
        display: flex;
        position: relative;
    }

    .shaba {
        /*color: var(--font-color);*/
        position: absolute;
        left: 1.5rem;
        bottom: 50%;
        transform: translateY(50%);
        height: 25px;
        width: 25px;
        margin: 0;
        font-size: 18px;
    }
    #sheba-error{
        position: absolute;
        top: 85%;
        color: red;
    }

    @media (max-width: 575px) {
        .shaba {
            left: 0.75rem;
        }

        .normal-btn {
            left: calc(15px + 0.25rem);
        }

        .trash{
            width: 100%;
        }
    }
</style>