<template>
    <div class="box-sub">
        <div @click.self="showMail = false" v-if="showMail" class="main-modal">
            <TwoStepEmailModal @submited="check"/>
        </div>
        <div @click.self="showSms = false" v-if="showSms" class="main-modal">
            <TwoStepSmsModal @submited="check"/>
        </div>
        <div @click.self="showGoogle = false" v-if="showGoogle" class="main-modal">
            <TwoStepGoogleAuthModal @submited="check" :googleImage='googleQR' :url='googleUrl'/>
        </div>

        <h2>شناسایی دو عاملی</h2>
        <form class="row no-gutters">
            <a class="col-12 col-lg-6">تایید هویت دو مرحله ای جهت ورود به حساب کاربری و درخواست برداشت</a>
            <div class="col-12 col-lg-6 row no-gutters">
                <label class="col-12 col-sm-auto" for="twostepemail">
                    
                    <input @change="showModal('EMAIL')" type="radio" id="twostepemail" v-model="twostep" value="EMAIL">
                    <svg fill="var(--main-green)" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" width="22px" height="22px"
                         viewBox="0 0 510 510" style="enable-background:new 0 0 510 510;" xml:space="preserve">
                        <g id="radio-button-on" v-if="twostep==='EMAIL'">
                            <path d="M255,127.5c-71.4,0-127.5,56.1-127.5,127.5c0,71.4,56.1,127.5,127.5,127.5c71.4,0,127.5-56.1,127.5-127.5
                            C382.5,183.6,326.4,127.5,255,127.5z M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z
                            M255,459c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z"/>
                        </g>
                        <g id="radio-button-off" v-if="twostep!=='EMAIL'">
                            <path d="M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z M255,459
                            c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z"/>
                        </g>
                    </svg>
                    ایمیل
                </label>
                <label class="col-12 col-sm-auto" for="twostepesms">
                    <input @change="showModal('SMS')" type="radio" id="twostepesms" v-model="twostep" value="SMS">
                    <svg fill="var(--main-green)" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="22px" height="22px"
                         viewBox="0 0 510 510" style="enable-background:new 0 0 510 510;" xml:space="preserve">
                        <g id="radio-button-on" v-if="twostep==='SMS'">
                            <path d="M255,127.5c-71.4,0-127.5,56.1-127.5,127.5c0,71.4,56.1,127.5,127.5,127.5c71.4,0,127.5-56.1,127.5-127.5
                            C382.5,183.6,326.4,127.5,255,127.5z M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z
                            M255,459c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z"/>
                        </g>
                        <g id="radio-button-off" v-if="twostep!=='SMS'">
                            <path d="M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z M255,459
                            c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z"/>
                        </g>
                    </svg>
                    پیامک
                </label>
                <label class="col-12 col-sm-auto" for="googleauth">
                    <input @change="showModal('GOOGLE_AUTHENTICATOR')" type="radio" id="googleauth" v-model="twostep" value="GOOGLE_AUTHENTICATOR">
                    <svg fill="var(--main-green)" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="22px" height="22px"
                         viewBox="0 0 510 510" style="enable-background:new 0 0 510 510;" xml:space="preserve">
                        <g id="radio-button-on" v-if="twostep==='GOOGLE_AUTHENTICATOR'">
                            <path d="M255,127.5c-71.4,0-127.5,56.1-127.5,127.5c0,71.4,56.1,127.5,127.5,127.5c71.4,0,127.5-56.1,127.5-127.5
                            C382.5,183.6,326.4,127.5,255,127.5z M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z
                            M255,459c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z"/>
                        </g>
                        <g id="radio-button-off" v-if="twostep!=='GOOGLE_AUTHENTICATOR'">
                            <path d="M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z M255,459
                            c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z"/>
                        </g>
                    </svg>
                    شناسایی دوعاملی گوگل
                </label>
            </div>
        </form>

        <div v-if="showToastVar" class="main-toast">
            <div class="toast">
                <h4>اطلاعات شما با موفقیت ذخیره شد.</h4>
                <span id="toast-line"></span>
            </div>
        </div>

    </div>
</template>

<script>
    import TwoStepEmailModal from './TwoStepEmailModal';
    import TwoStepGoogleAuthModal from './TwoStepGoogleAuthModal'
    import TwoStepSmsModal from './TwoStepSmsModal'
    export default {
        name: "SetTwoStepAuth",
        components:{TwoStepEmailModal,TwoStepGoogleAuthModal,TwoStepSmsModal},
        data() {
            return {
                twostep: 'EMAIL',
                showMail:false,
                showSms:false,
                showGoogle:false,
                showToastVar:false,
                toastWidth:0,
                googleUrl:'',
                googleQR:'not google qrcode'
            }
        },
        mounted(){
            // console.log(this.state.userinfo);
            this.twostep = this.state.userinfo.towStepAuthenticationType
        },
        methods:{
            check(item){
                console.log(item)
                if (item === 'show toast'){
                    this.showSms = false;
                    this.showMail = false;
                    this.showGoogle = false;
                    this.showToastVar = true;
                    this.showToast()
                    console.log('should show',this.showToastVar)
                }
            },
            showToast(){
                console.log('navid')
                let a = setInterval(()=>{
                    
                    this.toastWidth = this.toastWidth + 1
                    document.getElementById('toast-line').style.width = `${this.toastWidth}px`
                    if (this.toastWidth>=322){
                        clearInterval(a)
                        this.showToastVar = false
                        this.toastWidth = 0
                    }
                }
                ,15)
            },
            showModal(type){
                // console.log('send from two step')
                this.$axios.post(`/users/send-verification-code?twoStepAuthorization=${type}`)
                    .then(response=>{
                        // console.log(response.data)
                        this.res = response.data.message
                        // console.log(this.res)
                        this.state.mobilenumber = response.data.baseDTO.starTwoStepAuthentication
                        // console.log(this.state.mobilenumber)
                        if (this.res === 'Verification Code Sent'){
                        // console.log('res recieved',type)
                            if (type === 'EMAIL'){   
                            this.showMail = true
                            }else if(type === 'SMS'){
                                this.showSms = true
                            }else if(type === 'GOOGLE_AUTHENTICATOR'){
                                // console.log(response)
                                this.googleQR = response.data.baseDTO.file;
                                this.googleUrl = response.data.baseDTO.qrCodeLink
                                // console.log('from auth',this.googleQR)
                                this.$forceUpdate()
                                this.showGoogle = true
                            }
                        }   
                    })
                    // .catch(error=>{
                    //     console.log(error)
                    // })

                
                
            }
        }
    }
</script>

<style scoped>

    .box-sub {
        font-family: IRANSans, sans-serif;
        position: relative;
        display: block;
        background-color: white;
        text-align: right;
        border-bottom: #00000015 solid 1px;
        padding: 10px 31px 25px;
        margin: 20px 0;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        font-size: 16px;
    }

    h2{
        font-size: 17px;
        padding: 10px 0;
        margin: 0;
        border-bottom: 1px solid var(--light-border);
    }

    p {
        margin-top: 22px;
    }

    form {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        text-align: justify;
    }

    form div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    form label {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 10px 0;
        border: 1px solid var(--main-green);
        border-radius: 10px;
        padding: 5px 20px !important;
        box-sizing: border-box;
        color: var(--main-green);
    }

    input {
        display: none;
        cursor: pointer;
    }

    label svg{
        margin-left: 10px;
    }
    .main-modal{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        backdrop-filter: blur(3px);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        margin-right: -40px;
    }
    .main-modal>div{
        height: auto;
        border-radius: 10px;
        box-shadow: 0 0 5px 5px #f1f1f1;
    }
    @media screen and (max-width: 768px){
        .main-modal{
            flex-direction: column;
            justify-content: flex-start;
            overflow: auto;
            inset: 0;
            margin-right: 0;
        }
    }

    .main-toast{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        /* backdrop-filter: blur(3px); */
        margin-right: -40px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
    }

    .toast{
        width: 350px;
        height: 100px;
        background: #fff;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        box-shadow: 2px 2px 2px 2px #efebeb;
        overflow: hidden;
    }

    .toast>span{
        display: inline-block;
        position: absolute;
        bottom: 0;
        right: 14px;
        background: rgb(152, 194, 90);
        width: 0;
        height: 3px;
        border-radius: 50px;
    }
</style>