<template>
    <div class="Auth-box">
        <div class="Auth-header">
            <h3>شناسایی دو عاملی</h3>
        </div>
        <div class="Auth">
            <p>کد ۶ رقمی که به ایمیل {{$cookies.get('username')}} ارسال شده است را وارد نمایید.</p>
            <!-- <CodeInput class="auth-row"
                       @change="onChange" @complete="submit"
                       :fields="num" ref="code-input" :fieldWidth="45" :fieldHeight="45"/> -->
            <input class="auth-row new-input" type="text" v-model="code" maxlength="6">
            <div class="footer">

                <a v-if="countDown===0" @click.prevent="send" style="cursor: pointer" class="countdown">ارسال مجدد</a>
                <a v-else style="opacity:0.5;cursor:default" class="countdown">ارسال مجدد</a>
                <a style="text-decoration: none" class="countdown">{{countDown}} ثانیه </a>
            </div>
        </div>
        <!--<label class="label-code">
            <input class="input-code" v-model="test" maxlength="6" placeholder="&#45;&#45;&#45;&#45;&#45;&#45;">
        </label>-->
        <button class="normal-btn" @click.prevent="submit" :disabled="code.toString().length < num || countDown === 0">
            تایید
        </button>
        <a style="opacity: 0.8;color:red">{{error}}</a>
        <Loading v-if="showLoading"/>
    </div>
</template>

<script>
    import Loading from '../Tools/Loading';
    // import CodeInput from "vue-verification-code-input";

    export default {
        name: "TwoStepEmail",
        props: ['query'],
        data() {
            return {
                countDown: 120,
                smscode: [],
                error: '',
                num: 6,
                code: '',
                showLoading: false,
                forEmailOtp: '',
                res: '',
                isSent: false,
                OSName: 'Unknown',
                borwserType: '',
                deviceType: '',
                test: ''
            }
        },
        components: {
            Loading,
            // CodeInput
        },
        methods: {

            countDownTimer() {
                if (this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown--
                        this.countDownTimer()
                    }, 1000)
                } else {
                    this.error = 'زمان شما به اتمام رسید'
                }
            },
            // setPlaceholder() {
            //     for (let i = 0; i < this.num; i++) {
            //         this.$refs['code-input'].$refs['input_' + i][0].placeholder = '----'
            //     }
            // },
            // onChange(e) {
            //     this.code = e
            // },
            // next(e) {
            //     this.sortNumber()
            //     if (this.smscode[e - 1]) {
            //         if (e < this.num) {
            //             document.getElementById('auth' + e).focus();
            //         }
            //     } else {
            //         if (e > 1) {
            //             document.getElementById('auth' + (e - 2)).focus();
            //         }
            //     }
            // },
            // sortNumber() {
            //     var b = ''
            //     for (let i = 0; i < this.smscode.length; i++) {
            //         b = b + this.smscode[i]
            //     }
            //     this.code = Number(b)
            // },
            async send() {
                this.smscode = []
                this.state.email = this.$cookies.get('username')
                await this.$axios.post(`/users/resend-otp-code?email=${this.$cookies.get('username')}`)
                    .then(() => {
                        this.$error('کد جدید ارسال شد.', '', 'success')
                        this.$cookies.set('username', this.state.email)
                        // console.log(res,'second res');
                        this.isSent = true
                    })
                    .catch(err => {
                        console.log(err);
                    })
                if (this.countDown === 0 && this.isSent) {
                    this.countDown = 120
                    this.error = ''
                    this.isSent = false
                    this.countDownTimer()
                }

            },
            submit() {
                if (!(this.code.toString().length < this.num || this.ountDown === 0)) {
                    this.error = ''
                    var EmailOtp = {
                        'code': this.code,
                        'email': this.$cookies.get('username')
                    }
                    this.showLoading = true
                    this.$axios.post('/users/check-otp-code', EmailOtp)
                        .then(response => {
                            this.showLoading = false
                            if (response.data.token) {
                                localStorage.token = response.data.token;
                                this.state.token = localStorage.token
                            }
                            localStorage.token = response.data.token;
                            this.state.token = localStorage.token
                            if (this.state.token !== '') {
                                this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token
                                this.$router.push('trade')
                            }
                        })
                        .catch(error => {
                            this.showLoading = false
                            if (error.response) {
                                if (error.response.data.message === 'Code Expired') {
                                    this.error = 'کد شما منقضی شده است.'
                                } else if (error.response.data.message === 'Incorrect OTP') {
                                    this.error = 'کد شما اشتباه است.'
                                }
                            }
                        })
                }
            },
            detectOs() {
                var Name = "Unknown OS";
                if (navigator.userAgent.indexOf("Win") != -1) Name =
                    "WINDOWS";
                if (navigator.userAgent.indexOf("Mac") != -1) Name =
                    "MACINTOSH";
                if (navigator.userAgent.indexOf("Linux") != -1) Name =
                    "LINUX";
                if (navigator.userAgent.indexOf("Android") != -1) Name =
                    "ANDROID";
                if (navigator.userAgent.indexOf("like Mac") != -1) Name = "IOS";
                this.OSName = Name
            },
            detectBrowser() {
                // Opera 8.0+
                var isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
                if (isOpera) {
                    this.borwserType = 'OPERA'
                }

                // Firefox 1.0+
                var isFirefox = typeof InstallTrigger !== 'undefined';
                if (isFirefox) {
                    this.borwserType = 'FIREFOX'
                }

                // Safari 3.0+ "[object HTMLElementConstructor]" 
                var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
                    return p.toString() === "[object SafariRemoteNotification]";
                })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
                if (isSafari) {
                    this.borwserType = 'SAFARI'
                }
                // Internet Explorer 6-11
                var isIE = /*@cc_on!@*/false || !!document.documentMode;
                if (isIE) {
                    this.borwserType = 'IE'
                }
                // Edge 20+
                var isEdge = !isIE && !!window.StyleMedia;
                if (isEdge) {
                    this.borwserType = 'EDGE'
                }
                // Chrome 1 - 79
                var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
                if (isChrome) {
                    this.borwserType = 'CHROME'
                }
                // Edge (based on chromium) detection
                var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
                if (isEdgeChromium) {
                    this.borwserType = 'EDGECHROMIUM'
                }
                if (this.borwserType === '') {
                    this.borwserType = 'CHROME'
                }
            },
            detectDevice() {
                const ua = navigator.userAgent;
                if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
                    this.deviceType = 'TABLET'
                }
                if (
                    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                        ua
                    )
                ) {
                    this.deviceType = 'MOBILE'
                } else {
                    this.deviceType = 'DESKTOP'
                }
            }
        },
        created() {
            this.countDownTimer();
        },
        mounted() {
            // this.setPlaceholder()
        }
    }
</script>

<style lang="scss" scoped>

    input {
        padding: 5px 0;
    }

    .Auth-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 400px;
        height: 100%;
    }

    .Auth {
        margin-bottom: 70px;
    }

    .countdown {
        font-size: 14px;
        text-decoration: underline;
    }

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
    }

    .auth-row {
        display: flex;
        direction: ltr;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 45px;
        margin-top: 10px;
        border: 1px solid var(--light-border);
        border-radius: 15px;
    }

    .Auth-input {
        font-size: 25px;
        border-radius: 5px;
        /*border: solid 1px #00000010;*/
        width: 40px;
        height: 100%;
        text-align: center;
        letter-spacing: -2px;
        /*padding: 0;*/

        &:focus {
            border-color: var(--main-orange);
        }
    }

    p {
        margin: 20px 0 20px;
        text-align: right;
    }

    .normal-btn {
        margin-bottom: 80px;
    }
</style>
<style lang="scss">
    .auth-row {
        width: 100% !important;

        .react-code-input {
            border: none;

            input {
                border: none !important;
                color: var(--font-color3);
                font-family: 'VistaDev', sans-serif !important;
                font-size: 25px;
                letter-spacing: -2px;
                text-align: center !important;
            }
        }
    }

    .label-code {
        border: 1px solid #000;
        display: flex;
        justify-content: center;
        width: 90%;
        border-radius: 15px;
        .input-code {
            width: 100%;
            max-width: 220px;
            text-align: center;
            font-size: 25px;
            letter-spacing: 20px;
            &::placeholder{
                letter-spacing: 10px;
                font-size: 40px;
            }
            &:not(:placeholder-shown){
                background-color: red;
            }
            /*padding-left: 10px;*/
            /*background-image: linear-gradient(#00000080,#00000080);*/
            /*background-size: 25px 3px;*/
            /*background-position: left center;*/
            /*background-repeat: no-repeat;*/

            /*$image_url: linear-gradient(#00000080,#00000080);
            $image_position : right 0 center;
            $offset:15px;

            background-image: $image_url,$image_url,$image_url,$image_url,$image_url,$image_url;
            background-position: right (0 + $offset) center,
            right (35px + $offset) center,
            right (70px + $offset) center,
            right (105px + $offset) center,
            right (140px + $offset) center,
            right (175px + $offset) center;*/
        }
    }

</style>