<template>
  <div class="main">
    <div class="box">
      <h1>تاریخچه کیف پول ها</h1>
      <div class="box1">
        <h2>کیف پول ها</h2>
        <!-- <button @click="getWalletCredit">click</button> -->
        <my-wallet
          @deposit="depositModal($event)"
          @withdraw="withdrawModal($event)"
          :myWalletData="toMyWallet"
          @trade="gototrade($event)"
        />
      </div>
    </div>
    <withdraw-modal
      :wallet="walet"
      v-show="showWithModal"
      @close="closemodal"
      id="withmodal"
    />
    <withdraw-modal-rial
      :walletamount="rialamount"
      v-show="showRWithModal"
      @close="closemodal"
      :banks="bankCardNumbers"
      id="withmodalR"
    />
    <deposit-modal
      :wallet="walet"
      :walletaddress="depositWalletaddress"
      :walletqrcode='walletimage'
      @update-wallet="getWalletCredit"
      v-show="showDepModal"
      @close="closemodal"
      id="depmodal"
    />
    <deposit-modal-rial
      :walletamount="rialamount"
      v-show="showRDepModal"
      @close="closemodal"
      :banks="bankCardNumbers"
      id="depmodalR"
    />
    <!-- <button @click="getHistory">click</button> -->
    <wallet-history/>
    <Loading v-if="showLoading"/>
    <div @click.self="showMail = false" v-if="showMail" class="main-modal">
      <TwoStepEmailModal @submited="checktransactoin" />
    </div>
    <div @click.self="showSms = false" v-if="showSms" class="main-modal">
      <TwoStepSmsModal @submited="checktransactoin" />
    </div>
    <div @click.self="showGoogle = false" v-if="showGoogle" class="main-modal">
      <TwoStepGoogleModal @submited="checktransactoin" />
    </div>
    <ToastNotificatoin />
  </div>
</template>

<script>
import ToastNotificatoin from "../Tools/ToastNotification";
import WalletHistory from "./WalletHistory";
import WithdrawModal from "./WithdrawModal";
import WithdrawModalRial from "./WithdrawModalRial";
import DepositModal from "./DepositModal";
import DepositModalRial from "./DepositModalRial";
import MyWallet from "./MyWallet";
import TwoStepEmailModal from "./TwoStepEmailModal";
import TwoStepSmsModal from './TwoStepSmsModal';
import TwoStepGoogleModal from './TwoStepGoogleModal'
import Loading from '../Tools/Loading'

export default {
  name: "Wallet",
  components: {
    MyWallet,
    DepositModalRial,
    DepositModal,
    WithdrawModalRial,
    WithdrawModal,
    WalletHistory,
    TwoStepEmailModal,
    ToastNotificatoin,
    Loading,
    TwoStepSmsModal,
    TwoStepGoogleModal
  },
  props: {
    tab: {
      default: "withdraw",
    },
    id: {
      default: 1,
    },
  },
  data() {
    return {
      coinsList:
        "ETHEREUM,BITCOIN_CASH,RIPPLE,TETHER,TOMAN,BITCOIN,LITE_COIN,DASH",
      coinsList2:
        "coins=BITCOIN&coins=ETHEREUM&coins=BITCOIN_CASH&coins=TRON&coins=RIPPLE&coins=TETHER",
      toMyWallet: "",
      diposite: false,
      withraw: true,
      showWithModal: false,
      showDepModal: false,
      showRWithModal: false,
      showRDepModal: false,
      showMail: false,
      showSms: false,
      showGoogle: false,
      showLoading:false,
      showModalLoading:false,
      bankCardNumbers: [],
      walet: "",
      rialamount: "",
      depositWalletaddress:'',
      walletimage:'',
      wallets: [],
      coin: {
        BITCOIN: "بیتکوین",
        ETHEREUM: "اتریوم",
        BITCOIN_CASH: "بیتکوین کش",
        TRON: "ترون",
        RIPPLE: "ریپل",
        TETHER: "تتر",
        DASH:'دش',
        LITE_COIN:'لایت کوین',
        DOGE_COIN:'دوج',
        CLASSIC_ETHEREUM:'اتریوم کلاسیک'
      },
    };
  },
  methods: {
    
    getWalletCredit() {
      console.log("getWalletCredit");
      this.$axios(`/wallets/customer-addresses`)
        .then((res) => {
          console.log(res);
          this.toMyWallet = res.data;
          for (let i in this.toMyWallet) {
              // console.log(i,this.toMyWallet[i]);
            this.toMyWallet[i].engName = i;
            console.log(i);
            if (i === 'TOMAN'){
              this.toMyWallet[i].name = 'ریال'
              this.toMyWallet[i].relatedCoin = 'TOMAN'
              this.toMyWallet[i].wholePriceOfCredit = 0
              this.toMyWallet[i].credit = 0
            }else{
              this.toMyWallet[i].name = this.coin[this.toMyWallet[i].relatedCoin];
            }
            
          }
        })
    },
    checktransactoin(val) {
      // console.log("check two step", val);
      this.showMail = false;
      this.showSms = false;
      this.showGoogle = false;
      this.showModalLoading = true
      if (val === "success") {
        this.state.toastMessage = "تراکنش شما با موفقیت ثبت شد.";
        this.state.showToast = true;
      }
    },
    async depositModal(e) {
      // console.log(this.toMyWallet,e);

      if (this.toMyWallet[e].name !== "ریال") {
        this.showLoading = true
        await this.$axios(`/wallets/customer/wallet-address?relatedCoin=${e}`)
          .then(res=>{
            this.showLoading = false
            // console.log(res)
            this.depositWalletaddress = res.data.baseDTO.address
            this.walletimage = res.data.baseDTO.qrCode
             this.walet = this.toMyWallet[e];
            this.showDepModal = true;
          })
          .catch(()=>{
            this.showLoading = false
          })
       
      } else {
        this.showLoading = true
        let cards = [];
        await this.$axios(`/users/verified-bank-accounts`)

          .then((res) => {
            this.showLoading = false
            // console.log(res.data.content);
            for (let i = 0; i < res.data.content.length; i++) {
              cards.push(res.data.content[i].cardNumber);
            }
            // console.log(cards);
            this.bankCardNumbers = cards;
            this.showRDepModal = true;
          })
          .catch(() => {
            this.showLoading = false
          })
          
      }
    },
    async withdrawModal(e) {

      if (this.toMyWallet[e].name !== "ریال") {
        this.walet = this.toMyWallet[e];
        this.showWithModal = true;
      } else {
        this.showLoading = true
        let cards = [];
        await this.$axios(`/users/verified-bank-accounts`)
          .then((res) => {
            this.showLoading = false
            for (let i = 0; i < res.data.content.length; i++) {
              cards.push(res.data.content[i].cardNumber);
            }
            this.bankCardNumbers = cards;
            this.showRWithModal = true;
            this.rialamount = this.toMyWallet[e].totalBalance;
            console.log(this.rialamount);
          })
          .catch((err) => {
            this.showLoading = false
            console.log(err);
          })
      }
    },
    closemodal(val, type) {
      this.showWithModal = false;
      this.showDepModal = false;
      this.showRWithModal = false;
      this.showRDepModal = false;
      // console.log(val,'close modall');
      if (val === "open") {
        console.log("show otp modal", "its type", type);
        if (type === "EMAIL") {
          this.showMail = true;
        } else if (type === "SMS") {
          this.showSms = true;
        } else {
          this.showGoogle = true;
        }
      }
    },
    gototrade(e) {
      this.$router.push("/trade/0/" + (e - 1));
    },
  },
  mounted() {
    this.getWalletCredit();
  },
};
</script>

<style scoped>
.main {
  background-color: transparent;
  text-align: right;
  /* padding: 0 5px; */
  margin-top: 56px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1390px;
}
.box {
  background-color: transparent;
  text-align: right;
  margin-top: 10px;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  font-size: 14px;
}

h1 {
  font-size: 22px;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-right: 10px;
}

.box1 {
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  padding: 10px 2.5%;
  margin: 20px 0;
}

h2 {
  width: 100%;
  font-size: 17px;
  padding: 10px 0;
  margin: 0 0 20px;
  border-bottom: 1px solid var(--light-border);
}

.main-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* margin-right: -40px; */
}
.main-modal > div {
  height: auto;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px #f1f1f1;
}
</style>