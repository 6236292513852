<template>
    <transition name="modal-fade">
        <div id="modal" class="modal-backdrop">
            <div class="modal"
                 role="dialog"
                 aria-labelledby="WithdrawModal"
                 aria-describedby="WithdrawModal">
                <img
                        src="../../assets/icon/close.svg"
                        class="btn-close"
                        @click="close"
                        aria-label="Close modal">
                <img>
                <header class="modal-header">
                    لغو درخواست
                </header>
                <section class="modal-body" style="direction: rtl">
                    <img width="150px" src="../../assets/icon/trash.svg">
                    <div class="row justify-content-center" style="font-size: 16px">
                        ایا از لغو درخواست خود مطمئن هستید؟
                    </div>
                </section>
                <footer class="modal-btn">
                    <button type="button" class="normal-btn" @click="close">خیر</button>
                    <button type="button" class="normal-btn" @click="remove">بله</button>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "acceptModal",
        data() {
            return {
                amount: '',
                twostepcode: '',
                walletaddress: '0x40beE7b4e50d756C57B4e59693905D36B9cCD1B6',
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
            remove(){
                this.$emit('remove');
                this.$emit('close');
            }
        },
    }
</script>

<style scoped>

    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
    }

    .modal {
        position: relative;
        background: var(--account-back);
        border-radius: 5px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 950px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        /*padding: 10px 10px;*/
    }

    .modal-header {
        color: white;
        font-size: 20px;
        height: 70px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10%;
    }

    .btn-close {
        position: absolute;
        left: 15px;
        top: 15px;
        height: 22px;
        width: 22px;
        transition: 0.5s;
        cursor: pointer;
        z-index: 5;
    }

    .btn-close:hover {
        transform: rotate(180deg);
    }

    .modal-body {
        /*margin-top: 20px;*/
        position: relative;
        font-size: 16px;
        background-color: white;
        /*border-top-right-radius: 5px;*/
        /*border-top-left-radius: 5px;*/
        padding: 20px 10% 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .modal-body div {
        margin: 20px 0;
    }

    .modal-fade-enter,
    .modal-fade-leave-active {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .25s ease;
    }


    .modal-btn {
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: white;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .normal-btn {
        font-size: 16px;
        color: white;
        max-width: 150px;
        margin: 0 15px;
        border-radius: 5px;
        height: 40px;
        padding: 0;
    }

</style>
